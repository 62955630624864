import { ClientDetails, HoldingDetails, ReactQueryDataFetch } from 'common/types';
import MuiTable from 'common/components/table/Table';
import { useReactQuery } from 'store/hooks';
import { HOLDINGS_COLUMNS } from './constants';

const ClientHoldings = (props: { clientId: string; clientData: ClientDetails }) => {
  const { data = [], isLoading } = useReactQuery([`clientHoldings-${props.clientId}`], {
    url: `qaip/v1/holdingsmanagement/holdings/investor/${props.clientId}`,
  }) as { data: [HoldingDetails] } & ReactQueryDataFetch;

  return (
    <MuiTable
      isLoading={isLoading}
      title={`${data?.length} Clients`}
      rows={data}
      columns={HOLDINGS_COLUMNS}
      disableHeader
      ctaType='addOrRedeem'
      clientData={props.clientData}
    />
  );
};

export default ClientHoldings;
