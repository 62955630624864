export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Activity Logs',
  },
];

export const FUND_COLUMNS = [
  {
    id: 'fund_name',
    label: 'Fund Name',
    needsFormatter: false,
    width: '30%',
  },
  {
    id: 'action_performed',
    label: 'Action Performed',
    needsFormatter: false,
    width: '18%',
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',
    needsFormatter: false,
    width: '12%',
  },
  {
    id: 'time_of_action',
    label: 'Time of Action',
    needsFormatter: false,
    isDate: true,
    width: '25%',
  },
];

export const ORDER_COLUMNS = [
  {
    id: 'order_id',
    label: 'Order ID',
    needsFormatter: false,
    width: '8%',
  },
  {
    id: 'dealer_group',
    label: 'Dealer Group',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'fund',
    label: 'Fund',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'investor',
    label: 'Investor Name',
    needsFormatter: false,
    width: '12.5%',
  },
  {
    id: 'action_performed',
    label: 'Action Performed',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',
    needsFormatter: false,
    width: '14.5%',
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',
    needsFormatter: false,
    width: '12.5%',
  },
  {
    id: 'time_of_action',
    label: 'Time of Action',
    needsFormatter: false,
    isDate: true,
    width: '12.5%',
  },
];

export const CLIENT_COLUMNS = [
  {
    id: 'investor_account_name',
    label: 'Account Name',
    needsFormatter: false,
  },
  {
    id: 'dealer_group',
    label: 'Dealer Group',
    needsFormatter: false,
  },
  {
    id: 'team',
    label: 'Team',
    needsFormatter: false,
  },
  {
    id: 'action_performed',
    label: 'Action Performed',
    needsFormatter: false,
  },
  {
    id: 'action_performed_by',
    label: 'Action Performed By',
    needsFormatter: false,
  },
  {
    id: 'behalf_of',
    label: 'Behalf Of',
    needsFormatter: false,
  },
  {
    id: 'timeFormatted',
    label: 'Time of Action',
    needsFormatter: false,
    isDate: true,
  },
];

export const CLIENT_APPROVAL_COLUMNS = [
  {
    id: 'account_name',
    label: 'Account Name',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'account_number',
    label: 'Account Number',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'entity_type',
    label: 'Entity Type',
    needsFormatter: false,

    width: '25%',
  },
  {
    id: 'status',
    label: 'Status',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'lastUpdatedFormatted',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
    width: '20%',
  },
];
