import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { ErrorMessage } from 'common/components/errorMessageBox';
import SelectField from 'common/components/inputField/SelectField';
import InputField from 'common/components/inputField/Textbox';
import {
  ClientType,
  ClientDetails as ClientDetailsType,
  ReactQueryDataFetch,
  QueryType,
} from 'common/types';
import { getClientEligibility } from 'helpers/client';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { selectClientDetails, selectNewInvestorId } from 'store/investor/selectors';
import { setClientDetails, setNewInvestorId } from 'store/investor/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import { ReactComponent as CircleArrowIcon } from 'common/assets/images/CTA/CircleArrow.svg';
import { useQueryClient } from 'react-query';
import ConfirmClientModal from 'components/modals/ConfirmClientModal';
import LoadingButton from 'common/components/button/LoadingButton';

const StyledCard = styled(MuiCard)`
  h2 {
    font-size: 22px;
  }
`;

type Props = {
  inEditing: boolean;
  submitToNextTab: () => void;
  handleModalClose: () => void;
};

type AdvisorProps = {
  userId: string;
  userName: string;
  firstName?: string;
  lastName?: string;
};

const Step1 = ({ inEditing, submitToNextTab, handleModalClose }: Props) => {
  const queryClient = useQueryClient();
  const [confirmClientOpen, setConfirmClientOpen] = useState<boolean>(false);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const userTeam = user?.teamsRolesDetails[0];

  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  let confirmDetails = false;
  const dispatch = useTypedDispatch();

  const location = useLocation();
  const id = location.pathname.split('/')[2];

  const storeClientDetails = useAppSelector(selectClientDetails);
  const storeNewInvestorId = useAppSelector(selectNewInvestorId);

  const { data: clientDetails, refetch } = useReactQuery(
    [`clientDetails-${id ?? storeNewInvestorId}`],
    {
      url: `qaip/v1/investormanagement/investors/id/${id ?? storeNewInvestorId}`,
    },
    {
      enabled: !!id,
      refetchOnMount: true,
    },
  ) as { data: ClientDetailsType } & ReactQueryDataFetch;

  const { data: masterData } = useReactQuery([`fetchMasterData${user?.userId}`], {
    url: `qaip/v1/investormanagement/investors/masterData`,
  });

  const qp: boolean | undefined = clientDetails?.qualified_purchaser;
  const qc: boolean | undefined = clientDetails?.qualified_client;
  const ai: boolean | undefined = clientDetails?.accredited_investor;

  const [eligibility, setEligibility] = useState<string | undefined>(
    getClientEligibility(qp, qc, ai) ? getClientEligibility(qp, qc, ai) : '',
  );

  const [team, setTeam] = useState<string | null>(null);
  const [selectedPrimaryAdvisor, setSelectedPrimaryAdvisor] = useState<AdvisorProps | null>(null);
  const [selectedSecondaryAdvisor, setSelectedSecondaryAdvisor] = useState<AdvisorProps | null>(
    null,
  );

  const sortedEligibility = [ClientType.QUALIFIED_PURCHASER, ClientType.ACCREDITED_INVESTOR].sort();
  const { data: teamData } = useReactQuery(
    [`fetchTeam${team}`],
    {
      url: `qaip/v1/usermanagement/qualisuser/users/team?teamId=${
        clientDetails ? clientDetails?.team : userTeam?.teamId
      }`,
    },
    {
      enabled: inEditing ? !!clientDetails : !!user,
      refetchOnMount: true,
    },
  );

  const teamDetails: AdvisorProps[] = teamData?.map((team: AdvisorProps) => ({
    userId: team.userId,
    userName: `${team.firstName} ${team.lastName}`,
  }));

  const primaryAdvisor: AdvisorProps = {
    userId: clientDetails?.primary_advisor_id,
    userName: `${clientDetails?.primary_advisor_name}`,
  };
  const secondaryAdvisor: AdvisorProps = {
    userId: clientDetails?.secondary_advisor_id,
    userName: `${clientDetails?.secondary_advisor_name}`,
  };
  const defaultValues: any = {
    name: clientDetails?.account_name,
    accountNumber: clientDetails?.account_number,
    team: clientDetails?.team,
  };

  useEffect(() => {
    // set all the default values for the select fields
    if (inEditing && clientDetails) {
      setSelectedPrimaryAdvisor(primaryAdvisor);
      setSelectedEntity(clientDetails?.entity_type as string);
      setSelectedSecondaryAdvisor(secondaryAdvisor);
      setEligibility(getClientEligibility(qp, qc, ai) ? getClientEligibility(qp, qc, ai) : '');
      setTeam(clientDetails?.team_name);
      setValue('accountName', clientDetails?.account_name);
      setValue('accountNumber', clientDetails?.account_number);
    }
    // eslint-disable-next-line
  }, [clientDetails]);

  useEffect(() => {
    // set all the default values for the select fields
    if (!inEditing && storeClientDetails) {
      const primaryAdvisor: AdvisorProps = {
        userId: storeClientDetails?.primary_advisor_id,
        userName: `${storeClientDetails?.primary_advisor_name}`,
      };
      const secondaryAdvisor: AdvisorProps = {
        userId: storeClientDetails?.secondary_advisor_id,
        userName: `${storeClientDetails?.secondary_advisor_name}`,
      };

      const qp: boolean | undefined = storeClientDetails?.qualified_purchaser;
      const qc: boolean | undefined = storeClientDetails?.qualified_client;
      const ai: boolean | undefined = storeClientDetails?.accredited_investor;

      setSelectedPrimaryAdvisor(primaryAdvisor);
      setSelectedEntity(storeClientDetails?.entity_type as string);
      setSelectedSecondaryAdvisor(secondaryAdvisor);
      setEligibility(getClientEligibility(qp, qc, ai) ? getClientEligibility(qp, qc, ai) : '');

      const team = storeClientDetails?.team_array?.find(
        (el: any) => el.team === storeClientDetails?.team,
      );

      setTeam(team?.team_name);
      setValue('accountName', storeClientDetails?.account_name);
      setValue('accountNumber', storeClientDetails?.account_number);
    }
    // eslint-disable-next-line
  }, [storeClientDetails]);

  const { mutate: updateClient, isLoading: isUpDatingClient } = useRQMutation(
    {
      url: `qaip/v1/investormanagement/investors/id/${
        clientDetails?.investor_id || storeNewInvestorId
      }`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries(`clientDetails-${id}`);
        submitToNextTab();
      },
    },
  );

  const { mutate: addNewClient, isLoading: isAddingNewClient } = useRQMutation(
    {
      url: 'qaip/v1/investormanagement/investors',
    },
    {
      enabled: false,
      onSuccess: (data) => {
        queryClient.invalidateQueries(`clientList${user?.userId}`);
        dispatch(setNewInvestorId(data?.investor_id));
        submitToNextTab();
      },
    },
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: inEditing ? defaultValues : {},
  });

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (!confirmDetails && !inEditing && !storeNewInvestorId) {
      setConfirmClientOpen(true);
    } else {
      if (inEditing || storeNewInvestorId) {
        const formData = {
          ...clientDetails,
          ...storeClientDetails,
          account_number: data?.accountNumber,
          account_name: data?.accountName,
          entity_type: selectedEntity?.entityType || selectedEntity,
          entity_type_array: masterData?.entity_type,
          primaryAdvisors: teamData,
          secondaryAdvisor: teamData,
          primary_advisor_id: selectedPrimaryAdvisor?.userId,
          secondary_advisor_id: selectedSecondaryAdvisor?.userId,
          primary_advisor_name: selectedPrimaryAdvisor?.userName,
          secondary_advisor_name: selectedSecondaryAdvisor?.userName,
          qualified_purchaser: eligibility === ClientType.QUALIFIED_PURCHASER,
          qualified_client:
            eligibility === ClientType.QUALIFIED_PURCHASER
              ? true
              : eligibility === ClientType.QUALIFIED_CLIENT
              ? true
              : false,
          accredited_investor: true,
        };
        dispatch(setClientDetails(formData));
        updateClient(formData);
      } else {
        const formData = {
          account_number: data?.accountNumber,
          account_name: data?.accountName,
          entity_type: selectedEntity?.entityType || selectedEntity,
          entity_type_array: masterData?.entity_type,
          primaryAdvisors: teamData,
          secondaryAdvisor: teamData,
          primary_advisor_id: selectedPrimaryAdvisor?.userId,
          secondary_advisor_id: selectedSecondaryAdvisor?.userId,
          primary_advisor_name: selectedPrimaryAdvisor?.userName,
          secondary_advisor_name: selectedSecondaryAdvisor?.userName,
          team: userTeam?.teamId,
          team_array: [{ team: userTeam?.teamId, team_name: userTeam?.teamName }],
          investor_id: null,
          status: 'Approval Requested',
          status_array: masterData.status,
          statementDocuments: [],
          qualified_purchaser: eligibility === ClientType.QUALIFIED_PURCHASER,
          qualified_client:
            eligibility === ClientType.QUALIFIED_PURCHASER
              ? true
              : eligibility === ClientType.QUALIFIED_CLIENT
              ? true
              : false,
          accredited_investor: true,
        };
        dispatch(setClientDetails(formData));
        addNewClient(formData);
      }
    }
  };

  const saveNext = () => {
    setConfirmClientOpen(!confirmClientOpen);
    confirmDetails = true;
    handleSubmit(onSubmit)();
  };

  return (
    <StyledCard sx={{ p: 0, border: 'none' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={4} columnSpacing={4}>
          <Grid item xs={12}>
            <h2 className='font-wt-500 mb-0'>{inEditing ? 'Edit' : 'Add New'} Client</h2>
            <p className='mb-0 mt-12'>
              Fill in required information to set up new client. You can enter additional client
              details and upload documents in subsequent steps.
            </p>
          </Grid>
          <Grid item xs={6}>
            <h4 className='font-wt-400 mb-0'>Account Name</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='accountName'
              fullWidth
              onChange={(e: any) => setValue('accountName', e.target.value)}
              errorMessage='Please enter a valid name'
            />
            {errors.accountName && <ErrorMessage error='Please enter a valid name' />}
          </Grid>
          {/* {!inEditing && (
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Account Number</h4>
              <InputField
                variant='outlined'
                type='text'
                register={register}
                name='accountNumber'
                fullWidth
                onChange={(e: any) => setValue('accountNumber', e.target.value)}
                errorMessage='Please enter a valid account number'
              />
              {errors.accountNumber && <ErrorMessage error='Please enter a valid account number' />}
            </Grid>
          )} */}
          <Grid item xs={6}>
            <SelectField
              className='pt-0'
              label='Eligibility'
              setSelectedValue={setEligibility}
              name='eligibility'
              onChange={(e) => setValue('eligibility', e.target.value)}
              options={sortedEligibility}
              register={register}
              defaultValue={eligibility}
              required={!!!eligibility}
              value={eligibility}
              searchIcon={false}
            />
            {errors.eligibility && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name='entityType'
              label='Client Type'
              options={masterData?.entity_type
                ?.slice()
                ?.sort((a: any, b: any) => a.entityType.localeCompare(b.entityType))}
              optionId='entityType'
              optionName='entityType'
              register={register}
              disabled={inEditing}
              setSelectedValue={setSelectedEntity}
              defaultValue={selectedEntity?.entityType || selectedEntity}
              value={selectedEntity?.entityType || selectedEntity}
              onChange={(e) => {
                setValue('entityType', e.target.value);
              }}
              required={!!!selectedEntity}
              searchIcon={false}
            />
            {errors.entityType && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <SelectField
              label='Team'
              options={clientDetails ? [clientDetails.team_name] : [userTeam?.teamName]}
              register={register}
              // onChange={(e) => setValue('team', e.target.value)}
              setSelectedValue={setTeam}
              name='team'
              required={!!!team}
              value={team}
              defaultValue={team}
              errorMessage='This field is required'
              searchIcon={false}
            />
            {errors.team && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <SelectField
              label='Primary Advisor'
              options={teamDetails && teamDetails}
              optionName='userName'
              optionId='userId'
              setSelectedValue={setSelectedPrimaryAdvisor}
              defaultValue={selectedPrimaryAdvisor?.userId}
              onChange={(e) => setValue('primaryAdvisor', e.target.value)}
              register={register}
              required={!!!selectedPrimaryAdvisor}
              value={selectedPrimaryAdvisor?.userId}
              name='primaryAdvisor'
              searchIcon={false}
            />
            {errors.primaryAdvisor && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name='secondaryAdvisor'
              label='Secondary Advisor'
              optionName='userName'
              optionId='userId'
              register={register}
              options={teamDetails && teamDetails}
              setSelectedValue={setSelectedSecondaryAdvisor}
              defaultValue={selectedSecondaryAdvisor?.userId}
              onChange={(e) => setValue('secondaryAdvisor', e.target.value)}
              required={!!!selectedSecondaryAdvisor}
              value={selectedSecondaryAdvisor?.userId}
              searchIcon={false}
            />
            {errors.secondaryAdvisor && <ErrorMessage error='This field is required' />}
          </Grid>

          {/* This will prevent the submission of form on pressing enter key */}
          <button type='submit' disabled className='d-none' aria-hidden='true'></button>
          <Grid item xs={12} sx={{ mt: 2 }} container alignItems='center' justifyContent='flex-end'>
            <MuiButton
              buttonClick={() => {
                handleModalClose();
              }}
              className='mr-3'
            >
              Discard Changes
            </MuiButton>
            {isAddingNewClient || isUpDatingClient ? (
              <LoadingButton minWidth='150px' />
            ) : (
              <MuiButton minWidth='150px' variant='contained' type='submit'>
                Save & Next
                <CircleArrowIcon className='ml-2' />
              </MuiButton>
            )}
          </Grid>
        </Grid>
        <ConfirmClientModal
          isModalOpen={confirmClientOpen}
          handleClose={() => setConfirmClientOpen(!confirmClientOpen)}
          buttonClick={() => {
            saveNext();
          }}
        />
      </form>
    </StyledCard>
  );
};

export default Step1;
