import { Container, Divider, Grid, CircularProgress, Tooltip } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import { useState, useRef, useEffect } from 'react';
import { CheckCircle, Close } from '@mui/icons-material';
import MuiUpload from 'common/components/inputField/Upload';
import { selectAuthUser, selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { selectFundDocuments, selectFundID } from 'store/funds/selectors';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { encryptDocumentUrl } from 'helpers';
import { setFundDocuments } from 'store/funds/slice';
import Loading from 'common/components/Loading';
import { QueryType } from 'common/types';
import { useQueryClient } from 'react-query';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import { UserProfile } from 'store/user/types';
import { Link, useNavigate } from 'react-router-dom';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import { ReactComponent as Edit } from 'common/assets/images/Edit.svg';
import { FUND_FLOW_DOC_EDIT_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';
import EditDocConfirmModal from 'components/modals/EditDocConfirmModal';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
  handleClose?: any;
  id?: string;
};

const subscriptionDocumentTypes: string[] = [
  'Subscription Document',
  'Additional Subscription Document',
  'Supplemental Subscription Document',
  'Offering Memorandum',
  'Supplemental Offering Memorandum',
  'Partnership Agreement',
  'Investment Management Agreement',
  'Tifin Private Markets Disclosure Statement',
  'Qualified Purchaser Questionaire',
  'Privacy Notice',
  'Form W-9',
  'Form ADV Part 2A',
  'Form ADV Part 2B',
  'Entity Self Certification',
  'Individual Self Certification',
  'Politcally Exposed Persons Form (Entity)',
  'Politcally Exposed Persons Form (Indv.)',
  'Investor Vehicle Summary',
  'Notable Litigation Memorandum',
  'Wire Instructions',
  'ADV Acknowledement Form',
  'Other',
];

const marketingDocumentTypes: string[] = [
  'Fund Presentation',
  'Fact Sheet',
  'Firm Presentation',
  'Investor Letter',
  'Manager Biographies',
  'Track Record',
  'Due Diligence Questionaire',
  'Operational Due Diligence',
  'DD Questionaire Appendix',
  'Private Credit Allocation Policy',
  'Other',
];

const precheckedDocTypes: string[] = [
  'Subscription Document',
  'Tifin Private Markets Disclosure Statement',
];

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .status-div {
    font-size: 13px;
    color: #8e8e8e;
    display: flex;
    align-items: center;
    .checkCircle {
      font-size: inherit;
      color: #5eb734;
    }
    .close {
      color: var(--s30);
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  .sub-heading {
    color: #898989;
    &--table {
      font-size: 0.875rem;
      color: #525863;
      text-transform: uppercase;
    }
    &--tableBody {
      font-weight: 500;
      color: var(--s40);
    }
    &--filename {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--s40);
    }
    &--status {
      margin-left: 5px;
      color: #8e8e8e;
      font-weight: 400;
      font-style: italic;
    }
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
  .date-picker {
    margin-top: -20px;
    margin-right: auto;
  }
  .action-btns {
    margin: 0 5px;
  }
  .docToggle {
    border-radius: 1rem;
    border: 1px solid var(--p200);
    overflow: hidden;
    button {
      border: none;
      color: var(--p200);
      &.Mui-selected {
        background-color: var(--p200);
        color: white;
      }
    }
  }

  .table {
    border-radius: 0.5rem;
    border: 1px solid var(--s15);
    overflow: hidden;

    .table-head {
      padding: 1rem 1.5rem;
      background-color: var(--s15);
    }

    .table-row {
      padding: 1rem 1.5rem;
      min-height: 72px;
    }

    .table-row:nth-of-type(odd) {
      background-color: var(--s7);
    }
  }

  .checkbox {
    display: flex;
    .MuiCheckbox-root {
      padding: 0;
      & svg {
        font-size: 16px;
      }
    }
  }

  .mapsToSelect {
    width: 100%;
    padding-top: 0;
    .MuiSelect-select {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.875rem;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15);
    }
    .placeholder {
      left: 0.875rem;
    }
  }

  .MuiDivider-root {
    border-color: var(--s15);
  }
`;

const InvestmentDocumentForm = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
  handleClose,
  id,
}: Props) => {
  const queryClient = useQueryClient();
  const [subscriptionDocName, setSubscriptionDocName] = useState<string>('');
  const [marketingDocName, setMarketingDocName] = useState<string>('');
  const [filteredMarketingDocTypes, setFilteredMarketingDocTypes] = useState<any>();
  const [filteredSubDocTypes, setFilteredSubDocTypes] = useState<any>();
  const [subscriptionDocTitle, setSubscriptionDocTitle] = useState<string>('');
  const [marketingDocTitle, setMarketingDocTitle] = useState<string>('');
  const [uploadedDoc, setUploadedDoc] = useState<any>(null);
  const [docConfigObj, setDocConfigObj] = useState<any>(null);
  const [docObjectKey, setDocObjKey] = useState<any>(null);
  const [documentData, setDocumentData] = useState<any>({
    docName: '',
    docConfigTypeId: '',
    docType: 'file',
    docCategoryId: '',
    docClassificationName: '',
    docCategoryTypeId: 2,
    status: 'Active',
    docConfigId: '',
    objectKey: '',
    documentType: 'FUND_DOCUMENT',
  });
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const showPdftronData =
    process.env.REACT_APP_SHOW_PDFTRON_FEATURES?.toString().toLowerCase() === 'true';

  const [documentObject, setDocumentObject] = useState<any>({});
  const [documentArr, setDocumentArr] = useState<any[]>([]);
  const [filteredDocArr, setFilteredDocArr] = useState<any[]>([]);
  const [fileUploadLoading, setFileUploadLoading] = useState<boolean>(false);
  const [clickedDocId, setClickedDocId] = useState<string | null>(null);
  // const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const authUser = useAppSelector(selectAuthUser);
  const reduxFundID = useAppSelector(selectFundID);
  const fundID = id ? id : reduxFundID;
  const storeFundDocuments = useAppSelector(selectFundDocuments);
  const navigate = useNavigate();

  const dispatch = useTypedDispatch();

  const [editConfirmModalOpen, setEditConfirmModalOpen] = useState<boolean>(false);

  const uploadedFileName = useRef<any>(null);

  const filterDocTypes = (storeDocuments?: any[]) => {
    const currentDocuments = storeDocuments ? storeDocuments : documentArr;
    if (currentDocuments.length > 0) {
      const existingSubTypes: string[] = [];
      const existingMarketingTypes: string[] = [];
      currentDocuments.forEach((el: any) => {
        if (el.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003')
          existingMarketingTypes.push(el.docClassificationName);
        else existingSubTypes.push(el.docClassificationName);
      });
      const filteredSubTypes = subscriptionDocumentTypes.filter(
        (item) => !existingSubTypes.includes(item),
      );
      const filteredMarketingTypes = marketingDocumentTypes.filter(
        (item) => !existingMarketingTypes.includes(item),
      );

      setFilteredMarketingDocTypes(filteredMarketingTypes);
      setFilteredSubDocTypes(filteredSubTypes);
    } else {
      setFilteredMarketingDocTypes(marketingDocumentTypes);
      setFilteredSubDocTypes(subscriptionDocumentTypes);
    }
  };

  useEffect(() => {
    if (storeFundDocuments) {
      filterDocTypes(storeFundDocuments);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterDocTypes();
    // eslint-disable-next-line
  }, [documentArr]);

  //SUBSCRIPTION
  useEffect(() => {
    const docConfigObject = {
      createdBy: authUser?.userDetailsResponseDTO?.userId,
      docConfigList: [
        {
          docConfigTypeId: 'f40f45b2-6a99-11ea-bc55-0242ac130003',
          docList: [subscriptionDocTitle ? subscriptionDocTitle : subscriptionDocName],
        },
      ],
      fundId: fundID,
    };
    setDocConfigObj(docConfigObject);
  }, [authUser, subscriptionDocName, fundID, subscriptionDocTitle]);

  //MARKETING
  useEffect(() => {
    const docConfigObject = {
      createdBy: authUser?.userDetailsResponseDTO?.userId,
      docConfigList: [
        {
          docConfigTypeId: '64a11300-6aa4-11ea-bc55-0242ac130003',
          docList: [marketingDocTitle ? marketingDocTitle : marketingDocName],
        },
      ],
      fundId: fundID,
    };
    setDocConfigObj(docConfigObject);
  }, [authUser, marketingDocName, fundID, marketingDocTitle]);

  useEffect(() => {
    (async function () {
      if (storeFundDocuments) {
        setDocumentArr(storeFundDocuments);
        const filteredDocs = filterDocuments(storeFundDocuments);
        setFilteredDocArr(filteredDocs);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async function () {
      const filteredDocs = filterDocuments();
      setFilteredDocArr(filteredDocs);
    })();
    // eslint-disable-next-line
  }, [documentArr]);

  useEffect(() => {
    (async function () {
      dispatch(setFundDocuments(documentArr));
    })();
    // eslint-disable-next-line
  }, [documentArr]);

  const filterDocuments = (storeDocuments?: any[]) => {
    const documents = storeDocuments ? [...storeDocuments] : [...documentArr];
    return documents;
    //docConfigTypeId
  };

  const { data: subscriptionChecklistList } = useReactQuery(['masterSubscriptionChecklist'], {
    url: `qaip/v1/documentmanagement/getSubscriptionChecklist`,
    params: { fundId: fundID },
  });

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: (data) => {
        const newDocumentObject = {
          ...documentObject,
          ...documentData,
          filePath: data.filePath,
          documentId: data.documentId,
        };
        const newDocumentObjArr = [...documentArr];
        newDocumentObjArr.push(newDocumentObject);
        setFileUploadLoading(false);
        setSubscriptionDocName('');
        setMarketingDocName('');
        setSubscriptionDocTitle('');
        setMarketingDocTitle('');
        setDocumentArr(newDocumentObjArr);
      },
    },
  );

  const { mutate: fetchPresignedUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedDoc);
        updateDocumentData({
          documentData: {
            doc_name: documentData.docName,
            doc_config_type_id: documentData.docConfigTypeId,
            doc_type: documentData.docType,
            doc_category_id: documentData.docCategoryId,
            doc_classification_name: documentData.docClassificationName,
            doc_category_type_id: documentData.docCategoryTypeId,
            status: documentData.status,
            doc_config_id: documentData.docConfigId,
            object_key: documentData.objectKey,
            document_type: documentData.documentType,
          },
        });
      },
    },
  );

  //SUBSCRIPTION
  const { mutate: docConfigManagment } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/docconfigmanagement',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        const newDocObject = {
          ...documentData,
          docConfigId: data[0].docConfigId,
          docName: uploadedFileName.current,
          docConfigTypeId: 'f40f45b2-6a99-11ea-bc55-0242ac130003',
          docCategoryId: fundID,
          docClassificationName: subscriptionDocTitle ? subscriptionDocTitle : subscriptionDocName,
          objectKey: `${fundID}/file/${
            subscriptionDocTitle ? subscriptionDocTitle : subscriptionDocName
          }/${uploadedFileName.current}`,
          prefill: precheckedDocTypes.includes(subscriptionDocName),
        };
        setDocumentData(newDocObject);
        fetchPresignedUrl({ ...docObjectKey });
      },
    },
  );

  //MARKETING
  const { mutate: docConfigManagmentMarketing } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/docconfigmanagement',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        const newDocObject = {
          ...documentData,
          docConfigId: data[0].docConfigId,
          docName: uploadedFileName.current,
          docConfigTypeId: '64a11300-6aa4-11ea-bc55-0242ac130003',
          docCategoryId: fundID,
          docClassificationName: marketingDocTitle ? marketingDocTitle : marketingDocName,
          objectKey: `${fundID}/file/${marketingDocTitle ? marketingDocTitle : marketingDocName}/${
            uploadedFileName.current
          }`,
          prefill: precheckedDocTypes.includes(marketingDocName),
        };
        setDocumentData(newDocObject);
        fetchPresignedUrl({ ...docObjectKey });
      },
    },
  );

  const { refetch: deleteDocument, isFetching } = useReactQuery(
    ['deleteDocument'],
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${clickedDocId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries();
        deleteFilesFromList();
      },
    },
  );

  const { refetch: approveFund } = useReactQuery(
    [`approveFund-${fundID}`],
    {
      url: `qaip/v1/fundsmanagement/funds/status/${fundID}`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`fundList${user?.userId}`);
        navigate(`/investment/${fundID}`);
      },
    },
  );

  useEffect(() => {
    if (clickedDocId) {
      deleteDocument();
    }
    // eslint-disable-next-line
  }, [clickedDocId]);

  // useEffect(() => {
  //   for (let i = 0; i < documentArr.length; i++) {
  //     const curr = documentArr[i];
  //     if (curr.prefill && !curr.prefillMapTo) {
  //       setIsDisabled(true);
  //       return;
  //     }
  //   }
  //   setIsDisabled(false);
  // }, [documentArr]);

  const deleteFilesFromList = () => {
    // From docsArr
    const newDocsArr = documentArr.filter((el) => el.documentId !== clickedDocId);
    setDocumentArr(newDocsArr);

    // From filterDocsArr
    const newFilterDocsArr = filteredDocArr.filter((el) => el.documentId !== clickedDocId);
    setFilteredDocArr(newFilterDocsArr);

    setClickedDocId(null);
  };

  const handleDeleteBtnClick = (id: string) => {
    setClickedDocId(id);
  };

  const handleApproveFund = () => {
    approveFund();
  };

  const handlePrefillChange = (id: string) => {
    const newDocsArr = [...documentArr];
    const reqIdx = newDocsArr.findIndex((el: any) => el.documentId === id);
    const newReqDoc = { ...newDocsArr[reqIdx] };
    const prevPrefill = newReqDoc['prefill'];
    newReqDoc['prefill'] = !prevPrefill;
    if (!newReqDoc['prefill']) {
      newReqDoc['prefillMapTo'] = null;
    }
    newDocsArr[reqIdx] = newReqDoc;
    setDocumentArr(newDocsArr);
  };

  const handleMapsToChange = (id: string) => {
    const newDocsArr = [...documentArr];
    const reqIdx = newDocsArr.findIndex((el: any) => el.documentId === id);
    const newReqDoc = { ...newDocsArr[reqIdx] };
    return (event: any) => {
      newReqDoc['prefillMapTo'] = event.target.value;
      newDocsArr[reqIdx] = newReqDoc;
      setDocumentArr(newDocsArr);
    };
  };

  const { mutate: savePrefill } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/prefill',
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: async () => {
        !editing ? navigate(`/investment/${fundID}`) : handleApproveFund();
        queryClient.refetchQueries(`documents-${fundID}`);
      },
    },
  );

  //MARKETING
  const handleOnMarketingFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('MARKETING');
    setFileUploadLoading(true);
    const uploadedFile: any = e.target.files;
    const newUploadedFileName: string = uploadedFile.item(0).name;
    const docKey = {
      object_key: `${fundID}/file/${
        marketingDocTitle ? marketingDocTitle : marketingDocName
      }/${newUploadedFileName}`,
    };
    uploadedFileName.current = newUploadedFileName;
    console.log('DOCKEY', docKey);

    const documentObj = {
      documentType: 'Marketing Documents',
      documentName: marketingDocTitle ? marketingDocTitle : marketingDocName,
      documentFileName: newUploadedFileName,
    };

    setDocumentObject(documentObj);

    await setDocObjKey(docKey);
    setUploadedDoc(uploadedFile[0]);
    docConfigManagmentMarketing(docConfigObj);
    e.target.value = '';
    e.target.files = null;
  };

  //SUBSCRIPTION
  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('SUB');
    setFileUploadLoading(true);
    const uploadedFile: any = e.target.files;
    const newUploadedFileName: string = uploadedFile.item(0).name;
    const docKey = {
      object_key: `${fundID}/file/${
        subscriptionDocTitle ? subscriptionDocTitle : subscriptionDocName
      }/${newUploadedFileName}`,
    };
    uploadedFileName.current = newUploadedFileName;

    const documentObj = {
      documentType: 'Subscription Documents',
      documentName: subscriptionDocTitle ? subscriptionDocTitle : subscriptionDocName,
      documentFileName: newUploadedFileName,
    };

    setDocumentObject(documentObj);

    await setDocObjKey(docKey);
    setUploadedDoc(uploadedFile[0]);
    docConfigManagment(docConfigObj);
    e.target.value = '';
    e.target.files = null;
  };

  return (
    <StyledWrapper>
      <Container maxWidth='lg' className='mt-5' sx={{ mb: 8 }}>
        <Container maxWidth='lg' className='px-0'>
          <h2 className='heading'>Subscription Documents</h2>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0'>
          <Grid container alignItems='center' columnSpacing={2}>
            <Grid item xs={8.5}>
              <h4 className='font-wt-400 label'>Document Type</h4>
            </Grid>
            <Grid item xs={8.5}>
              <SelectField
                options={filteredSubDocTypes}
                required={subscriptionDocName === '' ? true : false}
                onChange={(e) => setSubscriptionDocName(e.target.value)}
                name='class'
                setSelectedValue={setSubscriptionDocName}
                value={subscriptionDocName}
                disabled={fileUploadLoading}
                searchIcon={false}
              />
            </Grid>
            <Grid item xs='auto'>
              <MuiUpload
                id={'subscription'}
                onFileUpload={handleOnFileUpload}
                error={subscriptionDocName === '' ? true : false}
                disable={
                  fileUploadLoading
                    ? true
                    : subscriptionDocName === ''
                    ? true
                    : subscriptionDocName === 'Other' && subscriptionDocTitle === ''
                    ? true
                    : false
                }
              >
                {fileUploadLoading ? <Loading /> : 'Upload File'}
              </MuiUpload>
            </Grid>
          </Grid>
        </Container>
        {subscriptionDocName === 'Other' && (
          <Grid container alignItems='center' columnSpacing={2}>
            <Grid item xs={8.5}>
              <h4 className='font-wt-400 label'>Document Title</h4>
            </Grid>
            <Grid item xs={8.5} className='mb-3'>
              <Textbox
                fullWidth
                name='fund_manager_contact'
                onChange={(e: any) => setSubscriptionDocTitle(e.target.value)}
                errorMessage='This field is required'
                required={true}
                value={subscriptionDocTitle}
                disabled={fileUploadLoading}
              />
            </Grid>
          </Grid>
        )}
        <Container maxWidth='lg' className='my-5 px-0 table'>
          {filteredDocArr?.filter(
            (doc: any) => doc.docConfigTypeId === 'f40f45b2-6a99-11ea-bc55-0242ac130003',
          ).length > 0 && (
            <Grid container className='table-head' columnSpacing={2}>
              <Grid item xs={!showPdftronData ? 6 : 2.5} className='flex-center-start'>
                <h4 className='sub-heading sub-heading--table mb-0'>Document Type</h4>
              </Grid>
              {showPdftronData && (
                <>
                  <Grid item xs={1.75} className='flex-center-center'>
                    <h4 className='sub-heading sub-heading--table mb-0'>
                      Pre-fill
                      <br /> with client info
                    </h4>
                  </Grid>
                  <Grid item xs={2} className='flex-center-center'>
                    <h4 className='sub-heading sub-heading--table mb-0'>
                      CREATE PRE-fILLable version
                    </h4>
                  </Grid>
                  <Grid item xs={2.5}>
                    <h4 className='sub-heading sub-heading--table mb-0'>
                      Map to SUBSCRIPTION CHECKLIST item
                    </h4>
                  </Grid>
                </>
              )}
              <Grid item xs={!showPdftronData ? 6 : 3.25} className='flex-center-start'>
                <h4 className='sub-heading sub-heading--table mb-0'>File Name</h4>
              </Grid>
            </Grid>
          )}
          {filteredDocArr
            ?.filter((doc: any) => doc.docConfigTypeId === 'f40f45b2-6a99-11ea-bc55-0242ac130003')
            ?.map((el: any, idx: number) => (
              <>
                <Grid container className='table-row' sx={{ px: 4 }} key={el} columnSpacing={2}>
                  <Grid item xs={!showPdftronData ? 6 : 2.5} className='flex-center-start'>
                    <h5 className='sub-heading sub-heading--filename mb-0'>
                      {el.docClassificationName}
                    </h5>
                  </Grid>
                  {showPdftronData && (
                    <Grid item xs={1.75} className='flex-center-center'>
                      <MuiCheckbox
                        name='prefill'
                        defaultValue={el.prefill}
                        disableRipple
                        className={`checkbox`}
                        onChange={() => handlePrefillChange(el.documentId)}
                      />
                    </Grid>
                  )}
                  {showPdftronData && (
                    <Grid item xs={2} className='flex-center-center'>
                      {el.prefill && id ? (
                        <Link
                          to={`/${fundID}/prefill/${el.documentId}`}
                          target={'_blank'}
                          className='flex-center-end'
                        >
                          <Tooltip
                            title={
                              <div className='tooltipContainer'>
                                {FUND_FLOW_DOC_EDIT_TOOLTIP_CONTENT}
                              </div>
                            }
                            placement='top'
                            arrow
                            classes={{ tooltip: 'p-0 tooltipParent' }}
                          >
                            <Edit className='cursor-pointer' />
                          </Tooltip>
                        </Link>
                      ) : (
                        el.prefill && (
                          <>
                            <Tooltip
                              title={
                                <div className='tooltipContainer'>
                                  {FUND_FLOW_DOC_EDIT_TOOLTIP_CONTENT}
                                </div>
                              }
                              placement='top'
                              arrow
                              classes={{ tooltip: 'p-0 tooltipParent' }}
                            >
                              <Edit
                                className='ml-3 cursor-pointer'
                                onClick={() => setEditConfirmModalOpen(true)}
                              />
                            </Tooltip>

                            <EditDocConfirmModal
                              isModalOpen={editConfirmModalOpen}
                              handleClose={() => setEditConfirmModalOpen(false)}
                              fundID={fundID}
                              docID={el.documentId}
                              buttonClick={() => savePrefill(documentArr)}
                            />
                          </>
                        )
                      )}
                    </Grid>
                  )}
                  {showPdftronData && (
                    <Grid item xs={2.5} className='flex-center-start' sx={{ pr: '20px' }}>
                      {el.prefill && (
                        <SelectField
                          className='mapsToSelect'
                          fullWidth
                          options={subscriptionChecklistList}
                          setSelectedValue={() => {}}
                          onChange={handleMapsToChange(el.documentId)}
                          optionName='title'
                          optionId='id'
                          value={el.prefillMapTo}
                          searchIcon={false}
                        />
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={!showPdftronData ? 6 : 3.25}
                    className='status-div flex-center-between'
                  >
                    <div className='flex-center-start'>
                      <CheckCircle className='checkCircle mr-1' />
                      {el.docName.length > 20
                        ? el.docName.substring(0, 15) +
                          '...' +
                          el.docName.substring(el.docName.length - 8, el.docName.length)
                        : el.docName}
                    </div>
                    <div className='flex-center-center'>
                      {isFetching && el?.documentId === clickedDocId ? (
                        <CircularProgress className='close ml-2' />
                      ) : (
                        <Close
                          className='close ml-2'
                          onClick={() => handleDeleteBtnClick(el.documentId)}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
        </Container>
      </Container>
      <Container maxWidth='lg'>
        <Container maxWidth='lg' className='px-0'>
          <h2 className='heading'>Marketing Documents</h2>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0'>
          <Grid container alignItems='center' columnSpacing={2}>
            <Grid item xs={8.5}>
              <h4 className='font-wt-400 label'>Document Type</h4>
            </Grid>
            <Grid item xs={8.5}>
              <SelectField
                options={filteredMarketingDocTypes}
                required={marketingDocName === '' ? true : false}
                onChange={(e) => setMarketingDocName(e.target.value)}
                name='class'
                setSelectedValue={setMarketingDocName}
                value={marketingDocName}
                disabled={fileUploadLoading}
                searchIcon={false}
              />
            </Grid>
            <Grid item xs='auto'>
              <MuiUpload
                id='marketing'
                onFileUpload={handleOnMarketingFileUpload}
                error={marketingDocName === '' ? true : false}
                disable={
                  fileUploadLoading
                    ? true
                    : marketingDocName === ''
                    ? true
                    : marketingDocName === 'Other' && marketingDocTitle === ''
                    ? true
                    : false
                }
              >
                {fileUploadLoading ? <Loading /> : 'Upload File'}
              </MuiUpload>
            </Grid>
          </Grid>
        </Container>
        {marketingDocName === 'Other' && (
          <Grid container alignItems='center' columnSpacing={2}>
            <Grid item xs={8.5}>
              <h4 className='font-wt-400 label'>Document Title</h4>
            </Grid>
            <Grid item xs={8.5} className='mb-3'>
              <Textbox
                fullWidth
                name='fund_manager_contact'
                onChange={(e: any) => setMarketingDocTitle(e.target.value)}
                errorMessage='This field is required'
                required={true}
                value={marketingDocTitle}
                disabled={fileUploadLoading}
              />
            </Grid>
          </Grid>
        )}
        <Container maxWidth='lg' className='my-5 px-0 table'>
          {filteredDocArr?.filter(
            (doc: any) => doc.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003',
          ).length > 0 && (
            <Grid container className='table-head' columnSpacing={2}>
              <Grid item xs={6} className='flex-center-start'>
                <h4 className='sub-heading sub-heading--table mb-0'>Document Type</h4>
              </Grid>
              <Grid item xs={6} className='flex-center-start'>
                <h4 className='sub-heading sub-heading--table mb-0'>File Name</h4>
              </Grid>
            </Grid>
          )}
          {filteredDocArr
            ?.filter((doc: any) => doc.docConfigTypeId === '64a11300-6aa4-11ea-bc55-0242ac130003')
            .map((el: any, idx: number) => (
              <>
                <Grid container className='table-row' sx={{ px: 4 }} key={el} columnSpacing={2}>
                  <Grid item xs={6} className='flex-center-start'>
                    <h5 className='sub-heading sub-heading--filename mb-0'>
                      {el.docClassificationName}
                    </h5>
                  </Grid>
                  <Grid item xs={6} className='status-div flex-center-between'>
                    <div className='flex-center-start'>
                      <CheckCircle className='checkCircle mr-1' />
                      {el.docName.length > 20
                        ? el.docName.substring(0, 15) +
                          '...' +
                          el.docName.substring(el.docName.length - 8, el.docName.length)
                        : el.docName}
                    </div>
                    <div className='flex-center-center'>
                      {isFetching && el?.documentId === clickedDocId ? (
                        <CircularProgress className='close ml-2' />
                      ) : (
                        <Close
                          className='close ml-2'
                          onClick={() => handleDeleteBtnClick(el.documentId)}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Divider />
              </>
            ))}
        </Container>
        <Grid container justifyContent='flex-end'>
          <MuiButton variant='text' buttonClick={handleBackBtnClick}>
            Back
          </MuiButton>
          {!editing && (
            <MuiButton
              variant='outlined'
              className='ml-3'
              minWidth='150px'
              buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                savePrefill(documentArr);
                e.stopPropagation();
                navigate(`/investment/${fundID}`);
              }}
            >
              Save only
            </MuiButton>
          )}
          <MuiButton
            variant='contained'
            className='ml-3'
            minWidth='150px'
            buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              savePrefill(documentArr);
              handleClose && handleClose();
            }}
            // disabled={isDisabled}
          >
            Save
          </MuiButton>
        </Grid>
      </Container>
    </StyledWrapper>
  );
};

export default InvestmentDocumentForm;
