import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import { FundDetails, QueryType, ReactQueryDataFetch, RedemptionType } from 'common/types';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { selectNewOrderDetails } from 'store/order/selectors';
import { resetOrderData, setOrderActiveStep, setOrderDetails } from 'store/order/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { ReactComponent as CircleArrowIcon } from 'common/assets/images/CTA/CircleArrow.svg';
import LoadingButton from 'common/components/button/LoadingButton';
import { regexValidator, valueFormatter } from 'helpers';
import PrefillDownloadModal from 'components/modals/PrefillDownloadModal';
//import { selectFundDocuments } from 'store/funds/selectors';

const StyledCard = styled(MuiCard)`
  h2 {
    font-size: 22px;
  }
`;

const OrderDetailsForm = (createNewOrder: any) => {
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const clientDetails = location?.state?.clientDetails;
  const fundDetails = location?.state?.fundDetails;
  const isRedeemOrder = location?.state?.isRedeemOrder;
  const currentOrderDetails = location?.state?.orderDetails;
  const isEditOrder = location?.state?.isEditOrderRequest;
  //const isRequestAllocation = location?.state?.isRequestAllocation;
  const placedOrderDetails: any = useAppSelector(selectNewOrderDetails);
  const [prefillModalOpen, setPrefillModalOpen] = useState<boolean>(false);
  const [params, setParams] = useState<any>(null);
  const [eventAmount, setEventAmount] = useState<any>('');
  const [selectedClient, setSelectedClient] = useState(
    !placedOrderDetails
      ? isEditOrder
        ? { investor_id: currentOrderDetails.investorId }
        : clientDetails
        ? clientDetails
        : null
      : placedOrderDetails.investorDetails,
  );
  const [selectedFund, setSelectedFund] = useState<FundDetails>(
    !placedOrderDetails
      ? isEditOrder
        ? { fund_id: currentOrderDetails.fundId }
        : fundDetails
        ? { ...fundDetails, fund_id: fundDetails.fundsId }
        : null
      : placedOrderDetails.selectedFund,
  );
  const [selectedRedemption, setSelectedRedemption] = useState<string>(RedemptionType.UNITS);
  const [date, setDate] = useState<Date>(
    !placedOrderDetails
      ? isEditOrder
        ? new Date(currentOrderDetails.eventDate)
        : new Date()
      : placedOrderDetails.eventDate,
  );
  const showEditIcon =
    process.env.REACT_APP_SHOW_PDFTRON_FEATURES?.toString().toLowerCase() === 'true';
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  //const fundDocs: any = useAppSelector(selectFundDocuments);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isAllocationWorkflowEnabled =
    selectedFund?.isAllocationWorkflowEnabled === 'no' ? false : true;
  const dispatch = useTypedDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (placedOrderDetails) {
      setEventAmount(Number(placedOrderDetails?.eventAmount));
      setValue('redemptionUnit', placedOrderDetails?.redemptionUnit);
      setValue('comments', placedOrderDetails?.comments);
      setValue('redemptionValue', placedOrderDetails?.redemptionValue);
      setSelectedRedemption(placedOrderDetails?.redemptionType);
    }
    if (isEditOrder && !placedOrderDetails) {
      setEventAmount(Number(currentOrderDetails?.eventAmount));
      setValue('redemptionUnit', currentOrderDetails?.redemptionUnit);
      setValue('comments', currentOrderDetails?.userComments[0]?.comment);
      setValue('redemptionValue', currentOrderDetails?.redemptionValue);
      setSelectedRedemption(currentOrderDetails?.redemptionType);
    }
    // eslint-disable-next-line
  }, [placedOrderDetails, currentOrderDetails]);

  const { data: clientList } = useReactQuery([`clientList${user?.userId}`], {
    url: 'qaip/v1/investormanagement/investors',
  });

  const { data: fundData } = useReactQuery([`funds${user?.userId}`], {
    url: `qaip/v1/fundsmanagement/funds?status=Active`,
  }) as { data: FundDetails[] } & ReactQueryDataFetch;

  useEffect(() => {
    const existingOrderData = {
      amount: Number(Number(placedOrderDetails?.eventAmount)),
      comments: placedOrderDetails?.comments || '',
      eventDate: new Date(placedOrderDetails?.eventDate),
      orderId: currentOrderDetails?.orderId,
      status: currentOrderDetails?.orderStatusId,
      units: placedOrderDetails?.units,
    };

    const onProcessOrder = {
      amount: Number(placedOrderDetails?.eventAmount),
      comments: placedOrderDetails?.comments || '',
      eventDate: new Date(placedOrderDetails?.eventDate),
      orderId: placedOrderDetails?.orderId,
      status: placedOrderDetails?.statusId,
      units: placedOrderDetails?.units,
    };
    setParams(currentOrderDetails ? existingOrderData : onProcessOrder);
  }, [placedOrderDetails, currentOrderDetails]);

  const { mutate: updateOrder, isLoading: isUpdateOrderLoading } = useRQMutation(
    {
      url: `qaip/v1/ordermanagement/update`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => dispatch(setOrderActiveStep(1)),
    },
  );

  const handleExitClick = () => {
    dispatch(resetOrderData());
    navigate('/orders');
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    delete data.fund;
    delete data.client;
    const orderDetails = {
      ...data,
      eventId: isAllocationWorkflowEnabled ? 3 : 1,
      selectedFund: selectedFund,
      investorDetails: selectedClient,
      investorId: selectedClient.investor_id,
      investorName: selectedClient.account_name,
      eventAmount: Number(eventAmount),
      event: isAllocationWorkflowEnabled ? 'Allocation Request' : 'Subscription',
      statusId: 8,
      redemptionType: selectedRedemption,
      eventDate: date.toString(),
      units: 0,
      orderId: placedOrderDetails?.orderId,
    };

    const { eventAmount: amount, ...rest } = orderDetails;
    const redemptionOrderDetails = {
      ...rest,
      eventId: 2,
      event: 'Redemption',
      eventAmount: 0,
    };
    await dispatch(setOrderDetails(!isRedeemOrder ? orderDetails : redemptionOrderDetails));
    if (isEditOrder || placedOrderDetails?.orderId) {
      await updateOrder(
        isEditOrder
          ? [
              {
                amount: Number(eventAmount),
                comments: data.comments || '',
                eventDate: new Date(data?.eventDate),
                orderId: currentOrderDetails?.orderId,
                status: currentOrderDetails?.orderStatusId,
                units: placedOrderDetails?.units,
                eventId: placedOrderDetails?.eventId,
              },
            ]
          : [{ ...params, comments: data.comments }],
      );
    } else {
      await createNewOrder.createNewOrder();
    }
  };

  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <StyledCard sx={{ py: 0, pl: 1, pr: 18, border: 'none' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={'30px'} columnSpacing={3}>
          <Grid item xs={12}>
            <h2 className='font-wt-500 mb-0'>
              {isRedeemOrder
                ? 'New Redemption Request'
                : isEditOrder
                ? 'Edit Order'
                : isAllocationWorkflowEnabled
                ? 'New Allocation Request'
                : 'Add New Order'}
            </h2>
            <p className='mt-3 mb-0'>
              {isAllocationWorkflowEnabled
                ? `
                  First, let's start a new allocation request by selecting a client, the fund, and inputting the subscription amount that you would like to request. The Fund Manager may allocate a lower amount if fund is oversubscribed.
                  `
                : `Start a new order by selecting a client and the fund, the subscription amount you would like for the client and the date for the subscription (typically, the last day of the calendar month).`}
            </p>
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='Client'
              options={
                clientList &&
                clientList.sort((a: any, b: any) => a.account_name.localeCompare(b.account_name))
              }
              optionName='account_name'
              optionId='investor_id'
              register={register}
              onChange={(e) => setValue('client', e.target.value)}
              name='client'
              setSelectedValue={setSelectedClient}
              defaultValue={selectedClient?.investor_id}
              value={selectedClient?.investor_id}
              required={!!!selectedClient}
              disabled={isEditOrder || !!clientDetails}
            />
            {errors.client && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='Fund'
              options={
                fundData &&
                fundData.sort((a: any, b: any) => a.fund_name.localeCompare(b.fund_name))
              }
              setSelectedValue={setSelectedFund}
              optionName='fund_name'
              optionId='fund_id'
              register={register}
              onChange={(e) => setValue('fund', e.target.value)}
              name='fund'
              required={!!!selectedFund}
              defaultValue={selectedFund?.fund_id}
              disabled={isEditOrder || !!fundDetails}
              value={selectedFund?.fund_id}
            />
            {errors.fund && <ErrorMessage error='This field is required' />}{' '}
          </Grid>
          {isRedeemOrder ? (
            <>
              <Grid item xs={6}>
                <SelectField
                  label='Redemption Type'
                  options={[
                    RedemptionType.VALUE,
                    RedemptionType.UNITS,
                    RedemptionType.FULL_REDEMPTION,
                  ]}
                  setSelectedValue={setSelectedRedemption}
                  variant='outlined'
                  value={selectedRedemption}
                  defaultValue={selectedRedemption}
                  disabled={isEditOrder}
                />
              </Grid>
              {selectedRedemption !== RedemptionType.FULL_REDEMPTION && (
                <Grid item xs={6}>
                  <h4 className='mb-0 font-wt-400'>
                    {selectedRedemption === RedemptionType.UNITS
                      ? 'Redemption Unit'
                      : 'Redemption Value'}
                  </h4>
                  <Textbox
                    fullWidth
                    type='text'
                    register={register}
                    startAdornment={'$'}
                    keyDownFunc={handleKeyDownOnlyNumbers}
                    valueAsNumber
                    onWheel={(e: any) => e.target.blur()}
                    name={
                      selectedRedemption === RedemptionType.UNITS
                        ? 'redemptionUnit'
                        : 'redemptionValue'
                    }
                    errorMessage='This field is required'
                  />
                  {errors.redemptionUnit && (
                    <ErrorMessage error={errors?.redemptionUnit?.message} />
                  )}
                  {errors.redemptionValue && (
                    <ErrorMessage error={errors?.redemptionValue?.message} />
                  )}
                </Grid>
              )}
              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Redemption Date</h4>
                <MuiDatePicker
                  fullWidth
                  views={['month', 'year']}
                  inputFormat='MM/yy'
                  setDate={setDate}
                  minDate={new Date()}
                  value={date}
                  name='redemptionDate'
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>
                  {isAllocationWorkflowEnabled ? 'Allocation Requested' : 'Subscription Amount'}
                </h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  value={valueFormatter(eventAmount)}
                  defaultValue={valueFormatter(eventAmount)}
                  name='eventAmount'
                  onChange={(e: any) => {
                    if (regexValidator(e.target.value.replace(/,/g, ''), /^[0-9,]*$/i)) return;
                    setEventAmount(e.target.value.replace(/,/g, ''));
                  }}
                  errorMessage='Please enter a valid amount'
                  startAdornment={'$'}
                />
                {errors.eventAmount && <ErrorMessage error='Please enter a valid amount' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Subscription Date</h4>
                <MuiDatePicker
                  fullWidth
                  views={['month', 'year', 'day']}
                  inputFormat='MMM-dd-yyyy'
                  setDate={setDate}
                  value={date}
                  name='subscriptionDate'
                />
              </Grid>
            </>
          )}
          {/* This will prevent the submission of form on pressing enter key */}
          <button type='submit' disabled className='d-none' aria-hidden='true'></button>
          <Grid item xs={12}>
            <Textbox
              fullWidth
              type='text'
              register={register}
              name='comments'
              multiline
              placeholder='Add comment'
              rows={2}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ mt: 2 }}
            container
            alignItems='center'
            justifyContent={showEditIcon ? `space-between` : 'flex-end'}
          >
            {/* {showEditIcon && (
              <MuiButton className='mr-3' buttonClick={() => setPrefillModalOpen(true)}>
                <DownloadDocIcon className='mr-2' />
                Download Documents
              </MuiButton>
            )} */}
            <div>
              <MuiButton buttonClick={handleExitClick} color='error' className='mr-3'>
                Exit Without Saving
              </MuiButton>
              {createNewOrder.isCreateOrderLoading || isUpdateOrderLoading ? (
                <LoadingButton />
              ) : (
                <MuiButton variant='contained' type='submit' minWidth='150px'>
                  Save & Next <CircleArrowIcon className='ml-2' />
                </MuiButton>
              )}
            </div>
          </Grid>
        </Grid>
      </form>
      <PrefillDownloadModal
        isModalOpen={prefillModalOpen}
        handleClose={() => setPrefillModalOpen(false)}
        selectedClient='TEST'
      />
    </StyledCard>
  );
};

export default OrderDetailsForm;
