//BETA PLACEHOLDER AND FUNCTIONS FOR NARRATIVE 1.0
import Cash from 'common/assets/images/emoji/cash.png';
import Rocket from 'common/assets/images/emoji/rocket.png';
import Recycle from 'common/assets/images/emoji/recycle.png';
import Umbrella from 'common/assets/images/emoji/umbrella.png';
import Hourglass from 'common/assets/images/emoji/hourglass.png';

export const CLOSE_STATES = [
  {
    chipName: 'Final Close 4/30',
    detailsName: 'Closing Soon',
    detailsBG: '#CC3B3B',
    detailsColor: 'white',
    firstClose: 'April-2021',
    finalClose: 'April-2022',
    color: '#FFFFFF',
    bgColor: '#CC3B3B',
  },
  {
    chipName: 'Next Close 4/30',
    detailsName: 'Closing Soon',
    detailsBG: '#CC3B3B',
    detailsColor: 'white',
    firstClose: 'April-2021',
    finalClose: 'April-2023',
    color: '#CC3B3B',
    bgColor: '#FFECEC',
  },
  {
    chipName: 'First Close 4/30',
    detailsName: 'Closing Soon',
    detailsBG: '#CC3B3B',
    detailsColor: 'white',
    firstClose: 'April-2022',
    finalClose: 'April-2023',
    color: '#CC3B3B',
    bgColor: '#FFECEC',
  },
  {
    chipName: 'Newly Added',
    detailsName: 'Newly Added',
    detailsBG: '#E2EEF8',
    detailsColor: '#4F4F4F',
    color: '#4F4F4F',
    bgColor: '#E2EEF8',
  },
  // {
  //   chipName: 'Top Pick for Impact',
  //   detailsName: 'Top Pick for Impact',
  //   detailsBG: '#E2EEF8',
  //   detailsColor: '#4F4F4F',
  //   color: '#4F4F4F',
  //   bgColor: '#E2EEF8',
  // },
  // {
  //   chipName: 'Top Pick for Growth',
  //   detailsName: 'Top Pick for Growth',
  //   detailsBG: '#E2EEF8',
  //   detailsColor: '#4F4F4F',
  //   color: '#4F4F4F',
  //   bgColor: '#E2EEF8',
  // },
];

export const getState = (fund_id: string) => {
  switch (fund_id) {
    case '81be7c96-87e4-4967-aa78-1a02458fba59':
      return 0;
    case 'd4c53556-2dde-4d73-ba44-b771a90efb5d':
      return 1;
    case '782a0666-fd8f-4c7c-98c3-6721ebd025a1':
      return 2;
    case '410fca53-106b-4b55-961c-b1ad01d4a1fb':
      return 1;
    case '72e2a42e-f8d9-43b6-8071-6c59f113f67c':
      return 0;
    case '4515db45-f867-4525-aef3-cd4fc81c097c':
      return 2;
    case '62836de4-0a7e-491c-94e4-d3854d5197d2':
      return 1;
    default:
      return -1;
  }
};

export const getCTA = (fund_id: string) => {
  //True for Request Alloc. False for Add New Order
  switch (fund_id) {
    case '81be7c96-87e4-4967-aa78-1a02458fba59':
      return true;
    case 'd4c53556-2dde-4d73-ba44-b771a90efb5d':
      return true;
    case '782a0666-fd8f-4c7c-98c3-6721ebd025a1':
      return false;
    case '410fca53-106b-4b55-961c-b1ad01d4a1fb':
      return true;
    case '72e2a42e-f8d9-43b6-8071-6c59f113f67c':
      return false;
    case '4515db45-f867-4525-aef3-cd4fc81c097c':
      return false;
    case '62836de4-0a7e-491c-94e4-d3854d5197d2':
      return true;
    default:
      return false;
  }
};

export const getEmoji = (theme: string) => {
  switch (theme) {
    case 'Income':
      return <img src={Cash} alt='💵' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Growth':
      return <img src={Rocket} alt='🚀' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'ESG':
      return <img src={Recycle} alt='♻️' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Diversification':
      return <img src={Umbrella} alt='☔️️' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Downside Protection':
      return <img src={Umbrella} alt='☔️️' className='mr-1' style={{ width: 11, height: 11 }} />;
    case 'Alloc':
      return <img src={Hourglass} alt='⏳️️' className='mr-1' style={{ width: 11, height: 11 }} />;
    default:
      return <></>;
  }
};
