import { eventList, statusList } from 'common/constants';

export const modifyClientOrderData = (data: any) => {
  const newData = data?.map((item: any) => {
    const orderStatus = statusList.filter((status: any) => status.orderStatusId === item.orderStatusId);
    const eventName = eventList.filter((event: any) => event.orderEventId === item.orderEventId);
    item.orderStatus = orderStatus[0];
    item.eventName = eventName[0]?.eventName;
    item.status = orderStatus[0]?.statusString;
    item.eventAmount =
      item.eventAmount === null
        ? null
        : item.eventAmount !== ""
          ? parseInt(item.eventAmount)
          : null;
    return item;
  });
  return newData;
}


export const getOrderStatus = (id: number) => {
  return statusList?.filter((x: any) => x.orderStatusId === id)[0]?.statusString;
};

//BETA PLACEHOLDER AND FUNCTIONS FOR NARRATIVE 1.0

export const ALLOCATION_REQUEST_ORDERS = [
  {
    id: 914,
    subAmount: '--',
    type: 'Allocation Request',
  },
  {
    id: 915,
    subAmount: '$500,000',
    type: 'Subscription',
  },
  {
    id: 916,
    subAmount: '--',
    type: 'Allocation Request',
  },
  {
    id: 917,
    subAmount: '--',
    type: 'Allocation Request',
  },
  {
    id: 918,
    subAmount: '$175,000',
    type: 'Subscription',
  },
];

export const getAllocRequest = (id: number) => {
  return ALLOCATION_REQUEST_ORDERS.findIndex((obj: any) => obj.id === id);
};
