import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import LoadingButton from 'common/components/button/LoadingButton';
import SubHeader from 'common/components/layout/SubHeader';
import { QueryType } from 'common/types';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { COLUMNS, CRUMBS } from './constants';
import SubMasterListTable from './SubMasterListTable';

const StyledGrid = styled(Grid)``;

const SubscriptionMasterChecklist = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [rows, setRows] = useState<any>([]);
  const [newRows, setNewRows] = useState<Array<any>>([]);
  const [count, setCount] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { data, isLoading, isRefetching } = useReactQuery(
    ['masterSubscriptionChecklist'],
    {
      url: `qaip/v1/documentmanagement/getSubscriptionChecklist`,
    },
    {
      refetchOnMount: true,
    },
  );

  const { mutate: update, isLoading: isUpdating } = useRQMutation(
    {
      url: `qaip/v1/documentmanagement/updateSubscriptionChecklist`,
      method: QueryType.PUT,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('masterSubscriptionChecklist');
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Changes have been saved.',
        };
        dispatch(showToast(toast));
      },
    },
  );

  const { mutate: deleteRow, isLoading: isDeleting } = useRQMutation(
    {
      url: `qaip/v1/documentmanagement/removeSubscriptionChecklist`,
      method: QueryType.DELETE,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('masterSubscriptionChecklist');
      },
    },
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const arr = data
        .filter((row: any) => !row.fundId)
        .map((row: any, i: number) => ({
          ...row,
          UID: `${i}-${new Date().getTime()}`,
        }));
      setRows([...arr, ...newRows]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.length]);

  const handleUpdateRows = (row: any, index: number) => {
    const arr = [...rows];
    arr[index] = row;
    setRows(arr);
  };

  const handleDeleteRow = (data: any) => {
    if (data.deleteFromServer) {
      deleteRow([data.id]);
    } else {
      setRows(rows.filter((row: any) => row.UID !== data.id));
    }
  };

  const handleNullCheck = (rows: any) => {
    const result = rows.some(
      (o: any) =>
        o.title === '' ||
        o.type === '' ||
        o.visibleToEntities === '' ||
        o.visibleToHybrids === '' ||
        o.visibleToNaturalPersons === '',
    );
    return result;
  };

  useEffect(() => {
    setCount(count || rows.length);
    setNewRows(rows.filter((row: any) => !row?.id));
    setIsDisabled(handleNullCheck(rows));
    //eslint-disable-next-line
  }, [rows]);

  return (
    <StyledGrid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mr-20'>Subscription Master Checklist</h1>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid item xs={12} mb={4}>
            <SubMasterListTable
              columns={COLUMNS}
              setRows={setRows}
              rows={rows}
              disableHeader
              setUpdateRow={(row: any, index: number) => handleUpdateRows(row, index)}
              setDeleteRow={(index: any) => handleDeleteRow(index)}
              isLoading={isLoading || isRefetching}
              isDeleting={isDeleting}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiButton
              variant='text'
              buttonClick={() => {
                const newRow = {
                  title: '',
                  type: '',
                  visibleToNaturalPersons: '',
                  visibleToEntities: '',
                  visibleToHybrids: '',
                  UID: `${count}-${new Date().getTime()}`,
                };
                setRows([...rows, newRow]);
              }}
            >
              Add Row
            </MuiButton>
          </Grid>
          <Grid item xs={12} mb={8} className='flex-center-end'>
            <MuiButton variant='text' buttonClick={() => navigate('/admin')}>
              Discard Changes
            </MuiButton>
            {isUpdating ? (
              <LoadingButton minWidth='150px' className='ml-3' />
            ) : (
              <MuiButton
                variant='contained'
                minWidth='150px'
                className='ml-3'
                disabled={isDisabled}
                buttonClick={() => {
                  setNewRows([]);
                  update(rows);
                }}
              >
                Save Changes
              </MuiButton>
            )}
          </Grid>
        </Container>
      </Grid>
    </StyledGrid>
  );
};

export default SubscriptionMasterChecklist;
