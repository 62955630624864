import { Dialog, Grid } from '@mui/material';
import MuiCard from 'common/components/card';
import { formatAumValues } from 'helpers';
import MuiTooltip from 'common/components/tooltip';
import { SINCE_INCEPTION_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';
import Iframe from 'common/components/IFrame';
import { useState } from 'react';
import { PlayArrow } from '@mui/icons-material';

type Props = {
  fundDetails: any;
  annualReturns: any;
  fundReturns: any;
};

const KeyStats = ({ fundDetails, annualReturns, fundReturns }: Props) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);

  const handleVideoModalOpen = () => {
    setIsVideoModalOpen((prevState: boolean) => !prevState);
  };

  const checkKeyStatsNull = (assetClass: string) => {
    if (assetClass === 'Hedge Funds') {
      return (
        annualReturns ||
        fundReturns ||
        fundReturns['1_year'] ||
        fundReturns['since_inception'] ||
        fundDetails?.fund_assets ||
        !isNaN(fundDetails?.fund_assets || fundDetails?.s_and_p_500)
      );
    } else {
      return (
        fundDetails?.target_irr ||
        fundDetails?.target_fund_size ||
        fundDetails?.investment_period ||
        fundDetails?.fund_term ||
        fundDetails?.s_and_p_500
      );
    }
  };

  return (
    <>
      {!fundDetails?.fund_video && (
        <Grid item xs={12}>
          <h2 className='font-wt-500'>Key Stats</h2>
        </Grid>
      )}

      <MuiCard
        minHeight='unset'
        className={`perfStats ${checkKeyStatsNull(fundDetails?.asset_class) ? '' : 'noKeyStats'}`}
        sx={{ p: 4, mb: 6, width: '100%', border: 'none' }}
      >
        <Grid item xs={12} columnSpacing={fundDetails?.fund_video ? 4 : 0} container>
          {fundDetails?.fund_video && (
            <Grid item xs={'auto'} className='position-relative'>
              <div className='overlay' onClick={() => handleVideoModalOpen()}>
                <PlayArrow />
              </div>
              <Iframe
                src={`${fundDetails?.fund_video}?byline=0&portrait=0&title=0&autoplay=0&rel=0&showinfo=0&controls=0`}
                title='Fund Video Thumbnail'
                width='100%'
                onInferredClick={() => handleVideoModalOpen()}
                style={{
                  width: '24vw',
                  height: '15vw',
                  border: 'none',
                  cursor: 'pointer',
                  maxWidth: '415px',
                  maxHeight: '260px',
                }}
              ></Iframe>
            </Grid>
          )}
          {checkKeyStatsNull(fundDetails?.asset_class) ? (
            <Grid item xs={fundDetails?.fund_video ? 8 : 12}>
              {fundDetails?.asset_class === 'Hedge Funds' ? (
                <Grid
                  container
                  columns={fundDetails?.fund_video ? 3 : 5}
                  className='perfStats'
                  spacing={fundDetails?.fund_video ? 4 : 0}
                >
                  {fundDetails?.fund_video && (
                    <Grid item xs={3}>
                      <h2 className='font-wt-500 mb-0'>Key Stats</h2>
                    </Grid>
                  )}
                  {annualReturns && annualReturns.length > 0 && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>YEAR TO DATE RETURN</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {annualReturns && annualReturns.length > 0
                          ? annualReturns[annualReturns.length - 1].value + '%'
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                  {fundReturns && fundReturns['1_year'] && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>1-YEAR Return</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundReturns && fundReturns['1_year'] ? fundReturns['1_year'] + '%' : '--'}
                      </h3>
                    </Grid>
                  )}
                  {fundReturns && fundReturns['since_inception'] && (
                    <Grid item xs={1}>
                      <h5 className='subtitle position-relative'>
                        SINCE INCEPTION <MuiTooltip content={SINCE_INCEPTION_TOOLTIP_CONTENT} />
                      </h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundReturns && fundReturns['since_inception']
                          ? `${fundReturns['since_inception']}%`
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets) && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>FUND AUM</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)
                          ? formatAumValues(
                              fundDetails?.fund_assets.replace(/,/g, '').replace(/\$/g, ''),
                              0,
                            )
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.s_and_p_500 && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>S&P 500 Correlation</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.s_and_p_500
                          ? Number(fundDetails?.s_and_p_500).toFixed(2)
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid
                  container
                  columns={fundDetails?.fund_video ? 3 : 5}
                  className='perfStats'
                  spacing={fundDetails?.fund_video ? 4 : 0}
                >
                  {fundDetails?.fund_video && (
                    <Grid item xs={3}>
                      <h2 className='font-wt-500 mb-0'>Key Stats</h2>
                    </Grid>
                  )}
                  {fundDetails?.target_irr && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>TARGET IRR</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.target_irr ? fundDetails?.target_irr : '--'}%
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.target_fund_size && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>TARGET AUM</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.target_fund_size
                          ? formatAumValues(fundDetails?.target_fund_size, 0)
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.investment_period && (
                    <Grid item xs={1}>
                      <h5 className='subtitle position-relative'>INVESTMENT PERIOD</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.investment_period ? fundDetails?.investment_period : '--'}{' '}
                        Years
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.fund_term && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>TERM</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.fund_term ? fundDetails?.fund_term : '--'} Years
                      </h3>
                    </Grid>
                  )}
                  {fundDetails?.s_and_p_500 && (
                    <Grid item xs={1}>
                      <h5 className='subtitle'>S&P 500 Correlation</h5>
                      <h3 className='font-wt-300 mb-0'>
                        {fundDetails?.s_and_p_500
                          ? Number(fundDetails?.s_and_p_500).toFixed(2)
                          : '--'}
                      </h3>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={fundDetails?.fund_video ? 8 : 12} className='flex-center-center'>
              <div className='text-center'>
                <h3>KEY STATS</h3>
                <h5>No Data Available</h5>
              </div>
            </Grid>
          )}
        </Grid>
      </MuiCard>
      <Dialog open={isVideoModalOpen} onClose={handleVideoModalOpen} maxWidth='xl'>
        <iframe
          src={`${fundDetails?.fund_video}?byline=0&portrait=0&title=1&autoplay=1&rel=0&showinfo=1&controls=1`}
          title='Fund Video'
          width='100%'
          allowFullScreen
          style={{
            height: '34vw',
            width: '54.5vw',
            border: 'none',
            cursor: 'pointer',
            background: 'black',
          }}
        ></iframe>
      </Dialog>
    </>
  );
};

export default KeyStats;
