import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import { CircularProgress, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import SelectField from 'common/components/inputField/SelectField';
import MuiUpload from 'common/components/inputField/Upload';
import MuiModal from 'common/components/modal';
import { FundDetails } from 'common/types';

const StyledWrapper = styled(Grid)`
  .fileName {
    display: flex;
    align-items: center;
    svg {
      font-size: 13px;
      color: var(--green);
      margin-right: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #8e8e8e;
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  isLoading?: boolean;
  handleModalClickClose: any;
  fundData: FundDetails[];
  selectedFund: FundDetails;
  setSelectedFund: any;
  setDate: any;
  onFileUpload: any;
  date: Date;
  fileName?: any;
};

const AddNewTaxDocumentModal = ({
  isModalOpen,
  isLoading,
  handleModalClickClose,
  fundData,
  selectedFund,
  setSelectedFund,
  setDate,
  onFileUpload,
  date,
  fileName,
}: Props) => {
  return (
    <MuiModal
      title='Add a New Tax Document'
      isModalOpen={isModalOpen}
      handleClose={handleModalClickClose}
      maxWidth='sm'
    >
      <StyledWrapper container>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <SelectField
            label='Select Fund'
            options={fundData && fundData}
            value={selectedFund?.fund_id}
            setSelectedValue={setSelectedFund}
            optionName='fund_name'
            optionId='fund_id'
            name='fund'
          />
        </Grid>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <h4 className='font-wt-400 mb-0'>Select Year</h4>
          <MuiDatePicker
            views={['year']}
            inputFormat='yyyy'
            setDate={setDate}
            value={date}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} mb={5} container justifyContent='space-between' alignItems='center'>
          <Grid item xs='auto'>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <MuiUpload onFileUpload={onFileUpload}>Upload Now</MuiUpload>
            )}
          </Grid>
          {fileName && (
            <Grid item xs='auto' className='fileName'>
              <CheckCircle />
              <span>{fileName?.name}</span>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className='flex-center-end'>
          <MuiButton variant='contained' minWidth='150px' buttonClick={handleModalClickClose}>
            Done
          </MuiButton>
        </Grid>
      </StyledWrapper>
    </MuiModal>
  );
};

export default AddNewTaxDocumentModal;
