import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid/Grid';
import EnterEmail from './EnterEmail';
import { useState } from 'react';
import ResetPassword from './ResetPassword';
import Logo from 'common/assets/images/QualisLogo.svg';
import { useAppSelector } from 'store/hooks';
import { selectForgotPasswordActiveStepper } from 'store/user/selectors';

const steps = ['Email Id', 'Verification'];

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const activeStep: number = useAppSelector(selectForgotPasswordActiveStepper);

  return (
    <div className='authBG'>
      <Grid container justifyContent='center' className='form'>
        <Grid item xs={10} mb={8}>
          <img src={Logo} alt='Tifin Private Markets' />
        </Grid>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          {activeStep === 0 ? <EnterEmail setEmail={setEmail} /> : <ResetPassword email={email} />}
        </Grid>
      </Grid>
    </div>
  );
}
