import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as PlaneIcon } from 'common/assets/images/CTA/Plane.svg';
import AlertIcon from 'common/assets/images/Alert.svg';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
  isUpdating: boolean;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.5rem !important;
  }
  p {
    font-size: 0.875rem;
    color: var(--s30) !important;
    &.desc {
      color: var(--s40) !important;
    }
  }
`;

const SaveAndAllocateModal = ({ isModalOpen, handleClose, buttonClick, isUpdating }: Props) => {
  return (
    <StyledModal
      title='Assign Allocations?'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container mb={4}>
        <p className='mb-0'>Allocations will be communicated to advisors.</p>
      </Grid>
      <Grid container alignItems='center' mb={4} flexWrap='nowrap'>
        <img src={AlertIcon} alt='' className='mr-3' />
        <p className='desc mb-0'>
          Once assigned, allocations cannot be changed through the platform. Please contact Tifin
          Private Markets team to make changes.
        </p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='text' buttonClick={handleClose}>
          Back
        </MuiButton>
        {isUpdating ? (
          <LoadingButton className='ml-3' minWidth='177px' />
        ) : (
          <MuiButton
            variant='contained'
            className='ml-3'
            buttonClick={buttonClick}
            minWidth='177px'
          >
            <PlaneIcon className='mr-2' />
            Save and Allocate
          </MuiButton>
        )}
      </Grid>
    </StyledModal>
  );
};

export default SaveAndAllocateModal;
