import UsersIcon from 'common/assets/images/admin/users.svg';
import EventsIcon from 'common/assets/images/admin/events.svg';
import RolesIcon from 'common/assets/images/admin/roles.svg';
import TeamsIcon from 'common/assets/images/admin/teams.svg';
import AplIcon from 'common/assets/images/admin/apl.svg';
import ActivityLogIcon from 'common/assets/images/admin/activity_logs.svg';
import OrgIcon from 'common/assets/images/admin/organisation.svg';
import ApprovalInvestorIcon from 'common/assets/images/admin/approval_investor.svg';
import ApprovalFundIcon from 'common/assets/images/admin/approval_fund.svg';
import SubMasterIcon from 'common/assets/images/admin/sub_master.svg';

export const adminLinks = {
  qualis: [
    {
      title: 'Investor Approval',
      link: '/admin/approve-clients',
      icon: ApprovalInvestorIcon,
    },
    {
      title: 'Fund Approval',
      link: '/admin/approve-funds',
      icon: ApprovalFundIcon,
    },
    {
      title: 'Organizations',
      link: '/admin/organizations',
      icon: OrgIcon,
    },
    {
      title: 'Roles',
      link: '/admin/roles',
      icon: RolesIcon,
    },
    {
      title: 'APL',
      link: '/admin/apls',
      icon: AplIcon,
    },
    {
      title: 'Activity Logs',
      link: '/admin/activity-logs',
      icon: ActivityLogIcon,
    },
    {
      title: 'Teams',
      link: '/admin/teams',
      icon: TeamsIcon,
    },
    {
      title: 'Events',
      link: '/admin/events',
      icon: EventsIcon,
    },
    {
      title: 'Users',
      link: '/admin/users',
      icon: UsersIcon,
    },
    {
      title: 'Subscription Master Checklist',
      link: '/admin/subscriptionMasterChecklist',
      icon: SubMasterIcon,
    },
  ],
  fmWithUserManagement: [
    {
      title: 'Roles',
      link: '/admin/roles',
      icon: RolesIcon,
    },
    {
      title: 'Users',
      link: '/admin/users',
      icon: UsersIcon,
    },
  ],
  fmWithoutUserManagement: [],
  dgWithUserManagement: [
    {
      title: 'Investor Approval',
      link: '/admin/approve-clients',
      icon: ApprovalInvestorIcon,
    },
    {
      title: 'Fund Approval',
      link: '/admin/approve-funds',
      icon: ApprovalFundIcon,
    },
    {
      title: 'Roles',
      link: '/admin/roles',
      icon: RolesIcon,
    },
    {
      title: 'Users',
      link: '/admin/users',
      icon: UsersIcon,
    },
    {
      title: 'Teams',
      link: '/admin/teams',
      icon: TeamsIcon,
    },
  ],
  dgWithoutUserManagement: [
    {
      title: 'Investor Approval',
      link: '/admin/approve-clients',
      icon: ApprovalInvestorIcon,
    },
    {
      title: 'Fund Approval',
      link: '/admin/approve-funds',
      icon: ApprovalFundIcon,
    },
  ],
};
