import getAxiosInstance from "common/utils/axios";
import { loginHandler } from "helpers/login";
import store from "store";
import { showToast, ToastMessage, ToastType } from "store/toast/slice";
import { AuthUser } from "store/user/types";



type InputData = {
    username: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
}

type ConfirmationData = {
    username: string,
    challangeName: string,
    newPassword: string,
    otp: string
}

export const validateUser = async (userForm: ConfirmationData, headers: any): Promise<AuthUser> => {
    const axios = await getAxiosInstance();
    return await axios.post(`qaip/v1/usermanagement/authrespondchallenge`, userForm, { headers });
}

export const RegistrationHandler = async (inputData: InputData) => {
    try {
        await loginHandler(inputData).then((data) => {
            if (!(data as any)?.sessionToken) {
                const toast: ToastMessage = {
                    type: ToastType.ERROR,
                    message: 'Password reset failed. Please reset password again or contact Tifin Private Markets.',
                };
                store.dispatch(showToast(toast));
                return;
            }
            const option = {
                sessionKey: data.sessionToken
            };
            let payload: ConfirmationData = {} as ConfirmationData;
            if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                payload = {
                    username: inputData.username,
                    challangeName: "NEW_PASSWORD_REQUIRED",
                    newPassword: inputData.newPassword,
                    otp: ""
                };
            }
            validateUser(payload, option).then((data) => {
                if ((data as any).status === 200) {
                    window.location.href = "/";
                } else {
                    const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Unable to register the user. Please verify the username and password and try again.',
                    };
                    store.dispatch(showToast(toast));
                }
            })
        });
    } catch (error) {
        const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'Unable to register the user. Please verify the username and password and try again.',
        };
        store.dispatch(showToast(toast));
        console.log(error);
    }
}