import { Container, Grid, SelectChangeEvent, Skeleton, Switch } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import Textbox from 'common/components/inputField/Textbox';
import MuiUpload from 'common/components/inputField/Upload';
import React, { useEffect, useState, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { selectFundsDetails } from 'store/funds/selectors';
import {
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setFundPriceMonthlyReturns,
  setFundPriceAnnualReturns,
  setFundPriceInceptionReturns,
  setFundDocuments,
  setNewFundID,
  setFundPriceDetails,
} from 'store/funds/slice';
import {
  useReactQuery,
  useAppSelector,
  useTypedDispatch,
  useMutationQuery,
  useRQMutation,
} from 'store/hooks';
import { ErrorMessage } from 'common/components/errorMessageBox';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import MultipleSelectField from 'common/components/inputField/MultipleSelectField';
import ControlledSelectField from 'common/components/inputField/ControlledSelectField';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import RichTextEditor from 'common/components/richTextEditor/RichEditor';
import { QueryType } from 'common/types';
import GroupSelectField from 'common/components/inputField/GroupSelectField';
import { useQueryClient } from 'react-query';
import statusOptions from './statusOptions.json';
import FundLogoPlaceholder from 'common/assets/images/FundLogoPlaceholder.svg';

type Props = {
  handleSubmitBtnClick: any;
  editing?: boolean;
  status?: string;
};

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
  }
  .sub-heading {
    font-size: 18px;
    padding-bottom: 0.5rem;
    margin-bottom: 20px;
    font-weight: 500;
    border-bottom: 2px solid var(--s20);
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
  .logo-preview {
    width: 150px;
    height: 80px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    margin: 0 !important;
  }
  .logo-img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  .logo-upload-div {
    margin-top: 20px;
    display: flex;
    align-items: center;
    h6 {
      max-width: 80%;
    }
  }
  .logo-name {
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    margin-left: 12px;
    margin-bottom: 0;
  }
  .checkbox {
    svg {
      font-size: 32px;
    }
  }
`;

const InvestmentDetialsForm = ({ handleSubmitBtnClick, editing, status }: Props) => {
  const queryClient = useQueryClient();
  const [selectedAssetClass, setSelectedAssetClass] = useState<any>('');
  const [selectedAssetClassArr, setSelectedAssetClassArr] = useState<string[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<any>('');
  const [selectedThemeArr, setSelectedThemeArr] = useState<string[]>([]);
  const [logoName, setLogoName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<any>(null);
  const logoImage = useRef<HTMLImageElement>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [strategyDescription, setStrategyDescription] = useState(() => EditorState.createEmpty());
  const [strategyDescriptionText, setStrategyDescriptionText] = useState<string>('');
  // error states
  const [strategyDescriptionError, setStrategyDescriptionError] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);
  const [fund, setFundStatus] = useState<string>('');
  const navigate = useNavigate();

  const storeFundDetails = useAppSelector(selectFundsDetails);
  const dispatch = useTypedDispatch();

  // Edit Funds state
  const location = useLocation();
  const id = location.pathname.split('/')[3];
  const { data } = useReactQuery(
    [`fundDetails-${id}`],
    {
      url: `qaip/v1/fundsmanagement/funds/id/${id}`,
    },
    {
      enabled: editing,
    },
  );

  const { data: documentsData } = useReactQuery(
    [`documents-${id}`],
    {
      url: `qaip/v1/documentmanagement/getList/fund?fund_id=${id}&document_type=file`,
    },
    {
      enabled: editing,
    },
  );

  const { data: allPrice } = useReactQuery(
    [`allPrices-${id}`],
    {
      url: `qaip/v1/holdingsmanagement/allprices/fund/${id}`,
    },
    {
      enabled: editing,
      refetchOnMount: true,
    },
  );

  const fundDetails = data?.fund_details;
  const monthlyReturns = data?.monthly_returns;
  const annualReturns = data?.annual_returns;
  const inceptionReturns = data?.returns_since_inception;
  const fundStats = data?.fund_stats;
  const isAllocationWorkflowEnabled =
    fundDetails?.isAllocationWorkflowEnabled === 'no' ? false : true;

  const checkFundStatus = (fund: any) => {
    if (fund === 'Inactive') {
      return 'InActive';
    } else if (fund === 'Onboarding Awaiting Apporval') {
      return 'Onboarding - Awaiting Approval';
    } else {
      return fund;
    }
  };
  const { mutate: setFundStatusRequest } = useRQMutation(
    {
      url: `/qaip/v1/fundsmanagement/fund/id/${id}?status=${checkFundStatus(fund)}`,
      method: QueryType.PUT,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`fundDetails-${id}`);
      },
    },
  );

  useEffect(() => {
    if (documentsData) {
      setFundDocumentsData();
    }
    // eslint-disable-next-line
  }, [documentsData]);

  useEffect(() => {
    const stratText = strategyDescription.getCurrentContent().getPlainText();
    setStrategyDescriptionText(stratText);
  }, [strategyDescription]);

  const setFundDocumentsData = async () => {
    await dispatch(setFundDocuments(documentsData));
    await dispatch(setNewFundID(id));
  };

  useEffect(() => {
    if (allPrice) {
      setAllPricesData();
    }
    // eslint-disable-next-line
  }, [allPrice]);

  const setAllPricesData = async () => {
    await dispatch(setFundPriceDetails(allPrice));
  };

  useEffect(() => {
    if (data) {
      setSelectedAssetClass(fundDetails.asset_class);
      setSelectedAssetClassArr(fundDetails['asset_class'].split(','));
      setSelectedTheme(fundDetails?.fund_themes);
      setChecked(fundDetails.isAllocationWorkflowEnabled === 'yes' ? true : false);
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(fundDetails.strategy_description)),
      );
      fundDetails['fund_themes'] && setSelectedThemeArr(fundDetails['fund_themes'].split(','));
      setLogoName(fundDetails.logo);
      setValue('iso_currency', fundDetails.iso_currency);
      setValue('class', fundDetails.class);
      setValue('eligibility', fundDetails.eligibility);
      setValue('strategy', fundDetails.strategy);
      setValue('rating', fundDetails.rating);
      setValue('fund_manager_id', fundDetails.fund_manager_id);
      setValue('fund_manager_title', fundDetails.fund_manager_title);
      setValue('fund_status', fundDetails.fund_status);
      setValue('strategy_description', fundDetails.strategy_description);
      setValue('fund_manager_contact', fundDetails.fund_manager_contact);
      setValue('fund_manager_phone', fundDetails.fund_manager_phone);
      setValue('fund_manager_email', fundDetails.fund_manager_email);
      setValue('fund_name', fundDetails.fund_name);
      setValue('sub_strategy', fundDetails.sub_strategy);
      setValue('final_close', fundDetails.final_close);
      setValue('min_investment', fundDetails.min_investment);
      setValue('fund_video', fundDetails.fund_video);
      setValue('tag_line', fundDetails.tag_line);

      mutate();

      // Invesrment struct and stats form
      setStoreFundStructAndStats();

      // Investment Terms and services form
      setStoreFundTSP();

      // Investment historical returns form
      setInvestmentHistoricalReturns();
    }
    // eslint-disable-next-line
  }, [data]);

  const setStoreFundStructAndStats = async () => {
    const storeFundStructAndStats = {
      liquidity_for_summary: fundDetails.liquidity_for_summary,
      lockup_for_summary: fundDetails.lockup_for_summary,
      funding_type: fundDetails.funding_type,
      income_distribution: fundDetails.income_distribution,
      funds_transfer_due_date: fundDetails.funds_transfer_due_date,
      fund_assets: fundDetails.fund_assets,
      firm_assets: fundDetails.firm_assets,
      final_close: fundDetails.final_close,
      management_fees: fundDetails.management_fees,
      preferred_return: fundDetails.preferred_return,
      gp_catch_up_term: fundDetails.gp_catch_up_term,
      incentives_fees: fundDetails.incentives_fees,
      investment_period: fundDetails.investment_period,
      fund_term: fundDetails.fund_term,
      redemptions: fundDetails.redemptions,
      lockup: fundDetails.lockup,
      bank_details: fundDetails.bank_details,
      compounded_rate_of_return: fundDetails.compounded_rate_of_return,
      standard_deviation: fundDetails.standard_deviation,
      sharpe_ratio: fundDetails.sharpe_ratio,
      profitable_months_percentage: fundDetails.profitable_months_percentage,
      maximum_drawdown: fundDetails.maximum_drawdown,
      next_close: fundDetails.next_close,
      s_and_p_500: fundDetails.s_and_p_500,
      target_fund_size: fundDetails.target_fund_size,
      cash_yield: fundDetails.cash_yield,
      target_irr: fundDetails.target_irr,
      impact_score: fundDetails.impact_score,
      fund_stats: fundStats ? fundStats : [{}],
    };

    dispatch(setFundStructAndStats(storeFundStructAndStats));
  };

  const setStoreFundTSP = async () => {
    const storeFundTSP = {
      auditor: fundDetails.auditor,
      administrator: fundDetails.administrator,
      fund_counsel: fundDetails.fund_counsel,
      prime_broker: fundDetails.prime_broker,
      disclaimer: fundDetails.disclaimer,
      managers_bio: fundDetails.managers_bio,
    };

    await dispatch(setFundTermsAndServices(storeFundTSP));
  };

  const setInvestmentHistoricalReturns = async () => {
    await dispatch(setFundPriceMonthlyReturns(monthlyReturns));
    await dispatch(setFundPriceAnnualReturns(annualReturns));
    await dispatch(setFundPriceInceptionReturns(inceptionReturns));
  };

  useEffect(() => {
    if (storeFundDetails) {
      setSelectedAssetClass(storeFundDetails.asset_class);
      setSelectedAssetClassArr(storeFundDetails['asset_class'].split(','));
      setSelectedTheme(storeFundDetails.fund_themes);
      setSelectedThemeArr(storeFundDetails['fund_themes'].split(','));
      setStrategyDescription(
        EditorState.createWithContent(stateFromHTML(storeFundDetails.strategy_description)),
      );
      !editing && setLogoName(storeFundDetails.logo_file.name);
      setValue('iso_currency', storeFundDetails.iso_currency);
      setValue('class', storeFundDetails.class);
      setValue('fund_status', storeFundDetails.fund_status);
      setValue('eligibility', storeFundDetails.eligibility);
      setValue('strategy', storeFundDetails.strategy);
      setValue('rating', storeFundDetails.rating);

      setValue('fund_manager_id', storeFundDetails.fund_manager_id);
      setValue('fund_manager_title', storeFundDetails.fund_manager_title);
      setValue('strategy_description', storeFundDetails.strategy_description);
      setValue('fund_manager_contact', storeFundDetails.fund_manager_contact);
      setValue('fund_manager_phone', storeFundDetails.fund_manager_phone);
      setValue('fund_manager_email', storeFundDetails.fund_manager_email);
      setValue('fund_name', storeFundDetails.fund_name);
      setValue('sub_strategy', storeFundDetails.sub_strategy);
      setValue('final_close', storeFundDetails.final_close);
      setValue('min_investment', storeFundDetails.min_investment);
      setValue('fund_video', storeFundDetails.fund_video);
      setValue('tag_line', storeFundDetails.tag_line);

      if (!editing && logoImage.current) {
        logoImage.current.src = URL.createObjectURL(storeFundDetails.logo_file);
        setLogoFile(storeFundDetails.logo_file);
      }
    }
    // eslint-disable-next-line
  }, [storeFundDetails]);

  const { data: organisationsList } = useReactQuery(['organisationsList'], {
    url: 'qaip/v1/usermanagement/organisations?categoryId=2',
  });

  const { data: masterData } = useReactQuery(['masterData'], {
    url: 'qaip/v1/fundsmanagement/funds/masterData',
  });

  const {
    mutate,
    data: logo,
    isLoading: logoLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: fundDetails?.logo },
    },
    {
      enabled: false,
    },
  );

  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLogoError(false);
    const uploadedFile: any = e.target.files;
    if (logoImage.current) {
      logoImage.current.src = URL.createObjectURL(uploadedFile[0]);
    }
    setLogoFile(uploadedFile[0]);
    const uploadedFileName: string = uploadedFile.item(0).name;
    setLogoName(uploadedFileName);
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    if (editing) {
      navigate(`/investment/${id}`);
    } else {
      navigate(`/investments`);
    }
  };

  const handleChangeStrategyDescription = (newEditorState: any) => {
    setStrategyDescription(newEditorState);
  };

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (!editing && logoName === '') {
      setLogoError(true);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Please upload the logo.',
      };
      dispatch(showToast(toast));
      window.scrollTo(0, 0);
      return;
    }

    const newFundDetails = {
      ...data,
      asset_class: selectedAssetClass,
      fund_themes: selectedTheme,
      logo: logoName,
      logo_file: logoFile,
      status: status ? status : fundDetails?.status ? fundDetails.status : 'Inactive',
      strategy_description: `${stateToHTML(strategyDescription.getCurrentContent())}`,
      isAllocationWorkflowEnabled: checked ? 'yes' : 'no',
    };

    if (strategyDescriptionText.length > 0) {
      dispatch(setFundsDetails(newFundDetails));
      handleSubmitBtnClick();
    }
  };

  const handleChangeMultipleSelect = (event: SelectChangeEvent, name: string) => {
    const {
      target: { value },
    } = event;

    if (name === 'asset_class') {
      setSelectedAssetClassArr(value as any);
      setSelectedAssetClass((value as any).join(','));
    } else {
      setSelectedThemeArr(value as any);
      setSelectedTheme((value as any).join(','));
    }
  };

  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyDownOnlyText = (e: any) => {
    if (!/^[a-zA-Z ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const URLRegex =
    // eslint-disable-next-line
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });
  useEffect(() => {
    if (fundDetails) {
      let fundStatusToBeUsed =
        fundDetails.status === 'Active'
          ? `Active,${fundDetails.fund_status}`
          : fundDetails.status === 'InActive'
          ? 'Inactive'
          : 'Onboarding Awaiting Apporval';
      setFundStatus(fundStatusToBeUsed);
    }
  }, [fundDetails]);

  return (
    <StyledWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container maxWidth='md' className='container-lr-padding my-5'>
          <Container maxWidth='lg' className='px-0'>
            <h2 className='heading'>Fund Details</h2>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Fund Manager Details</h4>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={9} className='mb-3'>
                <h4 className='font-wt-400 label'>Fund Logo Preview</h4>
                <div className='logo-upload-div'>
                  <MuiUpload variant='outlined' onFileUpload={handleOnFileUpload} accept='image/*'>
                    {logoName === '' ? 'Upload' : 'Re-upload'}
                  </MuiUpload>
                  {logoError && <ErrorMessage error='Please upload the logo' />}
                  <h6 className='logo-name'>{logoName}</h6>
                </div>
              </Grid>
              <Grid item xs={3} className='mb-3 flex-center-end'>
                <div className='logo-preview'>
                  {logoLoading ? (
                    <Skeleton variant='rectangular' sx={{ height: '100%' }} />
                  ) : (
                    <img
                      src={logo?.url || FundLogoPlaceholder}
                      alt='logo'
                      className='logo-img'
                      ref={logoImage}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} className='my-5'>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Funds Manager *</h4>
                <ControlledSelectField
                  options={
                    organisationsList &&
                    organisationsList.map((el: any) => ({
                      id: el.organisationId,
                      title: el.organisationName,
                    }))
                  }
                  defaultValue={storeFundDetails && storeFundDetails?.fund_manager_id}
                  name='fund_manager_id'
                  control={control}
                  errorMessage='This field is required'
                />
                {errors.fund_manager_id && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid container spacing={2} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Title</h4>
                <ControlledSelectField
                  control={control}
                  options={['Mr.', 'Mrs.', 'Miss']}
                  name='fund_manager_title'
                  searchIcon={false}
                  defaultValue={storeFundDetails && storeFundDetails?.fund_manager_title}
                />
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Manager Name *</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_manager_contact'
                  errorMessage='This field is required'
                  required={true}
                  keyDownFunc={handleKeyDownOnlyText}
                />
                {errors.fund_manager_contact && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid container spacing={2} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Manager’s Phone Number *</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='fund_manager_phone'
                  maxLength={11}
                  minLength={11}
                  errorMessage='This field is required'
                  startAdornment={'+'}
                  keyDownFunc={handleKeyDownOnlyNumbers}
                />
                {errors.fund_manager_phone && <ErrorMessage error='Enter a valid phone number' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Manager’s Email *</h4>
                <Textbox
                  fullWidth
                  type='email'
                  register={register}
                  name='fund_manager_email'
                  errorMessage='This field is required'
                  pattern={{
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  }}
                />
                {errors.fund_manager_email?.message && (
                  <ErrorMessage error='Please enter valid email' />
                )}
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='lg' className='my-5 px-0'>
            <h4 className='sub-heading'>Fund Details</h4>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Fund Name *</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_name'
                  errorMessage='This field is required'
                />
                {errors.fund_name && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Asset Class *</h4>
                <MultipleSelectField
                  register={register}
                  name='asset_class'
                  options={masterData?.asset_class}
                  onChange={(e: any) => {
                    handleChangeMultipleSelect(e, 'asset_class');
                  }}
                  required={selectedAssetClass === '' ? true : false}
                  value={selectedAssetClassArr}
                  valueArr={selectedAssetClassArr}
                />
                {errors.asset_class && !selectedAssetClass && (
                  <ErrorMessage error='This field is required' />
                )}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Class *</h4>
                <ControlledSelectField
                  control={control}
                  options={masterData?.class}
                  defaultValue={storeFundDetails && storeFundDetails?.class}
                  name='class'
                  searchIcon={false}
                  errorMessage='This field is required'
                />
                {errors.class && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label' style={{ marginBottom: '8px' }}>
                  Status *
                </h4>
                {/* <ControlledSelectField
                  options={masterData?.status}
                  defaultValue={storeFundDetails && storeFundDetails?.fund_status}
                  name='fund_status'
                  searchIcon={false}
                  control={control}
                  errorMessage='This field is required'
                /> */}
                <GroupSelectField
                  searchIcon={false}
                  setSelectedValue={async (val: any) => {
                    await setFundStatus(val);
                    setFundStatusRequest({});
                  }}
                  value={fund}
                  defaultValue={fund}
                  name='fund_status'
                  statusOptions={statusOptions}
                  className={`pt-0 groupSelect ${isAllocationWorkflowEnabled ? 'allocReq' : ''}`}
                  errorMessage='This field is required'
                />
                {fund === '' && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Eligibility *</h4>
                <ControlledSelectField
                  control={control}
                  defaultValue={storeFundDetails && storeFundDetails?.eligibility}
                  options={masterData?.eligibility}
                  name='eligibility'
                  errorMessage='This field is required'
                  searchIcon={false}
                />
                {errors.eligibility && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Strategy *</h4>
                <ControlledSelectField
                  control={control}
                  options={masterData?.strategy}
                  defaultValue={storeFundDetails && storeFundDetails?.strategy}
                  name='strategy'
                  searchIcon={false}
                />
                {errors.strategy && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Sub Strategy *</h4>
                <Textbox
                  fullWidth
                  required={true}
                  register={register}
                  name='sub_strategy'
                  errorMessage='This field is required'
                />
                {errors.sub_strategy && <ErrorMessage error='This field is required' />}
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} className='my-5'>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Minimum Investment *</h4>
                <Textbox
                  fullWidth
                  register={register}
                  required={true}
                  name='min_investment'
                  errorMessage='This field is required'
                  startAdornment={'$'}
                  keyDownFunc={handleKeyDownOnlyNumbers}
                />
                {errors.min_investment && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Rating *</h4>
                <ControlledSelectField
                  control={control}
                  options={masterData?.rating}
                  name='rating'
                  defaultValue={storeFundDetails && storeFundDetails?.rating}
                  errorMessage='This field is required'
                  searchIcon={false}
                />
                {errors.rating && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6} className='mt-5' display='flex' alignItems='center'>
                <h4 className='font-wt-400 label'>Enable Request Allocation Workflow</h4>
                <Switch
                  checked={checked}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(!checked)}
                  disabled={editing}
                />
              </Grid>
              <Grid item xs={12} className='my-5'>
                <h4 className='font-wt-400 label'>Fund Video Link</h4>
                <Textbox
                  fullWidth
                  register={register}
                  name='fund_video'
                  pattern={{
                    value: URLRegex,
                    message: 'Please enter a valid URL',
                  }}
                />
                {errors.fund_video?.message && <ErrorMessage error='Please enter valid URL' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>Themes *</h4>
                <MultipleSelectField
                  register={register}
                  required={selectedThemeArr.length === 0 ? true : false}
                  options={masterData?.fundThemes}
                  name='fund_themes'
                  onChange={(e: any) => {
                    handleChangeMultipleSelect(e, 'fund_themes');
                  }}
                  setSelectedValue={setSelectedTheme}
                  value={selectedThemeArr}
                  searchIcon={false}
                  valueArr={selectedThemeArr}
                />
                {errors.fund_themes && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={6}>
                <h4 className='font-wt-400 label'>ISO</h4>
                <ControlledSelectField
                  control={control}
                  defaultValue={storeFundDetails && storeFundDetails?.iso_currency}
                  options={masterData?.isoCurrency}
                  name='iso_currency'
                  searchIcon={false}
                />
              </Grid>
            </Grid>
            <Grid container className='my-5'>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Strategy Description *</h4>
                <RichTextEditor
                  editorState={strategyDescription}
                  handleChangeEditorState={(newEditorState: any) =>
                    handleChangeStrategyDescription(newEditorState)
                  }
                />
                {strategyDescriptionError && <ErrorMessage error='This field is required' />}
                {strategyDescriptionText.length > 5000 && (
                  <ErrorMessage error='Input limit exceeded. Character Limit is 5000' />
                )}
              </Grid>
            </Grid>
            <Grid container className='my-5'>
              <Grid item xs={12}>
                <h4 className='font-wt-400 label'>Tag Line *</h4>
                <Textbox
                  fullWidth
                  multiline
                  rows={4}
                  register={register}
                  required={true}
                  name='tag_line'
                  errorMessage='This field is required'
                  maxLength={400}
                />
                {errors.tag_line && errors.tag_line.type === 'required' && (
                  <ErrorMessage error='This field is required' />
                )}
                {errors.tag_line && errors.tag_line.type === 'maxLength' && (
                  <ErrorMessage error='Input limit exceeded. Character Limit is 400' />
                )}
              </Grid>
            </Grid>
          </Container>
          <Grid container justifyContent='flex-end'>
            <MuiButton variant='text' color='error' buttonClick={handleDiscardChanges}>
              Discard Changes
            </MuiButton>
            <MuiButton
              minWidth='150px'
              variant='contained'
              type='submit'
              className='ml-3'
              buttonClick={() => {
                if (strategyDescriptionText.length === 0) setStrategyDescriptionError(true);
              }}
            >
              Next
            </MuiButton>
          </Grid>
        </Container>
      </form>
    </StyledWrapper>
  );
};

export default InvestmentDetialsForm;
