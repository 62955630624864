import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import Textbox from 'common/components/inputField/Textbox';
import { AuthorizedRepresentativeDetails } from 'store/investor/types';

interface Props {
  additional?: boolean;
  register: any;
  errors: any;
  index: number;
  authorizedRepresentative?: AuthorizedRepresentativeDetails;
  onAuthorizedRepresentativeChange: (property: string, idx: number, value: any) => void;
  onAuthorizedRepresentativeRemove: (index: number) => void;
  handleAddAuthorizedRepresentativeDetails?: any;
  detailsState?: any;
  setDetailsState?: any;
}

const AuthorizedRepresentative = ({
  additional = false,
  register,
  errors,
  index,
  authorizedRepresentative,
  onAuthorizedRepresentativeChange,
  onAuthorizedRepresentativeRemove,
  handleAddAuthorizedRepresentativeDetails,
  detailsState,
  setDetailsState,
}: Props) => {
  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return authorizedRepresentative ? (
    <Grid container className='my-5'>
      {additional ? (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Additional Authorized Representative Details</h3>
          <MuiButton
            variant='text'
            className='p-0'
            buttonClick={() => onAuthorizedRepresentativeRemove(index)}
          >
            Remove
          </MuiButton>
        </Grid>
      ) : (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Authorized Representative Details</h3>
          <MuiButton
            variant='text'
            className='p-0'
            buttonClick={() => onAuthorizedRepresentativeRemove(index)}
          >
            Remove
          </MuiButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <h4 className='font-wt-400 mb-0'>Name</h4>
        <Textbox
          fullWidth
          type='text'
          register={register}
          name={`nameAR${index}`}
          required={true}
          value={authorizedRepresentative.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAuthorizedRepresentativeChange('name', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Mailing Address</h4>
        <Textbox
          fullWidth
          multiline
          rows={4}
          type='text'
          register={register}
          name={`addressAR${index}`}
          required={true}
          value={authorizedRepresentative.address}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAuthorizedRepresentativeChange('address', index, e.target.value)
          }
          maxLength={255}
        />
        {errors[`addressAR${index}`] && errors[`addressAR${index}`].type === 'maxLength' && (
          <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
        )}
      </Grid>
      <Grid container item justifyContent={'space-between'}>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Tel</h4>
          <Textbox
            fullWidth
            type='tel'
            register={register}
            name={`telAR${index}`}
            value={authorizedRepresentative.phoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 12) return;
              onAuthorizedRepresentativeChange('phoneNumber', index, e.target.value);
            }}
            keyDownFunc={handleKeyDownOnlyNumbers}
            maxLength={11}
            minLength={11}
            startAdornment='+'
          />
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 11 characters.' />
          )}
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
          )}
        </Grid>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Fax</h4>
          <Textbox
            fullWidth
            register={register}
            value={authorizedRepresentative.faxNumber}
            name={`faxAR${index}`}
            maxLength={10}
            minLength={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 11) return;
              onAuthorizedRepresentativeChange('faxNumber', index, e.target.value);
            }}
          />
          {errors[`faxAR${index}`] && errors[`faxAR${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 10 characters.' />
          )}
          {errors[`faxAR${index}`] && errors[`faxAR${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Email</h4>
        <Textbox
          fullWidth
          register={register}
          value={authorizedRepresentative.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAuthorizedRepresentativeChange('email', index, e.target.value)
          }
          name={`emailAR${index}`}
          pattern={{
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email',
          }}
        />
        {errors[`emailAR${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Authorized Representative Details</h3>
        </Grid>
        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-0'>Name</h4>
          <Textbox
            fullWidth
            type='text'
            register={register}
            name={`nameAR${index}`}
            required={true}
            value={detailsState.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Mailing Address</h4>
          <Textbox
            fullWidth
            multiline
            rows={4}
            type='text'
            register={register}
            name={`addressAR${index}`}
            required={true}
            value={detailsState.address}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, address: e.target.value })
            }
            maxLength={255}
          />
          {errors[`addressAR${index}`] && errors[`addressAR${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
          )}
        </Grid>
        <Grid container item justifyContent={'space-between'}>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Tel</h4>
            <Textbox
              fullWidth
              type='tel'
              register={register}
              name={`telAR${index}`}
              value={detailsState.phoneNumber}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 12) return;
                setDetailsState({ ...detailsState, phoneNumber: e.target.value });
              }}
              keyDownFunc={handleKeyDownOnlyNumbers}
              maxLength={11}
              minLength={11}
              startAdornment='+'
            />
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 11 characters.' />
            )}
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
            )}
          </Grid>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Fax</h4>
            <Textbox
              fullWidth
              register={register}
              value={detailsState.faxNumber}
              name={`faxAR${index}`}
              maxLength={10}
              minLength={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 11) return;
                setDetailsState({ ...detailsState, faxNumber: e.target.value });
              }}
            />
            {errors[`faxAR${index}`] && errors[`faxAR${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 10 characters.' />
            )}
            {errors[`faxAR${index}`] && errors[`faxAR${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Email</h4>
          <Textbox
            fullWidth
            register={register}
            value={detailsState.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, email: e.target.value })
            }
            name={`emailAR${index}`}
            pattern={{
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email',
            }}
          />
          {errors[`emailAR${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
        </Grid>
      </Grid>
      <MuiButton
        variant='outlined'
        className='mt-2'
        buttonClick={() => handleAddAuthorizedRepresentativeDetails({ ...detailsState })}
      >
        Add
      </MuiButton>
    </>
  );
};

export default AuthorizedRepresentative;
