export const handleSSNInput = (value: any) => {
  const val = value.toString();
  let tempString = '';
  if (val.length === 3) {
    if (val[val.length - 1] !== '-') {
      tempString = val.slice(0, 2) + '-' + val[val.length - 1];
    } else {
      tempString = val.slice(0, 2);
    }
  } else if (val.length === 7) {
    if (val[val.length - 1] !== '-') {
      tempString = val.slice(0, 6) + '-' + val[val.length - 1];
    } else {
      tempString = val.slice(0, 6);
    }
  } else {
    tempString = tempString + val;
  }

  return {
    useableValue: tempString.slice(0, 11).replaceAll('-', ''),
    mappedValue: tempString.slice(0, 11),
  };
};
