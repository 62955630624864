import MuiTable from 'common/components/table/Table';
import { OrderDetails, ReactQueryDataFetch } from 'common/types';
import { modifyClientOrderData } from 'helpers/orders';
import { useEffect, useState } from 'react';
import { useReactQuery } from 'store/hooks';
import { ORDER_HISTORY_COLUMNS } from './constants';

const ClientOrderHistory = (props: { clientId: string }) => {
  const { data = [], isLoading } = useReactQuery([`clientOrderHistory${props.clientId}`], {
    url: `qaip/v1/ordermanagement/orders/investor?investorId=${props.clientId}`,
  }) as { data: OrderDetails[] } & ReactQueryDataFetch;
  const [rows, setRows] = useState<Array<any>>([]);

  useEffect(() => {
    if (data) {
      setRows(
        modifyClientOrderData(data).sort((a: any, b: any) => {
          if (a.lastUpdatedDate > b.lastUpdatedDate) return -1;
          else if (a.lastUpdatedDate < b.lastUpdatedDate) return 1;
          else return 0;
        }),
      );
    }
  }, [data]);

  return (
    <MuiTable
      isLoading={isLoading}
      rows={rows}
      columns={ORDER_HISTORY_COLUMNS}
      disableHeader
      rowsPerPage={10}
      canRedirect
      rowClassName='cursor-pointer'
      redirectURLKey='orderId'
      redirectURLPrefix='order/'
    />
  );
};

export default ClientOrderHistory;
