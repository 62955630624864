import EventsModal from 'components/modals/EventsModal';
import CardCarousel from 'common/components/carousel/CardCarousel';
import { useState } from 'react';
import EventCard from './EventCard';
import { Events as EventType } from 'common/types';

interface Props {
  list: Array<EventType>;
}

const Events = ({ list }: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EventType>({} as EventType);

  const handleEventModalClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <CardCarousel length={list.length} heading='Events'>
      {list.map((event: EventType, i: number) => (
        <EventCard
          {...event}
          key={`eventCard${i}`}
          setSelectedEvent={setSelectedEvent}
          setModalOpen={setModalOpen}
        />
      ))}
      <EventsModal
        selectedEvent={selectedEvent}
        modalOpen={modalOpen}
        handleEventModalClick={handleEventModalClick}
      />
    </CardCarousel>
  );
};

export default Events;
