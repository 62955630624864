import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AccountHolder from './Blocks/AccountHolder';
import AuthorizedRepresentative from './Blocks/AuthorizedRepresentative';
import WireTransfferBank from './Blocks/WireTransfferBank';
import {
  AccountHolderDetails,
  AuthorizedRepresentativeDetails,
  CustodianType,
  WireTransfferBankDetails,
  EntityTypeDetails,
} from 'store/investor/types';
import Custodian from './Blocks/Custodian';
import Entity from './Blocks/Entity';
import {
  selectClientDetails,
  selectNewInvestorId,
  selectStoreAawceArr,
} from 'store/investor/selectors';
import { useAppSelector, useRQMutation, useTypedDispatch } from 'store/hooks';
import { QueryType } from 'common/types';
import { setStoreAawceArr } from 'store/investor/slice';
import { ReactComponent as CircleArrowIcon } from 'common/assets/images/CTA/CircleArrow.svg';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import LoadingButton from 'common/components/button/LoadingButton';
import Accordians from './Accordians';

const StyledCard = styled(MuiCard)`
  h2 {
    font-size: 22px;
  }
  h5 {
    font-size: 0.875rem;
  }
  .header {
    padding-bottom: 0.5rem;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--s20);
    h3 {
      font-size: 18px;
    }
  }
  .moreDetails {
    button {
      margin: 0 0.75rem 0.75rem 0;
    }
  }
  .checkbox-container {
    position: relative;
    left: -0.25rem;
    svg {
      font-size: 1rem;
    }
    span {
      font-size: 13px;
      color: var(--s40);
      margin-left: 0 !important;
      &.MuiCheckbox-root {
        padding: 0.25rem;
      }
    }
  }
`;

type Props = {
  moveBack: () => void;
  submitToNextTab: () => void;
  inEditing: boolean;
  handleModalClose: () => void;
};

const accountHolderDetails: AccountHolderDetails = {
  type: 'AccountHolderDetails',
  name: '',
  mailingAddress: '',
  addressSameAsPrimaryAccount: false,
  useableForFinanceAndTaxInfomation: false,
  useableForNoticeAndCommunications: false,
  phoneNumber: '',
  faxNumber: '',
  email: '',
  ssn: '',
  usCitizen: false,
};

const authorizedRepresentativeDetails: AuthorizedRepresentativeDetails = {
  type: 'AuthorizedRepresentativeDetails',
  name: '',
  address: '',
  phoneNumber: '',
  faxNumber: '',
  email: '',
};

const wireTransfferBankDetails: WireTransfferBankDetails = {
  type: 'WireTransfferBankDetails',
  bankName: '',
  bankAddress: '',
  swiftNumber: '',
  bankAccountNumber: '',
  bankAccountName: '',
  behalfOf: '',
};

const custodianDetails: CustodianType = {
  type: 'CustodianType',
  firmName: '',
  accountName: '',
  accountNumber: '',
  behalfOf: '',
};

const entityDetails: EntityTypeDetails = {
  type: 'EntityTypeDetails',
  name: '',
  address: '',
  email: '',
  phoneNumber: '',
  faxNumber: '',
  tin: '',
  contactName: '',
  incorporation: '',
  useableForFinanceAndTaxInfomation: false,
  useableForNoticeAndCommunications: false,
  useableForElectronicDeliveryOfStatements: false,
};

const naturalPersonEntities = [
  'Individual',
  'Individual Retirement Account of the Subscriber',
  'Joint Tenants with Right of Survivorship',
  'Tenants in Common',
  'Individual Retirement Account',
];

const entitiesOnlyEntities = [
  'Corporation',
  'Employee Benefit Plan',
  'Endowment',
  'Foundation',
  'Limited Liability Company',
  'Keogh Plan',
  'Partnership',
];

// const hybridsEntities = ['Trust', 'Other'];

const Step2 = ({ moveBack, submitToNextTab, inEditing, handleModalClose }: Props) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  // const navigate = useNavigate();
  const id = location.pathname.split('/')[2];
  const [aawceArr, setAawceArr] = useState<any[]>([]);
  const [accountHolderArr, setAccountHolderArr] = useState<any[]>([]);
  const [authorizedRepresentativeArr, setAuthorizedRepresentativeArr] = useState<any[]>([]);
  const [wireTransfferBankArr, setWireTransfferBankArr] = useState<any[]>([]);
  const [custodianArr, setCustodianArr] = useState<any[]>([]);
  const [entityArr, setEntityArr] = useState<any[]>([]);
  const [showAddMoreDetails, setShowAddMoreDetails] = useState<boolean>(true);
  const [entityTab, setEntityTab] = useState<string>('');
  const dispatch = useTypedDispatch();

  const storeAawceArr: any[] = useAppSelector(selectStoreAawceArr);
  const newInvestorId = useAppSelector(selectNewInvestorId);
  const clientDetails = useAppSelector(selectClientDetails);

  const [accountHolderState, setAccountHolderState] = useState({
    name: '',
    mailingAddress: '',
    addressSameAsPrimaryAccount: false,
    useableForNoticeAndCommunications: false,
    useableForFinanceAndTaxInfomation: false,
    phoneNumber: '',
    faxNumber: '',
    email: '',
    ssn: '',
    usCitizen: false,
    type: 'AccountHolderDetails',
  });

  const [authRepresentativeState, setAuthRepresentativeState] = useState({
    name: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
    type: 'AuthorizedRepresentativeDetails',
  });

  const [custodianState, setCustodianState] = useState({
    firmName: '',
    accountName: '',
    accountNumber: '',
    behalfOf: '',
    type: 'CustodianType',
  });

  const [entityState, setEntityState] = useState({
    name: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
    useableForFinanceAndTaxInfomation: false,
    useableForNoticeAndCommunications: false,
    type: 'EntityTypeDetails',
    useableForElectronicDeliveryOfStatements: false,
    tin: '',
    contactName: '',
    incorporation: '',
  });

  const [wireTransferState, setWireTransferState] = useState({
    bankName: '',
    bankAddress: '',
    bankAccountNumber: '',
    bankAccountName: '',
    behalfOf: '',
    swiftNumber: '',
    type: 'WireTransfferBankDetails',
  });

  useEffect(() => {
    if (storeAawceArr) {
      setAawceArr(storeAawceArr);
    }
  }, [storeAawceArr]);

  useEffect(() => {
    if (aawceArr) {
      setAccountHolderArr(aawceArr.filter((el: any) => el.type === 'AccountHolderDetails'));
      setAuthorizedRepresentativeArr(
        aawceArr.filter((el: any) => el.type === 'AuthorizedRepresentativeDetails'),
      );
      setWireTransfferBankArr(aawceArr.filter((el: any) => el.type === 'WireTransfferBankDetails'));
      setCustodianArr(aawceArr.filter((el: any) => el.type === 'CustodianType'));
      setEntityArr(aawceArr.filter((el: any) => el.type === 'EntityTypeDetails'));
    }
  }, [aawceArr]);

  useEffect(() => {
    if (entityTab === 'Other') {
      if (
        accountHolderArr.length >= 2 &&
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1 &&
        entityArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    } else if (entityTab === 'Natural') {
      if (
        accountHolderArr.length >= 2 &&
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    } else {
      if (
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1 &&
        entityArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    }
    // eslint-disable-next-line
  }, [
    accountHolderArr,
    authorizedRepresentativeArr,
    wireTransfferBankArr,
    custodianArr,
    entityArr,
  ]);

  const handleAddAccountHolderDetails = (prefill?: boolean, details?: Object) => {
    if (details) {
      const newAccountHolderArr = [...accountHolderArr];
      newAccountHolderArr.push({ ...details });
      setAawceArr(newAccountHolderArr);
    } else if (prefill) {
      const newAccountHolderArr = [...aawceArr];
      newAccountHolderArr.push({ ...accountHolderDetails, name: clientDetails?.account_name });
      setAawceArr(newAccountHolderArr);
    } else {
      const newAccountHolderArr = [...aawceArr];
      newAccountHolderArr.push(accountHolderDetails);
      setAawceArr(newAccountHolderArr);
    }
  };

  const handleAddAuthorizedRepresentativeDetails = (details?: Object) => {
    if (details) {
      const newAuthorizedRepresentativeArr = [...aawceArr];
      newAuthorizedRepresentativeArr.push(details);
      setAawceArr(newAuthorizedRepresentativeArr);
    } else {
      const newAuthorizedRepresentativeArr = [...aawceArr];
      newAuthorizedRepresentativeArr.push(authorizedRepresentativeDetails);
      setAawceArr(newAuthorizedRepresentativeArr);
    }
  };

  const handleAddWireTransfferBankDetails = (details?: Object) => {
    if (details) {
      const newWireTransfferBankarr = [...aawceArr];
      newWireTransfferBankarr.push(details);
      setAawceArr(newWireTransfferBankarr);
    } else {
      const newWireTransfferBankarr = [...aawceArr];
      newWireTransfferBankarr.push(wireTransfferBankDetails);
      setAawceArr(newWireTransfferBankarr);
    }
  };

  const handleAddCustodian = (details?: Object) => {
    if (details) {
      const newCustodianArr = [...aawceArr];
      newCustodianArr.push(details);
      setAawceArr(newCustodianArr);
    } else {
      const newCustodianArr = [...aawceArr];
      newCustodianArr.push(custodianDetails);
      setAawceArr(newCustodianArr);
    }
  };

  const handleAddEntity = (prefill?: boolean, details?: Object) => {
    if (details) {
      const newEntityArr = [...aawceArr];
      newEntityArr.push(details);
      setAawceArr(newEntityArr);
    } else if (prefill) {
      const newEntityArr = [...aawceArr];
      newEntityArr.push({ ...entityDetails, name: clientDetails?.account_name });
      setAawceArr(newEntityArr);
    } else {
      const newEntityArr = [...aawceArr];
      newEntityArr.push(entityDetails);
      setAawceArr(newEntityArr);
    }
  };

  const handleAawceChange = (property: string, index: number, value: any) => {
    const newAawceArr = [...aawceArr];
    const newAawceDetails = { ...newAawceArr[index] };
    newAawceDetails[property] = value;
    newAawceArr[index] = newAawceDetails;
    setAawceArr(newAawceArr);
  };

  const handleAawceRemove = (index: number) => {
    const newAawceArr = [...aawceArr];
    newAawceArr.splice(index, 1);
    setAawceArr(newAawceArr);
  };

  useEffect(() => {
    if (clientDetails) {
      if (naturalPersonEntities.includes(clientDetails.entity_type)) {
        setEntityTab('Natural');
        (storeAawceArr === null || storeAawceArr?.length === 0) &&
          handleAddAccountHolderDetails(true);
      } else if (entitiesOnlyEntities.includes(clientDetails.entity_type)) {
        setEntityTab('Entity');
        (storeAawceArr === null || storeAawceArr?.length === 0) && handleAddEntity(true);
      } else {
        setEntityTab('Other');
      }
    }
    // eslint-disable-next-line
  }, [clientDetails, storeAawceArr]);

  const { mutate: updateClient, isLoading: isUpDatingClient } = useRQMutation(
    {
      url: `qaip/v1/investormanagement/investors/id/${
        newInvestorId ? newInvestorId : clientDetails?.investor_id
      }`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`clientDetails-${id}`);
        submitToNextTab();
      },
    },
  );

  const handleFilterTypeArrs = () => {
    const arrTypes = [
      'AccountHolderDetails',
      'AuthorizedRepresentativeDetails',
      'WireTransfferBankDetails',
      'CustodianType',
      'EntityTypeDetails',
    ];
    const arrTypeKeys = [
      'account_Holder_type',
      'authorized_representative_type',
      'wire_transfer_bank_type',
      'custodian_type',
      'entity',
    ];

    const filteredResult = arrTypes.reduce((acc: any, arrType: any, index: number) => {
      const filteredByType = aawceArr.filter((el) => el.type === arrType);
      const arrTypeKey = arrTypeKeys[index];
      const newFilteredResult = { ...acc, [arrTypeKey]: filteredByType };
      return newFilteredResult;
    }, {});
    return filteredResult;
  };

  const addPrimaryAccountHolderFlag = (arrObject: any) => {
    const oldAccountHolderArr = [...arrObject['account_Holder_type']];
    const newAccountHolderArr = oldAccountHolderArr.map((el: any, idx: number) => {
      return { ...el, primaryAccountHolder: idx === 0 ? true : false };
    });

    arrObject['account_Holder_type'] = newAccountHolderArr;
    return arrObject;
  };

  const onSubmit = () => {
    dispatch(setStoreAawceArr(aawceArr));
    const filteredResult = handleFilterTypeArrs();
    const newFilteredResult = addPrimaryAccountHolderFlag(filteredResult);
    updateClient({
      ...clientDetails,
      ...newFilteredResult,
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  return (
    <StyledCard sx={{ p: 0, border: 'none' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <h2 className='font-wt-500 mb-0'>Add Client Details</h2>
            <p className='mb-0 mt-12'>
              Add client details here, including Authorized Representatives, Wiring Bank and
              Custodian account information. Information input is used to pre-fill subscription
              documents.
            </p>
          </Grid>
        </Grid>
        {aawceArr.map((el: any, index: number) => {
          return el.type === 'AccountHolderDetails' ||
            el.type === 'Other' ||
            el.type === 'Trust' ? (
            <AccountHolder
              additional={index === 0 ? false : true}
              register={register}
              errors={errors}
              key={`${el.type}-${index}`}
              index={index}
              accountHolderDetails={el}
              onAccountDetailsChange={(property: string, idx: number, value: any) =>
                handleAawceChange(property, idx, value)
              }
              onAccountDetailsRemove={(index: number) => handleAawceRemove(index)}
            />
          ) : el.type === 'AuthorizedRepresentativeDetails' ? (
            <AuthorizedRepresentative
              register={register}
              errors={errors}
              key={`${el.type}-${index}`}
              index={index}
              authorizedRepresentative={el}
              onAuthorizedRepresentativeChange={(property, idx, value) =>
                handleAawceChange(property, idx, value)
              }
              onAuthorizedRepresentativeRemove={(index) => handleAawceRemove(index)}
            />
          ) : el.type === 'WireTransfferBankDetails' ? (
            <WireTransfferBank
              key={`${el.type}-${index}`}
              register={register}
              errors={errors}
              index={index}
              wireTransfferBank={el}
              onWireTransfferChange={(property, idx, value) =>
                handleAawceChange(property, idx, value)
              }
              onWireTransfferRemove={(index) => handleAawceRemove(index)}
            />
          ) : el.type === 'CustodianType' ? (
            <Custodian
              register={register}
              key={`${el.type}-${index}`}
              errors={errors}
              index={index}
              custodianDetails={el}
              onCustodianChange={(property, idx, value) => handleAawceChange(property, idx, value)}
              onCustodianRemove={(index) => handleAawceRemove(index)}
            />
          ) : (
            <Entity
              additional={index === 0 ? false : true}
              register={register}
              key={`${el.type}-${index}`}
              errors={errors}
              index={index}
              entityDetails={el}
              onEntityDetailsChange={(property, idx, value) =>
                handleAawceChange(property, idx, value)
              }
              onEntityDetailsRemove={(index) => handleAawceRemove(index)}
              reset={reset}
            />
          );
        })}
        {showAddMoreDetails && (
          <Accordians
            entityTab={entityTab}
            accountHolderArr={accountHolderArr}
            handleAawceChange={handleAawceChange}
            register={register}
            reset={reset}
            errors={errors}
            handleAawceRemove={handleAawceRemove}
            handleAddWireTransfferBankDetails={handleAddWireTransfferBankDetails}
            handleAddAccountHolderDetails={handleAddAccountHolderDetails}
            wireTransfferBankArr={wireTransfferBankArr}
            aawceArr={aawceArr}
            entityArr={entityArr}
            handleAddEntity={handleAddEntity}
            custodianArr={custodianArr}
            authorizedRepresentativeArr={authorizedRepresentativeArr}
            handleAddCustodian={handleAddCustodian}
            handleAddAuthorizedRepresentativeDetails={handleAddAuthorizedRepresentativeDetails}
            accountHolderState={accountHolderState}
            setAccountHolderState={setAccountHolderState}
            authRepresentativeState={authRepresentativeState}
            setAuthRepresentativeState={setAuthRepresentativeState}
            custodianState={custodianState}
            setCustodianState={setCustodianState}
            entityState={entityState}
            setEntityState={setEntityState}
            wireTransferState={wireTransferState}
            setWireTransferState={setWireTransferState}
          />
        )}

        <Grid>
          <button type='submit' disabled className='d-none' aria-hidden='true'></button>
          <Grid item xs={12} sx={{ mt: 2 }} container alignItems='center' justifyContent='flex-end'>
            <MuiButton buttonClick={() => moveBack()} className='mr-3'>
              Back
            </MuiButton>
            <MuiButton
              buttonClick={() => {
                handleModalClose();
              }}
              color='error'
              className='mr-3'
            >
              Exit Without Saving
            </MuiButton>
            {isUpDatingClient ? (
              <LoadingButton minWidth='150px' />
            ) : (
              <MuiButton minWidth='150px' variant='contained' type='submit'>
                Save & Next
                <CircleArrowIcon className='ml-2' />
              </MuiButton>
            )}
          </Grid>
        </Grid>
      </form>
    </StyledCard>
  );
};

export default Step2;
