export const LIST_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '',
    label: 'Clients',
  },
];

export const LIST_FILTERS = [
  {
    id: 'status',
    filterGroupName: 'Status',
    value: ['Active', 'Approval Requested', 'Inactive', 'Saved'],
  },
  {
    id: 'entity_type',
    filterGroupName: 'Entity Type',
    value: [
      'Corporation',
      'Employee Benefit Plan',
      'Endowment',
      'Foundation',
      'Individual',
      'Individual Retirement Account of the Subscriber',
      'Joint Tenants with Right of Survivorship',
      'Keogh Plan',
      'Limited Liability Company',
      'Other',
      'Partnership',
      'Tenants in Common',
      'Trust',
    ],
  },
];

export const LIST_QUALISVIEW_COLUMNS = [
  {
    id: 'investorIdSimple',
    label: 'ID',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'account_name',
    label: 'Client Name',
    needsFormatter: false,
    width: '30%',
  },
  {
    id: 'entity_type',
    label: 'Type',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'total_holdings',
    label: 'Net Asset Value',
    align: 'right' as const,
    needsFormatter: true,
    width: '20%',
  },
];

export const LIST_COLUMNS = [
  {
    id: 'account_name',
    label: 'Client Name',
    needsFormatter: false,
    width: '35%',
  },
  {
    id: 'entity_type',
    label: 'Type',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'total_holdings',
    label: 'Net Asset Value',
    align: 'right' as const,
    needsFormatter: true,
    width: '20%',
  },
];

export const HOLDINGS_COLUMNS = [
  {
    id: 'fundName',
    label: 'FUND NAME',
    needsFormatter: false,
    cellClassName: 'color-p400',
  },
  {
    id: 'currentValue',
    label: 'NET ASSET VALUE',
    needsFormatter: true,
    align: 'right' as const,
  },
  {
    id: 'income',
    label: 'INCOME',
    needsFormatter: true,
    align: 'right' as const,
  },
  {
    id: 'roc',
    label: 'Return of Capital',
    needsFormatter: true,
    align: 'right' as const,
  },
  {
    id: 'subscription',
    label: 'Subscriptions',
    needsFormatter: true,
    align: 'right' as const,
  },
  {
    id: 'redemptions',
    label: 'Redemptions',
    needsFormatter: true,
    align: 'right' as const,
  },
];

export const ORDER_HISTORY_COLUMNS = [
  {
    id: 'orderId',
    label: 'ID',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: 'fundName',
    label: 'Fund Name',
    needsFormatter: false,
    width: '28%',
  },
  {
    id: 'fundClass',
    label: 'CLASS',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'eventAmount',
    label: 'AMOUNT',
    needsFormatter: true,
    width: '10%',
  },
  {
    id: 'eventName',
    label: 'EVENT',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'createdDate',
    label: 'EVENT DATE',
    needsFormatter: false,
    isDate: true,
    width: '10%',
  },
  {
    id: 'status',
    label: 'STATUS',
    needsFormatter: false,
    showProgress: true,
    width: '12%',
  },
  {
    id: 'lastUpdatedDate',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
  },
];

export const STATEMENTS_COLUMNS = [
  {
    id: 'fundName',
    label: 'Fund Name',
    needsFormatter: false,
  },
  {
    id: 'createdDate',
    label: 'Date',
    needsFormatter: false,
  },
];

export const TAX_DOCS_COLUMNS = [
  {
    id: 'docName',
    label: 'TAX DOCUMENT',
    needsFormatter: false,
  },
  {
    id: 'createdDate',
    label: 'DATE',
    needsFormatter: false,
  },
];
