import { ClientType } from "common/types";
import { format } from "date-fns";
import * as XLSX from 'xlsx';

export const getClientEligibility = (qp: boolean | undefined, qc: boolean | undefined, ai: boolean | undefined) => {
    if (qp === true && qc === true && ai === true) {
        return ClientType.QUALIFIED_PURCHASER;
    }
    if (qp === true && qc === true && ai === false) {
        return ClientType.QUALIFIED_PURCHASER;
    }
    if (qp === true && qc === false && ai === true) {
        return ClientType.QUALIFIED_PURCHASER;
    }
    if (qp === true && qc === false && ai === false) {
        return ClientType.QUALIFIED_PURCHASER;
    }
    if (qp === false && qc === true && ai === true) {
        return ClientType.QUALIFIED_CLIENT;
    }
    if (qp === false && qc === true && ai === false) {
        return ClientType.QUALIFIED_CLIENT;
    }
    if (qp === false && qc === false && ai === true) {
        return ClientType.ACCREDITED_INVESTOR;
    }
    if (qp === false && qc === false && ai === false) {
        return undefined;
    }
};


export const exportHoldingsAsExcel = (data: any) => {
    const downloadList = data.map((data: any) =>
    ({
        Investor_Id: data.investorId,
        Investor_Name: data.investorName,
        Account_Number: data.accountNumber,
        Fund_Name: data.fundName,
        Current_Market_Value_US$: data.currentValue,
        Subscription_US$: data.subscription,
        Redemption_US$: data.redemption,
        Income_US$: data.income,
        ROC_US$: data.roc,
        Period_To_Date: data.periodToDate,
        Year_To_Date: data.yearToDate,
        Inception: data.inception,
        As_Of_Date: data.asOf,
    }));
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(downloadList);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    let todayDate = format(new Date(), 'MMM-dd-yyy HH:MM:SS')
    XLSX.writeFile(wb, `Qualis Holdings ` + todayDate + `.xlsx`);
}