import {
    ENTITIES_TOOLTIP_CONTENT,
    HYBRIDS_TOOLTIP_CONTENT,
    NATURAL_PERSONS_TOOLTIP_CONTENT,
  } from 'common/constants/tooltipContent';
  
  export enum DocRequirementType {
    MANDATORY = '1',
    OPTIONAL = '2',
    'N/A' = '3',
  }
  
  const DOC_TYPE = [{ id: 'Investor', title: 'Investor' }, { id: 'Subscription', title: 'Subscription' }];
  const DOC_REQ = [{ id: DocRequirementType.MANDATORY, title: 'Mandatory' }, { id: DocRequirementType.OPTIONAL, title: 'Optional' }, { id: DocRequirementType['N/A'], title: 'N/A' }];
  
  export const CRUMBS = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/admin',
      label: 'Admin',
    },
    {
      link: '',
      label: 'Subscription Master Checklist',
    },
  ];
  
  export const COLUMNS = [
    {
      id: 'title',
      label: 'Name',
      needsFormatter: false,
      isSelect: false,
      isInput: true,
      cellClassName: 'primary',
    },
    {
      id: 'type',
      label: 'Type',
      needsFormatter: false,
      isSelect: true,
      selectOptions: DOC_TYPE,
      width: '17%',
    },
    {
      id: 'visibleToNaturalPersons',
      label: 'Natural Persons',
      needsFormatter: false,
      isSelect: true,
      selectOptions: DOC_REQ,
      width: '17%',
      tooltip: NATURAL_PERSONS_TOOLTIP_CONTENT,
    },
    {
      id: 'visibleToEntities',
      label: 'Entities',
      needsFormatter: false,
      isSelect: true,
      selectOptions: DOC_REQ,
      width: '17%',
      tooltip: ENTITIES_TOOLTIP_CONTENT,
    },
    {
      id: 'visibleToHybrids',
      label: 'Hybrid',
      needsFormatter: false,
      isSelect: true,
      selectOptions: DOC_REQ,
      width: '17%',
      tooltip: HYBRIDS_TOOLTIP_CONTENT,
    },
  ];
  