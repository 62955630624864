import { Container, Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import Textbox from 'common/components/inputField/Textbox';
import { useState, useEffect } from 'react';
import { selectFundPriceDetails } from 'store/funds/selectors';
import {
  setFundPriceAnnualReturns,
  setFundPriceDetails,
  setFundPriceInceptionReturns,
  setFundPriceMonthlyReturns,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
} from 'store/funds/slice';
import { useAppSelector, useMutationQuery, useTypedDispatch } from 'store/hooks';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { ToastMessage, ToastType, showToast } from 'store/toast/slice';
import { useQueryClient } from 'react-query';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
};

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
  }
  .sub-heading {
    color: #898989;
    &--table {
      color: #525863;
    }
    &--tableBody {
      font-weight: 500;
      color: var(--s40);
    }
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
  .date-picker {
    margin-top: -20px;
    margin-right: auto;
  }
  .action-btns {
    margin: 0 5px;
  }
  .table {
    border-radius: 0.5rem;
    border: 1px solid var(--s15);
    overflow: hidden;

    .table-head {
      padding: 1rem 2rem;
      background-color: var(--s15);
    }

    .table-row:nth-of-type(odd) {
      background-color: var(--s7);
    }
  }

  .close {
    color: var(--s30);
    font-size: 1.2rem;
  }
`;

const InvestmentPriceForm = ({ handleSubmitBtnClick, handleBackBtnClick }: Props) => {
  const [date, setDate] = useState<any>(null);
  const [fundPriceDatesList, setFundPriceDatesList] = useState<any[]>([]);
  const [price, setPrice] = useState<string>('');

  const storeFundPriceDetails = useAppSelector(selectFundPriceDetails);
  const dispatch = useTypedDispatch();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const id = location.pathname.split('/')[3];
  useEffect(() => {
    if (storeFundPriceDetails !== null) {
      setFundPriceDatesList([...storeFundPriceDetails]);
    }
  }, [storeFundPriceDetails]);

  const handleAddBtnClick = () => {
    if (date === null) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Please select a date.',
      };
      dispatch(showToast(toast));
      return;
    }
    if (price === '') {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Please enter a price.',
      };
      dispatch(showToast(toast));
      return;
    }
    const currentDate = format(new Date(date), 'yyyy-MM-dd');

    const datePriceObj = {
      date: currentDate,
      price: price,
    };
    setFundPriceDatesList([...fundPriceDatesList, datePriceObj]);

    setDate(null);
    setPrice('');
  };

  const mapPriceId = async (priceIdList: any[]) => {
    const updatedFundsPriceList: any[] = [];
    fundPriceDatesList.forEach((el: any, idx: number) => {
      el = { ...el, priceId: priceIdList[idx] };
      updatedFundsPriceList.push(el);
    });
    setFundPriceDatesList(updatedFundsPriceList);
    dispatch(setFundPriceDetails(updatedFundsPriceList));
    handleSubmitBtnClick();
  };

  const { mutate: addPriceList } = useMutationQuery(
    {
      url: `qaip/v1/holdingsmanagement/price/${id}`,
      params: { add_price_list: fundPriceDatesList },
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        mapPriceId(data.priceId);
        queryClient.invalidateQueries(`allPrices-${id}`);
      },
    },
  );

  const onSubmit = async () => {
    if (fundPriceDatesList.length === 0) {
      dispatch(setFundPriceDetails(fundPriceDatesList));
      handleSubmitBtnClick();
    }
    addPriceList();
  };

  const handleGoBackBtnClick = async () => {
    await dispatch(setFundPriceDetails(fundPriceDatesList));
    handleBackBtnClick();
  };

  const handleDeleteBtnClick = (index: number) => {
    const newDateList = [...fundPriceDatesList];
    newDateList.splice(index, 1);
    setFundPriceDatesList(newDateList);
  };

  const handleKeyDown = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    dispatch(setFundPriceAnnualReturns(null));
    dispatch(setFundPriceMonthlyReturns(null));
    dispatch(setFundPriceInceptionReturns(null));
    dispatch(setFundPriceDetails(null));
    navigate(`/investment/${id}`);
  };

  return (
    <StyledWrapper>
      <Container maxWidth='md' className='container-lr-padding my-5'>
        <Container maxWidth='lg' className='px-0'>
          <h2 className='heading'>Price</h2>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0'>
          {/* <h4 className='sub-heading'>FUND PRICE</h4> */}
          <Grid container columnSpacing={2} className='my-5' alignItems='flex-end'>
            <Grid item xs={5}>
              <h4 className='font-wt-400 label'>Date</h4>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MuiDatePicker
                  views={['year', 'month', 'day']}
                  inputFormat='yyyy-MM-dd'
                  value={date}
                  setDate={setDate}
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs='auto' sx={{ flexGrow: '1 !important' }}>
              <h4 className='font-wt-400 label'>Price</h4>
              <Textbox
                value={price}
                startAdornment='$'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
                fullWidth
                type='text'
                keyDownFunc={handleKeyDown}
              />
            </Grid>
            <Grid item xs='auto'>
              <MuiButton variant='outlined' buttonClick={handleAddBtnClick}>
                Add
              </MuiButton>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0 table'>
          <Grid container className='table-head'>
            <Grid item xs={6}>
              <h4 className='sub-heading sub-heading--table mb-0'>DATE</h4>
            </Grid>
            <Grid item xs={5}>
              <h4 className='sub-heading sub-heading--table mb-0'>PRICE</h4>
            </Grid>
          </Grid>
          {fundPriceDatesList.map((el: any, idx: number) => (
            <>
              <Grid container className='py-3 table-row' key={el} sx={{ px: 4 }}>
                <Grid item xs={6}>
                  <h4 className='sub-heading sub-heading--tableBody mb-0'>{el.date}</h4>
                </Grid>
                <Grid item xs={5}>
                  <h4 className='sub-heading sub-heading--tableBody mb-0'>
                    ${el.price.toLocaleString()}
                  </h4>
                </Grid>
                <Grid item xs={1} className='flex-center-end'>
                  <Close
                    className='close ml-2 cursor-pointer'
                    onClick={() => handleDeleteBtnClick(idx)}
                  />
                </Grid>
              </Grid>
              <Divider />
            </>
          ))}
        </Container>
        <Grid container justifyContent='flex-end'>
          <MuiButton variant='text' buttonClick={handleGoBackBtnClick}>
            Back
          </MuiButton>
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={handleDiscardChanges}
          >
            Discard Changes
          </MuiButton>
          <MuiButton variant='contained' minWidth='150px' className='ml-3' buttonClick={onSubmit}>
            Save
          </MuiButton>
        </Grid>
      </Container>
    </StyledWrapper>
  );
};

export default InvestmentPriceForm;
