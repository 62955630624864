import styled from '@emotion/styled';
import { ChevronRight } from '@mui/icons-material';
import { Container } from '@mui/material';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarouselBtn from 'common/assets/images/CarouselBtn.svg';
import MuiButton from '../button';
import MuiTooltip from '../tooltip';

const StyledWrapper = styled(Container)`
  margin-bottom: 3.5rem;

  svg.infoIcon {
    margin-left: 0.5rem;
  }

  &.recommendedFunds {
    padding-top: 29px;
    padding-bottom: 29px;
    border-top: 1px solid rgb(189, 189, 189);
    border-bottom: 1px solid rgb(189, 189, 189);
    margin-bottom: 29px;
  }

  .emblaContainer {
    position: relative;
    .carousel_btn {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      opacity: 1;
      transition: opacity 400ms;
      &.left {
        left: -25px;
        transform: translateY(-50%) rotate(180deg);
      }
      &.right {
        right: -25px;
      }
    }
    &:hover .carousel_btn {
      opacity: 1;
    }
  }

  .embla {
    overflow: hidden;
    cursor: grab;
    position: relative;
    .d-flex > div {
      flex: 0 0 30.1%;
      margin-right: 1.5rem;
    }
  }
`;

const CustomMuiButton = styled(MuiButton)`
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 7px;
`;

interface Props {
  length: number;
  heading: string;
  children: React.ReactNode;
  className?: string;
  disableViewAll?: boolean;
  tooltip?: string | React.ReactNode;
}

const CardCarousel = ({
  length,
  heading,
  children,
  className = 'container-lr-padding',
  disableViewAll = false,
  tooltip = '',
}: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, align: 'start' });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.selectedScrollSnap() < length - 3 && emblaApi.canScrollNext());
  }, [emblaApi, length]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <StyledWrapper maxWidth='xl' className={className}>
      <h2 className='font-wt-500 flex-center-start'>
        {heading}{' '}
        {!disableViewAll && (
          <CustomMuiButton className='ml-1' buttonClick={() => navigate('/investments')}>
            View All <ChevronRight fontSize='small' />
          </CustomMuiButton>
        )}
        {tooltip && <MuiTooltip content={tooltip} />}
      </h2>
      <div className='emblaContainer'>
        <img
          src={CarouselBtn}
          className={`carousel_btn left ${prevBtnEnabled ? '' : 'd-none'}`}
          alt='>'
          onClick={() => {
            scrollPrev();
          }}
        />
        <div className='embla' ref={emblaRef}>
          <div className='d-flex'>{children}</div>
        </div>
        <img
          src={CarouselBtn}
          className={`carousel_btn right ${nextBtnEnabled ? '' : 'd-none'}`}
          alt='>'
          onClick={() => {
            scrollNext();
          }}
        />
      </div>
    </StyledWrapper>
  );
};

export default CardCarousel;
