import { CheckCircle } from '@mui/icons-material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { useNavigate } from 'react-router';
import { useTypedDispatch } from 'store/hooks';
import { resetOrderData } from 'store/order/slice';

const SuccessfulOrderCreation = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const handleButtonClick = async () => {
    await dispatch(resetOrderData());
    await navigate('/orders');
  };

  return (
    <MuiCard minHeight='30vh' className='my-5 flex-col flex-stretch-between'>
      <div>
        <h2 className='flex-center-start font-wt-500'>
          <CheckCircle fontSize='inherit' sx={{ mr: 1, color: 'var(--green)' }} />
          Successfully placed your order!
        </h2>

        <h5 className='font-wt-400'>
          Lorem Ipsum Dolor Sit Lorem Ipsum Dolor Sit Lorem Ipsum Dolor Sit Lorem Ipsum Dolor Sit
        </h5>
      </div>
      <MuiButton variant='contained' buttonClick={handleButtonClick} className='mx-auto'>
        Okay
      </MuiButton>
    </MuiCard>
  );
};

export default SuccessfulOrderCreation;
