import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SelectField from 'common/components/inputField/SelectField';
import SubHeader from 'common/components/layout/SubHeader';
import MuiModal from 'common/components/modal';
import MuiTable from 'common/components/table/Table';
import { EntityType, QueryType } from 'common/types';
import CreateUpdateTeamModal from 'components/modals/CreateUpdateTeamModal';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { COLUMNS, CRUMBS } from './constants';

const Teams = () => {
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null);
  const [isTeamDeleteModalOpen, setIsTeamDeleteModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [rows, setRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;

  const { data } = useReactQuery(['userManagement'], {
    url: `qaip/v1/usermanagement/organisations?categoryId=1`,
  });

  const {
    data: teamsData,
    refetch,
    isLoading: teamsLoading,
    isRefetching: teamsRefetching,
  } = useReactQuery(
    ['teams'],
    {
      url: `qaip/v1/usermanagement/teams/organisation?organisationId=${
        !isQualisUser ? user?.organisationId : selectedOrganisation?.organisationId
      }`,
    },
    {
      enabled: false,
    },
  );

  const { refetch: deleteTeam } = useReactQuery(
    ['deleteTeam'],
    {
      url: `qaip/v1/usermanagement/teams/deleteTeam?teamId=${selectedTeam?.teamId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetch();
        setIsTeamDeleteModalOpen(!isTeamDeleteModalOpen);
      },
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [selectedOrganisation]);

  useEffect(() => {
    teamsData &&
      Array.isArray(teamsData) &&
      setRows(
        teamsData.map((team: any) => ({
          ...team,
          action: (
            <MuiButton
              variant='outlined'
              buttonClick={() => {
                setSelectedTeam(team);
                setIsTeamDeleteModalOpen(!isTeamDeleteModalOpen);
              }}
            >
              Delete
            </MuiButton>
          ),
        })),
      );

    //eslint-disable-next-line
  }, [teamsData]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = rows;
    rows &&
      rows.length > 0 &&
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Teams</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {selectedOrganisation && (
                <MuiButton
                  variant='contained'
                  className='ml-3'
                  buttonClick={() => {
                    setSelectedTeam(null);
                    setIsTeamModalOpen(!isTeamModalOpen);
                  }}
                >
                  Add New Team
                </MuiButton>
              )}
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>

      <Grid item xs={12} container sx={{ pt: 1 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          {isQualisUser && (
            <Grid item xs={2}>
              <SelectField
                options={
                  data &&
                  data.sort((a: any, b: any) =>
                    a.organisationName.localeCompare(b.organisationName),
                  )
                }
                className='adminSelect'
                setSelectedValue={setSelectedOrganisation}
                optionName='organisationName'
                optionId='organisationId'
                defaultValue={selectedOrganisation?.organisationId}
                value={selectedOrganisation?.organisationId}
                searchIcon={false}
                placeholder='Select Dealer Group'
                MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
              />
            </Grid>
          )}
          <Grid item xs={12} mt={4}>
            <MuiTable
              columns={COLUMNS}
              rows={filteredRows}
              disableHeader
              isLoading={teamsLoading || teamsRefetching}
            />
          </Grid>
        </Container>
      </Grid>
      <MuiModal
        title='Are you sure to delete this team?'
        maxWidth='sm'
        isModalOpen={isTeamDeleteModalOpen}
        handleClose={() => setIsTeamDeleteModalOpen(!isTeamDeleteModalOpen)}
      >
        <Grid container>
          <Grid item xs={12} mt={4}>
            <p>
              You are about to remove <strong>{selectedTeam?.teamName} Team</strong> from the
              system. Do you wish to proceed?
            </p>
          </Grid>
          <Grid item xs={12} mt={4}>
            <MuiButton variant='contained' buttonClick={() => deleteTeam()}>
              Confirm & Delete
            </MuiButton>
          </Grid>
        </Grid>
      </MuiModal>
      <CreateUpdateTeamModal
        handleModalClick={() => setIsTeamModalOpen(!isTeamModalOpen)}
        teamData={selectedTeam && selectedTeam}
        isTeamModalOpen={isTeamModalOpen}
        organisationData={selectedOrganisation}
        refetch={refetch}
      />
    </Grid>
  );
};

export default Teams;
