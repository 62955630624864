import MuiStepper from 'common/components/stepper';
import MuiModal from 'common/components/modal';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectInvestorActiveStep } from 'store/investor/selectors';
import Step1 from './Step1';
import { setInvestorsActiveStep } from 'store/investor/slice';
import Step2 from './Step2';
import styled from '@emotion/styled';
import Step3 from './Step3';

type Props = {
  handleModalClose: () => void;
  isModalOpen: boolean;
  editing: boolean;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 656px;
  }
  &.overrideMaxWidth {
    .MuiPaper-root {
      max-width: 1112px;
    }
  }
  .MuiDialogContent-root {
    padding: 2rem 20px;
  }
  .MuiStepper-root {
    margin-bottom: 48px;
  }

  p {
    font-size: 0.875rem;
    color: var(--s30);
  }

  .mt-12 {
    margin-top: 12px;
  }
`;

const ClientCreation = ({ handleModalClose, isModalOpen, editing }: Props) => {
  const steps = editing
    ? ['Edit Client', 'Edit Client Details', 'Upload Documents']
    : ['Add New Client', 'Add Client Details', 'Upload Documents'];
  const activeStep = useAppSelector(selectInvestorActiveStep);
  const dispatch = useTypedDispatch();

  const handleMoveToNextTab = () => {
    dispatch(setInvestorsActiveStep(activeStep + 1));
    window.scrollTo(0, 0);
  };

  const handleMoveToPreviousTab = () => {
    dispatch(setInvestorsActiveStep(activeStep - 1));
    window.scrollTo(0, 0);
  };

  return (
    <StyledModal
      title=''
      handleClose={handleModalClose}
      isModalOpen={isModalOpen}
      maxWidth={activeStep !== 2 ? 'md' : 'lg'}
      className={activeStep === 2 ? 'overrideMaxWidth' : ''}
      disableCloseIcon
      disableHeader
    >
      <div className='d-flex'>
        <MuiStepper steps={steps} activeStep={activeStep} />
      </div>
      <div className='px-2'>
        {activeStep === 0 && (
          <Step1
            submitToNextTab={() => handleMoveToNextTab()}
            inEditing={editing ? true : false}
            handleModalClose={handleModalClose}
          />
        )}
        {activeStep === 1 && (
          <Step2
            submitToNextTab={() => handleMoveToNextTab()}
            moveBack={() => handleMoveToPreviousTab()}
            inEditing={editing ? true : false}
            handleModalClose={handleModalClose}
          />
        )}
        {activeStep === 2 && (
          <Step3
            moveBack={() => handleMoveToPreviousTab()}
            handleModalClose={handleModalClose}
            inEditing={editing ? true : false}
          />
        )}
      </div>
    </StyledModal>
  );
};

export default ClientCreation;
