import { FilterAlt } from '@mui/icons-material';
import { Grid, IconButton, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import SearchBar from '../inputField/SearchBar';
import TableSkeleton from '../skeleton/Skeleton';
import TableFilters from './TableFilters';
import CircleIcon from '@mui/icons-material/Circle';
import { selectCurrentOrderActivityTab } from 'store/order/selectors';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { setOrderActivityTab } from 'store/order/slice';

const StyledGrid = styled(Grid)`
  padding: 22px 0 0;
  h3 {
    margin: unset;
    font-size: 20px;
    color: #677097;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .MuiInputBase-input {
    font-size: 11px;
    padding: 8px 8px 8px 0;
    min-width: 220px;
  }

  .filterBtn {
    background-color: #dfe1e9;
    border-radius: 8px;
    color: #677097;
    margin-left: 8px;
  }

  .MuiTabs-root {
    min-height: 0;
    margin-right: 1rem;
    .MuiTabs-flexContainer {
      padding: 0 1rem;
      border-bottom: 1px solid #d7d7d7;
    }
    .MuiTabs-indicator {
      height: 5px;
      background-color: var(--p300);
      border-radius: 5px 5px 0 0;
    }
    button {
      color: var(--s50);
      font-size: 18px;
      font-weight: 400;
      padding: 0 1rem;
      min-height: 0;
      margin-bottom: 10px;
      text-transform: none;
      &.Mui-selected {
        color: var(--p500);
        font-weight: 600;
      }
    }
    button + button {
      margin-left: 2rem;
    }
  }

  .MuiOutlinedInput-root {
    border: 1px solid #b3d1ed;
    color: var(--s30);
    svg {
      color: var(--s30);
    }
  }
`;

interface Props {
  title: string | undefined;
  searchQuery: string;
  setSearchQuery: (s: string) => void;
  filters?: Array<any>;
  currentFilters: Record<string, String[]>;
  setCurrentFilters: Function;
  isLoading: boolean;
  activityTable?: boolean;
  activityTab?: number;
  setActivityTab?: (num: number) => void;
  disableSearch?: boolean;
  hasOpenOrdersNotifications?: boolean;
  hasCompletetedOrdersNotification?: boolean;
  hasCancelledOrdersNotification?: boolean;
  openOrderArray?: string[];
  completedOrderArray?: string[];
  cancelledOrderArray?: string[];
  clearNotification?: any;
}

const TableHeader = ({
  title,
  filters,
  currentFilters,
  searchQuery,
  setSearchQuery,
  setCurrentFilters,
  isLoading,
  activityTable = false,
  disableSearch = false,
  hasOpenOrdersNotifications = false,
  hasCompletetedOrdersNotification = false,
  hasCancelledOrdersNotification = false,
  openOrderArray = [],
  completedOrderArray = [],
  cancelledOrderArray = [],
  clearNotification,
}: Props) => {
  const dispatch = useTypedDispatch();
  const currentTab = useAppSelector(selectCurrentOrderActivityTab);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openFilters = Boolean(anchorEl);

  const filterOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const filterClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledGrid container alignItems='center' justifyContent='space-between' sx={{ mb: 2 }}>
      <Grid item flexGrow={1}>
        {isLoading ? (
          <TableSkeleton width={200} height={50} variant='text' />
        ) : activityTable ? (
          <Tabs
            value={currentTab}
            onChange={(e: React.SyntheticEvent, val: string) => {
              dispatch(setOrderActivityTab(Number(val)));
            }}
          >
            <Tab
              icon={
                hasOpenOrdersNotifications ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              iconPosition='end'
              label='Open Orders'
              value={0}
            />
            <Tab
              icon={
                hasCompletetedOrdersNotification ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              label='Completed Orders'
              iconPosition='end'
              value={1}
            />
            <Tab
              icon={
                hasCancelledOrdersNotification ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              label='Cancelled Orders'
              iconPosition='end'
              value={2}
            />
          </Tabs>
        ) : (
          <h3>{title}</h3>
        )}
      </Grid>
      <Grid item>
        {!disableSearch && <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
        {filters && filters.length > 0 && (
          <>
            <IconButton className='filterBtn' onClick={filterOpen}>
              <FilterAlt sx={{ fontSize: '16px' }} />
            </IconButton>
            <TableFilters
              open={openFilters}
              anchor={anchorEl}
              onClose={filterClose}
              filters={filters}
              currentFilters={currentFilters}
              applyFilter={setCurrentFilters}
            />
          </>
        )}
      </Grid>
    </StyledGrid>
  );
};

export default TableHeader;
