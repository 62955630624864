export const CLIENT_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '/',
    label: 'Client Approval',
  },
];

export const FUNDS_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '/',
    label: 'Fund Approval',
  },
];

export const CLIENTS_COLUMNS = [
  {
    id: 'account_name',
    label: 'Account Name',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'account_number',
    label: 'Account Number',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'entity_type',
    label: 'Entity Type',
    needsFormatter: false,

    width: '25%',
  },
  {
    id: 'status',
    label: 'Status',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'lastUpdatedFormatted',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
    width: '20%',
  },
];

export const FUNDS_COLUMNS = [
  {
    id: 'fund_name',
    label: 'Fund',
    needsFormatter: false,
  },
  {
    id: 'asset_class',
    label: 'Asset Class',
    needsFormatter: false,
  },
  {
    id: 'strategy',
    label: 'Strategy',
    needsFormatter: false,
  },
  {
    id: 'sub_strategy',
    label: 'Sub Strategy',
    needsFormatter: false,
  },
  {
    id: 'class',
    label: 'Class',
    needsFormatter: false,
  },
  {
    id: 'rating',
    label: 'rating',
    needsFormatter: false,
  },
  {
    id: 'eligibility',
    label: 'eligibility',
    needsFormatter: false,
  },
  {
    id: 'min_investment',
    label: 'Min Investment',
    needsFormatter: true,
  },
  {
    id: 'status',
    label: 'status',
    needsFormatter: false,
  },
];
