import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';

const StyledWrapper = styled(Box)`
  .MuiSelect-root {
    position: relative;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.5rem;
    border-color: var(--s7);
  }
  .MuiSelect-outlined {
    padding: 10px 18px;
    font-size: 13px;
    background-color: var(--s7);
    &:hover .MuiOutlinedInput-notchedOutline,
    &:active .MuiOutlinedInput-notchedOutline {
      border-color: #b3d1ed !important;
    }
  }

  .staticIcon {
    transform: unset;
  }

  .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    pointer-events: none;
    color: var(--s30);
    font-size: 14px;
  }

  &.adminSelect {
    padding-top: unset;
    .MuiInputBase-root {
      color: #525863;
      font-size: 11px;
      background-color: white;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0.5rem;
      border-color: transparent;
    }
    .MuiMenuItem-root {
      font-size: 11px;
    }
    .placeholder {
      font-size: 11px;
    }
  }
`;

type Props = {
  options: Array<any>;
  label?: string;
  isLoading?: boolean;
  value?: any;
  onChange?: (event: SelectChangeEvent) => void;
  errorMessage?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  optionName?: string;
  className?: string;
  register?: any;
  control: any;
  name: string;
  defaultValue?: any;
  optionId?: string;
  setSelectedValue?: any;
  required?: boolean;
  placeholder?: string;
  searchIcon?: boolean;
  MenuProps?: any;
  multiple?: boolean;
};

export default function ControlledSelectField({
  options,
  label,
  value,
  onChange,
  variant,
  isLoading,
  errorMessage,
  optionName,
  optionId,
  name,
  register,
  control,
  disabled,
  defaultValue,
  setSelectedValue,
  required,
  placeholder = 'Select',
  searchIcon = true,
  className = '',
  MenuProps,
  multiple,
}: Props) {
  return (
    <StyledWrapper sx={{ minWidth: 120, pt: 1 }} className={className}>
      {label && <h4 className='font-wt-400 mb-2'>{label}</h4>}
      <FormControl fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue && defaultValue}
          rules={{ required: errorMessage }}
          render={({ field }) => (
            <>
              <Select
                key={value}
                disabled={disabled}
                IconComponent={searchIcon ? Search : KeyboardArrowDown}
                defaultValue={defaultValue && defaultValue}
                inputRef={register && register(name, { required })}
                MenuProps={{ ...MenuProps, disableScrollLock: true }}
                multiple={multiple}
                classes={{ iconOpen: searchIcon ? 'staticIcon' : '' }}
                {...field}
              >
                {React.Children.toArray(
                  options?.map((option: any) => (
                    <MenuItem value={option.id ?? option}>{option.title ?? option}</MenuItem>
                  )),
                )}
              </Select>
              {!defaultValue && !field.value && <div className='placeholder'>{placeholder}</div>}
            </>
          )}
        />
      </FormControl>
    </StyledWrapper>
  );
}
