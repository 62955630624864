export const handleDocDownloadVisibility = (title: string, fundDoc: any) => {
  const element = title && fundDoc?.find((doc: any) => title.includes(doc.docClassificationName));
  const isPrefilled = element ? element.prefill : false;
  return isPrefilled;
};

export const setDocOptionsalphabetically = (docOptions: any) => {
  const otherObject = [] as Array<any>;
  const docOptionsWithoutOtherfield = docOptions.filter((item: any) => {
    if (item.title === 'Other') {
      otherObject.push(item);
      return false;
    } else {
      return item;
    }
  });
  const sortedDocOptions = docOptionsWithoutOtherfield.sort((a: any, b: any) => {
    if (a?.title < b?.title) {
      return -1;
    }
    if (a?.title > b?.title) {
      return 1;
    }
    return 0;
  });
  const newDocOptions = [] as Array<any>;
  newDocOptions.push(...sortedDocOptions);
  newDocOptions.push(...otherObject);
  return newDocOptions;
};
