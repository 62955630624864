import { Container, Grid } from '@mui/material';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import { OrderSummary, ReactQueryDataFetch } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import { COLUMNS, CRUMBS } from './constants';
import MyOrderbooksTable from './MyOrderbooksTable';

const MyOrderbooks = () => {
  const [rows, setRows] = useState<Array<any>>([]);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data = [], isLoading } = useReactQuery(
    [`orders-eventId-3`],
    {
      url: 'qaip/v1/ordermanagement/orderEventClub?eventId=3',
    },
    {
      refetchOnMount: true,
      enabled: !!user,
    },
  ) as { data: OrderSummary[] } & ReactQueryDataFetch;

  const { data: notifications } = useReactQuery(['notifications'], {
    url: 'qaip/v1/notificationmanagement/emails',
  });

  useEffect(() => {
    if (data.length > 0) {
      (async () => {
        const notificationFundName = await notifications?.order_emails?.map((n: any) => n.fund);
        const newData = await data
          .sort((a: any, b: any) => {
            if (a.latestUpdatedDate > b.latestUpdatedDate) return -1;
            else if (a.latestUpdatedDate < b.latestUpdatedDate) return 1;
            else return 0;
          })
          .map((fund: any) => ({
            ...fund,
            type: fund.isAllocationWorkFlowEnabled === 'yes' ? 'Time Limited' : 'Evergreen',
            notified: notificationFundName.includes(fund.fundName),
          }));
        setRows(newData);
      })();
    }
  }, [data, notifications]);

  if (isLoading) return <Loading />;
  return (
    <Grid container sx={{ mt: 8 }}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS} className='pb-0'>
          <Grid container alignItems='flex-end' justifyContent='space-between' sx={{ pt: 1 }}>
            <Grid item xs='auto'>
              <h1 className='mr-20'>My Orderbooks</h1>
              <h3 className='font-wt-400 mt-2 mb-0'>
                Click on fund name to view orders and input allocations.
              </h3>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MyOrderbooksTable
            rows={rows}
            columns={COLUMNS}
            rowsPerPage={10}
            canRedirect
            isLoading={isLoading}
            redirectURLKey='fundId'
            redirectURLPrefix='myorderbooks/'
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default MyOrderbooks;
