import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
  fundID: string;
  docID: string;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 28px !important;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 2.25rem;
    color: var(--s40) !important;
  }

  a {
    text-decoration: none;
  }
`;

const EditDocConfirmModal = ({ isModalOpen, handleClose, fundID, docID, buttonClick }: Props) => {
  return (
    <StyledModal
      title='Save Changes?'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container>
        <p>
          The process of editing this PDF may take a long time. Please save your changes before
          proceeding.
        </p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <Link to={`/${fundID}/prefill/${docID}`} target={'_blank'} className='flex-center-end'>
          <MuiButton
            variant='contained'
            className='ml-3'
            minWidth='150px'
            buttonClick={buttonClick}
          >
            Save
          </MuiButton>
        </Link>
      </Grid>
    </StyledModal>
  );
};

export default EditDocConfirmModal;
