import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import MuiModal from 'common/components/modal';
import { QueryType } from 'common/types';
import { useState } from 'react';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';

const StyledWrapper = styled(Grid)`
  .MuiPaper-root {
    padding: 0;

    div {
      overflow-y: auto;
      padding: 0.5rem 0.75rem;
      height: 240px;
      border-radius: 0.5rem !important;
    }

    p {
      color: #4c4c4c;
      font-size: 13px;
    }

    a {
      color: var(--p300);
      text-decoration: none;
    }
  }

  .checkbox {
    position: relative;
    left: -10px;
  }
  svg {
    font-size: 18px;
  }

  span {
    font-size: 13px;
  }
`;

type Props = {
  isModalOpen: boolean;
  handleModalClick: any;
  setTermsModalOpen: any;
};

const TermsOfUseModal = ({ isModalOpen, handleModalClick, setTermsModalOpen }: Props) => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: any = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [checked, setChecked] = useState<boolean>(false);

  const handleTermsReject = () => {
    dispatch({ type: 'LOGOUT_ATTEMPT' });
  };

  const userData = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    userName: user?.userName,
    organisationId: user?.organisationId,
    phone: user?.phone,
    teamsRoles: user?.teamsRolesDetails,
    termsAndConditions: checked,
  };

  const { refetch: updateUser } = useReactQuery(
    [`updateUser${user?.userId}`],
    {
      url: 'qaip/v1/usermanagement/qualisuser/updateUser',
      params: userData,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        dispatch({ type: 'FETCH_USER_PROFILE', username: user?.userName });
        setTermsModalOpen(false);
      },
    },
  );

  return (
    <MuiModal
      title='Terms Of Use'
      isModalOpen={isModalOpen}
      handleClose={handleModalClick}
      maxWidth='sm'
      disableCloseIcon
      className='terms-of-use'
      zIndex={1301}
    >
      <StyledWrapper container>
        <Grid item xs={12} mb={1.5}>
          <MuiCard minHeight='200px'>
            <div>
              <p>
                Tifin Private Markets (“we”) has entered into a one or more agreements
                (collectively, “Placement Agent Agreements”) with certain private investment funds
                (each, a “Fund” and collectively, the “Funds”) directly and/or through their
                managers or advisers (each, a “Manager” and collectively, the “Managers”), under
                which we have been engaged as a placement agent to find eligible investors to
                purchase units, shares or interests (collectively, “Interests”) in eligible class or
                classes of such Funds as set forth in the confidential offering documents, including
                subscription documents, of the Funds, as each may be amended, restated or
                supplemented from time to time (collectively, the “Offering Documents”) and for such
                Funds to provide information about their Interests and their Manager on the Tifin
                Private Markets' website at{' '}
                <a href='www.tifinprivatemarkets.com' target='_blank' rel='noreferrer noopener'>
                  www.tifinprivatemarkets.com
                </a>{' '}
                (including any successor related website, the Tifin Private Markets Platform”).
              </p>
              <p>
                By clicking “I agree” below and entering, you agree on behalf of yourself, your
                employer and any other entity on whose behalf you are acting (collectively,
                &quot;Recipient&quot;) to such terms and, in particular, that you will not copy,
                reproduce, republish, download, post, broadcast or transmit the content or
                information provided on this website in any way to any third parties for commercial
                gain except as expressly authorized by Tifin Private Markets. You agree that you
                will use the content or information provided on this website solely in accordance
                with applicable laws and regulations. You also agree not to communicate your
                Interests directly with a Fund or Manager listed on the Tifin Private Markets
                Platform. You also may not frame, link or deep-link this website to any other
                website without Tifin Private Markets&#39; prior authorization.
              </p>
              <p>
                All information on this website is provided &quot;AS IS&quot; and on an &quot;AS AND
                WHERE AVAILABLE&quot; basis, without warranty of any kind. No responsibility is
                accepted by or on behalf of Tifin Private Markets for any errors, omissions, or
                inaccurate content or information on the website. The information provided does not
                constitute professional, financial or investment advice. No action should be taken
                or omitted to be taken in reliance upon information on this website, and Tifin
                Private Markets accepts no liability for the results of any such action taken on the
                basis of this information.
              </p>
              <p>
                You acknowledge and represent that you are duly authorized to enter into this
                agreement on behalf of the Recipient.
              </p>
            </div>
          </MuiCard>
        </Grid>
        <Grid item xs={12} container alignItems='center' className='checkbox'>
          <MuiCheckbox
            name='checkbox'
            defaultValue={checked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChecked(!checked)}
          />
          <span>I have read and accept to the Terms of Use</span>
        </Grid>
        <Grid item xs={12} mt={6} container justifyContent='flex-end'>
          <MuiButton variant='text' color='error' buttonClick={handleTermsReject}>
            Decline
          </MuiButton>
          <MuiButton
            className='ml-3'
            variant='contained'
            minWidth='150px'
            buttonClick={() => updateUser()}
            disabled={!checked}
          >
            Accept
          </MuiButton>
        </Grid>
      </StyledWrapper>
    </MuiModal>
  );
};

export default TermsOfUseModal;
