import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ReactComponent as PlaneIcon } from 'common/assets/images/CTA/Plane.svg';
import AlertIcon from 'common/assets/images/Alert.svg';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 0.5rem !important;
  }
  p {
    font-size: 0.875rem;
    color: var(--s30) !important;
    &.desc {
      color: var(--s40) !important;
    }
  }
`;

const ApproveConfirmationModal = ({ isModalOpen, handleClose, buttonClick }: Props) => {
  return (
    <StyledModal
      title='Are you sure you want to approve all orders?'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container alignItems='center' mb={4} flexWrap='nowrap'>
        <img src={AlertIcon} alt='' className='mr-3' />
        <p className='desc mb-0'>Once all the orders are approved, the orders will be accepted.</p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='text' buttonClick={handleClose}>
          Back
        </MuiButton>
        <MuiButton variant='contained' className='ml-3' buttonClick={buttonClick} minWidth='150px'>
          <PlaneIcon className='mr-2' />
          Approve All
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default ApproveConfirmationModal;
