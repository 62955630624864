import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { ClientDetails } from 'common/types';
import { useEffect, useState } from 'react';
import {
  AccountHolderDetails,
  AuthorizedRepresentativeDetails,
  CustodianType,
  EntityTypeDetails,
  WireTransfferBankDetails,
} from 'store/investor/types';
import AccountHolders from './AccountHolders';
import { useTypedDispatch, useAppSelector, useReactQuery } from 'store/hooks';
import {
  setInvestorsActiveStep,
  setNewInvestorId,
  setStoreAawceArr,
  setClientModalOpen,
  setClientDetails,
} from 'store/investor/slice';
import ClientCreation from '../ClientCreation';
import AuthorizedRepresentative from './AuthorizedRepresentative';
import WireTransfferBank from './WireTransfferBank';
import Custodian from './Custodian';
import Entity from './Entity';
import {
  accountHolderDetails,
  authorizedRepresentativeDetails,
  wireTransfferBankDetails,
  custodianDetails,
  entityDetails,
} from 'common/constants/InvestorAccount';
import { selectClientModalOpen } from 'store/investor/selectors';
import { useLocation } from 'react-router-dom';
import Documents from './Documents';
import MuiButton from 'common/components/button';

type Props = {
  clientDetails: ClientDetails;
};

const naturalPersonEntities = [
  'Individual',
  'Individual Retirement Account of the Subscriber',
  'Joint Tenants with Right of Survivorship',
  'Tenants in Common',
];

const entitiesOnlyEntities = [
  'Corporation',
  'Employee Benefit Plan',
  'Endowment',
  'Foundation',
  'Limited Liability Company',
  'Keogh Plan',
  'Partnership',
];

const ClientProfile = ({ clientDetails }: Props) => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [accountHolderArr, setAccountHolderArr] = useState<any[]>([]);
  const [authorizedRepresentativeArr, setAuthorizedRepresentativeArr] = useState<any[]>([]);
  const [wireTransfferBankArr, setWireTransfferBankArr] = useState<any[]>([]);
  const [custodianArr, setCustodianArr] = useState<any[]>([]);
  const [entityArr, setEntityArr] = useState<any[]>([]);
  const [entityTab, setEntityTab] = useState<string>('');
  const [docsArr, setDocsArr] = useState<any[]>([]);
  const [aawceArr, setAawceArr] = useState<any[]>([]);
  const [showAddMoreDetails, setShowAddMoreDetails] = useState<boolean>(true);
  const isClientDetailModalOpen = useAppSelector(selectClientModalOpen);

  const { data: orderDocs } = useReactQuery([`investorDoc-${id}`], {
    url: 'qaip/v1/documentmanagement/masterchecklist',
    params: {
      type: 'client',
      investorId: id,
    },
  });

  const addTypeInAawceArr = (arrAndTypeArr: any[]) => {
    const newAawceArr: any[] = [];
    arrAndTypeArr.forEach((el: any) => {
      el.value.forEach((v: any) => {
        const newObject = {
          ...v,
          type: el.typeName,
        };
        newAawceArr.push(newObject);
      });
    });
    setAawceArr(newAawceArr);
    dispatch(setStoreAawceArr(newAawceArr));
  };

  useEffect(() => {
    if (clientDetails) {
      const arrAndTypeArr: any[] = [];
      if (clientDetails?.account_Holder_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'AccountHolderDetails',
          value: clientDetails?.account_Holder_type,
        });
      }
      if (clientDetails?.authorized_representative_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'AuthorizedRepresentativeDetails',
          value: clientDetails.authorized_representative_type,
        });
      }
      if (clientDetails?.custodian_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'CustodianType',
          value: clientDetails.custodian_type,
        });
      }
      if (clientDetails?.entity?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'EntityTypeDetails',
          value: clientDetails.entity,
        });
      }
      if (clientDetails?.wire_transfer_bank_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'WireTransfferBankDetails',
          value: clientDetails.wire_transfer_bank_type,
        });
      }
      addTypeInAawceArr(arrAndTypeArr);
    }
    // eslint-disable-next-line
  }, [clientDetails]);

  const dispatch = useTypedDispatch();

  const handleModalClick = () => {
    dispatch(setInvestorsActiveStep(1));
    dispatch(setNewInvestorId(null));
    dispatch(setClientDetails(clientDetails));
    dispatch(setClientModalOpen(!isClientDetailModalOpen));
  };

  const handleDocumentModalClick = () => {
    dispatch(setInvestorsActiveStep(2));
    dispatch(setNewInvestorId(null));
    dispatch(setClientDetails(clientDetails));
    dispatch(setClientModalOpen(!isClientDetailModalOpen));
  };

  useEffect(() => {
    if (clientDetails) {
      if (naturalPersonEntities.includes(clientDetails?.entity_type)) {
        setEntityTab('Natural');
      } else if (entitiesOnlyEntities.includes(clientDetails?.entity_type)) {
        setEntityTab('Entity');
      } else {
        setEntityTab('Other');
      }
    }
    // eslint-disable-next-line
  }, [clientDetails]);

  useEffect(() => {
    if (clientDetails) {
      if (clientDetails?.account_Holder_type) {
        const oldAccoundHolderArr = [...clientDetails?.account_Holder_type];
        const primaryAccountHolderIdx = oldAccoundHolderArr.findIndex(
          (el: any) => el.primaryAccountHolder === true,
        );
        const primaryAccountHolderObj = oldAccoundHolderArr[primaryAccountHolderIdx];
        const withoutPrimaryAccount = oldAccoundHolderArr.filter(
          (el: any) => el.primaryAccountHolder === false,
        );
        withoutPrimaryAccount.unshift(primaryAccountHolderObj);

        setAccountHolderArr([...withoutPrimaryAccount]);
      }
      if (clientDetails?.authorized_representative_type)
        setAuthorizedRepresentativeArr([...clientDetails?.authorized_representative_type]);
      if (clientDetails?.custodian_type) setCustodianArr([...clientDetails?.custodian_type]);
      if (clientDetails?.entity) setEntityArr([...clientDetails?.entity]);
      if (clientDetails?.wire_transfer_bank_type)
        setWireTransfferBankArr([...clientDetails?.wire_transfer_bank_type]);
    }
  }, [clientDetails]);

  useEffect(() => {
    if (orderDocs?.investor?.length > 0) {
      setDocsArr(
        orderDocs.investor?.filter((doc: any) => doc.documentsResponseDto.length > 0 && doc),
      );
    }
  }, [orderDocs]);

  useEffect(() => {
    if (entityTab === 'Other') {
      if (
        accountHolderArr.length >= 2 &&
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1 &&
        entityArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    } else if (entityTab === 'Natural') {
      if (
        accountHolderArr.length >= 2 &&
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    } else {
      if (
        authorizedRepresentativeArr.length >= 1 &&
        wireTransfferBankArr.length >= 1 &&
        custodianArr.length >= 1 &&
        entityArr.length >= 1
      ) {
        setShowAddMoreDetails(false);
      } else {
        setShowAddMoreDetails(true);
      }
    }
    // eslint-disable-next-line
  }, [
    accountHolderArr,
    authorizedRepresentativeArr,
    wireTransfferBankArr,
    custodianArr,
    entityArr,
  ]);

  const handleAddAccountHolderDetails = () => {
    dispatch(setStoreAawceArr([...aawceArr, accountHolderDetails]));
    handleModalClick();
  };

  const handleAddAuthorizedRepresentativeDetails = () => {
    dispatch(setStoreAawceArr([...aawceArr, authorizedRepresentativeDetails]));
    handleModalClick();
  };

  const handleAddWireTransfferBankDetails = () => {
    dispatch(setStoreAawceArr([...aawceArr, wireTransfferBankDetails]));
    handleModalClick();
  };

  const handleAddEntity = () => {
    dispatch(setStoreAawceArr([...aawceArr, entityDetails]));
    handleModalClick();
  };

  const handleAddCustodian = () => {
    dispatch(setStoreAawceArr([...aawceArr, custodianDetails]));
    handleModalClick();
  };

  return (
    <StyledGrid container alignItems='flex-start' columnSpacing={5} mt={2}>
      <Grid item xs={8}>
        {accountHolderArr?.map((el: AccountHolderDetails, idx: number) => (
          <AccountHolders
            accountHolderDetails={el}
            index={idx}
            handleModalClick={handleModalClick}
          />
        ))}
        {authorizedRepresentativeArr?.map((el: AuthorizedRepresentativeDetails, idx: number) => (
          <AuthorizedRepresentative
            authorizedRepresentative={el}
            index={idx}
            handleModalClick={handleModalClick}
          />
        ))}
        {wireTransfferBankArr?.map((el: WireTransfferBankDetails, idx: number) => (
          <WireTransfferBank
            wireTransfferBank={el}
            index={idx}
            handleModalClick={handleModalClick}
          />
        ))}
        {custodianArr?.map((el: CustodianType, idx: number) => (
          <Custodian custodianDetails={el} index={idx} handleModalClick={handleModalClick} />
        ))}
        {entityArr?.map((el: EntityTypeDetails, idx: number) => (
          <Entity entityDetails={el} index={idx} handleModalClick={handleModalClick} />
        ))}
        {docsArr.length > 0 && (
          <Documents handleDocumentModalClick={handleDocumentModalClick} docs={docsArr} />
        )}
        {showAddMoreDetails && (
          <section className='my-5 moreDetails'>
            {(entityTab === 'Other' || entityTab === 'Natural') && accountHolderArr.length < 2 && (
              <MuiButton variant='outlined' buttonClick={() => handleAddAccountHolderDetails()}>
                Add Account Holder
              </MuiButton>
            )}
            {authorizedRepresentativeArr.length < 1 && (
              <MuiButton
                variant='outlined'
                buttonClick={() => handleAddAuthorizedRepresentativeDetails()}
              >
                Add Authorized Representative
              </MuiButton>
            )}
            {wireTransfferBankArr.length < 1 && (
              <MuiButton variant='outlined' buttonClick={() => handleAddWireTransfferBankDetails()}>
                Add Wire Transfer Bank
              </MuiButton>
            )}
            {entityTab === 'Other' && entityArr.length < 1 && (
              <MuiButton variant='outlined' buttonClick={() => handleAddEntity()}>
                Add Entity
              </MuiButton>
            )}
            {custodianArr.length < 1 && (
              <MuiButton variant='outlined' buttonClick={() => handleAddCustodian()}>
                Add Custodian
              </MuiButton>
            )}
          </section>
        )}
      </Grid>
      {isClientDetailModalOpen && (
        <ClientCreation
          handleModalClose={handleModalClick}
          isModalOpen={isClientDetailModalOpen}
          editing={true}
        />
      )}
      {/* <Grid item xs={4} className='profileStrength'>
        <OrderStatusBar value={50} />
        <h4>
          Profile Strength: <span>Intermediate</span>
        </h4>
        <h5>Complete your profile by uploading documents.</h5>
        <MuiButton>Upload</MuiButton>
      </Grid> */}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)`
  color: var(--s50);
  hr {
    border-color: var(--s20);
    border-width: 1px;
  }

  h3 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }

  .moreDetails {
    button {
      margin: 0 0.75rem 0.75rem 0;
    }
  }

  section {
    margin-bottom: 3.25rem;
    &.firstAccount {
      margin-bottom: 0.75rem;
    }
    hr {
      margin-bottom: 0.75rem;
    }
    .MuiGrid-container {
      padding: 1rem 0;
      font-size: 0.875rem;
      font-weight: 500;
      p {
        font-size: 0.8125rem;
        font-weight: 400;
        margin-bottom: 0;
      }
      p + p {
        margin-top: 1rem;
      }
    }
    .MuiGrid-container + .MuiGrid-container {
      border-top: 1px solid var(--s15);
    }
    .btnGroup {
      padding: 0.625rem 0;
    }
    .btnGroup + hr {
      margin-top: 3rem;
    }
  }

  .profileStrength {
    padding-top: 3.125rem;
    .MuiBox-root {
      max-width: 8rem;
      margin-bottom: 0.25rem;
    }

    h4,
    h5 {
      font-weight: 500;
    }
    h4 {
      color: var(--s30);
      font-size: 1.125rem;
      margin-bottom: 0.25rem;
      span {
        color: var(--s50);
      }
    }
    h5 {
      color: var(--s40);
      font-size: 0.8125rem;
      margin-bottom: 1rem;
    }
    button {
      position: relative;
      left: -0.5rem;
    }
  }

  .docFile {
    font-size: 13px;
    font-weight: 300;
    color: #8e8e8e;
    svg {
      font-size: 12px;
      color: var(--green);
    }
  }
`;

export default ClientProfile;
