import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import { OrderStatusType, OrderSummary, QueryType } from 'common/types';
import { getOrderStatus } from 'helpers/orders';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { selectOrderAllocationDetails } from 'store/order/selectors';
import {
  resetAllocationOrderDetails,
  setMyCancelledOrders,
  setMyCompletedOrders,
  setMyOpenOrders,
  setOrderActivityTab,
} from 'store/order/slice';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { ErrorMessage } from 'common/components/errorMessageBox';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import DiscardChangesModal from 'components/modals/myOrderbooks/DiscardChangesModal';
import SaveAndAllocateModal from 'components/modals/myOrderbooks/SaveAndAllocateModal';
import OrderSuccessModal from 'components/modals/orders/OrderSuccessModal';
import FundOrdersTableTabs from './FundOrdersTableTabs';
import ApproveConfirmationModal from 'components/modals/myOrderbooks/ApproveConfirmationModal';
import { UserProfile } from 'store/user/types';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';

const StyledGrid = styled(Grid)`
  .tableInputActive > .MuiOutlinedInput-root {
    background-color: var(--p20);
    border-radius: 0.5rem;
  }

  .MuiTabs-root {
    min-height: 0;
    margin-right: 1rem;
    .MuiTabs-flexContainer {
      padding: 0 1rem;
      border-bottom: 1px solid #d7d7d7;
    }
    .MuiTabs-indicator {
      height: 5px;
      background-color: var(--p300);
      border-radius: 5px 5px 0 0;
    }
    button {
      color: var(--s50);
      font-size: 18px;
      font-weight: 400;
      padding: 0 1rem;
      min-height: 0;
      margin-bottom: 10px;
      text-transform: none;
      &.Mui-selected {
        color: var(--p500);
        font-weight: 600;
      }
    }
    button + button {
      margin-left: 2rem;
    }
  }

  .help-icon {
    color: rgba(0, 0, 0, 0.54);
  }

  .proRataCheck {
    position: relative;
    left: -4px;
    .MuiCheckbox-root {
      padding: 0.25rem;
      svg {
        font-size: 1rem;
      }
    }
    .checkboxText {
      display: flex;
      align-items: center;
      font-size: 13px;
      svg {
        color: var(--s50);
        font-size: 18px;
        margin-left: 0.25rem;
      }
    }
  }
  .stat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h4 {
      color: var(--s50);
    }

    .amountCapacity {
      margin-top: 0;
      .MuiOutlinedInput-root {
        padding-left: 10px;
      }
      input {
        padding: 6px 10px 6px 0 !important;
      }
    }

    .subtitle {
      margin-bottom: 0.25rem;
    }

    .error-icon,
    .error-message {
      margin-top: 0.25rem;
    }
  }
`;

const FundOrders = () => {
  const dispatch = useTypedDispatch();
  const [completedRows, setCompletedRows] = useState<Array<any>>([]);
  const [openOrderRows, setOpenOrderRows] = useState<Array<any>>([]);
  const [cancelledRows, setCancelledRows] = useState<Array<any>>([]);
  const [rows, setRows] = useState<Array<any>>([]);
  const [allowCalculation, setAllowCalculation] = useState<boolean>(true);
  const [amountCapacity, setAmountCapacity] = useState<number>(0);
  const [amountAvailable, setAmountAvailable] = useState<number>(0);
  const [allocationDetails, setAllocationDetails] = useState<any[]>([]);
  const [approvalOrderDetails, setApprovalOrderDetails] = useState<any[]>([]);
  const [totalAllocationAmount, setTotalAllocationAmount] = useState<number>(0);
  const [allocate, setAllocate] = useState<boolean>(false);
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState<boolean>(false);
  const [saveAndAllocateModalOpen, setSaveAndAllocateModalOpen] = useState<boolean>(false);
  const [orderSuccessModalOpen, setOrderSuccessModalOpen] = useState<boolean>(false);
  const [notificationArray, setNotificationArray] = useState<Array<string>>([]);
  const location = useLocation();
  const queryClient = useQueryClient();
  const id: string = location.pathname.split('/')[2];
  const orderFundName: any = useAppSelector(selectOrderAllocationDetails);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data, isLoading } = useReactQuery(
    [`fund-orders${id}`],
    {
      url: `qaip/v1/ordermanagement/orderEvent?fundId=${id}`,
    },
    {
      enabled: !!user,
      refetchOnMount: true,
    },
  ) as any;

  const crumbs = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/myorderbooks',
      label: 'My Orderbooks',
    },
    {
      link: '',
      label: orderFundName?.fundName,
    },
  ];

  const { mutate: updateOrder, isLoading: isUpdating } = useRQMutation(
    {
      url: `qaip/v1/ordermanagement/update`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        setSaveAndAllocateModalOpen(false);
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'All orders have been allocated successfully.',
        };
        dispatch(showToast(toast));
        dispatch(setOrderActivityTab(1));
        setOrderSuccessModalOpen(true);
        handleDiscard();
        queryClient.invalidateQueries(`fund-orders${id}`);
        queryClient.invalidateQueries(`orders-eventId-3`);
      },
    },
  );

  const dataFilterByStatus = (data as any)?.order_details?.filter(
    (a: OrderSummary) =>
      Number(a.orderStatusId) === OrderStatusType.SENT_TO_FUND_MANAGER &&
      Number(a.orderEventId) === 3,
  );

  const allocationRequest: number = dataFilterByStatus
    ?.map((a: OrderSummary) => Number(a.eventAmount))
    .reduce((a: any, b: any) => a + b, 0);

  useEffect(() => {
    const amountAllocated: number = allocationDetails
      ?.map((a: any) => Number(a.amount))
      .reduce((a = 0, b: any) => a + b, 0);
    setTotalAllocationAmount(amountAllocated);
  }, [allocationDetails]);

  const generateInitialAllocationValues = () => {
    const initialAllocationDetails = (data as any)?.order_details.map((a: any) => ({
      orderId: a.orderId,
      amount: '',
      eventId: 1,
      status: OrderStatusType.ORDER_COMPLETED,
    }));
    return initialAllocationDetails;
  };

  const dataFormatter = (data: any) => {
    const newData = (data as any)
      ?.sort((a: any, b: any) => {
        if (a.lastUpdatedDate > b.lastUpdatedDate) return -1;
        else if (a.lastUpdatedDate < b.lastUpdatedDate) return 1;
        else return 0;
      })
      .map((row: any) => ({
        ...row,
        statusName:
          row.orderStatusId === '4' && row.orderEventId === '3'
            ? 'Pending Allocation'
            : getOrderStatus(Number(row.orderStatusId)),
      }));
    return newData;
  };

  useEffect(() => {
    if ((data as any)?.order_details?.length > 0) {
      setAllocationDetails(generateInitialAllocationValues());
      dispatch(
        resetAllocationOrderDetails({
          fundName: (data as any)?.order_details[0].fundName,
          orderIds: dataFilterByStatus.map((a: any) => a.orderId),
        }),
      );
      setRows(dataFormatter((data as any)?.order_details));
    } else setRows([]);
    if ((data as any)?.complete_orders?.length > 0) {
      setCompletedRows(dataFormatter((data as any)?.complete_orders));
      dispatch(setMyCompletedOrders(dataFormatter((data as any)?.complete_orders)));
    } else {
      setCompletedRows([]);
    }
    if ((data as any)?.cancelled_orders?.length > 0) {
      setCancelledRows(dataFormatter((data as any)?.cancelled_orders));
      dispatch(setMyCancelledOrders(dataFormatter((data as any)?.cancelled_orders)));
    } else {
      setCancelledRows([]);
    }
    if ((data as any)?.open_orders?.length > 0) {
      setOpenOrderRows(dataFormatter((data as any)?.open_orders));
      dispatch(setMyOpenOrders(dataFormatter((data as any)?.open_orders)));
      setApprovalOrderDetails(
        (data as any)?.open_orders.map((a: any) => ({
          orderId: data.orderId,
          status: OrderStatusType.ORDER_COMPLETED,
          cancelReason: '',
          comments: '',
          amount: null,
          units: 0,
          eventDate: new Date(),
        })),
      );
    } else {
      setOpenOrderRows([]);
    }
    setAmountCapacity((data as any)?.total_capacity);
    setAmountAvailable((data as any)?.available_capacity);
    //eslint-disable-next-line
  }, [data]);

  const calculateAllocationAmount = () => {
    const allocationDetailsArray = dataFilterByStatus?.map((a: any) => ({
      orderId: a.orderId,
      amount: Math.min(
        (a.amountRequested * amountAvailable) / allocationRequest,
        a.amountRequested,
      ).toFixed(2),
      eventId: 1,
      status: OrderStatusType.ORDER_COMPLETED,
    }));
    setAllocationDetails(allocationDetailsArray);
  };

  useEffect(() => {
    if (allowCalculation) calculateAllocationAmount();
    console.log('calculateAllocationAmount', allocationDetails);
    //eslint-disable-next-line
  }, [amountAvailable, allowCalculation, allocate]);

  const { data: orderNotifications } = useReactQuery(
    ['notifications'],
    {
      url: 'qaip/v1/notificationmanagement/emails',
    },
    {
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    setNotificationArray(orderNotifications?.order_emails?.map((email: any) => email.order_id));
    // eslint-disable-next-line
  }, [orderNotifications]);

  const { mutate: clearOrderNotification } = useRQMutation(
    {
      url: 'qaip/v1/ordermanagement/deleteNotification',
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries('notifications');
      },
    },
  );

  const handleDiscard = () => {
    setAllocate(!allocate);
    setAllocationDetails(generateInitialAllocationValues());
    setAllowCalculation(true);
    setTotalAllocationAmount(0);
  };

  if (isLoading) return <Loading />;
  return (
    <StyledGrid container sx={{ mt: 8 }}>
      <Grid item xs={12}>
        <SubHeader crumbs={crumbs}>
          <Grid container>
            <Grid
              item
              xs={12}
              container
              alignItems='flex-end'
              justifyContent='space-between'
              sx={{ pt: 1, mb: '26px' }}
            >
              <h1 className='mb-0 mr-20'>{orderFundName?.fundName}</h1>
            </Grid>
          </Grid>

          <Grid item xs={'auto'} container>
            {amountCapacity < totalAllocationAmount && (
              <ErrorMessage error='Total allocation amount should not exceed the amount available.' />
            )}
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <FundOrdersTableTabs
            rows={rows}
            notificationArray={notificationArray}
            clearOrderNotification={clearOrderNotification}
            completedRows={completedRows}
            cancelledRows={cancelledRows}
            openOrderRows={openOrderRows}
            isLoading={isLoading}
            allocationDetails={allocationDetails}
            setAllocationDetails={setAllocationDetails}
            allocate={allocate}
            setAllocate={setAllocate}
            fundId={(data as any)?.order_details[0]?.fundId}
            allocationRequest={allocationRequest}
            allowCalculation={allowCalculation}
            amountAvailable={amountAvailable}
            amountCapacity={amountCapacity}
            setAllowCalculation={setAllowCalculation}
            setAmountAvailable={setAmountAvailable}
            setAmountCapacity={setAmountCapacity}
            setDiscardChangesModalOpen={setDiscardChangesModalOpen}
            setSaveAndAllocateModalOpen={setSaveAndAllocateModalOpen}
            setIsApproveModalOpen={setIsApproveModalOpen}
            totalAllocationAmount={totalAllocationAmount}
            setHelpModalOpen={setHelpModalOpen}
            isAllocationWorkFlowEnabled={
              data?.is_allocation_workflow_enabled === 'yes' ? true : false
            }
          />
        </Container>
      </Grid>
      <AllocationDetailsModal
        disableCTA
        isModalOpen={helpModalOpen}
        handleClose={() => setHelpModalOpen(!helpModalOpen)}
      />
      <SaveAndAllocateModal
        isModalOpen={saveAndAllocateModalOpen}
        handleClose={() => setSaveAndAllocateModalOpen(!saveAndAllocateModalOpen)}
        isUpdating={isUpdating}
        buttonClick={() => {
          updateOrder(allocationDetails);
        }}
      />
      <ApproveConfirmationModal
        isModalOpen={isApproveModalOpen}
        handleClose={() => setIsApproveModalOpen(!isApproveModalOpen)}
        buttonClick={() => {
          updateOrder(approvalOrderDetails);
          setIsApproveModalOpen(false);
        }}
      />
      <DiscardChangesModal
        isModalOpen={discardChangesModalOpen}
        handleClose={() => setDiscardChangesModalOpen(!discardChangesModalOpen)}
        buttonClick={() => {
          handleDiscard();
          setDiscardChangesModalOpen(false);
        }}
      />
      <OrderSuccessModal
        disableRedirect
        title={'Orders Allocated!'}
        message={'Your orders have been allocated.'}
        isModalOpen={orderSuccessModalOpen}
        handleClose={() => setOrderSuccessModalOpen(false)}
      />
    </StyledGrid>
  );
};

export default FundOrders;
