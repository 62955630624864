import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useEffect, useState } from 'react';

import { AccountHolderDetails } from 'store/investor/types';

type Props = {
  accountHolderDetails: AccountHolderDetails;
  index: number;
  handleModalClick: any;
};

const AccountHolders = ({ accountHolderDetails, index, handleModalClick }: Props) => {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    if (accountHolderDetails) {
      if (
        accountHolderDetails?.email ||
        accountHolderDetails?.faxNumber ||
        accountHolderDetails?.mailingAddress ||
        accountHolderDetails?.name ||
        accountHolderDetails?.phoneNumber ||
        accountHolderDetails?.ssn
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, [accountHolderDetails]);

  const formatSSNNumber = (ssn: string) => {
    const formattedSSNArr = [];
    formattedSSNArr[0] = '**';
    formattedSSNArr[1] = '***';
    formattedSSNArr[2] = ssn.substring(5, 9);
    return formattedSSNArr.join('-');
  };

  return (
    <section
      className={index === 0 ? 'firstAccount' : ''}
      style={{ display: `${showSection ? 'block' : 'none'}` }}
    >
      {index === 0 && (
        <h3>
          Account Holders
          <MuiButton
            className='ml-2 py-0'
            buttonClick={() => {
              handleModalClick();
            }}
          >
            Edit
          </MuiButton>
        </h3>
      )}
      <Divider />
      {accountHolderDetails?.name && (
        <Grid item container>
          <Grid item xs={5}>
            Account Holder {index + 1}
          </Grid>
          <Grid item xs={7}>
            <p>{accountHolderDetails?.name ? accountHolderDetails?.name : '--'}</p>
          </Grid>
        </Grid>
      )}
      {(accountHolderDetails?.mailingAddress ||
        accountHolderDetails?.phoneNumber ||
        accountHolderDetails?.faxNumber) && (
        <Grid item container>
          <Grid item xs={5}>
            Mailing Address
          </Grid>
          <Grid item xs={7}>
            <p>
              {accountHolderDetails?.mailingAddress ? accountHolderDetails?.mailingAddress : '--'}
            </p>
            <p>
              {accountHolderDetails?.phoneNumber && `Tel: ${accountHolderDetails?.phoneNumber}`}{' '}
              {accountHolderDetails?.faxNumber && `Fax: ${accountHolderDetails?.faxNumber}`}
            </p>
          </Grid>
        </Grid>
      )}
      {accountHolderDetails?.email && (
        <Grid item container>
          <Grid item xs={5}>
            Email
          </Grid>
          <Grid item xs={7}>
            <p>{accountHolderDetails?.email ? accountHolderDetails?.email : '--'}</p>
          </Grid>
        </Grid>
      )}
      {accountHolderDetails?.ssn && (
        <Grid item container>
          <Grid item xs={5}>
            SSN/ TIN
          </Grid>
          <Grid item xs={7}>
            <p>{accountHolderDetails?.ssn ? formatSSNNumber(accountHolderDetails?.ssn) : '--'}</p>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12} className='btnGroup'>
        <MuiButton>Add Account Holder</MuiButton>
        <MuiButton className='ml-3'>Duplicate Account Holder</MuiButton>
      </Grid> */}
    </section>
  );
};

export default AccountHolders;
