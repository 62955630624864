import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useState, useEffect } from 'react';

import { CustodianType } from 'store/investor/types';

type Props = {
  custodianDetails: CustodianType;
  index: number;
  handleModalClick: any;
};

const Custodian = ({ custodianDetails, index, handleModalClick }: Props) => {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    if (custodianDetails) {
      if (
        custodianDetails?.firmName ||
        custodianDetails?.accountName ||
        custodianDetails?.accountNumber ||
        custodianDetails?.behalfOf
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, [custodianDetails]);

  return (
    <section style={{ display: `${showSection ? 'block' : 'none'}` }}>
      <h3>
        {index > 0 && 'Additional'} Custodian Details
        <MuiButton
          className='ml-2 py-0'
          buttonClick={() => {
            handleModalClick();
          }}
        >
          Edit
        </MuiButton>
      </h3>
      <Divider />
      {custodianDetails?.firmName && (
        <Grid item container>
          <Grid item xs={5}>
            Firm Name
          </Grid>
          <Grid item xs={7}>
            <p>{custodianDetails?.firmName ? custodianDetails?.firmName : '--'}</p>
          </Grid>
        </Grid>
      )}
      {custodianDetails?.accountName && (
        <Grid item container>
          <Grid item xs={5}>
            Account Name
          </Grid>
          <Grid item xs={7}>
            <p>{custodianDetails?.accountName ? custodianDetails?.accountName : '--'}</p>
          </Grid>
        </Grid>
      )}
      {custodianDetails?.accountNumber && (
        <Grid item container>
          <Grid item xs={5}>
            Account Number
          </Grid>
          <Grid item xs={7}>
            <p>{custodianDetails?.accountNumber ? custodianDetails?.accountNumber : '--'}</p>
          </Grid>
        </Grid>
      )}
      {custodianDetails?.behalfOf && (
        <Grid item container>
          <Grid item xs={5}>
            For Benefit of (FBO)
          </Grid>
          <Grid item xs={7}>
            <p>{custodianDetails?.behalfOf ? custodianDetails?.behalfOf : '--'}</p>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12} className='btnGroup'>
        <MuiButton>Add Account Holder</MuiButton>
        <MuiButton className='ml-3'>Duplicate Account Holder</MuiButton>
      </Grid> */}
    </section>
  );
};

export default Custodian;
