import { Help } from '@mui/icons-material';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SubHeader from 'common/components/layout/SubHeader';
import OrderStatusBar from 'common/components/orderStatusBar';
import { statusList } from 'common/constants';
import { OrderDetails, OrderDocument, OrderStatusType } from 'common/types';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import AllocationModal from 'components/modals/AllocationModal';
import { format } from 'date-fns';
import { getOrderStatus } from 'helpers/orders';
import {
  canAllocateOrder,
  canApproveOrder,
  canAskForNMI,
  canCancelOrder,
  canConfirmFR,
  canConfirmFT,
  canEditOrder,
  canRejectOrder,
  canResubmitOrder,
} from 'helpers/roles';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch } from 'store/hooks';
import { resetOrderData } from 'store/order/slice';
import { UserProfile } from 'store/user/types';
import { ReactComponent as CheckIcon } from 'common/assets/images/CTA/Check.svg';
import { ReactComponent as AllocateIcon } from 'common/assets/images/CTA/Allocate.svg';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  data: OrderDetails;
  setOrderStatus: (e: OrderStatusType) => void;
  user: UserProfile;
  orderDocs: OrderDocument[];
  setCancelRejectOrder: (e: { open: boolean; isCancel: boolean }) => void;
  ctaClicked: string;
  setCtaClicked: any;
  isUpdating: boolean;
};

const OrderDetailsHeader = ({
  data,
  setOrderStatus,
  user,
  orderDocs,
  setCancelRejectOrder,
  ctaClicked,
  setCtaClicked,
  isUpdating,
}: Props) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const id: string = location.pathname.split('/')[2];
  const isRequestAllocation = data.orderEventId === 3;
  const [allocModalOpen, setAllocModalOpen] = useState<boolean>(false);
  const fromMyOrderBooks = location?.state?.fromMyOrderBooks;

  const crumbs = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: fromMyOrderBooks ? fromMyOrderBooks : '/orders',
      label: fromMyOrderBooks ? 'My Orderbooks' : 'Activity',
    },
    {
      link: '',
      label: `ID: ${data.orderId}`,
    },
  ];
  return (
    <Grid item xs={12}>
      <SubHeader crumbs={crumbs} closeIcon closeFunction={() => navigate('/orders')}>
        <Grid container sx={{ pt: 1 }}>
          <Grid item xs={10} container columnSpacing={2} mb={'30px'}>
            <Grid item xs={3}>
              <h6 className='subtitle'>Order ID</h6>
              <h2 className='font-wt-300 pt-2'>{data.orderId || '--'}</h2>
            </Grid>
            <Grid item xs={3}>
              <h6 className='subtitle'>Subscription Amount</h6>
              <h2 className='font-wt-300 pt-2'>
                {!isRequestAllocation
                  ? `$${Number(data.eventAmount).toLocaleString()}` || '--'
                  : 'Awaited'}
              </h2>
            </Grid>
            <Grid item xs={2}>
              <h6 className='subtitle' style={{ marginBottom: '12px' }}>
                Status
              </h6>
              <OrderStatusBar
                value={
                  statusList?.filter((val: any) => val.orderStatusId === data.orderStatusId)[0]
                    ?.width
                }
              />
              <h4 className='font-wt-300 mb-0'>
                {data.orderEventId === 3 && data.orderStatusId === 4
                  ? 'Pending Allocation'
                  : getOrderStatus(data.orderStatusId) || '--'}
              </h4>
            </Grid>
          </Grid>
          <Grid item xs={10} container columnSpacing={2} mb={'30px'}>
            <Grid item xs={3}>
              <h6 className='subtitle'>INVESTOR</h6>
              <h4 className='font-wt-300 mb-0'>{data.investorAccountName || '--'}</h4>
            </Grid>
            <Grid item xs={3}>
              <h6 className='subtitle'>FUND NAME</h6>
              <h4 className='font-wt-300 mb-0'>{data.fundName || '--'}</h4>
            </Grid>
            {data.amountRequested && (
              <Grid
                item
                xs={3}
                container
                direction='column'
                alignItems='start'
                justifyContent='center'
              >
                <h6 className='subtitle position-relative'>
                  ALLOCATION REQUESTED
                  <Help
                    className='allocTooltip cursor-pointer'
                    onClick={() => setAllocModalOpen(true)}
                  />
                </h6>
                <h4 className='font-wt-300 mb-0'>
                  {' '}
                  {data.amountRequested
                    ? `$${Number(data.amountRequested).toLocaleString()}` || '--'
                    : '--'}
                </h4>
              </Grid>
            )}
            <Grid item xs={3}>
              <h6 className='subtitle'>DATE</h6>
              <h4 className='font-wt-300 mb-0'>
                {format(new Date(data.eventDate), 'MMM-dd-yyyy') || '--'}
              </h4>
            </Grid>
          </Grid>

          <Grid item xs={10} container columnSpacing={2}>
            <Grid item xs={3}>
              <h6 className='subtitle'>PRIMARY ADVISOR</h6>
              <h4 className='font-wt-300 mb-0'>{data.primaryAdvisor || '--'}</h4>
            </Grid>
            <Grid item xs={3}>
              <h6 className='subtitle'>Dealer Group</h6>
              <h4 className='font-wt-300 mb-0'>{data.organisation || '--'}</h4>
            </Grid>
            <Grid item xs={3}>
              <h6 className='subtitle'>Type</h6>
              <h4 className='font-wt-300 mb-0'>
                {data.orderEventId === 1 && 'Subscription'}
                {data.orderEventId === 2 && 'Redemption'}
                {data.orderEventId === 3 && 'Allocation Request'}
              </h4>
            </Grid>
          </Grid>
          <Grid item xs={12} container sx={{ mt: 6 }} justifyContent='flex-end'>
            <Grid item>
              {user && canCancelOrder(user, data.orderStatusId) && (
                <MuiButton
                  className='mr-3'
                  color='error'
                  buttonClick={() => {
                    setCtaClicked('Cancel');
                    setCancelRejectOrder({ open: true, isCancel: true });
                  }}
                >
                  Cancel Order
                </MuiButton>
              )}
              {user && !(data as any)?.old && canEditOrder(user, data.orderStatusId) && (
                <MuiButton
                  className='mr-3'
                  buttonClick={() => {
                    setCtaClicked('Edit');
                    dispatch(resetOrderData());
                    navigate(`/edit-order/${id}`, {
                      state: {
                        orderDetails: data,
                        isEditOrderRequest: true,
                        orderDocs: orderDocs,
                      },
                    });
                  }}
                >
                  Edit Order
                </MuiButton>
              )}
              {user && canRejectOrder(user, data.orderStatusId) && (
                <MuiButton
                  buttonClick={() => {
                    setCtaClicked('Reject');
                    setCancelRejectOrder({ open: true, isCancel: false });
                  }}
                  className='mr-3'
                >
                  Reject Order
                </MuiButton>
              )}
              {user &&
                !data.amountRequested &&
                canConfirmFT(user, data.orderStatusId) &&
                (isUpdating && ctaClicked === 'Funds Transferred' ? (
                  <LoadingButton minWidth='155px' />
                ) : (
                  <MuiButton
                    variant='contained'
                    minWidth='155px'
                    buttonClick={() => {
                      setCtaClicked('Funds Transferred');
                      setOrderStatus(OrderStatusType.FUNDS_TRANSFERRED);
                    }}
                  >
                    Funds Transferred
                  </MuiButton>
                ))}
              {user &&
                canConfirmFR(user, data.orderStatusId) &&
                (isUpdating && ctaClicked === 'Order Completed' ? (
                  <LoadingButton minWidth='150px' className='mr-3' />
                ) : (
                  <MuiButton
                    buttonClick={() => {
                      setCtaClicked('Order Completed');
                      setOrderStatus(OrderStatusType.ORDER_COMPLETED);
                    }}
                    className='mr-3'
                    variant='contained'
                    minWidth='150px'
                  >
                    Order Completed
                  </MuiButton>
                ))}
              {user &&
                canAskForNMI(user, data.orderStatusId) &&
                (isUpdating && ctaClicked === 'Need More Information' ? (
                  <LoadingButton variant='text' className='mr-3' minWidth='171px' />
                ) : (
                  <MuiButton
                    minWidth='171px'
                    buttonClick={() => {
                      setCtaClicked('Need More Information');
                      setOrderStatus(OrderStatusType.MORE_INFORMATION);
                    }}
                    className='mr-3'
                  >
                    Need More Information
                  </MuiButton>
                ))}
              {user &&
                canResubmitOrder(user, data.orderStatusId) &&
                (isUpdating && ctaClicked === 'Resubmit Order' ? (
                  <LoadingButton minWidth='150px' className='mr-3' />
                ) : (
                  <MuiButton
                    variant='contained'
                    minWidth='150px'
                    className='mr-3'
                    buttonClick={() => {
                      setCtaClicked('Resubmit Order');
                      setOrderStatus(OrderStatusType.SENT_TO_QUALIS);
                    }}
                  >
                    Resubmit Order
                  </MuiButton>
                ))}
              {data.orderEventId === 3 && user && canAllocateOrder(user, data.orderStatusId) && (
                <MuiButton
                  buttonClick={() => {
                    setCtaClicked('Allocate Order');
                    setIsModalOpen(!isModalOpen);
                  }}
                  variant='contained'
                  minWidth='150px'
                  className='mr-3'
                >
                  <AllocateIcon className='mr-2' /> Allocate Order
                </MuiButton>
              )}
              {user &&
                canApproveOrder(user, data.orderStatusId, data.orderEventId) &&
                (isUpdating && ctaClicked === 'Approve Order' ? (
                  <LoadingButton className='mr-3' minWidth='156px' />
                ) : (
                  <MuiButton
                    buttonClick={() => {
                      setCtaClicked('Approve Order');
                      user.category === 1 &&
                        setOrderStatus(
                          data.orderEventId === 3
                            ? OrderStatusType.SENT_TO_FUND_MANAGER
                            : OrderStatusType.SENT_TO_QUALIS,
                        );
                      user.category === 2 && setOrderStatus(OrderStatusType.ORDER_ACCEPTED);
                      user.category === 3 && setOrderStatus(OrderStatusType.SENT_TO_FUND_MANAGER);
                    }}
                    variant='contained'
                    minWidth='156px'
                    className='mr-3'
                  >
                    <CheckIcon className='mr-2' /> Approve Order
                  </MuiButton>
                ))}
              <AllocationModal
                allocRequested={data.amountRequested}
                isModalOpen={isModalOpen}
                handleModalToggle={() => setIsModalOpen(!isModalOpen)}
                placedOrderDetails={data}
              />
            </Grid>
          </Grid>
        </Grid>

        <AllocationDetailsModal
          disableCTA
          isModalOpen={allocModalOpen}
          handleClose={() => setAllocModalOpen(!allocModalOpen)}
        />
      </SubHeader>
    </Grid>
  );
};

export default OrderDetailsHeader;
