import SelectField from 'common/components/inputField/SelectField';
import { useState } from 'react';

type Props = {
  index: number;
  name: any;
  type: any;
  dropdownDetails: any;
  handleOnChange: any;
  initialValue: any;
};
export function SelectionMapper({
  index,
  name,
  type,
  dropdownDetails,
  handleOnChange,
  initialValue,
}: Props) {
  const [selectedMapping, setSelectedMapping] = useState(initialValue);

  return (
    <div>
      <h4>
        {index + 1}. {name}
      </h4>
      <SelectField
        options={dropdownDetails}
        value={selectedMapping}
        setSelectedValue={setSelectedMapping}
        onChange={(e) => handleOnChange(e, name, type)}
        searchIcon={false}
      />
    </div>
  );
}
