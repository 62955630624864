import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import InputField from 'common/components/inputField/Textbox';
import MuiModal from 'common/components/modal';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutationQuery } from 'store/hooks';

type Props = {
  isTeamModalOpen: boolean;
  handleModalClick: any;
  teamData: any;
  organisationData: any;
  refetch: any;
};

const CreateUpdateTeamModal = ({
  isTeamModalOpen,
  handleModalClick,
  teamData,
  organisationData,
  refetch,
}: Props) => {
  const [newTeamData, setNewTeamData] = useState<any[]>([]);
  useEffect(() => {
    reset();
    //eslint-disable-next-line
  }, [teamData]);

  const defaultValues = {
    teamName: teamData?.teamName,
    teamLocation: teamData?.teamLocation,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: teamData ? defaultValues : undefined,
  });

  const { mutate: editTeam, isLoading: isEditLoading } = useMutationQuery(
    {
      url: 'qaip/v1/usermanagement/teams/team',
      params: newTeamData,
    },
    {
      enabled: false,
      onSuccess: () => {
        reset();
        refetch();
        handleModalClick();
      },
    },
  );

  useEffect(() => {
    if (newTeamData.length !== 0) editTeam();
    //eslint-disable-next-line
  }, [newTeamData]);

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    const updateData = {
      ...teamData,
      teamName: data.teamName,
      teamLocation: data.teamLocation,
    };
    const addData = {
      ...organisationData,
      teamName: data.teamName,
      teamLocation: data.teamLocation,
    };
    setNewTeamData(teamData ? [updateData] : [addData]);
  };

  return (
    <MuiModal
      title='Add a New Team'
      maxWidth='sm'
      isModalOpen={isTeamModalOpen}
      handleClose={handleModalClick}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2} mt={4}>
          <Grid item xs={6}>
            <h4 className='font-wt-400 mb-1'>Team Name</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='teamName'
              fullWidth
              defaultValue={teamData?.teamName}
              errorMessage='This field is required'
            />
            {errors.teamName && <ErrorMessage error='This field is required' />}
          </Grid>
          <Grid item xs={6}>
            <h4 className='font-wt-400 mb-1'>Team Location</h4>
            <InputField
              variant='outlined'
              type='text'
              register={register}
              name='teamLocation'
              fullWidth
              defaultValue={teamData?.teamLocation}
              errorMessage='This field is required'
            />
            {errors.teamLocation && <ErrorMessage error='This field is required' />}
          </Grid>
        </Grid>
        <Grid container mt={4}>
          {/* This will prevent the submission of form on pressing enter key */}
          <button type='submit' disabled className='d-none' aria-hidden='true'></button>
          <MuiButton variant='contained' type='submit' minWidth='150px'>
            {isEditLoading ? 'Loading...' : 'Add Team'}
          </MuiButton>
        </Grid>
      </form>
    </MuiModal>
  );
};

export default CreateUpdateTeamModal;
