import { CircularProgress, Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SelectField from 'common/components/inputField/SelectField';
import SubHeader from 'common/components/layout/SubHeader';
import { QueryType } from 'common/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import RolesTable from '../roles/RolesTable';
import { COLUMNS, CRUMBS } from './constants';

const APL = () => {
  const [selectedDg, setSelectedDg] = useState<any>(null);
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const [approvedFundsId, setApprovedFundsId] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const navigate = useNavigate();

  const { data } = useReactQuery([`dgName${user?.userId}`], {
    url: `qaip/v1/usermanagement/organisations?categoryId=1`,
  });

  const {
    data: fundData,
    refetch: refetchFundData,
    isLoading: isFetchingFund,
  } = useReactQuery(
    [`funds${selectedDg?.organisationId}`],
    {
      url: `qaip/v1/fundsmanagement/apl/${selectedDg?.organisationId}`,
    },
    {
      enabled: selectedDg !== null,
    },
  );

  const { refetch: updateApl, isFetching: isUpdating } = useReactQuery(
    [`updateApl${selectedDg?.organisationId}`],
    {
      url: `qaip/v1/fundsmanagement/apl/${selectedDg?.organisationId}`,
      params: { approved_funds: approvedFundsId },
      method: QueryType.PUT,
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (fundData) {
      let approvedFundsArray: string[] = [];
      const approvedFunds = fundData.approved_funds.map((fund: any) => {
        approvedFundsArray.push(fund.fund_id);
        return {
          ...fund,
          isApproved: true,
        };
      });
      const nonApprovedFunds = fundData.other_funds.map((fund: any) => {
        return {
          ...fund,
          isApproved: false,
        };
      });
      setRows([...approvedFunds, ...nonApprovedFunds]);
      setApprovedFundsId(approvedFundsArray);
    }
  }, [fundData]);

  const handleCheckboxClick = async (row: any, name: any, i: any) => {
    const newRows = [...rows];
    const index = newRows.findIndex((r: any) => r.fund_id === row.role.fund_id);
    newRows[index].isApproved = !newRows[index].isApproved;
    setRows(newRows);
    const fundIdList = rows.filter((row: any) => row.isApproved).map((id: any) => id.fund_id);
    setApprovedFundsId(fundIdList);
  };

  // --- Search ---
  useEffect(() => {
    let arr: Array<any> = rows;
    rows &&
      rows.length > 0 &&
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mb-0'>APL</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                minWidth='275px'
              />
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} container sx={{ pt: 1 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid item xs={12} container sx={{ mb: 4 }}>
            <Grid item xs={2} sx={{ mr: '14px' }}>
              <SelectField
                placeholder='Dealers Group Name'
                options={
                  data &&
                  data.sort((a: any, b: any) =>
                    a.organisationName.localeCompare(b.organisationName),
                  )
                }
                setSelectedValue={setSelectedDg}
                onChange={() => refetchFundData()}
                value={selectedDg?.organisationId}
                optionName='organisationName'
                optionId='organisationId'
                name='organisation'
                searchIcon={false}
                className='adminSelect'
                MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
              />
            </Grid>
          </Grid>
          {rows && rows.length > 0 && (
            <>
              <Grid item xs={12} mb={4}>
                <RolesTable
                  isLoading={isFetchingFund}
                  columns={COLUMNS}
                  rows={filteredRows}
                  handleOnChange={handleCheckboxClick}
                  canColToggle
                />
              </Grid>
              <Grid item xs={12} container justifyContent='flex-end' alignItems='center'>
                <MuiButton
                  buttonClick={() => {
                    setSelectedDg(null);
                    setApprovedFundsId([]);
                    navigate('/admin');
                  }}
                  color='error'
                  className='mr-3'
                >
                  Exit Without Saving
                </MuiButton>
                {isUpdating ? (
                  <CircularProgress />
                ) : (
                  <MuiButton
                    minWidth='150px'
                    variant='contained'
                    buttonClick={updateApl as () => void}
                  >
                    Update
                  </MuiButton>
                )}
              </Grid>
            </>
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export default APL;
