import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAppSelector, useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { docTypeDetails } from 'common/constants';
import { downloadAllDocuments } from 'helpers/document';
import ViewDocumentModal from 'common/components/modal/ViewDocumentModal';
import MuiCard from 'common/components/card';
import FundsLineChart from 'common/components/charts/FundsLineChart';
import MuiTable from 'common/components/table/Table';
import InvestmentIntroBox from './InvestmentIntroBox';
import KeyStats from './KeyStats';
import InvestmentOverview from './InvestmentOverview';
import { ANNUAL_COLUMNS, MANAGER_CHART, MONTHLY_COLUMNS } from './constants';
import Funds from 'components/home/Funds';
import { useGraphQLQuery } from 'store/hooks';
import { RECOMMENDED_FUND_QUERY } from 'components/home/Query';
import { UserProfile } from 'store/user/types';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { FUNDS_SIMILAR_TO_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';
import { formatAumValues } from 'helpers';
import { setFundDocuments } from 'store/funds/slice';

const StyledGrid = styled(Grid)`
  h2 {
    font-size: 22px;
  }

  h1.fundName {
    line-height: 1.2em;
    .MuiChip-root {
      position: relative;
      bottom: 2px;
    }
  }

  .theme {
    font-family: 'SF Compact', Inter, sans-serif;
    font-size: 11px;
    padding: 6px 7px;
    margin-right: 0.5rem;
    border-radius: 3px;
    border: 1px solid var(--s15);
  }

  .investmentDetails {
    .subtitle {
      width: max-content;
      .infoIcon {
        position: absolute;
        right: -1.25rem;
        top: 45%;
        transform: translateY(-50%);
        font-size: 1rem;
      }
    }
  }

  .perfStats {
    h3 {
      font-size: 2rem;
      font-weight: 300;
    }

    .infoIcon {
      font-size: 21px;
      position: absolute;
      left: 123px;
      top: -4px;
    }

    .overlay {
      position: absolute;
      inset: 0;
      z-index: 10;
      margin-left: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.3);
      svg {
        font-size: 5rem;
        color: var(--p300);
      }
    }
  }

  .fundOverview {
    .MuiTabs-root {
      min-height: 0;
      .MuiTabs-flexContainer {
        padding: 0 1rem;
        border-bottom: 1px solid #d7d7d7;
        justify-content: space-between;
      }
      button {
        font-size: 13px;
        padding: 0;
        min-height: 0;
        min-width: 0;
        margin-bottom: 0.5rem;
        text-transform: none;
      }
    }

    p > p {
      margin-bottom: 0;
    }

    .MuiTabPanel-root {
      padding: 1rem;
      font-size: 13px;
      color: #525863;
      section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--s15);
        &:last-of-type {
          border: none;
          margin-bottom: 0;
        }
        p {
          font-size: inherit;
          margin-bottom: 0;
          line-height: initial;
          font-weight: 500;
        }
        .infoIcon {
          font-size: 1.1rem;
          position: absolute;
          right: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .downloadBtn {
    font-size: 14px;
    padding: 0;
  }

  .editBtn svg {
    height: 1rem;
    width: 1rem;
    & path {
      fill: #4590d3;
    }
  }

  .disclaimer {
    font-size: 13px;
    line-height: 27px;
    color: #525863;
  }

  .disclaimer-heading {
    font-size: 14px;
    line-height: 17px;
  }

  #barChartDisclaimer {
    font-size: 10px;
    color: #626262;
    font-style: italic;
  }

  .btnInactive {
    color: #a2a2a2;
  }

  .fundLogo {
    max-height: 104px;
    object-fit: contain;
    object-position: center center;
  }

  .hedgeFundReturns .MuiTableCell-root {
    padding: 0.75rem !important;
    &:first-of-type {
      padding: 0.75rem 0.75rem 0.75rem 1rem !important;
    }
    &:last-of-type {
      padding: 0.75rem 1rem 0.75rem 0.75rem !important;
    }
  }

  .red-close {
    color: #cc3b3b;
  }

  .fundPerformance {
    padding: 1rem 0;
    h5 {
      font-size: 13px;
      margin-bottom: 1rem;
      margin-top: 0.25rem;
      color: var(--s50);
    }
  }

  .recommendedFunds {
    padding: 29px 0;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    margin-bottom: 29px;
    .fundCard {
      border: none;
      img,
      .MuiSkeleton-root {
        height: 60px !important;
      }
      h2 {
        margin-bottom: 1rem;
      }
      h3 {
        font-size: 18px;
      }
    }
  }

  .groupSelect {
    position: absolute;
    right: 0;
    top: 0;
    .MuiSelect-select {
      width: 198px;
    }
    &.allocReq .MuiSelect-select {
      width: 220px;
    }
  }

  .noKeyStats {
    background-color: transparent;
    color: var(--s20);
    padding: 0;
    h3 {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 0.75rem;
    }
    h5 {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
`;

const InvestmentDetails = () => {
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [newFundStats, setNewFundStats] = useState([]);
  const [tableMonthlyReturns, setTableMonthlyReturns] = useState<any[]>([]);
  const [newChartData, setNewChartData] = useState<any>([]);
  const [subscriptionDocs, setSubscriptionDocs] = useState<any[]>([]);
  const [marketingDocs, setMarketingDocs] = useState<any[]>([]);
  const [docModalOpen, setDocModalOpen] = useState<boolean>(false);
  const [viewDoc, setViewDoc] = useState<any>({});
  const [isManagerTableEmpty, setIsManagerTableEmpty] = useState<boolean>(false);
  const [showKeyStatsDiv, setShowKeyStatsDiv] = useState<boolean>(false);
  const [disableFundsPerformanceTab, setDisableFundsPerformanceTab] = useState<boolean>(true);
  const [disableStructAndTerms, setDisableStructAndTerms] = useState<boolean>(true);
  const [disableServiceProviders, setDisableServiceProviders] = useState<boolean>(true);
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const { data, isLoading } = useReactQuery([`fundDetails-${id}`], {
    url: `qaip/v1/fundsmanagement/funds/id/${id}`,
  });
  const showFunds =
    process.env.REACT_APP_SHOW_RECOMMENDED_FUNDS?.toString().toLowerCase() === 'true';
  const fundDetails = data?.fund_details;
  const fundReturns = data?.returns_since_inception;
  const fundStats = data?.fund_stats;
  const annualReturns = data?.annual_returns?.map((item: any) => ({
    ...item,
    value: Number(item.value).toFixed(2),
  }));
  const monthlyReturns = data?.monthly_returns;
  const { data: chartData } = useReactQuery([`chartData-${id}`], {
    url: `qaip/v1/fundsmanagement/funds/chartdata/${id}`,
  });
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (monthlyReturns) {
      let reducedArr = Object.values(
        monthlyReturns.reduce((acc: any, elem: any) => {
          const { year, month, value } = elem;
          acc[year] = acc[year] || {};
          acc[year][month] = value.toFixed(2) + '%';
          acc[year]['Year'] = year;
          return acc;
        }, {}),
      );
      setTableMonthlyReturns(reducedArr);
    }
  }, [monthlyReturns]);

  useEffect(() => {
    if (data) {
      const newFundStats = fundStats
        ?.map((item: any) => {
          return {
            ...item,
            dpi: Number(item.net_dpi?.replace('x', '')),
            tvpi: Number(item.net_tvpi?.replace('x', '')),
            irr: Number(item?.net_irr),
            primaryBar: 'DPI',
            secondaryBar: 'TVPI',
            tertiaryBar: 'IRR',
            secondaryXAxis: `${item?.vintage} • $ ${item?.size}`,
          };
        })
        .map((item: any) => {
          if (
            item.fund ||
            item.net_dpi ||
            item.net_irr ||
            item.net_tvpi ||
            item.size ||
            item.vintage
          ) {
            return { ...item, size: formatAumValues(item.size, 1) };
          }
          return false;
        });
      setNewFundStats(newFundStats);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (data) {
      const fundStatsLength = fundStats?.length;
      for (let index = 0; index < fundStatsLength; index++) {
        const element = fundStats[index];
        if (element.fund) {
          setIsManagerTableEmpty(true);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    // For Key Stats Div
    if (data) {
      const assetClass = fundDetails?.asset_class;
      if (assetClass === 'Hedge Funds') {
        if (
          fundDetails?.fund_video ||
          annualReturns.length > 0 ||
          fundReturns['1_year'] ||
          fundReturns['since_inception'] ||
          (fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)) ||
          fundDetails?.s_and_p_500
        ) {
          setShowKeyStatsDiv(true);
        }
      } else {
        if (
          fundDetails?.fund_video ||
          fundDetails?.target_irr ||
          fundDetails?.target_fund_size ||
          fundDetails?.investment_period ||
          fundDetails?.fund_term ||
          fundDetails?.s_and_p_500
        ) {
          setShowKeyStatsDiv(true);
        }
      }
      // For Fund Performance Tab
      if (
        checkInceptionReturnsValue(fundReturns) ||
        fundDetails?.standard_deviation !== null ||
        fundDetails?.sharpe_ratio !== null ||
        fundDetails?.profitable_months_percentage !== null ||
        fundDetails?.maximum_drawdown !== null ||
        (fundDetails?.s_and_p_500 !== null && fundDetails?.s_and_p_500 !== '')
      ) {
        setDisableFundsPerformanceTab(false);
      }

      // For Structure & Terms tab
      if (
        (fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)) ||
        (fundDetails?.min_investment && !isNaN(fundDetails?.min_investment)) ||
        fundDetails?.management_fees ||
        fundDetails?.incentives_fees ||
        fundDetails?.redemptions ||
        fundDetails?.lockup ||
        (fundDetails?.asset_class !== 'Hedge Funds' &&
          (fundDetails?.final_close ||
            fundDetails?.next_close ||
            fundDetails?.target_fund_size ||
            fundDetails?.preferred_return ||
            fundDetails?.gp_catch_up_term ||
            fundDetails?.fund_term ||
            fundDetails?.funding_type))
      ) {
        setDisableStructAndTerms(false);
      }
    }

    // For service Providers
    if (
      fundDetails?.prime_broker ||
      fundDetails?.fund_counsel ||
      fundDetails?.auditor ||
      fundDetails?.administrator
    ) {
      setDisableServiceProviders(false);
    }

    // eslint-disable-next-line
  }, [data]);

  const checkInceptionReturnsValue = (inceptionReturns: any) => {
    if (fundReturns) {
      const returns = Object.values(fundReturns);
      for (let index = 0; index < returns.length; index++) {
        const element = returns[index];
        if (element) {
          return true;
        }
      }
    }
  };

  useEffect(() => {
    const newChartData = chartData?.fund_amount.map((amt: any, i: number) => {
      return {
        fundAmount: amt,
        fundAmountReleaseDate: chartData?.fund_amount_release_date[i],
      };
    });
    setNewChartData(newChartData?.slice(1));
  }, [chartData]);

  const {
    data: documentData,
    isLoading: isDocumentsFetchLoading,
    refetch: refetchFundDocuments,
  } = useReactQuery(
    ['fundDocuments'],
    {
      url: `qaip/v1/documentmanagement/getList/fund?fund_id=${id}&document_type=file`,
    },
    {
      enabled: false,
    },
  );

  const { data: recommendedFunds } = useGraphQLQuery(
    `recommendedFunds${id}`,
    {
      query: RECOMMENDED_FUND_QUERY,
      variables: {
        investerId: '',
        fundId: id,
        userId: user?.userId,
        categoryId: user?.category,
        organisationId: (user as any)?.organisationId,
      },
    },
    { enabled: !!user },
  );

  useEffect(() => {
    const subscriptionDocuments: any[] = [];
    const marketingDocuments: any[] = [];
    const redemptionDocuments: any[] = [];
    if (documentData) {
      dispatch(setFundDocuments(documentData));
      documentData.forEach((doc: any) => {
        if (doc.docConfigTypeId === docTypeDetails[0].docConfigTypeId) {
          subscriptionDocuments.push(doc);
        }
        if (doc.docConfigTypeId === docTypeDetails[1].docConfigTypeId) {
          redemptionDocuments.push(doc);
        }
        if (doc.docConfigTypeId === docTypeDetails[2].docConfigTypeId) {
          marketingDocuments.push(doc);
        }
      });
    }
    setMarketingDocs(marketingDocuments);
    // setRedemptionDocs(redemptionDocuments);
    setSubscriptionDocs(subscriptionDocuments);
    //eslint-disable-next-line
  }, [documentData]);

  const {
    mutate,
    data: logo,
    isLoading: logoLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: fundDetails?.logo },
    },
    {
      enabled: false,
    },
  );

  // const { data: latestFunds = [] } = useReactQuery([`latestFunds${user?.userId}`], {
  //   url: 'qaip/v1/fundsmanagement/funds/latest',
  // });

  const crumbs = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/investments',
      label: 'Investments',
    },
    {
      link: '',
      label: fundDetails?.fund_name,
    },
  ];

  useEffect(() => {
    if (data) {
      mutate();
      refetchFundDocuments();
    }
    // eslint-disable-next-line
  }, [data]);

  if (isLoading || isDocumentsFetchLoading) return <Loading />;

  return (
    <StyledGrid container sx={{ mt: '4rem' }}>
      <Grid item xs={12}>
        <SubHeader crumbs={crumbs} className='investmentDetails'>
          <InvestmentIntroBox logoLoading={logoLoading} logo={logo} fundDetails={fundDetails} />
        </SubHeader>
      </Grid>

      <Container maxWidth='xl' className='container-lr-padding' sx={{ mt: '29px' }}>
        <Grid container>
          {showKeyStatsDiv && (
            <KeyStats
              fundDetails={fundDetails}
              annualReturns={annualReturns}
              fundReturns={fundReturns}
            />
          )}
          {showFunds && (
            <Funds
              list={recommendedFunds?.getRecFunds}
              className='px-0 recommendedFunds'
              heading={`Funds Similar to ${fundDetails?.fund_name}`}
              disableViewAll
              tooltip={FUNDS_SIMILAR_TO_TOOLTIP_CONTENT}
            />
          )}
          <InvestmentOverview
            fundReturns={fundReturns}
            fundDetails={fundDetails}
            subscriptionDocs={subscriptionDocs}
            marketingDocs={marketingDocs}
            setViewDoc={setViewDoc}
            setDocModalOpen={setDocModalOpen}
            disableFundPerformanceTab={disableFundsPerformanceTab}
            disableStructAndTerms={disableStructAndTerms}
            disableServiceProviders={disableServiceProviders}
          />
          <Grid item xs={12} container>
            {newChartData && (
              <>
                <Grid item xs={12}>
                  <h2 className='font-wt-500 mb-0'>Fund Performance</h2>
                  <h5 className='font-wt-400'>Value of $100,000 from fund inception</h5>
                </Grid>
                <MuiCard
                  minHeight='unset'
                  sx={{ p: 0, overflowX: 'auto', width: '100%', border: 'none' }}
                >
                  <Grid item xs={12} sx={{ px: 1 }}>
                    <FundsLineChart data={newChartData} />
                  </Grid>
                </MuiCard>
                <Grid
                  item
                  xs={12}
                  container
                  columnSpacing={3}
                  mt={6}
                  columns={10}
                  className='hedgeFundReturns'
                >
                  <Grid item xs={8}>
                    <h2 className='font-wt-500 mb-0'>Monthly Returns</h2>
                    <h5 className='font-wt-400'>(Net of fees)</h5>
                    <MuiTable
                      columns={MONTHLY_COLUMNS}
                      rows={tableMonthlyReturns}
                      disableHeader
                      rowsPerPage={50}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <h2 className='font-wt-500 mb-0'>Annual Returns</h2>
                    <h5 className='font-wt-400'>(Net of fees)</h5>
                    <MuiTable
                      columns={ANNUAL_COLUMNS}
                      rows={annualReturns}
                      disableHeader
                      rowsPerPage={50}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {newFundStats && isManagerTableEmpty && (
              <>
                <Grid item xs={12} mt={6}>
                  <h2 className='font-wt-500 '>Manager Track Record </h2>
                </Grid>
                <MuiTable
                  columns={MANAGER_CHART}
                  rows={newFundStats}
                  disableHeader
                  rowsPerPage={50}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={12}>
              <p className='disclaimer-heading mt-5'>Footnotes & Disclosures</p>
              <p className='disclaimer'>{fundDetails?.disclaimer}</p>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <ViewDocumentModal
        title={viewDoc.docName}
        isModalOpen={docModalOpen}
        handleClose={() => setDocModalOpen(false)}
        docLink={viewDoc.url}
        handleDocDownload={() => {
          downloadAllDocuments([viewDoc]);
        }}
        downloadable
        deletable={false}
      />
    </StyledGrid>
  );
};

export default InvestmentDetails;
