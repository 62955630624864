import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { Investment } from './types';

export const initialState: Investment = {
  isListView: true,
  activeSort: 0,
  page: 0,
  searchQuery: '',
  currentFilters: {},
};

const slice = createSlice({
  name: 'investments',
  initialState,
  reducers: reducers,
});

export const {
  setIsListView,
  setActiveSort,
  setPage,
  setSearchQuery,
  setCurrentFilters,
} = slice.actions;

export default slice.reducer;
