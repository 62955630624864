import { FundDetails } from 'common/types';
import CardCarousel from 'common/components/carousel/CardCarousel';
import FundCard from './FundCard';

interface Props {
  list: Array<FundDetails>;
  className?: string;
  heading: string;
  disableViewAll?: boolean;
  tooltip?: string | React.ReactNode;
}

const Funds = ({
  list = [],
  className = 'container-lr-padding',
  heading,
  disableViewAll = false,
  tooltip = '',
}: Props) => {
  return (
    <CardCarousel
      disableViewAll={disableViewAll}
      length={list?.length}
      heading={heading}
      className={className}
      tooltip={tooltip}
    >
      {list?.map((fund: FundDetails, i: number) => (
        <FundCard {...fund} key={`fundCard${i}`} />
      ))}
    </CardCarousel>
  );
};

export default Funds;
