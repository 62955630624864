import { Box, Grid, IconButton } from '@mui/material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { ChevronLeft } from '@mui/icons-material';

const StyledWrapper = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px 12px;
  background: linear-gradient(95.96deg, #2667a1 -2.77%, #3e89cd 101.66%);
  border-radius: 12px 0px 0px 0px;

  .chatbot__drawer-header {
    color: white;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 500;
  }

  svg.close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

type Props = {
  toggleDrawer: () => void;
  displayState: number;
  setDisplayState: any;
};

const HeaderChatBot = ({ toggleDrawer, displayState, setDisplayState }: Props) => {
  return (
    <StyledWrapper>
      <Grid xs={12} item className='chatbot__drawer-header flex-center-between'>
        {displayState !== 0 && (
          <h5
            className='mb-0 flex-center-center cursor-pointer'
            onClick={() => setDisplayState(displayState - 1)}
          >
            <ChevronLeft sx={{ fontSize: '20px' }} /> Back
          </h5>
        )}
        <h3 className='mb-0'>Personal Investing Assistant</h3>
        <IconButton color='inherit' onClick={toggleDrawer}>
          <CloseIcon className='close' />
        </IconButton>
      </Grid>
    </StyledWrapper>
  );
};

export default HeaderChatBot;
