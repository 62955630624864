import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiModal from 'common/components/modal';
import InputField from 'common/components/inputField/Textbox';
import { useReactQuery } from 'store/hooks';
import { OrderStatusType, QueryType } from 'common/types';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  isModalOpen: boolean;
  handleModalToggle: any;
  placedOrderDetails: any;
  allocRequested: any;
};

const StyledModal = styled(MuiModal)`
  h5 {
    font-size: 11px;
  }
`;

const AllocationModal = ({
  isModalOpen,
  handleModalToggle,
  placedOrderDetails,
  allocRequested,
}: Props) => {
  const queryClient = useQueryClient();
  const [allocationAmount, setAllocationAmount] = useState<string>('');
  const isValidated = Number(allocationAmount) <= Number(placedOrderDetails?.eventAmount);

  const {
    refetch: updateOrder,
    isRefetching,
    isLoading,
  } = useReactQuery(
    [`orderUpdate${placedOrderDetails.orderId}`],
    {
      url: `qaip/v1/ordermanagement/update`,
      params: [
        {
          amount: Number(allocationAmount),
          orderId: placedOrderDetails?.orderId,
          status: OrderStatusType.ORDER_COMPLETED,
          eventId: 1,
        },
      ],
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`clientOrderDetails${placedOrderDetails?.orderId}`);
        setAllocationAmount('0');
        handleModalToggle();
      },
    },
  );

  return (
    <StyledModal
      title='Allocate Order'
      isModalOpen={isModalOpen}
      handleClose={handleModalToggle}
      maxWidth='sm'
      disableCloseIcon
    >
      <Grid container>
        <Grid item xs={12} sx={{ mb: 4 }}>
          <h4 className='font-wt-400 mb-0'>
            Input the amount that you would like to allocate to this order. This amount will be set
            as the investor's subscription in your fund.
          </h4>
        </Grid>
        <Grid item xs={6} sx={{ mb: 2 }}>
          <h5 className='subtitle'>Amount Requested</h5>
          <h4 className='mb-3 pb-1 font-wt-400'>${Number(allocRequested).toLocaleString()}</h4>
          <h5 className='subtitle'>Amount Allocated</h5>
          <InputField
            variant='outlined'
            type='text'
            fullWidth
            onChange={(e: any) => setAllocationAmount(e.target.value)}
            value={allocationAmount}
            startAdornment='$'
            className='my-0 mt-0'
          />
          {!isValidated && (
            <ErrorMessage error='Allocation amount should be equal or less than the requested amount.' />
          )}
        </Grid>
        <Grid item xs={12} className='flex-center-end'>
          <MuiButton
            variant='text'
            color='error'
            buttonClick={() => {
              setAllocationAmount('');
              handleModalToggle();
            }}
          >
            Discard Changes
          </MuiButton>
          {isLoading || isRefetching ? (
            <LoadingButton className='ml-3' minWidth='176px' />
          ) : (
            <MuiButton
              variant='contained'
              buttonClick={() => isValidated && updateOrder()}
              minWidth='176px'
              className='ml-3'
            >
              Allocate and Approve
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </StyledModal>
  );
};

export default AllocationModal;
