import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import Loading from 'common/components/Loading';
import { entityDropdownList } from 'common/constants';
import { EntityType, QueryType } from 'common/types';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactQuery, useRQMutation } from 'store/hooks';

const StyledContainer = styled(Container)`
  .checkboxes {
    font-size: 13px;
    .MuiCheckbox-root {
      padding: 0;
      margin-right: 0.5rem;
      svg {
        font-size: 1rem;
      }
    }
  }
`;

const OrganisationDetails = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const isAdd = location?.state?.isAdd;
  const id: string = location.pathname.split('/')[3];
  const isAddNewOrganisationRequest = id === 'add-new-organization';
  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  const [selectedAggregator, setSelectedAggregator] = useState<any>(null);
  const [assignDefaultTeams, setAssignDefaultTeams] = useState<boolean>(false);
  const [assignActiveAPL, setAssignActiveAPL] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: refetchOrgDetails,
  } = useReactQuery(
    [`organisation${id}`],
    {
      url: `qaip/v1/organizationmanagement/organization/id/${id}`,
    },
    {
      enabled: !isAddNewOrganisationRequest,
      refetchOnMount: !isAddNewOrganisationRequest,
    },
  );

  const { data: aggregatorData } = useReactQuery(['aggregator'], {
    url: `qaip/v1/usermanagement/organisations?categoryId=4`,
  });

  const { mutate: updateOrganisation } = useRQMutation(
    {
      url: `qaip/v1/organizationmanagement/organization/id/${id}`,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetchOrgDetails();
        navigate('/admin/organizations');
      },
    },
  );

  const { mutate: addNewOrg } = useRQMutation(
    {
      url: 'qaip/v1/organizationmanagement/organization',
    },
    {
      enabled: false,
      onSuccess: (data) => {
        refetchOrgDetails();

        if (data) navigate(`/admin/organization/${data?.organisation_id}`);
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (data) {
      const entity = entityDropdownList?.filter(
        (entity: any) => entity.id === Number(data?.category_id),
      )[0];
      setSelectedEntity(entity);
      setSelectedAggregator({ organisationId: data.aggregator_id });
      const defaultValue = {
        ...data,
        organisation_name: data?.organisation_name,
        main_address: data?.main_address,
        mailing_address: data?.mailing_address,
        administrator_name: data?.administrator_name,
        administrator_email: data?.administrator_email,
        administrator_phone: data?.administrator_phone,
        administrator_cell: data?.administrator_cell,
        key_contact_name: data?.key_contact_name,
        website: data?.website,
      };
      setAssignDefaultTeams(data?.is_team ? data?.is_team : false);
      setAssignActiveAPL(data?.is_apl ? data?.is_apl : false);
      reset(defaultValue);
    }
    //eslint-disable-next-line
  }, [data]);

  const onSubmit: SubmitHandler<any> = async (formData) => {
    if (!isAddNewOrganisationRequest) {
      updateOrganisation({
        ...formData,
        category_id: `${data.category_id}`,
        aggregator_id: selectedAggregator?.organisationId,
        is_apl: assignActiveAPL,
        is_team: assignDefaultTeams,
      });
    } else {
      addNewOrg({
        ...formData,
        category_id: `${selectedEntity.id}`,
        aggregator_id: selectedAggregator?.organisationId,
        is_apl: assignActiveAPL,
        is_team: assignDefaultTeams,
      });
    }
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  if (isLoading) return <Loading />;

  return (
    <StyledContainer maxWidth='xl' className='container-lr-padding container-t-margin'>
      <Container maxWidth='md'>
        <MuiCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <h2 className='font-wt-500'>
                  {isAdd ? 'Add New Organization' : 'Edit Organization'}
                </h2>
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  label='Entity Type'
                  options={entityDropdownList}
                  setSelectedValue={setSelectedEntity}
                  optionName='name'
                  optionId='id'
                  name='entity'
                  defaultValue={selectedEntity?.id}
                  value={selectedEntity?.id}
                  disabled={!isAddNewOrganisationRequest}
                  fullWidth
                  register={register}
                  onChange={(e) => setValue('entity', e.target.value)}
                  required={!!!selectedEntity}
                />
                {errors.entity && <ErrorMessage error='This field is required' />}
              </Grid>
              <Grid item xs={12}>
                <h4 className='font-wt-400 mb-0'>Organization name</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='organisation_name'
                  errorMessage='This field is required'
                  defaultValue={data?.organisation_name}
                />
                {errors.organisation_name && (
                  <ErrorMessage error={errors?.organisation_name.message} />
                )}
              </Grid>

              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Administrator name</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='administrator_name'
                  errorMessage='This field is required'
                />
                {errors.administrator_name && (
                  <ErrorMessage error={errors?.administrator_name.message} />
                )}
              </Grid>

              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Administrator Email</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='administrator_email'
                  errorMessage='This field is required'
                />
                {errors.administrator_email && (
                  <ErrorMessage error={errors?.administrator_email.message} />
                )}
              </Grid>

              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Administrator Phone</h4>
                <Textbox
                  fullWidth
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  register={register}
                  name='administrator_phone'
                  errorMessage='This field is required'
                />
                {errors.administrator_phone && (
                  <ErrorMessage error={errors?.administrator_phone.message} />
                )}
              </Grid>

              <Grid item xs={6}>
                <h4 className='font-wt-400 mb-0'>Administrator Cell</h4>
                <Textbox
                  fullWidth
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  register={register}
                  name='administrator_cell'
                  errorMessage='This field is required'
                />
                {errors.administrator_cell && (
                  <ErrorMessage error={errors?.administrator_cell.message} />
                )}
              </Grid>
              <Grid item xs={12}>
                <h4 className='font-wt-400 mb-0'>Key Contact Name</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='key_contact_name'
                  errorMessage='This field is required'
                />
                {errors.key_contact_name && (
                  <ErrorMessage error={errors?.key_contact_name.message} />
                )}
              </Grid>

              {isAddNewOrganisationRequest && selectedEntity?.id === EntityType.FUNDS_MANAGER && (
                <>
                  <Grid item xs={6}>
                    <h4 className='font-wt-400 mb-0'>Key Contact Email</h4>
                    <Textbox
                      fullWidth
                      type='text'
                      register={register}
                      name='key_contact_email'
                      errorMessage='This field is required'
                    />
                    {errors.key_contact_email && (
                      <ErrorMessage error={errors?.key_contact_email.message} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <h4 className='font-wt-400 mb-0'>Key Contact Phone</h4>
                    <Textbox
                      fullWidth
                      type='text'
                      register={register}
                      name='key_contact_phone'
                      errorMessage='This field is required'
                    />
                    {errors.key_contact_phone && (
                      <ErrorMessage error={errors?.key_contact_phone.message} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <h4 className='font-wt-400 mb-0'>Key Contact Cell</h4>
                    <Textbox
                      fullWidth
                      type='text'
                      register={register}
                      name='key_contact_cell'
                      errorMessage='This field is required'
                    />
                    {errors.key_contact_cell && (
                      <ErrorMessage error={errors?.key_contact_cell.message} />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <h4 className='font-wt-400 mb-0'>Main Mobile</h4>
                    <Textbox
                      fullWidth
                      type='text'
                      register={register}
                      name='main_mobile'
                      errorMessage='This field is required'
                    />
                    {errors.main_mobile && <ErrorMessage error={errors?.main_mobile.message} />}
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <h4 className='font-wt-400 mb-0'>Website</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='website'
                  errorMessage='This field is required'
                />
                {errors.website && <ErrorMessage error={errors?.website.message} />}
              </Grid>
              {selectedEntity?.id === EntityType.DEALERS_GROUP && (
                <Grid item xs={12}>
                  <h4 className='font-wt-400 mb-0'>Aggregators</h4>
                  <SelectField
                    options={aggregatorData}
                    setSelectedValue={setSelectedAggregator}
                    optionName='organisationName'
                    optionId='organisationId'
                    name='aggregators'
                    defaultValue={selectedAggregator?.organisationId}
                    value={selectedAggregator?.organisationId}
                  />
                </Grid>
              )}

              <Grid item xs={12} className='d-flex flex-col'>
                <h4 className='font-wt-400 mb-2'>Main address</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='main_address'
                  multiline
                  rows={4}
                  errorMessage='This field is required'
                />
                {errors.main_address && <ErrorMessage error={errors?.main_address.message} />}
              </Grid>

              <Grid item xs={12} className='d-flex flex-col'>
                <h4 className='font-wt-400 mb-2'>Mailing address</h4>
                <Textbox
                  fullWidth
                  type='text'
                  register={register}
                  name='mailing_address'
                  multiline
                  rows={4}
                  errorMessage='This field is required'
                />
                {errors.mailing_address && <ErrorMessage error={errors?.mailing_address.message} />}
              </Grid>

              {selectedEntity?.id === EntityType.DEALERS_GROUP && (
                <Grid item xs={12} className='checkboxes'>
                  <div className='flex-center-start mb-1'>
                    <MuiCheckbox
                      name='teams'
                      disableRipple
                      defaultValue={assignDefaultTeams}
                      onChange={() => setAssignDefaultTeams(!assignDefaultTeams)}
                    />{' '}
                    <span>Add default Team</span>
                  </div>

                  <div className='flex-center-start'>
                    <MuiCheckbox
                      name='funds'
                      disableRipple
                      defaultValue={assignActiveAPL}
                      onChange={() => setAssignActiveAPL(!assignActiveAPL)}
                    />{' '}
                    <span>Activate all active funds</span>
                  </div>
                </Grid>
              )}

              <Grid item xs={12} container mt={4} justifyContent='space-between'>
                {/* This will prevent the submission of form on pressing enter key */}
                <button type='submit' disabled className='d-none' aria-hidden='true'></button>
                <MuiButton color='error' buttonClick={() => navigate('/admin/organizations')}>
                  Exit without Saving
                </MuiButton>
                <MuiButton variant='contained' type='submit' minWidth='150px'>
                  Submit
                </MuiButton>
              </Grid>
            </Grid>
          </form>
        </MuiCard>
      </Container>
    </StyledContainer>
  );
};

export default OrganisationDetails;
