import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ClientTabPanelBody from 'components/clients/ClientTabPanelBody';
import { ClientDetails } from 'common/types';
import styled from '@emotion/styled';
import { selectInvestorProfileStep } from 'store/investor/selectors';
import { useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
const StyledWrapper = styled(Box)`
  .MuiTabs-root {
    min-height: 0;
    .MuiTabs-flexContainer {
      padding: 0 1rem;
      border-bottom: 1px solid #d7d7d7;
      justify-content: flex-start;
    }
    .MuiTabs-indicator {
      height: 5px;
      background-color: var(--p300);
      border-radius: 5px 5px 0 0;
    }
    button {
      color: var(--s50);
      font-size: 18px;
      font-weight: 400;
      padding: 0 1rem;
      min-height: 0;
      margin-bottom: 10px;
      text-transform: none;
      &.Mui-selected {
        color: var(--p500);
        font-weight: 500;
      }
    }
    button + button {
      margin-left: 2rem;
    }
  }
`;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MuiTabs(props: {
  tabHeadings: string[];
  clientId: string;
  clientData: ClientDetails;
}) {
  const profileStep = useAppSelector(selectInvestorProfileStep);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (profileStep) {
      setValue(profileStep);
    }
  }, [profileStep]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledWrapper sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          {React.Children.toArray(
            props.tabHeadings.map((heading: string, index: number) => (
              <Tab label={heading} {...a11yProps(index)} />
            )),
          )}
        </Tabs>
      </Box>
      <ClientTabPanelBody clientId={props.clientId} clientData={props.clientData} value={value} />
    </StyledWrapper>
  );
}
