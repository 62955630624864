import { Container } from '@mui/material';
import MuiStepper from 'common/components/stepper';
import { OrderStatusType, QueryType } from 'common/types';
import OrderDetailsForm from 'components/orders/orderCreation/OrderDetailsForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectNewOrderDetails, selectOrderActiveStep } from 'store/order/selectors';
import { setOrderActiveStep, setOrderDetails, setOrderSubmittedModalOpen } from 'store/order/slice';
import OrderDocumentsForm from './OrderDocumentsForm';
import OrderSummary from './OrderSummary';
import SuccessfulOrderCreation from './SuccessfulOrderCreation';

type ButtonClickType = {
  isDeleteOrderRequest: boolean;
  isResubmitOrder?: boolean;
};

const OrderCreation = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const location: any = useLocation();
  const id = location.pathname.split('/')[2];
  const isEditOrder = location?.state?.isEditOrderRequest;
  const [dataForRequest, setDataForRequest] = useState<any>(null);
  const activeStep = useAppSelector(selectOrderActiveStep);
  const newOrderDetails: any = useAppSelector(selectNewOrderDetails);
  const placedOrderDetails: any = useAppSelector(selectNewOrderDetails);
  const orderDetails = {
    ...newOrderDetails,
    fundId: newOrderDetails?.selectedFund?.fund_id,
    fundName: newOrderDetails?.selectedFund?.fund_name,
    investorId: newOrderDetails?.investorDetails?.investor_id,
    investorName: newOrderDetails?.investorDetails?.account_name,
    eventDate: new Date(newOrderDetails?.eventDate),
  };
  delete orderDetails?.selectedFund;
  delete orderDetails?.investorDetails;

  const {
    mutate: createNewOrder,
    data: orderData,
    isLoading: isCreateOrderLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/ordermanagement/order',
      params: orderDetails,
      isFormData: false,
    },
    {
      onSuccess: (data: any) => {
        dispatch(setOrderDetails({ ...placedOrderDetails, orderId: data.order_id }));
        dispatch(setOrderActiveStep(1));
      },
    },
  );

  const {
    refetch: requestApproval,
    isLoading: isUpdateLoading,
    isRefetching: isUpdateRefetching,
  } = useReactQuery(
    ['requestOrderApproval'],
    {
      url: 'qaip/v1/ordermanagement/update',
      params: [dataForRequest],
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        dispatch(setOrderSubmittedModalOpen(true));
        dispatch(setOrderActiveStep(0));
        dispatch(setOrderDetails(null));
        navigate(`/order/${newOrderDetails?.orderId ? newOrderDetails?.orderId : id}`);
      },
    },
  );

  const steps = isEditOrder
    ? ['Order Details', 'Upload Documents']
    : ['Order Details', 'Upload Documents', 'Review'];

  useEffect(() => {
    if (dataForRequest) requestApproval();
    // eslint-disable-next-line
  }, [dataForRequest]);

  const handleRequestClick = ({
    isDeleteOrderRequest = false,
    isResubmitOrder = false,
  }: ButtonClickType) => {
    if (isDeleteOrderRequest) {
      let data = {
        orderId: isEditOrder ? id : orderData?.order_id,
        status: OrderStatusType.ORDER_CANCELLED,
        cancelReason: '',
        amount: null,
        units: 0,
        eventDate: new Date(),
      };
      setDataForRequest(data);
    } else {
      let data = {
        orderId: isEditOrder ? id : orderData?.order_id,
        status: isResubmitOrder ? OrderStatusType.SENT_TO_QUALIS : OrderStatusType.SENT_TO_APPROVER,
      };
      setDataForRequest(data);
    }
  };

  return (
    <Container maxWidth='xl' className='container-lr-padding container-t-margin'>
      <Container maxWidth='lg' sx={{ px: '88px !important' }}>
        <MuiStepper steps={steps} activeStep={activeStep} />
        {activeStep === 0 && (
          <OrderDetailsForm
            createNewOrder={createNewOrder}
            isCreateOrderLoading={isCreateOrderLoading}
          />
        )}
        {activeStep === 1 && (
          <OrderDocumentsForm
            orderId={orderData?.order_id}
            handleRequestClick={handleRequestClick}
            isUpdateLoading={isUpdateLoading || isUpdateRefetching}
          />
        )}
        {!isEditOrder && activeStep === 2 && (
          <OrderSummary
            handleRequestClick={handleRequestClick}
            isUpdateLoading={isUpdateLoading || isUpdateRefetching}
          />
        )}
        {!isEditOrder && activeStep === 3 && <SuccessfulOrderCreation />}
      </Container>
    </Container>
  );
};

export default OrderCreation;
