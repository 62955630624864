import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { canRequestForApproval, canResubmitOrder } from 'helpers/roles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectNewOrderDetails } from 'store/order/selectors';
import { resetOrderData, setOrderActiveStep, setOrderDocs } from 'store/order/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { ReactComponent as CircleArrowIcon } from 'common/assets/images/CTA/CircleArrow.svg';
import { ReactComponent as PlaneIcon } from 'common/assets/images/CTA/Plane.svg';
import styled from '@emotion/styled';
import OrderDocsTable from './OrderDocsTable';
import { columns } from './constants';
import LoadingButton from 'common/components/button/LoadingButton';
import { useState } from 'react';
import OrderDocPrefillModal from 'components/modals/orders/OrderDocPrefillModal';

type Props = {
  orderId: string;
  handleRequestClick: any;
  isUpdateLoading: boolean;
};

const StyledCard = styled(MuiCard)`
  .docRow {
    flex-wrap: nowrap;
    padding: 0.75rem 0.75rem 0.75rem 2px;
    & > div {
      display: flex;
      align-items: center;
      padding: 0 1rem;
    }
    svg {
      font-size: 1rem;
      color: #8e8e8e;
      &.check {
        color: var(--green);
      }
    }
    .partOfAnother {
      padding: 0 1rem 0 0;
      font-size: 0.625rem;
      border-right: 1px solid var(--s20);
      svg {
        color: var(--p150);
      }
    }
    .docName {
      font-size: 0.875rem;
    }
    .files {
      padding-right: 0;
      flex-grow: 1 !important;
      flex-shrink: 1 !important;
      color: var(--s30);
      flex-wrap: wrap;
      div {
        display: flex;
        align-items: center;
        padding: 0.25rem 0.5rem;
        border: 1px solid var(--s15);
        border-radius: 0.25rem;
        font-size: 13px;
      }
    }
  }
  .docRow + .docRow {
    border-top: 1px solid var(--s15);
  }

  .mandatory {
    font-size: 13px;
  }

  .saveDoc {
    position: relative;
    left: -0.5rem;
    span {
      font-size: 14px;
    }
    svg {
      font-size: 1rem;
    }
  }
`;

const OrderDocumentsForm = ({ orderId, handleRequestClick, isUpdateLoading }: Props) => {
  const location: any = useLocation();
  const navigate: any = useNavigate();
  const dispatch = useTypedDispatch();
  const id = location.pathname.split('/')[2];
  const isEditOrder = location?.state?.isEditOrderRequest;
  const newOrderDetails = useAppSelector(selectNewOrderDetails) as any;
  const userOnBehalfOf = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const currentOrderDetails = location?.state?.orderDetails;
  const fundDetails = location?.state?.fundDetails;
  const fund = fundDetails ? fundDetails : newOrderDetails;
  const order = newOrderDetails ? newOrderDetails : currentOrderDetails;
  const [docPrefillModalOpen, setDocPrefillModalOpen] = useState<boolean>(false);

  const { data: orderDocs } = useReactQuery(
    [`orderDocs${user?.userId}`],
    {
      url: `qaip/v1/documentmanagement/masterchecklistOrder`,
      params: {
        investorId: order?.investorId,
        orderId: order?.orderId ?? id,
      },
    },
    {
      enabled: !!user || !!newOrderDetails,
      refetchOnMount: true,
      onSuccess: (data: any) => {
        dispatch(setOrderDocs(data));
      },
    },
  );
  const { data: fundDoc } = useReactQuery([`fundDetails${fund?.selectedFund?.fund_id}`], {
    url: `qaip/v1/documentmanagement/getList/fund?fund_id=${fund?.selectedFund?.fund_id ?? fund?.fund_id}&document_type=file`,
  });

  const handleNextClick = async () => {
    if (isEditOrder) {
      await dispatch(resetOrderData());
      await navigate(`/order/${id}`);
    } else {
      await dispatch(setOrderActiveStep(2));
    }
  };

  const handleBackClick = async () => {
    await dispatch(setOrderActiveStep(0));
  };

  return (
    <StyledCard
      sx={{ py: 0, pl: 1, pr: 1, border: 'none' }}
      className='flex-col flex-stretch-between'
    >
      <Grid container>
        <Grid item xs={12} className='flex-center-between'>
          <h2 className='font-wt-500 mb-0'>Upload Documents</h2>
          <MuiButton variant='text' buttonClick={() => setDocPrefillModalOpen(true)}>
            How this works
          </MuiButton>
        </Grid>
        <Grid item xs={12} mb={3}>
          <p className='mt-3'>
            {newOrderDetails?.selectedFund?.isAllocationWorkflowEnabled === 'yes' ? (
              `Next, let's upload the documents required for a subscription. Please refer to this fund's subscription checklist for details.`
            ) : (
              <>
                Next, let's upload the documents required for the subscription. Documents previously
                used for the client are pre-populated.
                <br />
                Please refer to the fund's subscription document for detailed requirements.
              </>
            )}
          </p>
        </Grid>
        <Grid item xs={12} mb={1.5}>
          <OrderDocsTable fundDoc={fundDoc} rows={orderDocs} columns={columns} />
        </Grid>
        <Grid item xs={12} mb={4}>
          <span className='mandatory'>
            Documents marked with * are deemed mandatory according to Fund's subscription document.
          </span>
        </Grid>
      </Grid>
      <section className='flex-center-end'>
        <div>
          <MuiButton buttonClick={handleBackClick} className='mr-3'>
            Back
          </MuiButton>
          {!isEditOrder && (
            <MuiButton
              className='mr-3'
              buttonClick={() => {
                dispatch(resetOrderData());
                navigate(`/order/${newOrderDetails?.orderId}`);
              }}
            >
              Save & Exit
            </MuiButton>
          )}
          <MuiButton variant='contained' buttonClick={handleNextClick} minWidth='150px'>
            {isEditOrder ? 'Save & Exit' : 'Review Order'} <CircleArrowIcon className='ml-2' />
          </MuiButton>

          {isEditOrder &&
            user &&
            canRequestForApproval(user) &&
            !canResubmitOrder(user, currentOrderDetails.orderStatusId) &&
            (isUpdateLoading ? (
              <LoadingButton className='ml-2' minWidth='176px' />
            ) : (
              <MuiButton
                variant='contained'
                buttonClick={handleRequestClick}
                className='ml-2'
                minWidth='176px'
              >
                <PlaneIcon className='mr-2' /> Request Approval
              </MuiButton>
            ))}
          {isEditOrder &&
            user &&
            canResubmitOrder(user, currentOrderDetails.orderStatusId) &&
            (isUpdateLoading ? (
              <LoadingButton className='ml-2' />
            ) : (
              <MuiButton
                variant='contained'
                buttonClick={() => handleRequestClick({ isResubmitOrder: true })}
                className='ml-2'
                minWidth='150px'
              >
                Resubmit Order
              </MuiButton>
            ))}
        </div>
      </section>
      <OrderDocPrefillModal
        isModalOpen={docPrefillModalOpen}
        handleClose={() => setDocPrefillModalOpen(false)}
      />
    </StyledCard>
  );
};

export default OrderDocumentsForm;
