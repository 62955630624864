import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import MuiTooltip from '../tooltip';

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string | React.ReactNode;
}

type Props = {
  finalCols?: Column[];
  orderBy?: any;
  setOrderBy?: any;
  ctaType?: string;
};

const TableSubHeader = ({ finalCols = [], orderBy, setOrderBy, ctaType }: Props) => {
  return (
    <TableHead>
      <TableRow>
        {React.Children.toArray(
          finalCols?.map((col: Column, c: number) => (
            <TableCell align={col.align} sortDirection={'asc'}>
              <TableSortLabel
                active={orderBy?.id === col?.id}
                direction={orderBy?.dir ? 'asc' : 'desc'}
                onClick={() => {
                  orderBy?.id === col.id
                    ? setOrderBy && setOrderBy({ id: col.id, dir: !orderBy?.dir, index: c })
                    : setOrderBy && setOrderBy({ id: col.id, dir: true, index: c });
                }}
              >
                {col.label}
              </TableSortLabel>
              {col.tooltip && <MuiTooltip content={col.tooltip} />}
            </TableCell>
          )),
        )}
        {ctaType ? <TableCell></TableCell> : null}
      </TableRow>
    </TableHead>
  );
};

export default TableSubHeader;
