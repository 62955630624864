import { EntityType, OrderStatusType } from 'common/types';

export const statusList = [
  {
    orderStatusId: OrderStatusType.APPROVAL_REQUESTED,
    statusName: 'APPROVAL_REQUESTED',
    statusString: 'Approval Requested',
    width: 10,
  },
  {
    orderStatusId: OrderStatusType.SENT_TO_APPROVER,
    statusName: 'SENT_TO_APPROVER',
    statusString: 'Pending Order Submission',
    width: 20,
  },
  {
    orderStatusId: OrderStatusType.SENT_TO_QUALIS,
    statusName: 'SENT_TO_QUALIS',
    statusString: 'Pending Platform Review',
    width: 35,
  },
  {
    orderStatusId: OrderStatusType.ALLOC_REQUESTED,
    statusName: 'SENT_TO_FUND_MANAGER',
    statusString: 'Sent to Fund Manager',
    width: 35,
  },
  {
    orderStatusId: OrderStatusType.ALLOC_RECEIVED,
    statusName: 'SENT_TO_FUND_MANAGER',
    statusString: 'Sent to Fund Manager',
    width: 35,
  },
  {
    orderStatusId: OrderStatusType.SENT_TO_FUND_MANAGER,
    statusName: 'SENT_TO_FUND_MANAGER',
    statusString: 'Pending FM Acceptance',
    width: 50,
  },
  {
    orderStatusId: OrderStatusType.SENT_TO_FUND_MANAGER,
    statusName: 'SENT_TO_FUND_MANAGER',
    statusString: 'Pending Allocation',
    width: 50,
  },
  {
    orderStatusId: OrderStatusType.ORDER_ACCEPTED,
    statusName: 'ORDER_ACCEPTED',
    statusString: 'Pending Fund Transfer',
    width: 65,
  },
  {
    orderStatusId: OrderStatusType.FUNDS_TRANSFERRED,
    statusName: 'FUNDS_TRANSFERRED',
    statusString: 'Pending Order Completion',
    width: 80,
  },
  {
    orderStatusId: OrderStatusType.MORE_INFORMATION,
    statusName: 'MORE_INFORMATION',
    statusString: 'Pending Information',
    width: 0,
  },
  {
    orderStatusId: OrderStatusType.NOT_SUBMITTED,
    statusName: 'NOT_SUBMITTED',
    statusString: 'Not Submitted',
    width: 0,
  },
  {
    orderStatusId: OrderStatusType.ORDER_REJECTED,
    statusName: 'ORDER_REJECTED',
    statusString: 'Order Rejected',
    width: 0,
  },
  {
    orderStatusId: OrderStatusType.ORDER_COMPLETED,
    statusName: 'ORDER_COMPLETED',
    statusString: 'Order Completed',
    width: 100,
  },
  {
    orderStatusId: OrderStatusType.ORDER_CANCELLED,
    statusName: 'ORDER_CANCELLED',
    statusString: 'Order Cancelled',
    width: 0,
  },
];

export const entityDropdownList = [
  {
    id: EntityType.DEALERS_GROUP,
    name: 'Dealers Group',
  },
  {
    id: EntityType.FUNDS_MANAGER,
    name: 'Funds Manager',
  },
  {
    id: EntityType.QUALIS,
    name: 'Tifin Private Markets',
  },
];

export const eventList = [
  { orderEventId: 1, eventName: 'Subscription' },
  { orderEventId: 2, eventName: 'Redemption' },
  { orderEventId: 3, eventName: 'Request Allocation' },
];

export const docTypeDetails = [
  {
    docConfigTypeId: 'f40f45b2-6a99-11ea-bc55-0242ac130003',
    docConfigType: 'Offering and Subscription Documents',
  },
  {
    docConfigTypeId: 'f8fbdb76-6a99-11ea-bc55-0242ac130003',
    docConfigType: 'Redemption',
  },
  {
    docConfigTypeId: '64a11300-6aa4-11ea-bc55-0242ac130003',
    docConfigType: 'Marketing Documents and Other',
  },
];

export const ELEMENT_DATA_QUALIS = [
  {
    menu: 'Fund',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Organisation',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Investor',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Holdings',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Orders',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Activity Logs',
    viewaccess: false,
    modifyaccess: false,
    createaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'User Management',
    viewaccess: false,
    modifyaccess: false,
    createaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'APL',
    viewaccess: false,
    modifyaccess: false,
    createaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Notification Management',
    viewaccess: false,
    modifyaccess: false,
    createaccess: false,
    approvalaccess: false,
  },
];

export const ELEMENT_DATA_DG = [
  {
    menu: 'Fund',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Investor',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Holdings',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Orders',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'User Management',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'APL',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
];

export const ELEMENT_DATA_AG = [
  {
    menu: 'Fund',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Organisation',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Investor',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Holdings',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'Orders',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'User Management',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'APL',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
];

export const ELEMENT_DATA_FM = [
  {
    menu: 'Orders',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
  {
    menu: 'User Management',
    viewaccess: false,
    createaccess: false,
    modifyaccess: false,
    approvalaccess: false,
  },
];
