import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { ClientDetails, OrderDetails } from 'common/types';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import InvestmentMasterListTableRow from './InvestmentMasterListTableRow';

const StyledWrapper = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    border-bottom: 1px solid #b8bcc4;
    padding: 0 22px;

    th {
      font-weight: 700;
      background-color: var(--s15);
      border: 0;
      color: #525863;
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }
    tr:nth-of-type(even) {
      background-color: var(--s7);
    }
  }

  td {
    color: #626262;
    border: none;
    padding: 6px 1rem;
    &:first-of-type {
      padding-left: 2rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .showOnHover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .MuiTableRow-hover:hover .showOnHover {
    opacity: 1;
  }

  .bold {
    font-weight: 700;
  }

  .nullTable {
    background-color: #fafafa;
    height: 360px;
    color: #bdbdbd;
    text-align: center;
    vertical-align: center;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    position: relative;
    top: 6px;
    left: unset;
  }

  .penIcon.edit path {
    fill: var(--p200);
  }

  .textInput {
    .MuiInputBase-root {
      color: var(--p500);
      background-color: transparent !important;
      input {
        padding: 0.5rem !important;
        &.Mui-disabled {
          color: var(--s50);
          -webkit-text-fill-color: var(--s50);
        }
      }
      fieldset {
        border: none;
      }
    }

    &.empty input {
      border-bottom: 1px solid var(--s15);
    }

    &.edit input {
      border-bottom: 1px solid var(--p200) !important;
    }
  }

  .selectField {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    div.Mui-disabled {
      color: var(--s50);
      -webkit-text-fill-color: var(--s50);
    }
    svg {
      color: var(--p500);
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
    &.edit {
      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: var(--p200) !important;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--p200) !important;
      }
    }
  }

  .MuiTableSortLabel-icon {
    display: none;
  }
`;

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string | React.ReactNode;
  isSelect?: boolean;
  isInput?: boolean;
  selectOptions?: Array<any>;
}

// Add row types here
// Need to figure out the right way to do this
// Right now it throws error when you try to use it as index
type Row = ClientDetails | OrderDetails | any;

interface TableProps {
  rows: Row;
  columns: Array<Column>;
  isLoading?: boolean;
  disableHeader?: boolean;
  ctaType?: string;
  tableClassName?: string;
  setRows?: (rows: Row[]) => void;
  setUpdateRow: (row: any, index: number) => void;
  setDeleteRow: any;
}

const InvestmentMasterListTable = ({
  rows = [],
  columns,
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  setUpdateRow,
  setDeleteRow,
  setRows,
  disableHeader,
}: TableProps) => {
  return (
    <StyledWrapper className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader finalCols={columns} ctaType={'test'} />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + 1} className='nullTable'>
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) : (
                  rows.map((row: Row, i: number) => (
                    <InvestmentMasterListTableRow
                      key={row.UID}
                      i={i}
                      row={row}
                      columns={columns}
                      updateRow={(row: any, index: number) => setUpdateRow(row, index)}
                      deleteRow={setDeleteRow}
                    />
                  ))
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </StyledWrapper>
  );
};

export default InvestmentMasterListTable;
