import React, { useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import SelectField from 'common/components/inputField/SelectField';
import { Close } from '@mui/icons-material';
import { ReactComponent as DocUpload } from 'common/assets/images/DocUpload.svg';
import { useAppSelector, useRQMutation } from 'store/hooks';
import { encryptDocumentUrl } from 'helpers';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import { selectNewOrderDetails } from 'store/order/selectors';
import { useQueryClient } from 'react-query';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { DocumentOwnerType, QueryType } from 'common/types';
import AddOrderDocumentModal from 'components/modals/AddOrderDocumentModal';
import { selectClientDetails } from 'store/investor/selectors';
import { useSelector } from 'react-redux';
import DocumentDownload from './DocumentDownload';
import { handleDocDownloadVisibility, setDocOptionsalphabetically } from './utils';
interface Props {
  columns: Array<any>;
  name: string;
  docOptions: Array<string>;
  setIsAddRowVisible: any;
  isOrderDoc?: boolean;
  id: string;
  orderId?: string;
  fundDoc?: any;
  isClientRow?: boolean;
}

const NewRow = ({
  columns,
  name,
  docOptions,
  setIsAddRowVisible,
  isOrderDoc = false,
  id,
  orderId,
  fundDoc,
  isClientRow = false,
}: Props) => {
  const queryClient = useQueryClient();
  const investor = useSelector(selectClientDetails);
  const [docType, setDocType] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [documentData, setDocumentData] = useState<Object | null>({});
  const [title, setTitle] = useState('');
  const newOrderDetails = useAppSelector(selectNewOrderDetails) as any;
  const userOnBehalfOf = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { mutate: updateRow } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/masterchecklistOrder',
      method: QueryType.PUT,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`orderDocs${user?.userId}`);
        queryClient.invalidateQueries(`investorDoc-${newOrderDetails?.investorId ?? id}`);
        queryClient.invalidateQueries('orderDoc');
      },
      onError: () => setLoading(false),
    },
  );

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: async () => {
        await updateRow([{ status: true, id: docType?.id }]);
        queryClient.invalidateQueries(`orderDoc${user?.userId}`);
        queryClient.invalidateQueries(`investorDoc-${investor?.investor_id ?? id}`);
        queryClient.invalidateQueries('orderDoc');
        setDocType(null);
        setIsAddRowVisible(false);
        setTitle('');
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const { mutate: fetchPresignedUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData({ documentData: documentData });
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const docData = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: id,
      documentType:
        docType?.type === 'Investor'
          ? DocumentOwnerType.INVESTOR_PERSONAL
          : DocumentOwnerType.ORDER,
      docType: 'file',
      status: 'Active',
      isOrder: isOrderDoc,
      ...(isOrderDoc && {
        orderId: newOrderDetails?.orderId
          ? newOrderDetails?.orderId.toString()
          : orderId?.toString(),
      }),
    });
    setDocumentData({
      ...docData.documentData,
      doc_classification_name: title !== '' ? title : docType?.title,
      doc_category_type_id: 3,
      master_checklist_id: docType?.masterDocId ?? docType?.id,
      ...(isOrderDoc && {
        order_id: newOrderDetails.orderId
          ? newOrderDetails.orderId.toString()
          : orderId?.toString(),
      }),
    });
    fetchPresignedUrl(docData.uploaddocumentKey);
    event.target.value = '';
    event.target.files = null;
  };

  const sortedDocOptions = setDocOptionsalphabetically(docOptions);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align='right'>
        <div className='d-flex flex-center-end'>
          <Close onClick={() => setIsAddRowVisible(false)} className='close-icon cursor-pointer' />
          {loading && <CircularProgress className='loading' />}
        </div>
      </TableCell>
      {React.Children.toArray(
        columns.slice(1, columns.length - 1).map((col: any, index: number) => (
          <TableCell
            align={col.align}
            className={`
        ${col.showOnHover && 'showOnHover'}
        ${col.bold && 'bold'}
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.isSelect && (
              <SelectField
                className={`selectField pt-0`}
                searchIcon={false}
                optionId={name === 'addOptionalClientDocs' ? 'id' : 'masterDocId'}
                optionName='title'
                options={sortedDocOptions}
                setSelectedValue={async (e: any) => {
                  if (
                    e.type === 'Investor' &&
                    e.fundId == null &&
                    e.documentsResponseDto.length > 0
                  ) {
                    await updateRow([{ status: true, id: e?.id }]);
                  }
                  setDocType(e);
                }}
                value={name === 'addOptionalClientDocs' ? docType?.id : docType?.masterDocId}
                defaultValue={name === 'addOptionalClientDocs' ? docType?.id : docType?.masterDocId}
              />
            )}
          </TableCell>
        )),
      )}
      <TableCell>
        <div className='d-flex'>
          <input
            id={'doc-upload-' + name}
            hidden
            onChange={(e: any) => onFileUpload(e)}
            type='file'
            disabled={loading ? loading : docType ? docType?.title === '' : true}
          />
          <label htmlFor={'doc-upload-' + name} style={{ height: '1rem' }}>
            <DocUpload
              className={`cursor-pointer docUpload ${
                (loading ? loading : docType ? docType?.title === '' : true) ? 'disable' : ''
              }`}
            />
          </label>
          {!isClientRow &&
            docType &&
            docType?.title &&
            handleDocDownloadVisibility(docType?.title as string, fundDoc) && (
              <DocumentDownload fundDoc={fundDoc} row={{ title: docType?.title }} />
            )}
        </div>
      </TableCell>
      <AddOrderDocumentModal
        isModalOpen={docType?.title === 'Other'}
        handleModalClick={() => {
          setDocType(null);
          setTitle('');
        }}
        onFileUpload={onFileUpload}
        setValue={setTitle}
        uploadedFileData={uploadedFileData}
        isLoading={loading}
        hasSuccessfullyUploaded={false}
        docName={title}
      />
    </TableRow>
  );
};

export default NewRow;
