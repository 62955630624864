export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Teams',
  },
];

export const COLUMNS = [
  {
    id: 'teamName',
    label: 'TEAM',
    needsFormatter: false,
  },
  {
    id: 'teamLocation',
    label: 'Location',
    needsFormatter: false,
  },
  {
    id: 'action',
    label: '',
    needsFormatter: false,
    align: 'right' as const,
  },
];
