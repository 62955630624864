import React, { useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { CheckCircle, Close, VisibilityOutlined } from '@mui/icons-material';
import { ReactComponent as DocUpload } from 'common/assets/images/DocUpload.svg';
import { ReactComponent as DocDelete } from 'common/assets/images/DocDelete.svg';
import { useAppSelector, useRQMutation } from 'store/hooks';
import { useLocation } from 'react-router-dom';
import { encryptDocumentUrl } from 'helpers';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import { selectNewOrderDetails } from 'store/order/selectors';
import { useQueryClient } from 'react-query';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { QueryType } from 'common/types';
import ViewDocumentModal from 'common/components/modal/ViewDocumentModal';
import DocumentDownload from './DocumentDownload';
import MuiTooltip from 'common/components/tooltip';
import { ORDER_DOCS_PREFILL_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';

interface Props {
  row: any;
  columns: Array<any>;
  i: number;
  name: string;
  documentType: string;
  fundDoc: any;
  dto?: any;
  investorId?: string;
}

const OrderDocsRow = ({ row, columns, i, name, documentType, fundDoc, dto, investorId }: Props) => {
  const queryClient = useQueryClient();
  const [viewDocModalOpen, setViewDocModalOpen] = useState<boolean>(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [documentData, setDocumentData] = useState<Object | null>({});
  const location: any = useLocation();
  const newOrderDetails = useAppSelector(selectNewOrderDetails) as any;
  const userOnBehalfOf = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const hasUploadedFiles = row?.documentsResponseDto?.length > 0;
  const id = location.pathname.split('/')[2];
  const [loading, setLoading] = useState<boolean>(false);

  const { mutate: updateRow, isLoading: isRowUpdating } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/masterchecklistOrder',
      method: QueryType.PUT,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`orderDocs${user?.userId}`);
        queryClient.invalidateQueries(`investorDoc-${newOrderDetails?.investorId ?? id}`);
        queryClient.invalidateQueries('orderDoc');
      },
      onError: () => setLoading(false),
    },
  );

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`orderDocs${user?.userId}`);
        queryClient.invalidateQueries(`investorDoc-${newOrderDetails?.investorId ?? id}`);
        queryClient.invalidateQueries('orderDoc');
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    },
  );
  const { mutate: fetchDocUrl, data: docUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    },
    {
      onError: () => setLoading(false),
    },
  );

  const { mutate: deleteDocument, isLoading: isDeleting } = useRQMutation(
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${dto?.documentId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`orderDocs${user?.userId}`);
        queryClient.invalidateQueries(`investorDoc-${newOrderDetails?.investorId ?? id}`);
        queryClient.invalidateQueries('orderDoc');
        setLoading(false);
      },
      onError: () => setLoading(false),
    },
  );

  const { mutate: fetchPresignedUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData({ documentData: documentData });
      },
      onError: () => setLoading(false),
    },
  );

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, isReupload?: boolean) => {
    setLoading(true);
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const docData = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: newOrderDetails.investorId,
      documentType: documentType,
      docType: 'file',
      status: 'Active',
      isOrder: true,
      orderId: newOrderDetails.orderId ? newOrderDetails.orderId : id,
    });
    setDocumentData({
      ...docData.documentData,
      doc_classification_name: row.title !== 'Other' ? row.title : dto.docClassificationName,
      doc_category_type_id: 3,
      master_checklist_id: row.masterDocId,
      order_id: newOrderDetails.orderId ? newOrderDetails.orderId : id,
      ...(isReupload && { reupload_doc_id: dto.documentId }),
    });
    fetchPresignedUrl(docData.uploaddocumentKey);
    event.target.value = '';
    event.target.files = null;
  };

  const handleUpdateRow = async () => {
    if (row.type === 'Investor' && row.fundId === null) {
      await updateRow([{ status: false, id: row?.id }]);
    } else {
      if (dto?.documentId) await deleteDocument({});
      await updateRow([{ status: false, id: row?.id }]);
    }
  };

  const isDownloadable = fundDoc?.some((doc: any) => doc.prefillMapTo === row?.masterDocId);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align='right' sx={{ width: '6%' }}>
        <div className='d-flex flex-center-end'>
          {!isRowUpdating ? (
            <Close onClick={() => handleUpdateRow()} className='close-icon cursor-pointer' />
          ) : (
            <CircularProgress className='loading' />
          )}
          {loading ? (
            <CircularProgress className='loading' />
          ) : (
            hasUploadedFiles && <CheckCircle className='check' />
          )}
        </div>
      </TableCell>
      {React.Children.toArray(
        columns.slice(1, columns.length - 1).map((col: any, index: number) => (
          <TableCell
            align={col.align}
            className={`
        ${col.showOnHover && 'showOnHover'}
        ${col.bold && 'bold'}
        ${col.cellClassName ? col.cellClassName : ''}
        ${
          col.id === 'title' && isDownloadable
            ? 'active'
            : ''
        }
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            <div className='flex-center-start'>
              {col.id === 'docName'
                ? dto?.docName
                : row.title === 'Other'
                ? dto.docClassificationName
                : `${row[col.id]}${row.visibility === 'Mandatory' ? '*' : ''}`}
              {col.id === 'title' && isDownloadable && (
                <>
                  <DocumentDownload fundDoc={fundDoc} row={row} investorId={investorId} />
                  <MuiTooltip content={ORDER_DOCS_PREFILL_TOOLTIP_CONTENT} />
                </>
              )}
            </div>
          </TableCell>
        )),
      )}
      <TableCell sx={{ width: '20%' }}>
        <div className='d-flex'>
          <input
            id={'doc-upload-' + i + name}
            hidden
            onChange={(e: any) => {
              row?.documentsResponseDto.length > 0 ? onFileUpload(e, true) : onFileUpload(e);
            }}
            type='file'
          />
          <label htmlFor={'doc-upload-' + i + name} style={{ height: '1rem' }}>
            <DocUpload className='docUpload cursor-pointer' />
          </label>
          {hasUploadedFiles && (
            <VisibilityOutlined
              onClick={() => {
                fetchDocUrl({ object_key: dto?.docLink });
                setViewDocModalOpen(true);
              }}
              className='visibility cursor-pointer ml-2'
            />
          )}
          {isDeleting ? (
            <CircularProgress className='loading' />
          ) : (
            hasUploadedFiles && (
              <DocDelete
                onClick={() => {
                  deleteDocument({});
                }}
                className='cursor-pointer ml-2 docDelete'
              />
            )
          )}
        </div>
      </TableCell>
      <ViewDocumentModal
        isModalOpen={viewDocModalOpen}
        title={dto?.docClassificationName}
        handleClose={() => setViewDocModalOpen(false)}
        docLink={docUrl?.url}
        deletable={false}
      />
    </TableRow>
  );
};

export default OrderDocsRow;
