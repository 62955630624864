import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: calc(100vh - 60px);

  .sidebar {
    width: 200px;
    background-color: var(--s10);
    padding: 0.75rem 0.75rem 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 90px);
    overflow-y: auto;

    h3 {
      font-size: 13px;
      font-weight: 500;
    }

    h4 {
      font-size: 11px;
      font-weight: 400;
      margin-bottom: 0;
    }

    h6 {
      font-size: 9px;
      color: #646464;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    button {
      border-radius: 6px;
    }

    .addedFields {
      margin-bottom: 3rem;
      & > div {
        margin-top: 1rem;
      }
      .MuiSelect-select {
        border-radius: 0.5rem;
        padding-left: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
        background: white;
      }
      .placeholder {
        left: 8px;
      }
    }
  }

  .webviewer {
    width: calc(100vw - 230px);
  }

  .field-use {
    font-size: 12px;
    color: #102c44;
  }
`;
