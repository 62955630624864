import { selectAuthUser } from 'store/user/selectors';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { LoginType } from './types';
import { useForm, SubmitHandler } from 'react-hook-form';
import MuiButton from 'common/components/button';
import { Grid, TextField } from '@mui/material';
import { ErrorMessage } from 'common/components/errorMessageBox';
import Logo from 'common/assets/images/QualisLogo.svg';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const authUser = useAppSelector(selectAuthUser);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>();

  const onSubmit: SubmitHandler<LoginType> = async (data) => {
    dispatch({ type: 'INIT_AUTH', payload: data });
  };

  useEffect(() => {
    if (authUser && authUser.accessToken) navigate('/');
    // eslint-disable-next-line
  }, [authUser]);

  return (
    <div className='authBG'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent='center'>
          <Grid item xs={10} mb={8}>
            <img src={Logo} alt='Tifin Private Markets' />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant='standard'
              label='Username'
              type='text'
              {...register('username', {
                required: 'Please enter a valid Email ID.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid Email ID.',
                },
              })}
              name='username'
              className='mb-3'
            />
            {errors.username && <ErrorMessage error={errors?.username.message} />}
          </Grid>
          <Grid item xs={12} mb={2}>
            <TextField
              fullWidth
              variant='standard'
              label='Password'
              type='password'
              {...register('password', { required: 'Please enter a valid Password.' })}
              name='password'
              className='mb-3'
            />
            {errors.password && <ErrorMessage error={errors?.password.message} />}
          </Grid>
          <Grid item xs={12} mb={8} className='flex-center-end'>
            <Link to='/forgot-password'>Forgot Password?</Link>
          </Grid>
          <Grid item xs={12}>
            <MuiButton disableElevation fullWidth type='submit' variant='contained' color='primary'>
              Login
            </MuiButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Login;
