import styled from '@emotion/styled';
import { NotificationsOutlined } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Badge,
  CircularProgress,
  Container,
  Divider,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';
import NavLogo from '../../assets/images/QualisLogo.svg';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { TabContext, TabPanel } from '@mui/lab';
import { format } from 'date-fns';
import Loading from '../Loading';
import MuiButton from '../button';
import { getRoleBasedHeader } from 'helpers/roles';
import { EntityType, QueryType } from 'common/types';
import AllocationReceivedModal from 'components/modals/AllocationReceivedModal';
import AllocationRequestedModal from 'components/modals/AllocationRequestedModal';
import HeaderNotificationBubbleGenerator from './HeaderNotificationBubbleGenerator';

const NavBar = styled(AppBar)`
  background-color: #1a1d27;
  color: white;

  img {
    cursor: pointer;
  }

  hr {
    border-color: #c2c2c2;
    margin: 0.5rem 34px 0.5rem 50px;
  }

  .MuiToolbar-root {
    padding: 0;
    min-height: 60px;
  }

  .MuiTabs-root {
    flex-grow: 1;
    align-self: stretch;
  }

  .MuiTabs-scroller {
    display: flex;
  }

  .MuiTab-textColorPrimary {
    color: #b6bacd;
    &.Mui-selected {
      color: white;
    }
  }

  .MuiTabs-indicator {
    background-color: white;
  }

  .MuiTab-root {
    font-size: 13px;
    min-width: 60px;
    min-height: 60px;
    text-transform: capitalize;
  }

  .MuiIconButton-root {
    color: white;
  }

  .MuiAvatar-root {
    background-color: #4e5573;
  }

  em {
    font-size: 13px;
  }
`;

const NotifMenu = styled(Popover)`
  .MuiPaper-root {
    width: 360px;
    height: 420px;
    padding: 0px 18px 18px;
    border-radius: 0.5rem;
    .MuiTabs-root {
      min-height: 0;
      padding-top: 4px;
      background-color: white;
      position: sticky;
      top: 0;
      .MuiTabs-flexContainer {
        border-bottom: 1px solid #d7d7d7;
        justify-content: space-between;
      }
      button {
        padding: 14px 16px;
        min-height: 0;
        min-width: 0;
        text-transform: none;
      }
    }
    .MuiTabPanel-root {
      padding: 0;
      overflow-y: auto;

      .notification {
        padding: 14px;
        font-size: 13px;
        border-bottom: 1px solid var(--s15);
        strong {
          font-weight: 500;
          margin-bottom: 0.4em;
        }
        p {
          margin-bottom: 0.25em;
        }
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
`;

// A small function to return the current tab based on the url
const getCurrentTabUrl = (pathname: string, length: number) => {
  if (pathname.includes('admin')) return 'admin';
  if (pathname.includes('create-order')) return 'orders';
  if (length > 2) return `${pathname}s`;
  else return pathname;
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  // const hasNewNotification = useAppSelector(selectHasNewNotifications);
  const pathname: string = location.pathname.split('/')[1];
  const urlSplit: string[] = location.pathname.split('/');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [header, setHeader] = useState<any>(null);
  const open = Boolean(anchorEl);
  const user = userProfile;
  const isDGUser = user?.category === EntityType.DEALERS_GROUP;
  const isFMUser = user?.category === EntityType.FUNDS_MANAGER;
  const isNotificationModalOpen = localStorage.getItem('isNotificationModalOpen');

  const [allocRecModalOpen, setAllocRecModalOpen] = useState<boolean>(true);
  const [allocReceivedRows, setAllocReceivedRows] = useState<Array<any>>([]);

  const [allocReqModalOpen, setAllocReqModalOpen] = useState<boolean>(true);
  const [allocRequestedRows, setAllocRequestedRows] = useState<Array<any>>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotifAnchorEl(event.currentTarget);
  };

  const handleNotifClose = () => {
    setNotifAnchorEl(null);
  };

  const handleLogoutClick = async () => {
    await dispatch({ type: 'LOGOUT_ATTEMPT' });
    await handleClose();
  };

  const currentTabUrl: string = getCurrentTabUrl(pathname, urlSplit.length);

  // --- Notifications ---
  const [notifAnchorEl, setNotifAnchorEl] = React.useState<null | HTMLElement>(null);
  const notifOpen = Boolean(notifAnchorEl);
  const [notifTab, setNotifTab] = React.useState<string>('0');

  const {
    data: notifications,
    isLoading: notificationsLoading,
    refetch,
  } = useReactQuery(['notifications'], {
    url: 'qaip/v1/notificationmanagement/emails',
  });

  useEffect(() => {
    const pathName = location.pathname;
    if (
      pathName === '/' ||
      pathName === '/clients' ||
      pathName === '/investments' ||
      pathName === '/orders' ||
      pathName === '/admin'
    )
      refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { refetch: clearNotifications, isLoading } = useReactQuery(
    ['clearNotifications'],
    {
      url: 'qaip/v1/notificationmanagement/emails/clearAll/order',
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetch();
        setNotifAnchorEl(null);
      },
    },
  );

  useEffect(() => {
    if (userProfile) {
      const headerData = getRoleBasedHeader(userOnBehalfOf ? userOnBehalfOf : userProfile);
      setHeader(headerData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, userOnBehalfOf]);

  useEffect(() => {
    if (notifications && notifications.order_emails.length > 0) {
      if (isFMUser) {
        setAllocRequestedRows(
          notifications.order_emails.filter(
            (n: any) => n.event === 'Allocation_Workflow' && n.status === 'SENT TO FUND MANAGER',
          ),
        );
      } else if (isDGUser) {
        setAllocReceivedRows(
          notifications.order_emails
            .filter(
              (n: any) =>
                n.event === 'Subscription' &&
                (n.status === 'ORDER ACCEPTED' || n.status === 'COMPLETED') &&
                n.amount_requested,
            )
            .map((n: any) => ({ ...n, amount: '$' + n.amount.substring(4, n.amount.length - 3) })),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, isDGUser, isFMUser]);

  return (
    <NavBar color='primary'>
      <Container maxWidth='xl' className='container-lr-padding'>
        <Toolbar>
          <img
            src={NavLogo}
            alt='Tifin Private Markets'
            width={196}
            onClick={() => navigate('/')}
          />
          <Divider orientation='vertical' flexItem light />
          <Tabs value={`/${currentTabUrl}`} className='flex-grow-1'>
            {React.Children.toArray(
              header?.map((link: any) => (
                <Tab
                  iconPosition='end'
                  icon={
                    <HeaderNotificationBubbleGenerator
                      link={link.href}
                      notifications={notifications}
                    />
                  }
                  label={link.label}
                  value={link.href}
                  onClick={() => {
                    navigate(link.href);
                  }}
                />
              )),
            )}
          </Tabs>
          {userOnBehalfOf && <em>On behalf of {userOnBehalfOf.firstName}</em>}
          <IconButton sx={{ mx: '1rem' }} onClick={handleNotifClick}>
            <Badge max={99} badgeContent={notifications?.order_emails.length} color='primary'>
              <NotificationsOutlined fontSize='small' />
            </Badge>
          </IconButton>
          <NotifMenu
            id='notification-menu'
            open={notifOpen}
            anchorEl={notifAnchorEl}
            onClose={handleNotifClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <TabContext value={notifTab}>
              <Tabs
                value={notifTab}
                onChange={(e: React.SyntheticEvent, val: string) => setNotifTab(val)}
              >
                <Tab label='Orders' value='0' />
                {!isLoading ? (
                  <MuiButton buttonClick={() => clearNotifications()} className='clearAll'>
                    Clear All
                  </MuiButton>
                ) : (
                  <CircularProgress />
                )}
              </Tabs>
              <TabPanel value='0'>
                {notificationsLoading ? (
                  <Loading />
                ) : notifications.order_emails.length > 0 ? (
                  React.Children.toArray(
                    notifications.order_emails
                      .sort((a: any, b: any) => {
                        if (a.email_sent_date > b.email_sent_date) return -1;
                        if (a.email_sent_date < b.email_sent_date) return 1;
                        else return 0;
                      })
                      .map((notif: any) => (
                        <section className='notification'>
                          <p className='flex-center-between'>
                            <strong>
                              <span className='mr-4'>{notif.order_id}</span>
                              {notif.status}
                            </strong>
                            <strong>
                              {format(
                                new Date(notif.email_sent_date.split(' ')[0].replace(/-/g, '/')),
                                'MMM dd, yyyy',
                              )}
                            </strong>
                          </p>
                          <p>
                            {notif.event} - {notif.amount}
                          </p>
                          <p>{notif.fund}</p>
                          <p>{notif.investor}</p>
                        </section>
                      )),
                  )
                ) : (
                  <section className='notification'>No notifications.</section>
                )}
              </TabPanel>
            </TabContext>
          </NotifMenu>
          <Avatar
            variant='rounded'
            sx={{ width: 30, height: 30, fontSize: '12px' }}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className='cursor-pointer'
          >
            {userProfile?.firstName[0]}
            {userProfile?.lastName[0]}
          </Avatar>
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                navigate('/change-password');
                handleClose();
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </Container>
      {isDGUser && allocReceivedRows.length > 0 && isNotificationModalOpen && (
        <AllocationReceivedModal
          isModalOpen={allocRecModalOpen}
          handleClose={() => {
            setAllocRecModalOpen(!allocRecModalOpen);
            localStorage.removeItem('isNotificationModalOpen');
          }}
          rows={allocReceivedRows}
        />
      )}
      {isFMUser && allocRequestedRows.length > 0 && isNotificationModalOpen && (
        <AllocationRequestedModal
          isModalOpen={allocReqModalOpen}
          handleClose={() => {
            setAllocReqModalOpen(!allocReqModalOpen);
            localStorage.removeItem('isNotificationModalOpen');
          }}
          rows={allocRequestedRows}
        />
      )}
    </NavBar>
  );
};

export default Header;
