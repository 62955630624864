import {
  DocumentOwnerType,
  FundDetails,
  ReactQueryDataFetch,
  DocumentDetails,
  QueryType,
} from 'common/types';
import { useEffect, useState } from 'react';
import { useMutationQuery, useReactQuery } from 'store/hooks';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import MuiTable from 'common/components/table/Table';
import { format } from 'date-fns';
import AddNewStatementModal from 'components/modals/AddNewStatementModal';
import { encryptDocumentUrl } from 'helpers';
import { STATEMENTS_COLUMNS } from './constants';

const ClientStatements = (props: { clientId: string }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFund, setSelectedFund] = useState<FundDetails>({} as FundDetails);
  const [documentKey, setDocumentKey] = useState<{ object_key: string } | null>(null);
  const [documentData, setDocumentData] = useState<Object | null>(null);
  const [monthDate, setMonthDate] = useState<Date>(new Date());
  const [yearDate, setYearDate] = useState<Date>(new Date());
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [viewDocDetails, setViewDocDetails] = useState<any>(null);
  const [uploadedFileData, setUploadedFileData] = useState(null);

  const handleModalClick = () => {
    setUploadedFileData(null);
    setSelectedFund({} as FundDetails);
    setMonthDate(new Date());
    setYearDate(new Date());
    setIsModalOpen(!isModalOpen);
  };

  const {
    data: clientStatements = [],
    isLoading,
    refetch: refetchClientStatements,
  } = useReactQuery([`clientStatements${props.clientId}`], {
    url: `qaip/v1/documentmanagement/getList/investor?investor_id=${props.clientId}&document_owner_type=${DocumentOwnerType.INVESTOR_STATEMENT}`,
  }) as { data: DocumentDetails[] } & ReactQueryDataFetch;

  const { data: fundData } = useReactQuery([`funds${props.clientId}`], {
    url: `qaip/v1/fundsmanagement/funds?status=Active`,
  }) as { data: FundDetails[] } & ReactQueryDataFetch;

  const { refetch: deleteDocument } = useReactQuery(
    ['deleteDocument'],
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${viewDocDetails?.docId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetchClientStatements();
        setShowDocumentModal(false);
      },
    },
  );

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPresignedUrl } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
      params: documentKey,
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData();
      },
    },
  );

  const { mutate: updateDocumentData } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      params: { documentData: documentData },
      isFormData: true,
    },
    {
      onSuccess: () => {
        refetchClientStatements();
      },
    },
  );

  const {
    mutate: fetchDocLink,
    data: documentLink,
    isLoading: isDocumentLinkLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: viewDocDetails?.docLink },
    },
    {
      enabled: false,
      onSuccess: () => {
        setShowDocumentModal(true);
      },
    },
  );

  useEffect(() => {
    if (viewDocDetails) fetchDocLink();
    // eslint-disable-next-line
  }, [viewDocDetails]);

  const handleDocViewOnclickOpen = (docLink: string, docId: string) => {
    setViewDocDetails({
      docLink: docLink,
      docId: docId,
    });
  };

  const handleDocViewOnclickClose = () => {
    setShowDocumentModal(false);
  };

  const handleDocDeleteClick = () => {
    deleteDocument();
  };

  const statementDetails: any =
    clientStatements &&
    clientStatements.map((item: any) => ({
      ...item,
      fundName: item.docClassificationName.split('|')[1],
      createdDate: format(new Date(item.createdDate), 'MMM-dd-yyyy'),
    }));

  const onFileUpload = async (event: any) => {
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const data = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: props.clientId,
      documentType: DocumentOwnerType.INVESTOR_STATEMENT,
      selectedFund,
      docType: 'file',
      status: 'Active',
      date: `${format(monthDate, 'MMM')}|${format(monthDate, 'yyyy')}`,
      // current date being passed to also check if the name formating is needed or not
      needsNameFormatingWithDate: true,
    });
    setDocumentKey(data.uploaddocumentKey);
    setDocumentData(data.documentData);
    fetchPresignedUrl();
  };

  return (
    <>
      <MuiTable
        isLoading={isLoading}
        rows={statementDetails}
        columns={STATEMENTS_COLUMNS}
        disableHeader
        ctaType='viewNow'
        handleDocViewOnclickOpen={handleDocViewOnclickOpen}
        handleDocViewOnclickClose={handleDocViewOnclickClose}
        isViewDocModalOpen={showDocumentModal}
        docLink={documentLink}
        isDocumentLinkLoading={isDocumentLinkLoading}
        handleDocReuploadClick={() => {}}
        handleDocDeleteClick={handleDocDeleteClick}
      />
      <AddNewStatementModal
        isModalOpen={isModalOpen}
        handleModalClick={handleModalClick}
        setMonthDate={setMonthDate}
        setYearDate={setYearDate}
        selectedFund={selectedFund}
        fundData={fundData}
        monthDate={monthDate}
        yearDate={yearDate}
        onFileUpload={onFileUpload}
        setSelectedFund={setSelectedFund}
        isLoading={isFetchingPresignedUrl}
        fileName={uploadedFileData}
      />
    </>
  );
};

export default ClientStatements;
