import { Help } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Grid, Tab, Tabs } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import Textbox from 'common/components/inputField/Textbox';
import { regexValidator, valueFormatter } from 'helpers';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useRQMutation, useTypedDispatch } from 'store/hooks';
import { FUND_ORDERS_COLUMNS, ORDER_COLUMNS } from './constants';
import FundOrdersTable from './FundOrdersTable';
import { ReactComponent as AllocateIcon } from 'common/assets/images/CTA/Allocate.svg';
import { ReactComponent as PlaneIcon } from 'common/assets/images/CTA/Plane.svg';
import MuiTooltip from 'common/components/tooltip';
import { SUGGEST_ALLLOCATIONS_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';
import CircleIcon from '@mui/icons-material/Circle';
import { setOrderActivityTab } from 'store/order/slice';
import { selectCurrentOrderActivityTab } from 'store/order/selectors';

type Props = {
  rows: any;
  isLoading: any;
  allocationDetails: any;
  setAllocationDetails: any;
  allocate: any;
  fundId: any;
  setAmountCapacity: any;
  amountCapacity: any;
  allowCalculation: any;
  setAmountAvailable: any;
  amountAvailable: any;
  setAllowCalculation: any;
  allocationRequest: any;
  setDiscardChangesModalOpen: any;
  setSaveAndAllocateModalOpen: any;
  setAllocate: any;
  totalAllocationAmount: any;
  setHelpModalOpen: any;
  completedRows: any;
  cancelledRows: any;
  openOrderRows: any;
  isAllocationWorkFlowEnabled: boolean;
  setIsApproveModalOpen: any;
  notificationArray?: Array<string>;
  clearOrderNotification?: any;
};

const FundOrdersTableTabs = ({
  rows,
  completedRows,
  cancelledRows,
  openOrderRows,
  isLoading,
  allocationDetails,
  setAllocationDetails,
  allocate,
  setAmountCapacity,
  amountCapacity,
  allowCalculation,
  setAmountAvailable,
  allocationRequest,
  setAllowCalculation,
  amountAvailable,
  setDiscardChangesModalOpen,
  setSaveAndAllocateModalOpen,
  setAllocate,
  totalAllocationAmount,
  setHelpModalOpen,
  isAllocationWorkFlowEnabled,
  setIsApproveModalOpen,
  notificationArray = [],
  clearOrderNotification,
}: Props) => {
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const currentTab = useAppSelector(selectCurrentOrderActivityTab);
  const id: string = location.pathname.split('/')[2];

  const { mutate: updateAvailableAmount, isLoading: isAmountLoading } = useRQMutation(
    {
      url: `qaip/v1/ordermanagement/totalCapacity`,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        setAmountAvailable(data.amountAvailableForAllocation);
      },
    },
  );

  return (
    <Grid container>
      <TabContext value={`${currentTab}`}>
        <Grid item xs={12}>
          <Tabs
            value={`${currentTab}`}
            onChange={(e: React.SyntheticEvent, val: string) => {
              dispatch(setOrderActivityTab(Number(val)));
            }}
          >
            <Tab
              icon={
                openOrderRows.some((row: any) => notificationArray.includes(`${row.orderId}`)) ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              iconPosition='end'
              label='Open Orders'
              value={'0'}
            />
            <Tab
              icon={
                completedRows.some((row: any) => notificationArray.includes(`${row.orderId}`)) ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              label='Completed Orders'
              iconPosition='end'
              value={'1'}
            />
            <Tab
              icon={
                cancelledRows.some((row: any) => notificationArray.includes(`${row.orderId}`)) ? (
                  <CircleIcon className='notification-bubble' fontSize='small' />
                ) : (
                  <></>
                )
              }
              label='Cancelled Orders'
              iconPosition='end'
              value={'2'}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={'0'} className='px-0'>
            <Grid container mb={isAllocationWorkFlowEnabled ? 2 : 0} columnSpacing={3}>
              {isAllocationWorkFlowEnabled && (
                <>
                  <Grid item xs={1.5} container className='stat'>
                    <h6 className='subtitle mb-0'>Total Capacity</h6>
                    {allocate ? (
                      <Textbox
                        disabled={!allocate}
                        onBlur={() =>
                          updateAvailableAmount({
                            fundId: id,
                            amountAvailable: amountCapacity,
                          })
                        }
                        onChange={(e: any) => {
                          if (regexValidator(e.target.value, /^[0-9,]*$/i)) return;
                          setAmountCapacity(e.target.value.replace(/,/g, ''));
                        }}
                        value={valueFormatter(amountCapacity)}
                        startAdornment={'$'}
                        className={`amountCapacity ${!allowCalculation ? 'tableInputActive' : ''}`}
                      />
                    ) : (
                      <h4 className='font-wt-400 mb-0'>
                        {amountCapacity ? `$${Number(amountCapacity).toLocaleString()}` : '$--'}
                      </h4>
                    )}
                  </Grid>
                  <Grid item xs={1.5} container className='stat'>
                    <h6 className='subtitle'>Capacity Available</h6>
                    {!isAmountLoading ? (
                      <h4 className='font-wt-400'>
                        ${Number(amountAvailable)?.toLocaleString() ?? '$0'}
                      </h4>
                    ) : (
                      'Loading'
                    )}
                  </Grid>
                  {/* <Grid item xs={'auto'} container className='stat'>
                  <h6 className='subtitle'>Amount Allocated</h6>
                  <h4 className='font-wt-400'>
                    {allocate && !isNaN(totalAllocationAmount)
                      ? '$' + totalAllocationAmount.toLocaleString()
                      : '$--'}
                  </h4>
                </Grid> */}
                  <Grid item xs={1.5} container className='stat'>
                    <h6 className='subtitle'>New Allocation Requests</h6>
                    <h4 className='font-wt-400 mb-0'>${allocationRequest.toLocaleString()}</h4>
                  </Grid>
                  {allocate && (
                    <Grid item xs={'auto'} className='flex-center-start'>
                      <div className='proRataCheck flex-center-start mt-1'>
                        <MuiCheckbox
                          onChange={() => setAllowCalculation(!allowCalculation)}
                          defaultValue={allowCalculation}
                          name='test'
                        />
                        <span className='checkboxText'>
                          Suggest Allocations{' '}
                          <MuiTooltip content={SUGGEST_ALLLOCATIONS_TOOLTIP_CONTENT} />
                        </span>
                      </div>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={'auto'} className='flex-center-end' sx={{ flexGrow: '1 !important' }}>
                {allocate && (
                  <MuiButton
                    buttonClick={() => setDiscardChangesModalOpen(true)}
                    minWidth='150px'
                    variant='text'
                  >
                    Discard Changes
                  </MuiButton>
                )}
                {isAllocationWorkFlowEnabled && allocationRequest > 0 && (
                  <MuiButton
                    buttonClick={() =>
                      allocate ? setSaveAndAllocateModalOpen(true) : setAllocate(!allocate)
                    }
                    minWidth='150px'
                    variant={'contained'}
                    disabled={
                      allocate &&
                      (amountCapacity < totalAllocationAmount ||
                        allocationDetails.some(
                          (alloc: any) => alloc.amount === null || alloc.amount === 0,
                        ))
                    }
                  >
                    {allocate ? (
                      <>
                        <PlaneIcon className='mr-2' />
                        Save and Allocate
                      </>
                    ) : (
                      <>
                        <AllocateIcon className='mr-2' />
                        Allocate
                      </>
                    )}
                  </MuiButton>
                )}
                {isAllocationWorkFlowEnabled && (
                  <Help
                    className='help-icon cursor-pointer ml-3'
                    onClick={() => setHelpModalOpen(true)}
                  />
                )}
              </Grid>
            </Grid>
            <FundOrdersTable
              rows={openOrderRows}
              columns={isAllocationWorkFlowEnabled ? FUND_ORDERS_COLUMNS : ORDER_COLUMNS}
              rowsPerPage={1000000}
              rowClassName={ allowCalculation ? 'cursor-pointer' : ''}
              isLoading={isLoading}
              isAllocatedAmountDisabled={allowCalculation}
              allocationDetails={allocationDetails}
              setAllocationDetails={setAllocationDetails}
              allocate={allocate}
              canRedirect = {allowCalculation ? true : false}
              // canRedirect
              redirectURLPrefix='order/'
              redirectURLKey='orderId'
              fundId={(openOrderRows as any)?.fundId}
              clearNotification={clearOrderNotification}
              notificationArray={notificationArray}
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={'1'} className='px-0'>
            <FundOrdersTable
              rows={completedRows}
              columns={isAllocationWorkFlowEnabled ? FUND_ORDERS_COLUMNS : ORDER_COLUMNS}
              rowsPerPage={1000000}
              rowClassName='cursor-pointer'
              isLoading={isLoading}
              isAllocatedAmountDisabled={allowCalculation}
              allocationDetails={allocationDetails}
              setAllocationDetails={setAllocationDetails}
              allocate={allocate}
              canRedirect
              redirectURLPrefix='order/'
              redirectURLKey='orderId'
              fundId={(completedRows as any)?.fundId}
              clearNotification={clearOrderNotification}
              notificationArray={notificationArray}
            />
          </TabPanel>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={'2'} className='px-0'>
            <FundOrdersTable
              rows={cancelledRows}
              columns={isAllocationWorkFlowEnabled ? FUND_ORDERS_COLUMNS : ORDER_COLUMNS}
              rowsPerPage={1000000}
              rowClassName='cursor-pointer'
              isLoading={isLoading}
              isAllocatedAmountDisabled={allowCalculation}
              allocationDetails={allocationDetails}
              setAllocationDetails={setAllocationDetails}
              allocate={allocate}
              canRedirect
              redirectURLPrefix='order/'
              redirectURLKey='orderId'
              fundId={(cancelledRows as any)?.fundId}
              clearNotification={clearOrderNotification}
              notificationArray={notificationArray}
            />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default FundOrdersTableTabs;
