import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import MuiTable from 'common/components/table/Table';
import CreateUpdateEvents from 'components/modals/CreateUpdateEventsModal';
import EventsModal from 'components/modals/EventsModal';
import { useEffect, useState } from 'react';
import { useReactQuery } from 'store/hooks';
import { COLUMNS, CRUMBS } from './constants';

const Events = () => {
  const [rows, setRows] = useState<Array<any>>([]);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(null);
  const { data = [], isLoading } = useReactQuery(['events'], {
    url: `qaip/v1/notificationmanagement/events?published=true`,
  });

  const handleEventModalClick = () => {
    setIsEventModalOpen(!isEventModalOpen);
  };

  const handleEventEditClick = () => {
    setIsEditEventModalOpen(!isEditEventModalOpen);
    setIsEventModalOpen(false);
  };

  const handleCloseModals = () => {
    setIsEditEventModalOpen(false);
    setIsEventModalOpen(false);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setRows(
        data
          .map((row: any) => ({
            ...row,
            publish_status: row.is_published ? 'Published' : 'Unpublished',
          }))
          .sort((a: any, b: any) => a.updated_at.localeCompare(b.updated_at) * -1),
      );
    }
  }, [data]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  useEffect(() => {
    if (rows) {
      let arr: Array<any> = rows;
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='end'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Events</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                className='mr-20'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <MuiButton
                variant='contained'
                minWidth='150px'
                buttonClick={() => {
                  setSelectedEventData(null);
                  handleEventEditClick();
                }}
              >
                Add New Event
              </MuiButton>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MuiTable
            columns={COLUMNS}
            rows={filteredRows}
            isLoading={isLoading}
            ctaType='events'
            disableHeader
            handleCtaClick={(data: any) => {
              setIsEventModalOpen(!isEventModalOpen);
              setSelectedEventData({
                ...data,
                starTime: data.start_time,
                endTime: data.end_time,
              });
            }}
          />
        </Container>
        {selectedEventData && (
          <EventsModal
            isEventsFromAdmin
            selectedEvent={selectedEventData}
            modalOpen={isEventModalOpen}
            handleEventModalClick={handleEventModalClick}
            handleEventEditClick={handleEventEditClick}
          />
        )}
        {isEditEventModalOpen && (
          <CreateUpdateEvents
            selectedEvent={selectedEventData}
            isEditEventModalOpen={isEditEventModalOpen}
            handleEventEditClick={handleEventEditClick}
            handleCloseModals={handleCloseModals}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Events;
