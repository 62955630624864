import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { KeyboardArrowDown } from '@mui/icons-material';

const StyledWrapper = styled(Box)`
  .MuiSelect-root {
    position: relative;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.5rem;
    border-color: var(--s7);
  }
  .MuiSelect-outlined {
    padding: 10px 18px;
    font-size: 13px;
    background-color: var(--s7);
    &:hover .MuiOutlinedInput-notchedOutline,
    &:active .MuiOutlinedInput-notchedOutline {
      border-color: #b3d1ed !important;
    }
  }

  .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    pointer-events: none;
    color: var(--s30);
    font-size: 14px;
  }

  &.adminSelect {
    padding-top: unset;
    .MuiInputBase-root {
      color: #525863;
      font-size: 11px;
      background-color: white;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0.5rem;
      border-color: transparent;
    }
    .MuiMenuItem-root {
      font-size: 11px;
    }
    .placeholder {
      font-size: 11px;
    }
  }
`;

type Props = {
  options?: Array<any>;
  label?: string;
  isLoading?: boolean;
  value?: any;
  valueArr?: any;
  onChange?: (event: SelectChangeEvent) => void;
  errorMessage?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  optionName?: string;
  className?: string;
  register?: any;
  name?: string;
  defaultValue?: any;
  optionId?: string;
  setSelectedValue?: any;
  required?: boolean;
  placeholder?: string;
  searchIcon?: boolean;
  MenuProps?: any;
  multiple?: boolean;
};

export default function MultipleSelectField({
  options,
  label,
  value,
  valueArr,
  onChange,
  variant,
  isLoading,
  optionName,
  optionId,
  name,
  register,
  disabled,
  defaultValue,
  setSelectedValue,
  required,
  placeholder = 'Select',
  searchIcon = true,
  className = '',
  MenuProps,
  multiple,
}: Props) {
  return (
    <StyledWrapper sx={{ minWidth: 120, pt: 1 }} className={className}>
      {label && <h4 className='font-wt-400 mb-2'>{label}</h4>}
      <FormControl fullWidth>
        <Select
          key={value}
          multiple
          value={value}
          onChange={onChange}
          renderValue={(selected: any) => selected.join(', ')}
          MenuProps={{ ...MenuProps, disableScrollLock: true }}
          inputRef={register && register(name, { required })}
          IconComponent={KeyboardArrowDown}
        >
          {options?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={valueArr.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {!value && !defaultValue && <div className='placeholder'>{placeholder}</div>}
      </FormControl>
    </StyledWrapper>
  );
}
