import styled from '@emotion/styled';
import { Close, Search } from '@mui/icons-material';
import { InputAdornment, OutlinedInput } from '@mui/material';

const StyledSearchBar = styled(OutlinedInput)`
  font-size: 13px;
  color: var(--s30);
  background-color: var(--s7);

  input {
    padding: 8px 6px;
    &::placeholder {
      opacity: 1;
    }
  }

  svg {
    font-size: 1rem;
    color: var(--s30);
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--p150);
  }
`;

interface Props {
  searchQuery: string;
  setSearchQuery: (s: string) => void;
  minWidth?: string;
  className?: string;
  fullWidth?: boolean;
}

const SearchBar = ({
  searchQuery,
  setSearchQuery,
  minWidth = '',
  className = '',
  fullWidth = false,
}: Props) => {
  return (
    <StyledSearchBar
      id='searchBar'
      sx={{ minWidth: minWidth }}
      type='text'
      value={searchQuery}
      onChange={(e) => {
        setSearchQuery(e.target.value);
      }}
      placeholder='Search'
      className={className}
      fullWidth={fullWidth}
      startAdornment={
        <InputAdornment position='start'>
          <Search />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position='end'>
          <Close className='close' onClick={() => setSearchQuery('')} />
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
