export const RECOMMENDED_FUND_QUERY = `
  query($investerId: String!, $fundId: String!){
    getRecFunds(invester_id: $investerId, fund_id: $fundId) {
        fund_id
        fund_label
        fund_name
        fund_id_simple
        asset_class
        min_investment
        rating
        status
        next_close
        logo
        final_close
        target_fund_size
    }
  }
`;
