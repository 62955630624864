import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 28px !important;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 2.25rem;
    color: var(--s40) !important;
  }
`;

const DiscardChangesModal = ({ isModalOpen, handleClose, buttonClick }: Props) => {
  return (
    <StyledModal
      title='Discard Changes'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container>
        <p>
          Any changes you made to allocations will be lost and allocations will not be published.
        </p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='text' buttonClick={handleClose}>
          Back
        </MuiButton>
        <MuiButton variant='contained' className='ml-3' buttonClick={buttonClick} minWidth='150px'>
          Discard Changes
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default DiscardChangesModal;
