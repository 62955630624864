import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import SubHeader from 'common/components/layout/SubHeader';
import {
  ELEMENT_DATA_DG,
  ELEMENT_DATA_FM,
  ELEMENT_DATA_QUALIS,
  entityDropdownList,
} from 'common/constants';
import { EntityType, QueryType } from 'common/types';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { COLUMNS, CRUMBS } from './constants';
import RolesTable from './RolesTable';

const Roles = () => {
  const navigate = useNavigate();
  const [selectedEntity, setSelectedEntity] = useState<any>(entityDropdownList[0]);
  const [selectedName, setSelectedName] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [newRolesData, setNewRolesData] = useState<any>([]);
  const [rolePrivileges, setRolePrivileges] = useState<any>([]);
  const [newRoleName, setNewRoleName] = useState<any>('');
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;

  const { data, refetch } = useReactQuery(
    [`subCategory${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/organisations?categoryId=${selectedEntity?.id}`,
    },
    {
      enabled: selectedEntity !== null,
    },
  );
  const { data: rolesData, refetch: refetchRoles } = useReactQuery(
    [`roles${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/roles/organisation?organisationId=${
        !isQualisUser ? user?.organisationId : selectedName?.organisationId
      }`,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (!Array.isArray(data)) {
          setNewRolesData([]);
          setRolePrivileges([]);
        }
      },
    },
  );

  useEffect(() => {
    if (user && !isQualisUser) refetchRoles();
    // eslint-disable-next-line
  }, [user]);

  const { refetch: updateRoles } = useReactQuery(
    ['updateRole'],
    {
      url: `qaip/v1/usermanagement/roles/updateRole`,
      params: {
        ...newRolesData,
        privilegeJson: JSON.stringify(rolePrivileges),
      },
      method: QueryType.PUT,
    },
    {
      enabled: false,
    },
  );

  const { mutate: addNewRole } = useMutationQuery(
    {
      url: 'qaip/v1/usermanagement/roles/role',
      params: {
        organisationId: selectedName?.organisationId,
        privilegeJson: JSON.stringify(rolePrivileges),
        roleName: newRoleName,
      },
    },
    {
      onSuccess: () => {
        setSelectedName(null);
        setSelectedRole(null);
        setNewRoleName('');
        refetchRoles();
      },
    },
  );

  const handleOnChange = ({ role, name, i }: any) => {
    const x = rolePrivileges[i];
    const newValues = {
      ...x,
      [name]: !role[name],
    };
    rolePrivileges[i] = newValues;
    const updateRolePrivileges = [...rolePrivileges];
    setRolePrivileges(updateRolePrivileges);
  };

  useEffect(() => {
    if (selectedEntity) {
      refetch();
      setSelectedRole(null);
    }
    if (selectedEntity && selectedName) {
      refetchRoles();
    }
    // eslint-disable-next-line
  }, [selectedEntity, selectedName]);

  useEffect(() => {
    if (selectedRole && selectedRole.roleId !== '0') {
      const selectedRolesData = rolesData?.filter(
        (role: any) => role.roleId === selectedRole?.roleId,
      );
      setNewRolesData(selectedRolesData[0]);
      setRolePrivileges(JSON.parse(selectedRolesData[0]?.privilegeJson));
    }
    if (selectedRole?.roleId === '0') {
      if (selectedEntity.id === EntityType.DEALERS_GROUP) {
        setRolePrivileges(ELEMENT_DATA_DG);
      }
      if (selectedEntity.id === EntityType.FUNDS_MANAGER) {
        setRolePrivileges(ELEMENT_DATA_FM);
      }
      if (selectedEntity.id === EntityType.QUALIS) {
        setRolePrivileges(ELEMENT_DATA_QUALIS);
      }
    }
    // eslint-disable-next-line
  }, [selectedRole]);

  const onSubmit = async () => {
    updateRoles();
  };

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container>
            <h1 className='mb-0'>Roles & Permissions</h1>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} container sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid item xs={12} container sx={{ mb: 4 }}>
            {isQualisUser && (
              <>
                <Grid item xs={2} sx={{ mr: '14px' }}>
                  <SelectField
                    placeholder='entity'
                    options={entityDropdownList}
                    setSelectedValue={setSelectedEntity}
                    optionName='name'
                    optionId='id'
                    name='entity'
                    defaultValue={selectedEntity.id}
                    value={selectedEntity.id}
                    searchIcon={false}
                    className='adminSelect'
                    MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ mr: '14px' }}>
                  <SelectField
                    placeholder='Name'
                    options={
                      data &&
                      data.sort((a: any, b: any) =>
                        a.organisationName.localeCompare(b.organisationName),
                      )
                    }
                    value={selectedName?.organisationId}
                    setSelectedValue={setSelectedName}
                    optionName='organisationName'
                    optionId='organisationId'
                    name='name'
                    searchIcon={false}
                    className='adminSelect'
                    MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={2} sx={{ mr: '14px' }}>
              <SelectField
                placeholder='Roles'
                options={
                  Array.isArray(rolesData)
                    ? [
                        ...rolesData,
                        {
                          roleId: '0',
                          roleName: 'Add New Role',
                        },
                      ]
                    : [
                        {
                          roleId: '0',
                          roleName: 'Add New Role',
                        },
                      ]
                }
                value={selectedRole?.roleId}
                setSelectedValue={setSelectedRole}
                optionName='roleName'
                optionId='roleId'
                name='roles'
                searchIcon={false}
                className='adminSelect'
                MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
              />
            </Grid>
            <Grid item xs={2}>
              {selectedRole?.roleId === '0' && (
                <Textbox
                  fullWidth
                  className='admin'
                  placeholder='New Role Name'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewRoleName(e.target.value)
                  }
                />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} mb={4}>
            {rolePrivileges && Array.isArray(rolePrivileges) && rolePrivileges.length > 0 && (
              <RolesTable columns={COLUMNS} rows={rolePrivileges} handleOnChange={handleOnChange} />
            )}
          </Grid>
          {rolePrivileges && rolePrivileges.length > 0 && (
            <Grid item xs={12} container justifyContent='flex-end' alignItems='center'>
              <Grid item>
                <MuiButton
                  buttonClick={() => {
                    setNewRolesData([]);
                    setRolePrivileges([]);
                    navigate('/admin');
                  }}
                  color='error'
                  className='mr-3'
                >
                  Exit Without Saving
                </MuiButton>
              </Grid>
              <Grid item xs='auto'>
                {selectedRole?.roleId !== '0' ? (
                  <MuiButton
                    buttonClick={onSubmit}
                    type='submit'
                    variant='contained'
                    minWidth='150px'
                  >
                    Update
                  </MuiButton>
                ) : (
                  <MuiButton
                    buttonClick={() => {
                      newRoleName === ''
                        ? alert('Please assign a name for the new role.')
                        : addNewRole();
                    }}
                    disabled={newRoleName === ''}
                    type='submit'
                    variant='contained'
                    minWidth='150px'
                  >
                    Add Role
                  </MuiButton>
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </Grid>
    </Grid>
  );
};

export default Roles;
