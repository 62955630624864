import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHeader from './TableHeader';
import React from 'react';
import { ClientDetails, OrderDetails, OrderStatusType } from 'common/types';
import { useNavigate } from 'react-router';
import TableHeadSkeleton from '../skeleton/TableHeadSkeleton';
import TableBodySkeleton from '../skeleton/TableBodySkeleton';
import OrderStatusBar from '../orderStatusBar';
import { format } from 'date-fns';
import { statusList } from 'common/constants';
import { canModifyHoldings } from 'helpers/roles';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import TableSubHeader from './TableSubHeader';
import { Pagination } from '@mui/material';
import ViewDocumentModal from '../modal/ViewDocumentModal';
import CTAgenerator from './CTAgenerator';
import { ALLOCATION_REQUEST_ORDERS, getAllocRequest } from 'helpers/orders';
import { Help } from '@mui/icons-material';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import CircleIcon from '@mui/icons-material/Circle';
import { selectCurrentOrderActivityTab } from 'store/order/selectors';

const StyledWrapper = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    border-bottom: 1px solid #b8bcc4;
    padding: 0 22px;

    th {
      font-weight: 700;
      background-color: var(--s15);
      border: 0;
      color: #525863;
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }
    tr:nth-of-type(even) {
      background-color: var(--s7);
    }
  }

  td {
    color: #626262;
    border: none;
    &:first-of-type {
      padding-left: 2rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .showOnHover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .MuiTableRow-hover:hover .showOnHover {
    opacity: 1;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bold {
    font-weight: 700;
  }

  .pagination {
    padding: 1.2rem 2.5rem;
    border-top: 1px solid #d7d7d7;
  }

  .nullTable {
    background-color: #fafafa;
    height: 360px;
    color: #bdbdbd;
    text-align: center;
    vertical-align: center;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    position: relative;
    top: 6px;
    left: unset;
  }

  .alloc {
    font-style: italic;
    color: var(--s20);
  }

  .allocTooltip {
    font-size: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 50%;
    transform: translateY(15%);
  }
`;

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string;
}

// Add row types here
// Need to figure out the right way to do this
// Right now it throws error when you try to use it as index
type Row = ClientDetails | OrderDetails | any;

interface Filters {
  id: string;
  filterGroupName: string;
  value: Array<string>;
}

interface TableProps {
  title?: string | undefined;
  rows: Row;
  columns: Array<Column>;
  filters?: Array<Filters>;
  rowClassName?: string;
  disableHeader?: boolean;
  isLoading?: boolean;
  ctaType?: string;
  tableClassName?: string;
  redirectURLKey?: string;
  redirectURLPrefix?: string;
  canRedirect?: boolean;
  handleDocViewOnclickOpen?: any;
  handleDocViewOnclickClose?: any;
  isViewDocModalOpen?: boolean;
  handleDocReuploadClick?: any;
  handleDocDeleteClick?: any;
  docLink?: {
    url: string;
  };
  isDocumentLinkLoading?: boolean;
  rowsPerPage?: number;
  clientData?: ClientDetails;
  activityTable?: boolean;
  activityTableNewColumns?: Array<Column>;
  handleCtaClick?: any;
  disableSearch?: boolean;
  dateFormat?: string;
  notificationArray?: string[];
  clearNotification?: any;
  allocClick?: boolean;
  onRowClick?: any;
  appendText?: string;
}

const MuiTable = ({
  title,
  rows = [],
  columns,
  filters,
  rowClassName = '',
  disableHeader = false,
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  redirectURLKey = '',
  redirectURLPrefix = '',
  canRedirect = false,
  isViewDocModalOpen = false,
  handleDocViewOnclickOpen,
  handleDocViewOnclickClose,
  docLink = { url: '' },
  isDocumentLinkLoading = false,
  handleDocReuploadClick,
  handleDocDeleteClick,
  rowsPerPage = 10,
  clientData,
  activityTable = false,
  handleCtaClick,
  disableSearch = true,
  activityTableNewColumns,
  clearNotification,
  notificationArray = [],
  dateFormat = 'MMM-dd-yyyy',
  allocClick = false,
  onRowClick,
  appendText = '',
}: TableProps) => {
  const [allocModalOpen, setAllocModalOpen] = useState<boolean>(false);
  const [finalRows, setFinalRows] = useState<Row[]>(rows);
  const [rowsBeforePagination, setRowsBeforePagination] = useState<Row[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [orderBy, setOrderBy] = useState<{ id: string; dir: boolean; index: number }>({
    id: '',
    dir: true,
    index: -1,
  });
  const [currentFilters, setCurrentFilters] = useState<Record<string, String[]>>({});
  const [index, setIndex] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [docTitle, setDocTitle] = useState<string>('');
  const [finalCols, setFinalCols] = useState<Array<Column>>([]);
  const [openOrders, setOpenOrders] = useState<any>([]);
  const [completedOrders, setCompletedOrders] = useState<any>([]);
  const [cancelledOrders, setCancelledOrders] = useState<any>([]);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const navigate = useNavigate();
  const userCanDelete = user && canModifyHoldings(user);
  const activityTab = useAppSelector(selectCurrentOrderActivityTab);

  useEffect(() => {
    setFinalRows(rows);
  }, [rows]);

  // If activity Table, do not render Status column
  useEffect(() => {
    if (activityTab !== 0 && activityTableNewColumns) {
      setFinalCols(activityTableNewColumns);
    } else setFinalCols(columns);
  }, [activityTable, activityTab, columns, activityTableNewColumns]);

  // Column Ordering
  const orderByReturnVal = (a: any, b: any) => {
    if (finalCols[orderBy.index].isDate) {
      if (orderBy.dir) {
        const c: any = new Date(a[orderBy.id]);
        const d: any = new Date(b[orderBy.id]);
        return c - d;
        // return (new Date(a[orderBy.id]) - new Date(b[orderBy.id]));
      } else {
        const c: any = new Date(a[orderBy.id]);
        const d: any = new Date(b[orderBy.id]);
        return d - c;
      }
    } else if (isNaN(a[orderBy.id]) && isNaN(b[orderBy.id])) {
      if (!a[orderBy.id]) {
        return 1;
      } else if (orderBy.dir) {
        return a[orderBy.id]?.localeCompare(b[orderBy.id]);
      } else {
        return a[orderBy.id]?.localeCompare(b[orderBy.id]) * -1;
      }
    } else {
      if (orderBy.dir) {
        return a[orderBy.id] - b[orderBy.id];
      } else {
        return b[orderBy.id] - a[orderBy.id];
      }
    }
  };
  useEffect(() => {
    const arr = rowsBeforePagination.sort((a: Row, b: Row) => {
      const val = orderBy.id !== '' ? orderByReturnVal(a, b) : 0;
      return val;
    });
    setRowsBeforePagination([...arr]);
    // eslint-disable-next-line
  }, [orderBy]);

  //Filtering
  useEffect(() => {
    let notificationRow: any = rows;
    let filteredRows: Row = rows;
    const open = notificationRow.filter(
      (row: any) =>
        row.orderStatusId !== OrderStatusType.ORDER_COMPLETED &&
        row.orderStatusId !== OrderStatusType.ORDER_CANCELLED &&
        row.orderStatusId !== OrderStatusType.ORDER_REJECTED,
    );
    setOpenOrders(open);

    const completed = notificationRow.filter(
      (row: any) => row.orderStatusId === OrderStatusType.ORDER_COMPLETED,
    );
    setCompletedOrders(completed);

    const cancelled = notificationRow.filter(
      (row: any) =>
        row.orderStatusId === OrderStatusType.ORDER_CANCELLED ||
        row.orderStatusId === OrderStatusType.ORDER_REJECTED,
    );
    // cancelled and rejected Orders will show in one tab only
    setCancelledOrders(cancelled);

    if (ctaType !== 'viewNow') setPage(0);

    // ACTIVITY PAGE Tab filtering
    if (activityTable) {
      switch (activityTab) {
        case 0: {
          filteredRows = filteredRows.filter(
            (row: any) =>
              row.orderStatusId !== OrderStatusType.ORDER_COMPLETED &&
              row.orderStatusId !== OrderStatusType.ORDER_CANCELLED &&
              row.orderStatusId !== OrderStatusType.ORDER_REJECTED,
          );
          break;
        }
        case 1: {
          filteredRows = filteredRows.filter(
            (row: any) => row.orderStatusId === OrderStatusType.ORDER_COMPLETED,
          );
          break;
        }
        case 2: {
          filteredRows = filteredRows.filter(
            (row: any) =>
              row.orderStatusId === OrderStatusType.ORDER_CANCELLED ||
              row.orderStatusId === OrderStatusType.ORDER_REJECTED,
          );
          break;
        }
      }
    }

    // Specific Filtering
    Object.keys(currentFilters).forEach((f: string) => {
      if (currentFilters[f].length > 0) {
        filteredRows = filteredRows.filter((row: any) => currentFilters[f].indexOf(row[f]) > -1);
      }
    });

    // Search Query Filtering
    filteredRows = filteredRows.filter((row: any) => {
      for (let prop in row) {
        if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
      }
      return false;
    });

    setRowsBeforePagination(filteredRows);
    // eslint-disable-next-line
  }, [currentFilters, searchQuery, activityTab, rows]);

  // Pagination
  useEffect(() => {
    if (rowsPerPage)
      setFinalRows(
        rowsBeforePagination.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      );
  }, [page, rowsBeforePagination, rowsPerPage]);

  return (
    <StyledWrapper className={tableClassName}>
      {!disableHeader && (
        <TableHeader
          title={title}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filters={filters}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
          isLoading={isLoading}
          activityTable={activityTable}
          disableSearch={disableSearch}
          hasOpenOrdersNotifications={openOrders.some((row: any) =>
            notificationArray.includes(`${row.orderId}`),
          )}
          hasCompletetedOrdersNotification={completedOrders.some((row: any) =>
            notificationArray.includes(`${row.orderId}`),
          )}
          hasCancelledOrdersNotification={cancelledOrders.some((row: any) =>
            notificationArray.includes(`${row.orderId}`),
          )}
          openOrderArray={openOrders.map((row: any) => row.orderId)}
          completedOrderArray={completedOrders.map((row: any) => row.orderId)}
          cancelledOrderArray={cancelledOrders.map((row: any) => row.orderId)}
          clearNotification={clearNotification}
        />
      )}
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }}>
          <Table>
            {isLoading ? (
              <TableHeadSkeleton length={finalCols?.length} />
            ) : (
              <TableSubHeader
                finalCols={finalCols}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                ctaType={ctaType}
              />
            )}
            <TableBody>
              {isLoading ? (
                <TableBodySkeleton length={finalCols?.length} />
              ) : (
                <>
                  {finalRows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={finalCols?.length + (ctaType ? 1 : 0)}
                        className='nullTable'
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    React.Children.toArray(
                      finalRows.map((row: Row, i: number) => (
                        <TableRow
                          onClick={() => {
                            !!clearNotification && clearNotification([row[redirectURLKey]]);
                            canRedirect && navigate(`/${redirectURLPrefix}${row[redirectURLKey]}`);
                            allocClick && onRowClick();
                          }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={rowClassName}
                        >
                          {React.Children.toArray(
                            finalCols.map((col: Column) => (
                              <TableCell
                                align={col.align}
                                className={`
                            ${col.showOnHover && 'showOnHover'}
                            ${col.bold && 'bold'}
                            ${
                              activityTable &&
                              col.id === 'eventAmount' &&
                              getAllocRequest(row?.orderId) >= 0 &&
                              ALLOCATION_REQUEST_ORDERS[getAllocRequest(row?.orderId)].subAmount ===
                                '--' &&
                              'italic'
                            }
                            ${col.cellClassName ? col.cellClassName : ''}
                            ${
                              activityTable &&
                              col.id === 'eventAmount' &&
                              row['eventName'] === 'Allocation Request'
                                ? 'alloc'
                                : ''
                            }
                            position-relative
                          `}
                                sx={{ width: `${col.width ? col.width : 'initial'}` }}
                              >
                                {col.showProgress && (
                                  <OrderStatusBar
                                    value={
                                      statusList.filter(
                                        (val: any) => val.statusString === row[col.id],
                                      )[0].width
                                    }
                                  />
                                )}
                                {row[col.id] ? (
                                  col.needsFormatter ? (
                                    `$${Number(row[col.id])?.toLocaleString()}`
                                  ) : col.isDate ? (
                                    format(new Date(row[col.id]), dateFormat)
                                  ) : col.percent ? (
                                    row[col.id] + '%'
                                  ) : activityTable &&
                                    col.label === 'ID' &&
                                    notificationArray.includes(row['orderId'].toString()) ? (
                                    <div className='d-flex'>
                                      <CircleIcon
                                        className='notification-bubble row-bubble'
                                        fontSize='small'
                                      />{' '}
                                      {row[col.id]}
                                    </div>
                                  ) : (
                                    <span>
                                      {row[col.id]}{' '}
                                      {activityTable &&
                                        col.id === 'eventName' &&
                                        row[col.id] === 'Allocation Request' && (
                                          <Help
                                            className='allocTooltip'
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setAllocModalOpen(true);
                                            }}
                                          />
                                        )}
                                    </span>
                                  )
                                ) : (
                                  '--'
                                )}
                              </TableCell>
                            )),
                          )}
                          {ctaType !== '' && (
                            <CTAgenerator
                              ctaType={ctaType}
                              user={user}
                              clientData={clientData}
                              row={row}
                              finalRows={finalRows}
                              handleCtaClick={handleCtaClick}
                              setIndex={setIndex}
                              setDocTitle={setDocTitle}
                              handleDocViewOnclickOpen={handleDocViewOnclickOpen}
                              isDocumentLinkLoading={isDocumentLinkLoading}
                              index={index}
                              i={i}
                            />
                          )}
                        </TableRow>
                      )),
                    )
                  )}
                </>
              )}
              {appendText && (
                <TableRow>
                  <TableCell>{appendText}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {rowsPerPage && rowsBeforePagination.length > rowsPerPage && (
          <Pagination
            className='pagination'
            count={Math.ceil(rowsBeforePagination.length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)}
          />
        )}
      </div>
      <ViewDocumentModal
        isModalOpen={isViewDocModalOpen}
        title={docTitle}
        handleClose={handleDocViewOnclickClose}
        docLink={docLink?.url}
        handleDocReuploadClick={handleDocReuploadClick}
        handleDocDeleteClick={handleDocDeleteClick}
        deletable={user && userCanDelete}
      />
      <AllocationDetailsModal
        disableCTA
        isModalOpen={allocModalOpen}
        handleClose={() => setAllocModalOpen(!allocModalOpen)}
      />
    </StyledWrapper>
  );
};

export default MuiTable;
