import TabPanelBody from 'common/components/tabs/TabPanelBody';
import { TabPanel } from 'common/components/tabs/TabPanel';
import ClientHoldings from './ClientHoldings';
// import ClientDocuments from './ClientDocuments';
import ClientOrderHistory from './ClientOrderHistory';
import ClientStatements from './ClientStatements';
import ClientTaxDocuments from './ClientTaxDocuments';
import { ClientDetails } from 'common/types';
import ClientProfile from './ClientProfile';

const ClientTabPanelBody = (props: {
  value: number;
  clientId: string;
  clientData: ClientDetails;
}) => {
  return (
    <TabPanelBody>
      <TabPanel value={props.value} index={0}>
        <ClientHoldings clientData={props.clientData} clientId={props.clientId} />
      </TabPanel>
      <TabPanel value={props.value} index={1}>
        <ClientOrderHistory clientId={props.clientId} />
      </TabPanel>
      <TabPanel value={props.value} index={2}>
        <ClientStatements clientId={props.clientId} />
      </TabPanel>
      <TabPanel value={props.value} index={3}>
        <ClientTaxDocuments clientId={props.clientId} />
      </TabPanel>
      <TabPanel value={props.value} index={4}>
        <ClientProfile clientDetails={props.clientData}/>
      </TabPanel>
    </TabPanelBody>
  );
};

export default ClientTabPanelBody;
