import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import { CircularProgress, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiDatePicker from 'common/components/inputField/DatePicker';
import SelectField from 'common/components/inputField/SelectField';
import MuiUpload from 'common/components/inputField/Upload';
import MuiModal from 'common/components/modal';
import { FundDetails } from 'common/types';
import { canModifyHoldings } from 'helpers/roles';
import { useAppSelector } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';

const StyledWrapper = styled(Grid)`
  .fileName {
    display: flex;
    align-items: center;
    svg {
      font-size: 13px;
      color: var(--green);
      margin-right: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #8e8e8e;
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  isLoading?: boolean;
  isFundsLoading?: boolean;
  handleModalClick: any;
  setMonthDate: any;
  setYearDate: any;
  onFileUpload: any;
  fundData: FundDetails[];
  selectedFund: FundDetails;
  monthDate: Date;
  yearDate: Date;
  setSelectedFund: any;
  fileName?: any;
};

const AddNewStatementModal = ({
  isModalOpen,
  handleModalClick,
  setMonthDate,
  setYearDate,
  fundData,
  monthDate,
  yearDate,
  onFileUpload,
  selectedFund,
  setSelectedFund,
  isLoading,
  fileName,
}: Props) => {
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  return (
    <>
      {user && canModifyHoldings(user) && (
        <MuiButton buttonClick={handleModalClick} variant='text' color='primary' className='mt-3'>
          ADD A NEW STATEMENT
        </MuiButton>
      )}
      <MuiModal
        title='Add a New Statement'
        maxWidth='sm'
        isModalOpen={isModalOpen}
        handleClose={handleModalClick}
      >
        <StyledWrapper container>
          <Grid item xs={12} sx={{ mb: 5 }}>
            <SelectField
              label='Fund'
              options={fundData && fundData}
              value={selectedFund?.fund_id}
              setSelectedValue={setSelectedFund}
              optionName='fund_name'
              optionId='fund_id'
              name='fund'
            />
          </Grid>
          <Grid item xs={12} container sx={{ mb: 5 }} columnSpacing={3}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Month</h4>
              <MuiDatePicker
                views={['month']}
                inputFormat='MMM'
                setDate={setMonthDate}
                value={monthDate}
              />
            </Grid>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Year</h4>
              <MuiDatePicker
                views={['year']}
                inputFormat='yyyy'
                setDate={setYearDate}
                value={yearDate}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container mb={5} justifyContent='space-between' alignItems='center'>
            <Grid item xs='auto'>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <MuiUpload disable={!selectedFund.fund_id} onFileUpload={onFileUpload}>
                  Upload Now
                </MuiUpload>
              )}
            </Grid>
            {fileName && (
              <Grid item xs='auto' className='fileName'>
                <CheckCircle />
                <span>{fileName?.name}</span>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className='flex-center-end'>
            <MuiButton variant='contained' buttonClick={handleModalClick} minWidth='150px'>
              Done
            </MuiButton>
          </Grid>
        </StyledWrapper>
      </MuiModal>
    </>
  );
};

export default AddNewStatementModal;
