export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'APL',
  },
];

export const COLUMNS = [
  {
    id: 'fund_name',
    label: 'Fund Name',
    isCheckbox: false,
    width: '30%',
  },
  {
    id: 'fund_manager_name',
    label: 'Fund Manager Name',
    isCheckbox: false,
    width: '30%',
  },
  {
    id: 'fund_class',
    label: 'Fund Class',
    isCheckbox: false,
    width: '20%',
  },
  {
    id: 'isApproved',
    label: 'Include on APL',
    isCheckbox: true,
    align: 'center' as const,
    width: '20%',
  },
];
