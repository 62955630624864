import { Chip } from '@mui/material';

type Props = {
  label: string;
  color: string;
  bg: string;
};

const Chips = ({ label, bg, color }: Props) => {
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: bg,
        color: color,
        fontSize: '12px',
        height: '24px',
        '& span': { padding: '8px' },
      }}
    />
  );
};

export default Chips;
