import useEmblaCarousel from 'embla-carousel-react';

import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CarouselBtn from 'common/assets/images/CarouselBtn.svg';
import MuiCard from 'common/components/card';
import { useTypedDispatch } from 'store/hooks';
import { setCurrentFilters } from 'store/investment/slice';

type StyledProps = {
  delay: number;
};

const StyledWrapper = styled(Container)<StyledProps>`
  margin-bottom: 3.5rem;

  &.chatbot_themes {
    margin-bottom: 0.75rem;
    opacity: 0;
    animation: fadeIn 0.5s ${(props) => props.delay}ms forwards,
      moveUp 0.5s ${(props) => props.delay}ms forwards;
  }

  .emblaContainer {
    position: relative;
    .carousel_btn {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 5;
      opacity: 1;
      transition: opacity 400ms;
      &.chatBotBtn {
        width: 2.5rem;
        height: 2.5rem;
        opacity: 1;
      }
      &.left {
        left: -25px;
        transform: translateY(-50%) rotate(180deg);
        &.chatBotBtn {
          left: 0.5rem;
        }
      }
      &.right {
        right: -25px;
        &.chatBotBtn {
          right: 1.25rem;
        }
      }
    }
    &:hover .carousel_btn {
      opacity: 1;
    }
  }

  .embla {
    overflow: hidden;
    cursor: grab;
    position: relative;
  }

  .ml-24 {
    margin-left: 24px;
  }

  .themeCard {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 22px 24px;
    flex: 0 0 26.5%;
    margin-right: 1.5rem;
    cursor: pointer;

    &.chatbot__themeCard {
      flex: 0 0 37% !important;
      margin-right: 1rem;
      padding: 20px 24px;
    }

    img,
    .MuiSkeleton-root {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .MuiGrid-root {
      position: relative;
      z-index: 1;
      color: white;
      h3 {
        font-size: 1.25rem;
      }
      h5 {
        font-size: 0.875rem;
      }
    }
  }
`;

interface Props {
  list: Array<any>;
  isChatBot?: boolean;
  className?: string;
  initDelay?: number;
  toggleDrawer?: any;
}

const Card = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  return (
    <MuiCard
      minHeight={props.isChatBot ? '120px' : '180px'}
      className={`themeCard ${props.isChatBot ? 'chatbot__themeCard' : ''}`}
      onClick={() => {
        props.toggleDrawer && props.toggleDrawer();
        dispatch(setCurrentFilters({ fund_themes: [props.themeName] }));
        navigate(`/investments`);
      }}
    >
      <img
        src={
          props.themeImageUrl ??
          'https://images.unsplash.com/photo-1579621970795-87facc2f976d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
        }
        alt=''
      />
      <Grid container direction='column'>
        <h3 className='font-wt-700 mb-0'>{props.themeName}</h3>
        <h5 className='font-wt-500 mb-0'>
          {props.total_funds || '0'} {props.total_funds === 1 ? 'Fund' : 'Funds'}
        </h5>
      </Grid>
    </MuiCard>
  );
};

const Themes = ({ list, isChatBot = false, initDelay = 0, toggleDrawer }: Props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, align: 'start' });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(
      emblaApi.selectedScrollSnap() < list.length - (isChatBot ? 2 : 3) && emblaApi.canScrollNext(),
    );
  }, [emblaApi, list, isChatBot]);

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <StyledWrapper
      maxWidth='xl'
      className={!isChatBot ? 'container-lr-padding' : 'px-0 chatbot_themes'}
      delay={initDelay}
    >
      {!isChatBot && <h2 className='font-wt-500'>Explore by Themes </h2>}
      <div className='emblaContainer'>
        <img
          src={CarouselBtn}
          className={`carousel_btn left ${prevBtnEnabled ? '' : 'd-none'} ${
            isChatBot ? 'chatBotBtn' : ''
          }`}
          alt='>'
          onClick={() => {
            scrollPrev();
          }}
        />
        <div className='embla' ref={emblaRef}>
          <div className={`d-flex ${isChatBot ? 'ml-24' : ''}`}>
            {list?.map((theme: any, i: number) => (
              <Card
                {...theme}
                key={`themeCard${i}`}
                isChatBot={isChatBot}
                toggleDrawer={toggleDrawer}
              />
            ))}
          </div>
        </div>
        <img
          src={CarouselBtn}
          className={`carousel_btn right ${nextBtnEnabled ? '' : 'd-none'} ${
            isChatBot ? 'chatBotBtn' : ''
          }`}
          alt='>'
          onClick={() => {
            scrollNext();
          }}
        />
      </div>
    </StyledWrapper>
  );
};

export default Themes;
