import Grid from '@mui/material/Grid/Grid';
import MuiButton from 'common/components/button';
import SelectField from 'common/components/inputField/SelectField';
import MuiModal from 'common/components/modal';
import { entityDropdownList } from 'common/constants';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { setBehalfOf } from 'store/user/slice';

const BehalfOfModal = () => {
  const dispatch = useTypedDispatch();
  const userOnBehalfOf = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<any>(entityDropdownList[0]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [selectedName, setSelectedName] = useState<any>(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const { data, refetch } = useReactQuery(
    ['subCategory'],
    {
      url: `qaip/v1/usermanagement/organisations?categoryId=${selectedEntity?.id}`,
    },
    {
      enabled: false,
    },
  );

  const { data: rolesData, refetch: refetchRoles } = useReactQuery(
    ['roles'],
    {
      url: `qaip/v1/usermanagement/roles/organisation?organisationId=${selectedName?.organisationId}`,
    },
    {
      enabled: false,
    },
  );

  const { data: usersData, refetch: refetchUsers } = useReactQuery(
    ['users'],
    {
      url: `qaip/v1/usermanagement/qualisuser/users/organisation?organisationId=${selectedName?.organisationId}`,
    },
    {
      enabled: false,
    },
  );

  const handleOnBehalfOfSelection = async () => {
    await localStorage.setItem('behalfOf', JSON.stringify(selectedUser));
    await dispatch(setBehalfOf(selectedUser));
    await setIsModalOpen(false);
    window.location.reload();
  };

  useEffect(() => {
    const isSuperuser = userProfile?.teamsRolesDetails.some(
      (role) => role.roleName === 'Superuser',
    );
    const isBehalfAvailable = localStorage.getItem('behalfOf');
    if (userProfile && isSuperuser && !isBehalfAvailable) {
      setIsModalOpen(true);
    }
    // eslint-disable-next-line
  }, [userProfile, userOnBehalfOf]);

  useEffect(() => {
    if (userProfile) {
      if (selectedEntity) refetch();
      if (selectedEntity && selectedName) refetchRoles();
      if (selectedEntity && selectedName && selectedRole) refetchUsers();
    }
    // eslint-disable-next-line
  }, [selectedEntity, selectedName, selectedRole, userProfile]);

  useEffect(() => {
    if (Array.isArray(usersData)) {
      const newusersData = usersData
        ?.filter((user) => {
          return user.teamsRolesDetails.some(
            (role: any) => Number(role.roleId) === Number(selectedRole?.roleId),
          );
        })
        .map((user: any) => ({ ...user, fullName: `${user.firstName} ${user.lastName}` }));
      setFilteredUsers(newusersData);
      // eslint-disable-next-line
    }
  }, [selectedRole, usersData]);

  return (
    <>
      <MuiModal
        title='On Behalf Of:'
        maxWidth='sm'
        isModalOpen={isModalOpen}
        handleClose={() => {
          localStorage.setItem('behalfOf', 'null');
          dispatch(setBehalfOf(null));
          setIsModalOpen(false);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectField
              label='Category'
              options={entityDropdownList}
              setSelectedValue={setSelectedEntity}
              optionName='name'
              optionId='id'
              name='entity'
              defaultValue={selectedEntity.id}
              searchIcon={false}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='Organization'
              options={
                data &&
                data.sort((a: any, b: any) => a.organisationName.localeCompare(b.organisationName))
              }
              value={selectedName?.organisationId}
              setSelectedValue={setSelectedName}
              optionName='organisationName'
              optionId='organisationId'
              name='name'
              searchIcon={false}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='Role'
              options={Array.isArray(rolesData) ? rolesData : []}
              value={selectedRole?.roleId}
              setSelectedValue={setSelectedRole}
              optionName='roleName'
              optionId='roleId'
              name='roles'
              searchIcon={false}
              disabled={!!!selectedName}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              label='User'
              options={
                Array.isArray(filteredUsers)
                  ? filteredUsers.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
                  : []
              }
              value={selectedUser?.userId}
              setSelectedValue={setSelectedUser}
              optionName='fullName'
              optionId='userId'
              name='users'
              searchIcon={false}
              disabled={!!!selectedRole}
            />
          </Grid>
          <Grid item xs={12} mt={4} container justifyContent='flex-end'>
            <MuiButton variant='contained' buttonClick={handleOnBehalfOfSelection} minWidth='150px'>
              Continue
            </MuiButton>
          </Grid>
        </Grid>
      </MuiModal>
    </>
  );
};

export default BehalfOfModal;
