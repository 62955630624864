import { getUserDetails } from 'helpers';
import { takeLatest, call, put } from 'redux-saga/effects';
import { setProfile } from './slice';
import { TeamsRolesDetails, UserProfile } from './types';
import { setAuthFailed } from './slice';

function* fetchUserProfile(action: any) {
  try {
    const { username } = action;
    const userProfile: UserProfile = yield call(getUserDetails, username);
    // doing the below logic because the user roles details 
    // returned is in string format
    // and it is supposed to be an array of objects
    const newTeamsRolesDetails: TeamsRolesDetails[] = [];
    userProfile.teamsRolesDetails.map((item: TeamsRolesDetails, index) => (
      newTeamsRolesDetails[index] = { ...userProfile.teamsRolesDetails[index], privilege: JSON.parse(item.privilege as string) }
    ))
    yield put(setProfile({ ...userProfile, teamsRolesDetails: newTeamsRolesDetails }));
  } catch (e) {
    console.log('Error Fetching User Profile', e);
    yield localStorage.removeItem("currentUser");
    yield put(setAuthFailed());
    yield window.location.href = "/login";
  }
}

export default function* profileSaga() {
  yield takeLatest('FETCH_USER_PROFILE', fetchUserProfile);
}
