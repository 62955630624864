import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Fade, Grid, Menu, MenuItem, Pagination, ToggleButton } from '@mui/material';
import MuiToggle from 'common/components/button/MuiToggle';
import InvestmentCard from 'components/investments/InvestmentCard';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import Filters from './Filters';
import { FundDetails, FundsMasterData, ReactQueryDataFetch } from 'common/types';
import styled from '@emotion/styled';
import { ReactComponent as CardViewIcon } from 'common/assets/images/investment_card.svg';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import MuiButton from 'common/components/button';
import { Done, KeyboardArrowDown } from '@mui/icons-material';
import {
  selectActiveSort,
  selectCurrentFilters,
  selectIsListView,
  selectPage,
  selectSearchQuery,
} from 'store/investment/selectors';
import {
  setActiveSort,
  setCurrentFilters,
  setIsListView,
  setPage,
  setSearchQuery,
} from 'store/investment/slice';
import React from 'react';
import { LIST_CRUMBS } from './constants';
import { UserProfile } from 'store/user/types';
import { useNavigate } from 'react-router-dom';
import { canCreateEditApproveFund } from 'helpers/roles';
import {
  setFundsActiveStep,
  setNewFundID,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setFundPriceMonthlyReturns,
  setFundPriceInceptionReturns,
  setFundPriceAnnualReturns,
  setFundDocuments,
  setInvestmentStats,
} from 'store/funds/slice';

const Wrapper = styled.div`
  .no-results {
    color: var(--s30);
    font-weight: 400;
  }
`;

const InvestmentList = () => {
  const dispatch = useTypedDispatch();
  const isListView = useAppSelector(selectIsListView);
  const page = useAppSelector(selectPage);
  const searchQuery = useAppSelector(selectSearchQuery);
  const currentFilters = useAppSelector(selectCurrentFilters);
  const activeSort = useAppSelector(selectActiveSort);
  const [fundsBeforePagination, setFundsBeforePagination] = useState<Array<any>>([]);
  const [funds, setFunds] = useState<Array<FundDetails>>([]);
  const [fundsBeforeFiltering, setFundsBeforeFiltering] = useState<Array<FundDetails>>([]);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const FUNDS_PER_PAGE = 10;

  const navigate = useNavigate();

  const { data = [], isLoading } = useReactQuery([`fundList${user?.userId}`], {
    url: 'qaip/v1/fundsmanagement/funds?status=Active',
  }) as { data: Array<FundDetails> } & ReactQueryDataFetch;

  //---Sort By---
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const sortByOpen = Boolean(anchorEl);

  const sortByTypes = ['Fund Name', 'Last Updated Fund'];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const newFund = data
        .map((d: any) => {
          return {
            ...d,
            lockup_for_summary: d.lockup_for_summary
              ? d.lockup_for_summary === 'N/A'
                ? 'No Lockup'
                : d.lockup_for_summary
              : 'No Lockup',
            nextClose: d.asset_class === 'Private Equity' ? 'Closing Soon' : '',
          };
        })
        .sort((a: any, b: any) => {
          switch (activeSort) {
            case 0:
              return a.fund_name.localeCompare(b.fund_name);
            case 1:
              return a.last_updated.localeCompare(b.last_updated) * -1;
            default:
              return 0;
          }
        });
      setFundsBeforeFiltering(newFund);
    }
  }, [data, activeSort]);

  useEffect(() => {
    let filteredFunds: Array<FundDetails> = fundsBeforeFiltering;
    // Specific Filtering
    Object.keys(currentFilters).forEach((f: string) => {
      if (currentFilters[f].length > 0) {
        filteredFunds = filteredFunds.filter((fund: any) => {
          if (f === 'liquidities') {
            return currentFilters[f].indexOf(fund['liquidity_for_summary']) > -1;
          } else if (f === 'lockups') {
            return currentFilters[f].indexOf(fund['lockup_for_summary']) > -1;
          } else if (f === 'fund_themes') {
            return currentFilters[f]?.some((filt: string) => fund['fund_themes']?.includes(filt));
          } else return currentFilters[f].indexOf(fund[f]) > -1;
        });
      }
    });

    // Search Query Filtering
    filteredFunds = filteredFunds?.filter((fund: any) => {
      for (let prop in fund) {
        if (String(fund[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
      }
      return false;
    });
    setFundsBeforePagination(filteredFunds);
  }, [fundsBeforeFiltering, currentFilters, searchQuery]);

  //Pagination
  useEffect(() => {
    setFunds(
      fundsBeforePagination.slice(page * FUNDS_PER_PAGE, page * FUNDS_PER_PAGE + FUNDS_PER_PAGE),
    );
  }, [fundsBeforePagination, page]);

  // Get filter list from masterData and only choose specific filters
  const { data: masterData } = useReactQuery([`masterDataForFunds${user?.userId}`], {
    url: 'qaip/v1/fundsmanagement/funds/masterData',
  }) as { data: FundsMasterData };

  const [filterList, setFilterList] = useState<Record<string, string[]>>({});
  useEffect(() => {
    if (masterData) {
      const eligibility = masterData.eligibility.filter(
        (value: string) => value !== 'Qualified Client',
      );
      setFilterList({
        nextClose: ['Closing Soon'],
        fund_themes: ['ESG', 'Income', 'Growth', 'Diversification'],
        asset_class: masterData.asset_class,
        strategy: masterData.strategy,
        eligibility: eligibility,
        class: masterData.class,
        lockups: masterData.lockups,
        liquidities: masterData.liquidities,
      });
    }
  }, [masterData]);

  useEffect(() => {
    const key = Object.keys(currentFilters);
    const hasFilters = key.some((f: string) => currentFilters[f].length > 0);
    if (!hasFilters) {
      let activeFiltersSchema: { [id: string]: any } = {};
      Object.keys(filterList).forEach((id: string) => {
        activeFiltersSchema[id] = [];
        if (id === 'class') {
          activeFiltersSchema['class'] = ['US Onshore'];
        }
      });
      dispatch(setCurrentFilters(activeFiltersSchema));
    }
    //eslint-disable-next-line
  }, [filterList]);

  if (isLoading) return <Loading />;

  return (
    <Wrapper>
      <Grid container sx={{ mt: '4rem' }}>
        <Grid item xs={12}>
          <SubHeader crumbs={LIST_CRUMBS} perfSummary>
            <Grid container justifyContent='space-between' alignItems='flex-end' columns={14}>
              <Grid item xs='auto'>
                <h1>Explore Funds</h1>
              </Grid>
              <Grid
                item
                xs={7}
                container
                alignItems='center'
                justifyContent='flex-end'
                flexWrap='nowrap'
              >
                <Grid item xs='auto'>
                  <SearchBar
                    fullWidth
                    searchQuery={searchQuery}
                    setSearchQuery={(data) => dispatch(setSearchQuery(data))}
                  />
                </Grid>
                <Grid item xs='auto' sx={{ ml: '20px' }}>
                  <MuiButton className='filterMainBtn' buttonClick={handleClick}>
                    Sort By <KeyboardArrowDown sx={{ fontSize: '16px', ml: '11px' }} />
                  </MuiButton>
                  <Menu
                    id='fade-menu'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={sortByOpen}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                  >
                    {React.Children.toArray(
                      sortByTypes.map((type: string, t: number) => (
                        <MenuItem
                          className='flex-center-start'
                          key={`sortByType${t}`}
                          onClick={() => dispatch(setActiveSort(t))}
                        >
                          <Done
                            fontSize='small'
                            className={`mr-2`}
                            sx={{ opacity: activeSort === t ? 1 : 0 }}
                          />{' '}
                          {type}
                        </MenuItem>
                      )),
                    )}
                  </Menu>
                </Grid>
                <Grid item xs='auto' ml={'20px'}>
                  <MuiToggle value={isListView}>
                    <ToggleButton value={false} onClick={() => dispatch(setIsListView(false))}>
                      <MenuIcon />
                    </ToggleButton>
                    <ToggleButton value={true} onClick={() => dispatch(setIsListView(true))}>
                      <CardViewIcon />
                    </ToggleButton>
                  </MuiToggle>
                </Grid>
                {user && canCreateEditApproveFund(user, 'create') && (
                  <Grid item xs='auto' ml={'20px'}>
                    <MuiButton
                      variant='outlined'
                      buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        dispatch(setFundsActiveStep(0));
                        dispatch(setFundsDetails(null));
                        dispatch(setFundStructAndStats(null));
                        dispatch(setFundTermsAndServices(null));
                        dispatch(setFundPriceAnnualReturns(null));
                        dispatch(setFundPriceMonthlyReturns(null));
                        dispatch(setFundPriceInceptionReturns(null));
                        dispatch(setFundDocuments(null));
                        dispatch(setNewFundID(null));
                        dispatch(setInvestmentStats([{}]));
                        navigate(`/admin/new-investment`);
                      }}
                    >
                      Add new Fund
                    </MuiButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </SubHeader>
        </Grid>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid container>
            <Grid item xs={3}>
              <Filters list={filterList} currentFilters={currentFilters} />
            </Grid>
            <Grid item container xs={9}>
              <Grid
                item
                container
                columnSpacing={3}
                rowSpacing={isListView ? 3 : 0}
                xs={12}
                sx={{
                  padding: '2rem 0 2rem 2rem',
                  flexDirection: isListView ? 'row' : 'column',
                  flexWrap: isListView ? 'wrap' : 'nowrap',
                }}
              >
                {funds?.length > 0 ? (
                  funds?.map((fund: FundDetails, i: number) => (
                    <InvestmentCard fund={fund} cardView={isListView} key={`fund${i}`} />
                  ))
                ) : (
                  <h4 className='no-results mx-auto'>No results found</h4>
                )}
                <Grid item xs={12}>
                  {FUNDS_PER_PAGE && fundsBeforePagination.length > FUNDS_PER_PAGE && (
                    <Pagination
                      className='pagination'
                      count={Math.ceil(fundsBeforePagination.length / FUNDS_PER_PAGE)}
                      page={page + 1}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
                        dispatch(setPage(value - 1))
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Wrapper>
  );
};

export default InvestmentList;
