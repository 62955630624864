import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import { ReactComponent as Delete } from 'common/assets/images/DocDelete.svg';
import { ReactComponent as Edit } from 'common/assets/images/Pencil.svg';

interface Props {
  row: any;
  columns: Array<any>;
  i: number;
  updateRow: (row: any, index: number) => void;
  deleteRow: any;
}

const InvestmentMasterListTableRow = ({ row, columns, i, updateRow, deleteRow }: Props) => {
  const [edit, setEdit] = useState<boolean>(row?.id ? false : true);
  const [fields, setFields] = useState<any>({
    title: row.title || '',
    type: row.type || '',
    visibleToNaturalPersons: row.visibleToNaturalPersons || '',
    visibleToEntities: row.visibleToEntities || '',
    visibleToHybrids: row.visibleToHybrids || '',
  });

  useEffect(() => {
    updateRow({ ...row, ...fields }, i);
    //eslint-disable-next-line
  }, [fields, i]);

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      {React.Children.toArray(
        columns.map((col: any) => (
          <TableCell
            align={col.align}
            className={`
        ${col.showOnHover && 'showOnHover'}
        ${col.bold && 'bold'}
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.isSelect ? (
              <SelectField
                className={`selectField pt-0 ${edit ? 'edit' : ''}`}
                disabled={!edit}
                searchIcon={false}
                optionId='id'
                optionName='title'
                options={col.selectOptions}
                setSelectedValue={(e: any) => {
                  setFields({ ...fields, [col.id]: e.id });
                }}
                value={fields[col.id]}
                defaultValue={fields[col.id]}
              />
            ) : col.isInput ? (
              <div className='flex-center-between'>
                <Textbox
                  fullWidth
                  placeholder='Document Name'
                  className={`textInput ${fields[col.id] ? '' : 'empty'} ${
                    edit ? 'edit' : ''
                  } mt-0`}
                  disabled={!edit}
                  value={fields[col.id]}
                  onChange={(e: any) => setFields({ ...fields, [col.id]: e.target.value })}
                />
              </div>
            ) : (
              row[col.id]
            )}
          </TableCell>
        )),
      )}
      <TableCell>
        <div className='flex-center-start'>
          <Edit
            className={`penIcon mr-3 cursor-pointer ${edit ? 'edit' : ''}`}
            onClick={() => {
              if (!row?.fundId) return;
              setEdit(!edit);
            }}
          />
          <Delete
            className='cursor-pointer'
            onClick={() => {
              if (!row?.fundId) return;
              deleteRow(
                row?.id
                  ? {
                      id: row.id,
                      deleteFromServer: true,
                    }
                  : {
                      id: row.UID,
                      deleteFromServer: false,
                    },
              );
            }}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default InvestmentMasterListTableRow;
