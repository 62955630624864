import {
  DocumentDetails,
  DocumentOwnerType,
  FundDetails,
  QueryType,
  ReactQueryDataFetch,
} from 'common/types';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAppSelector, useMutationQuery, useReactQuery } from 'store/hooks';
import MuiTable from 'common/components/table/Table';
import { format } from 'date-fns';
import MuiButton from 'common/components/button';
import AddNewTaxDocumentModal from 'components/modals/AddNewTaxDocumentModal';
import { encryptDocumentUrl } from 'helpers';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { canModifyHoldings } from 'helpers/roles';
import { TAX_DOCS_COLUMNS } from './constants';

const ClientTaxDocuments = (props: { clientId: string }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFund, setSelectedFund] = useState<FundDetails>({} as FundDetails);
  const [documentKey, setDocumentKey] = useState<{ object_key: string } | null>(null);
  const [documentData, setDocumentData] = useState<Object | null>({});
  const [date, setDate] = useState<Date>(new Date());
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(false);
  const [viewDocDetails, setViewDocDetails] = useState<any>(null);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const handleModalClickOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClickClose = () => {
    setDate(new Date());
    setUploadedFileData(null);
    setSelectedFund({} as FundDetails);
    setIsModalOpen(false);
  };

  const {
    data: clientTaxDocuments = [],
    isLoading,
    refetch: refreshClientTaxDocuments,
  } = useReactQuery([`clientTaxDocuments${props.clientId}`], {
    url: `qaip/v1/documentmanagement/getList/investor?investor_id=${props.clientId}&document_owner_type=${DocumentOwnerType.INVESTOR_TAX}`,
  }) as { data: DocumentDetails[] } & ReactQueryDataFetch;

  const { data: fundData } = useReactQuery([`funds${user?.userId}`], {
    url: `qaip/v1/fundsmanagement/funds?status=Active`,
  }) as { data: FundDetails[] } & ReactQueryDataFetch;

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPresignedUrl } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
      params: documentKey,
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData();
      },
    },
  );

  const { mutate: updateDocumentData } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      params: { documentData: documentData },
      isFormData: true,
    },
    {
      onSuccess: () => {
        refreshClientTaxDocuments();
      },
    },
  );

  const { refetch: deleteDocument } = useReactQuery(
    ['deleteDocument'],
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${viewDocDetails?.docId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        refreshClientTaxDocuments();
        setShowDocumentModal(false);
      },
    },
  );

  const {
    mutate: fetchDocLink,
    data: documentLink,
    isLoading: isDocumentLinkLoading,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: viewDocDetails?.docLink },
    },
    {
      enabled: false,
      onSuccess: () => {
        setShowDocumentModal(true);
      },
    },
  );

  useEffect(() => {
    if (viewDocDetails) fetchDocLink();
    // eslint-disable-next-line
  }, [viewDocDetails]);

  const handleDocViewOnclickOpen = (docLink: string, docId: string) => {
    setViewDocDetails({
      docLink: docLink,
      docId: docId,
    });
  };

  const handleDocViewOnclickClose = () => {
    setShowDocumentModal(false);
  };

  const handleDocDeleteClick = () => {
    deleteDocument();
  };

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const data = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: props.clientId,
      documentType: DocumentOwnerType.INVESTOR_TAX,
      selectedFund,
      docType: 'file',
      status: 'Active',
      date: format(date, 'yyyy'),
    });
    setDocumentKey(data.uploaddocumentKey);
    setDocumentData(data.documentData);
    fetchPresignedUrl();
  };

  const newData = clientTaxDocuments?.map((item: any) => ({
    ...item,
    createdDate: format(new Date(item.createdDate), 'MMM-dd-yyyy'),
  }));

  return (
    <>
      <MuiTable
        isLoading={isLoading}
        rows={newData}
        columns={TAX_DOCS_COLUMNS}
        disableHeader
        ctaType='viewNow'
        handleDocViewOnclickOpen={handleDocViewOnclickOpen}
        handleDocViewOnclickClose={handleDocViewOnclickClose}
        isViewDocModalOpen={showDocumentModal}
        docLink={documentLink}
        isDocumentLinkLoading={isDocumentLinkLoading}
        handleDocDeleteClick={handleDocDeleteClick}
      />
      {user && canModifyHoldings(user) && (
        <MuiButton
          buttonClick={handleModalClickOpen}
          variant='text'
          color='primary'
          className='mt-3'
        >
          ADD A NEW TAX DOCUMENT
        </MuiButton>
      )}
      <AddNewTaxDocumentModal
        isModalOpen={isModalOpen}
        handleModalClickClose={handleModalClickClose}
        fundData={fundData}
        selectedFund={selectedFund}
        setSelectedFund={setSelectedFund}
        setDate={setDate}
        onFileUpload={onFileUpload}
        date={date}
        isLoading={isFetchingPresignedUrl}
        fileName={uploadedFileData}
      />
    </>
  );
};

export default ClientTaxDocuments;
