import axios from "axios";
import { getTokenId, getUserId } from "helpers";

type AxiosHeaderType = {
  Authorization?: string;
  'x-idToken'?: string;
  'x-userid'?: string;
  'x-buserid'?: string;
  'Access-Control-Allow-Origin'?: string;
}

const getAxiosInstance = async (buserId?: string, queryWithoutToken?: boolean | undefined, baseURL?: string | undefined) => {
  const token = await getTokenId();
  const userId = await getUserId();

  // Validates if token exist or not
  // If not returns an empty object
  const headers: AxiosHeaderType = token ? {
    Authorization: `Bearer ${token}`,
    'x-idToken': token,
    'x-userid': userId,
    'x-buserid': buserId ? buserId : '',
    'Access-Control-Allow-Origin': '*',
  } : {};

  return axios.create({
    baseURL: baseURL ?? process.env.REACT_APP_SERVER_BASE_URL,
    headers: queryWithoutToken ? {} : headers,
  });
};

export default getAxiosInstance;
