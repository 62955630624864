import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { QueryType } from 'common/types';
import { useEffect } from 'react';
import { useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';

type Props = {
  existingUserData: any;
  userData: any;
  handleClose: any;
  reset: any;
  setUserData: any;
  handleModalClick: any;
  refetchUsers: any;
};

const ButtonSection = ({
  existingUserData,
  userData,
  handleClose,
  reset,
  setUserData,
  handleModalClick,
  refetchUsers,
}: Props) => {
  const dispatch = useTypedDispatch();
  const isUserActive = existingUserData?.active;

  const { mutate: addNewUser, isLoading: isAddingNewClient } = useMutationQuery(
    {
      url: 'qaip/v1/usermanagement/qualisuser/user',
      params: userData,
    },
    {
      enabled: false,
      onSuccess: (data: any) => {
        if (data.code === 'USER100002') {
          const toast: ToastMessage = {
            type: ToastType.ERROR,
            message: 'This user already exists.',
          };
          dispatch(showToast(toast));
        } else {
          reset();
          setUserData(null);
          refetchUsers();
          handleModalClick();
        }
      },
    },
  );

  const { refetch: disableUser } = useReactQuery(
    [`disableUser${existingUserData?.userId}`],
    {
      url: `qaip/v1/usermanagement/qualisuser/changeStatus?userName=${existingUserData?.userName}&disable=${isUserActive}`,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetchUsers();
        handleModalClick();
      },
    },
  );

  const { refetch: resendInvitation } = useReactQuery(
    ['resendInvite'],
    {
      url: `qaip/v1/usermanagement/qualisuser/resendInvite?userName=${existingUserData?.userName}`,
    },
    {
      enabled: false,
      onSuccess: () => {
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Invitation sent successfully.',
        };
        dispatch(showToast(toast));
        handleModalClick();
      },
    },
  );

  const { refetch: updateUser } = useReactQuery(
    [`updateUser${existingUserData?.userId}`],
    {
      url: 'qaip/v1/usermanagement/qualisuser/updateUser',
      params: userData,
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        refetchUsers();
        setUserData(null);
        handleModalClick();
      },
    },
  );

  useEffect(() => {
    if (!existingUserData && userData) addNewUser();
    if (existingUserData && userData) updateUser();
    // eslint-disable-next-line
  }, [userData]);

  return (
    <Grid item container justifyContent='flex-end' mt={5} xs={12}>
      <Grid item xs={12}>
        {/* This will prevent the submission of form on pressing enter key */}
        <button type='submit' disabled className='d-none' aria-hidden='true'></button>
        {existingUserData && (
          <MuiButton
            buttonClick={() => disableUser()}
            variant='text'
            color={isUserActive ? 'error' : 'primary'}
            minWidth='120px'
          >
            {isUserActive ? 'Disable' : 'Enable'}
          </MuiButton>
        )}
        <MuiButton
          buttonClick={() => handleClose()}
          variant='text'
          className='mr-3'
          minWidth='120px'
        >
          Discard Changes
        </MuiButton>

        {existingUserData?.statusFormatted === 'Inactive' && (
          <MuiButton
            buttonClick={resendInvitation as any}
            variant='text'
            className='mr-3'
            minWidth='120px'
          >
            Resend Invitation
          </MuiButton>
        )}
        <MuiButton variant='contained' minWidth='120px' type='submit' className='mr-3'>
          {isAddingNewClient ? 'Loading' : existingUserData ? 'Update Now' : 'Add User'}
        </MuiButton>
      </Grid>
    </Grid>
  );
};

export default ButtonSection;
