import styled from '@emotion/styled';
import { Grid, Menu, TabPanelUnstyled, TabsUnstyled, TabUnstyled } from '@mui/material';
import { useState } from 'react';
import MuiButton from '../button';

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: 24px;
  }
  ul {
    padding: unset;
  }
  button {
    cursor: pointer;
  }

  .TabPanelUnstyled-root {
    max-height: 200px;
    overflow-y: auto;
  }

  .MuiGrid-item {
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    &.categories {
      background-color: var(--s7);
      font-size: 13px;
      h5 {
        font-size: inherit;
        font-weight: 700;
        padding-left: 0.5rem;
      }
      button {
        border: 0;
        border-radius: 0.5rem;
        background-color: transparent;
        color: #626262;
        text-align: left;
        padding: 10px;
        &.active {
          background-color: #dbdbdb;
        }
      }
      button + button {
        margin-top: 1rem;
      }
    }
    &.filters {
      justify-content: space-between;
      .TabPanelUnstyled-root {
        padding: 1.5rem 0;
      }
      .MuiGrid-item {
        padding: 0.5rem 0 0 0.5rem;
        flex-direction: unset;
      }
    }
  }
`;

interface Props {
  open: boolean;
  anchor: any;
  title?: string;
  filters: Array<any>;
  currentFilters: Record<string, String[]>;
  onClose: () => void;
  applyFilter: Function;
}

const TableFilters = ({
  open,
  anchor,
  title = 'Filter by',
  filters,
  currentFilters,
  onClose,
  applyFilter,
}: Props) => {
  let activeFiltersSchema: { [id: string]: any } = {};
  filters.map((f: any) => f.id).forEach((id: string) => (activeFiltersSchema[id] = []));

  const [activeGroup, setActiveGroup] = useState(0);
  const [activeFilters, setActiveFilters] = useState(activeFiltersSchema);

  const addFilter = (id: string, filter: string) => {
    let arr = activeFilters[id];
    //Remove if filter is already active. Else, add
    if (arr.some((f: string) => f === filter)) {
      arr = arr.filter((f: string) => f !== filter);
    } else arr.push(filter);
    setActiveFilters((prevState) => ({ ...prevState, [id]: arr }));
  };

  return (
    <StyledMenu
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <TabsUnstyled orientation='vertical' component='div' value={activeGroup}>
        <Grid container sx={{ width: '570px', height: '350px' }}>
          <Grid item xs={4} className='categories'>
            <h5>{title}</h5>

            {filters.map((group, i) => (
              <TabUnstyled
                value={i}
                key={`FilterCat${i}`}
                onClick={() => setActiveGroup(i)}
                className={activeGroup === i ? 'active' : ''}
              >
                {group.filterGroupName}
              </TabUnstyled>
            ))}
          </Grid>
          <Grid item xs={8} className='filters'>
            {filters.map((group, i) => (
              <TabPanelUnstyled value={i} key={`filterPanel${i}`}>
                <Grid container columnSpacing={1} flexWrap='wrap'>
                  {group.value.map((item: any, j: number) => (
                    <Grid item xs={'auto'} key={`filter${i}${j}`}>
                      <MuiButton
                        buttonClick={() => addFilter(group.id, item)}
                        className={`${
                          activeFilters[group.id]?.some((f: any) => f === item) ? 'active' : ''
                        } filterBtn`}
                      >
                        {item}
                      </MuiButton>
                    </Grid>
                  ))}
                </Grid>
              </TabPanelUnstyled>
            ))}
            <section className='flex-center-between'>
              <MuiButton
                variant='text'
                color='error'
                buttonClick={() => {
                  applyFilter(activeFiltersSchema);
                  setActiveFilters(activeFiltersSchema);
                  onClose();
                }}
              >
                Clear All
              </MuiButton>
              <div>
                <MuiButton
                  variant='text'
                  buttonClick={() => {
                    setActiveFilters(currentFilters);
                    onClose();
                  }}
                  className='mr-3'
                >
                  Cancel
                </MuiButton>
                <MuiButton
                  variant='contained'
                  buttonClick={() => {
                    applyFilter(activeFilters);
                    onClose();
                  }}
                >
                  Apply
                </MuiButton>
              </div>
            </section>
          </Grid>
        </Grid>
      </TabsUnstyled>
    </StyledMenu>
  );
};

export default TableFilters;
