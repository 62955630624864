import { createGlobalStyle } from 'styled-components';
import SFCompact from 'common/assets/fonts/SF-Compact.ttf';
import Inter300 from 'common/assets/fonts/Inter-Light.ttf';
import Inter400 from 'common/assets/fonts/Inter-Regular.ttf';
import Inter500 from 'common/assets/fonts/Inter-Medium.ttf';
import Inter700 from 'common/assets/fonts/Inter-Bold.ttf';

export const lightTheme = {
  body: '#FFF',
};
export const darkTheme = {
  body: '#F8F8F8',
};

type Themetype = typeof lightTheme;

const GlobalStyle = createGlobalStyle<{ theme: Themetype }>`

  /* Font Imports */
  @font-face {
		font-family: "SF Compact";
		src: url(${SFCompact}) format('truetype');
	}
  @font-face {
		font-family: "Inter";
    font-weight: 300;
		src: url(${Inter300}) format('truetype');
	}
  @font-face {
		font-family: "Inter";
    font-weight: 400;
		src: url(${Inter400}) format('truetype');
	}
  @font-face {
		font-family: "Inter";
    font-weight: 500;
		src: url(${Inter500}) format('truetype');
	}
  @font-face {
		font-family: "Inter";
    font-weight: 700;
		src: url(${Inter700}) format('truetype');
	}

  html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: ${({ theme }) => theme.body};
    font-family: Inter, Nunito Sans, sans-serif;
    scroll-behavior: smooth;

    /* Color variables */
    --s5: #fafafa;
    --s7: #f8f8f8;
    --s10: #F4F4F4;
    --s15: #DDDDDD;
    --s20: #BDBDBD;
    --s30: #828282;
    --s40: #4f4f4f;
    --s50: #333;
    --p15: #EFF5FB;
    --p20: #E2EEF8;
    --p100: #87B8E3;
    --p150: #77AEDF;
    --p200: #66A4DB;
    --p300: #4590D3;
    --p400: #2E7CC2;
    --p500: #2667A1;
    --p600: #1D4F7C;
    --p700: #143757;
    --green: #69ca3b;
  }

  .App {
    min-height: 100%;
  }

  .d-none {
    display: none;
  }

  .d-inline {
    display: inline;
  }

  .d-flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flex-center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-center-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .flex-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .flex-end-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .flex-stretch-center {
    display: flex;
    align-items: stretch;
    justify-content: center;
  }

  .flex-stretch-between {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }

  .container-t-margin {
    margin-top: 108px;
  }
  .container-lr-padding {
    padding: 0 50px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .position-relative {
    position: relative;
  }

  .text-decoration-none {
    text-decoration: none;
  }

  .text-justify {
    text-align: justify;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /*---Colours---*/
  .color-p500 {
    color: var(--p500) !important;
  }

  /*---Margin---*/
  /* 1. Top */
  .mt-0 {
    margin-top: 0;
  }
  .mt-1 {
    margin-top: 0.25rem;
  }
  .mt-2 {
    margin-top: 0.5rem;
  }
  .mt-3 {
    margin-top: 1rem;
  }
  .mt-5 {
    margin-top: 3rem;
  }
  .m-header {
    margin-top: 60px;
  }

  // This is temporary
  .mt-100 {
    margin-top: 200px;
  }

  /* 2. Bottom */
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 1rem;
  }
  .mb-5 {
    margin-bottom: 3rem;
  }
  .mb-14 {
    margin-bottom: 14px;
  }

  /* 3. Top+Bottom */
  .my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  /* 4. Left */
  .ml-1 {
    margin-left: 0.25rem;
  }
  .ml-2 {
    margin-left: 0.5rem;
  }
  .ml-3 {
    margin-left: 1rem;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-auto {
    margin-left: auto;
  }

  /* 5. Right */
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mr-2 {
    margin-right: 0.5rem;
  }
  .mr-3 {
    margin-right: 1rem;
  }
  .mr-4 {
    margin-right: 1.5rem;
  }
  .mr-5 {
    margin-right: 3rem;
  }
  .mr-20 {
    margin-right: 20px;
  }

  /* 6. Left + Right */
  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .mx-3 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  /* Padding */
  /* 1. Top */
  .pt-0 {
    padding-top: 0;
  }
  .pt-2 {
    padding-top: 0.5rem;
  }
  /* 2. Bottom */
  .pb-0 {
    padding-bottom: 0;
  }
  .pb-1 {
    padding-bottom: 0.25rem;
  }
  /* 3. Top + Bottom */
  .py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  /* 4. Left */
  .pl-0 {
    padding-left: 0;
  }
  .pl-2 {
    padding-left: 0.5rem;
  }
  .pl-3 {
    padding-left: 1rem;
  }
  .pl-4 {
    padding-left: 1.5rem;
  }
  /* 5. Right */
  .pr-5 {
    padding-right: 3rem;
  }
  .pr-0 {
    padding-right: 0;
  }
  /* 6. Left + Right */
  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .px-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .px-5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .px-50 {
    padding-left: 50px;
    padding-right: 50px;
  }

  /* 7. All */
  .p-0 {
    padding: 0;
  }
  .p-2 {
    padding: 0.5rem;
  }
  .p-3 {
    padding: 1rem;
  }
  .p-4 {
    padding: 1.5rem;
  }


  /* Typography */
  /* 1. Reset */
  h1, h2, h3, h4, h5, h6, p {
    margin: 0 0 1em;
  }

  /* 2. Font Weights */
  .font-wt-300 {
    font-weight: 300;
  }
  .font-wt-400 {
    font-weight: 400;
  }
  .font-wt-500 {
    font-weight: 500;
  }
  .font-wt-600 {
    font-weight: 600;
  }

  /* 3. Alignment */
  .text-center {
    text-align: center;
  }
  .text-end {
    text-align: end;
  }

  /* 4. Misc Classes */
  .subtitle {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    color: var(--s30);
    text-transform: uppercase; 
    margin-bottom: 0.25rem; 
  }

  /*---Misc Classes---*/
  .authBG {
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #F0F0F0;
    background-color: #102C44;
    overflow: hidden;
    &:before, &:after {
      content: '';
      display: block;
      z-index: 1;
      position: absolute;
      width: calc(70vw);
      height: calc(70vw);
      border-radius: 100%;
      background: linear-gradient(155.3deg, 
          var(--p600) 3.53%, 
          #102C44 40.71%, 
          #0C2032 57.2%
        );
    }
    &:before {
      top: -50vw;
      right: -20vw;
    }
    &:after {
      bottom: -50vw;
      left: -20vw;
    }

    .MuiStepLabel-label {
      color: white;
    }
    img {
      width: 100%;
    }
    form, .form {
      position: relative;
      z-index: 5;
      max-width: 450px;

      label, .MuiInput-root {
        color: inherit;
        padding: 4px 12px;
      }
      .MuiInput-root:before {
        border-color: #F9F9F9 !important;
      }

      .MuiCheckbox-root {
        color: #b7c0c7;
      }

      a {
        color: #87B8E3;
        text-decoration: none;
      }

      button {
        background: linear-gradient(96.99deg, var(--p500) 2.96%, #3E89CD 104.56%);
      }
    }
  }

  .w-100 {
    display: block;
    width: 100%;
  }

  .pagination {
    button {
      color: grey;
      border-radius: 8px;
      &.Mui-selected {
        color: var(--p500);
        border: 2px solid #b3d1ed;
        background-color: unset;
      }
      svg {
        color: var(--p500);
      }
    }
  }

  /*---Animations---*/
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  @keyframes moveUp {
    from { transform: translateY(10px) }
    to { transform: translateY(0) }
  }

  /* Autofill bg fix */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    transition-delay: 9999s;
  }

  .tooltipParent {
    background-color: #102C44;
    .tooltipContainer {
      max-height: 200px;
      overflow-y: auto;
      padding: 0.5rem;
      scrollbar-width: thin;
      border-radius: 0.5rem;
  
      ul, ol {
        color: var(--s15);
        padding-left: 0.75rem;
        margin: 0 0 1rem;
        &.mb-0 {
          margin-bottom: 0;
        }
        li {
          margin-bottom: 0.25rem;
        }
      }
    }

    .MuiTooltip-arrow {
      color: #102C44;
    }
  }

  .rc-time-picker-panel {
    z-index: 1301 !important;
    width: 200px;
    .rc-time-picker-panel-inner {
      top: 2.5rem;
      border: none;
      width: 100%;
    }
    .rc-time-picker-panel-input-wrap {
      display: none;
    }
    .rc-time-picker-panel-combobox {
      display: flex;
      .rc-time-picker-panel-select {
        font-size: 1rem;
        flex-grow: 1;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          width: 0;
        }
        li {
          height: unset;
          padding: 6px;
          margin: 0 auto;
          text-align: center;
          &:focus {
            outline: none;
          }
          &:hover {
            background: rgba(0, 0, 0, 0.04);
          }
          &.rc-time-picker-panel-select-option-selected {
            font-weight: unset;
            background: rgba(38, 103, 161, 0.08);
          }
        }
      }
    }
  }

  .styled-date-picker {
    bottom: 0 !important;
    left: -15% !important;
  }
  .styled-date-picker-monthly{
    bottom: 0 !important; 

    .css-1dozdou {
      display: none;
    }
  }

  
  .chatbot__drawer .MuiDrawer-paperAnchorRight {
    border-radius: 12px 0 0 12px;
    width: 45%;
  }

  .notification-bubble {
    font-size: 0.8rem;
    color: var(--p500);
  }

  .header-notification-bubble {
    font-size: 1rem;
    color: var(--p400);
    position: absolute;
    top: 22%;
    right: 1px;
  }

  .row-bubble {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--p500);
    font-size: 0.8rem;
  }
`;

export default GlobalStyle;
