import {
  DPI_TOOLTIP_CONTENT,
  IRR_TOOLTIP_CONTENT,
  TVPI_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';

export const LIST_CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '',
    label: 'Investments',
  },
];

export const MONTHLY_COLUMNS = [
  {
    id: 'Year',
    label: 'Year',
    needsFormatter: false,
  },
  {
    id: '1',
    label: 'Jan',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '2',
    label: 'Feb',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '3',
    label: 'Mar',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '4',
    label: 'Apr',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '5',
    label: 'May',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '6',
    label: 'June',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '7',
    label: 'July',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '8',
    label: 'Aug',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '9',
    label: 'Sep',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '10',
    label: 'Oct',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '11',
    label: 'Nov',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: '12',
    label: 'Dec',
    needsFormatter: false,
    width: '5%',
  },
];

export const ANNUAL_COLUMNS = [
  {
    id: 'year',
    label: 'Year',
    needsFormatter: false,
  },
  {
    id: 'value',
    label: 'Total',
    needsFormatter: false,
    percent: true,
    align: 'right' as const,
  },
];

export const MANAGER_CHART = [
  {
    id: 'fund',
    label: 'Fund',
    needsFormatter: false,
    width: '11%',
  },
  {
    id: 'vintage',
    label: 'Vintage',
    needsFormatter: false,
    align: 'right' as const,
    width: '15.67%',
  },
  {
    id: 'size',
    label: 'Size',
    needsFormatter: false,
    width: '15.67%',
    align: 'right' as const,
  },
  {
    id: 'net_dpi',
    label: 'Net DPI',
    needsFormatter: false,
    align: 'right' as const,
    width: '17.67%',
    tooltip: DPI_TOOLTIP_CONTENT,
  },
  {
    id: 'net_tvpi',
    label: 'Net TVPI',
    needsFormatter: false,
    align: 'right' as const,
    width: '17.67%',
    tooltip: TVPI_TOOLTIP_CONTENT,
  },
  {
    id: 'net_irr',
    label: 'Net IRR',
    needsFormatter: false,
    width: '17.67%',
    align: 'right' as const,
    tooltip: IRR_TOOLTIP_CONTENT,
  },
];
