import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import InputField from 'common/components/inputField/Textbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTypedDispatch } from 'store/hooks';

type EmailType = {
  username: string;
};

type Props = {
  setEmail: any;
};

const EnterEmail = ({ setEmail }: Props) => {
  const dispatch = useTypedDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailType>();

  const onSubmit: SubmitHandler<EmailType> = async (data) => {
    setEmail(data.username);
    await dispatch({ type: 'SEND_PASSWORD_RESET_REQUEST', payload: data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} mb={6}>
          <InputField
            label='Enter your email'
            type='email'
            register={register}
            name='username'
            errorMessage='Please enter a valid Email ID'
            variant='standard'
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {errors.username && <ErrorMessage error={errors?.username.message} />}
          <MuiButton type='submit' variant='contained' color='primary' fullWidth>
            Next
          </MuiButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default EnterEmail;
