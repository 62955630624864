import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { ClientDetails, DocumentOwnerType, OrderDetails } from 'common/types';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import OrderDocsRow from './OrderDocsRow';
import { StyledWrapper } from 'common/styles/DocUploadTableStyle';
import NewRow from './NewRow';
import { useAppSelector } from 'store/hooks';
import { selectNewOrderDetails } from 'store/order/selectors';
import { useLocation } from 'react-router-dom';

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string | React.ReactNode;
  isSelect?: boolean;
  isInput?: boolean;
  selectOptions?: Array<string>;
}

// Add row types here
// Need to figure out the right way to do this
// Right now it throws error when you try to use it as index
type Row = ClientDetails | OrderDetails | any;

interface TableProps {
  rows: Row;
  columns: Array<Column>;
  isLoading?: boolean;
  ctaType?: string;
  tableClassName?: string;
  fundDoc: any;
}

const OrderDocsTable = ({
  rows = [],
  columns,
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  fundDoc,
}: TableProps) => {
  const location: any = useLocation();
  const [isAddRowVisible, setIsAddRowVisible] = React.useState(false);
  const newOrderDetails = useAppSelector(selectNewOrderDetails) as any;
  const currentOrderDetails = location?.state?.orderDetails;
  const investorId = newOrderDetails?.investorId
    ? newOrderDetails?.investorId
    : currentOrderDetails?.investorId;
  const orderId = newOrderDetails?.orderId
    ? newOrderDetails?.orderId
    : currentOrderDetails?.orderId;

  return (
    <StyledWrapper className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader finalCols={columns} ctaType={ctaType} />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {!rows ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + (ctaType ? 1 : 0)} className='nullTable'>
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {rows
                      ?.filter((row: Row) =>
                        row.title === 'Other'
                          ? row.status &&
                            row.title === 'Other' &&
                            row.documentsResponseDto.length > 0
                          : row.status && row.title !== 'Other',
                      )
                      .map((row: Row) =>
                        row.documentsResponseDto.length > 0 ? (
                          row.documentsResponseDto.map((dto: any) => (
                            <OrderDocsRow
                              fundDoc={fundDoc}
                              key={dto.documentId}
                              name={'orderDocsDTO'}
                              i={dto.documentId}
                              row={row}
                              dto={dto}
                              investorId={investorId}
                              columns={columns}
                              documentType={
                                row.type === 'Investor'
                                  ? DocumentOwnerType.INVESTOR_PERSONAL
                                  : DocumentOwnerType.ORDER
                              }
                            />
                          ))
                        ) : (
                          <OrderDocsRow
                            fundDoc={fundDoc}
                            key={row.id}
                            name={'orderDocs'}
                            i={row.id}
                            row={row}
                            columns={columns}
                            investorId={investorId}
                            documentType={
                              row.type === 'Investor'
                                ? DocumentOwnerType.INVESTOR_PERSONAL
                                : DocumentOwnerType.ORDER
                            }
                          />
                        ),
                      )}
                    {isAddRowVisible && (
                      <NewRow
                        fundDoc={fundDoc}
                        name={'otherOrderDocs'}
                        docOptions={rows.map((row: any) => ({
                          ...row,
                          id: row.id,
                          title: row.title,
                          masterDocId: row.masterDocId,
                          type: row.type,
                        }))}
                        columns={columns}
                        setIsAddRowVisible={setIsAddRowVisible}
                        isOrderDoc
                        id={investorId}
                        orderId={orderId}
                      />
                    )}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        onClick={() => setIsAddRowVisible(true)}
                        className={`addNew ${isAddRowVisible ? 'disable' : ''} font-wt-500`}
                      >
                        + Add New
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </StyledWrapper>
  );
};

export default OrderDocsTable;
