import { DocumentDetails, FundDetails } from 'common/types';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

type Props = {
  uploadedFileName: string,
  formattedFileName: string,
  id: string,
  documentType: string,
  selectedFund?: FundDetails | null,
  docType: string,
  status: string,
  docConfigId?: string,
  docConfigTypeId?: string,
  orderId?: number,
  isNewOrder?: boolean,
  date?: string,
  docCategoryId?: string,
  docName?: string,
  isOrder?: boolean,
  isSummaryDoc?: boolean,
  needsNameFormatingWithDate?: boolean,
  configName?: string,
}

export const generateDocumentRelatedNames = (uploadedFile: any) => {
  let currentdate = new Date();
  const uploadedFileName: string = uploadedFile.item(0).name;
  const fileString = uploadedFileName.substring(0, uploadedFileName.indexOf('.'));
  const fileType = uploadedFileName.split('.').pop();
  const formattedFileName: string = `${fileString}-${currentdate.getDate()}-${currentdate.getMonth() + 1
    }-${currentdate.getFullYear()}-${currentdate.getHours()}${currentdate.getMinutes()}${currentdate.getSeconds()}.${fileType}`;
  return {
    uploadedFileName,
    formattedFileName,
  }
}

export const generateDataForDocumentUpload = ({
  uploadedFileName,
  formattedFileName,
  id,
  documentType,
  selectedFund,
  docType,
  status,
  docConfigTypeId,
  orderId,
  docConfigId,
  isNewOrder = false,
  date,
  docName = '',
  isOrder = false,
  needsNameFormatingWithDate = false,
  isSummaryDoc = false,
  configName,
}: Props) => {
  const docClassificationName: string = docName === '' ? `${selectedFund?.fund_id}|${selectedFund?.fund_name}|${date}` : docName;
  const fileName = uploadedFileName.replace(',', ' ').replace('  ', ' ');
  let object_key;
  const fileExtension = fileName.split('.').pop();
  const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
  const formatDate = format(new Date(), 'dd-M-yyyy-HHmmss');
  if (needsNameFormatingWithDate) {
    object_key = `${id}/${isOrder ? `order-${orderId}` : `file`}/${fileNameWithoutExtension}-${formatDate}.${fileExtension}`;
  } else if (isSummaryDoc) {
    object_key = `${id}/pdf/${fileName}`;
  } else {
    object_key = `${id}/${isOrder ? `order-${orderId}` : `file`}/${fileName}`;
  }
  const documentData = {
    doc_name: formattedFileName,
    doc_type: docType,
    doc_category_id: id,
    doc_classification_name: docClassificationName,
    status: status,
    doc_config_id: null,
    object_key: object_key,
    document_type: documentType,
  };
  const uploaddocumentKey = {
    object_key: documentData.object_key,
  };
  const orderCreationDocData = {
    ...documentData,
    doc_classification_name: configName,
    doc_config_type_id: docConfigTypeId,
    doc_category_type_id: 3,
    order_id: orderId,
    doc_config_id: docConfigId,
  }
  return {
    uploaddocumentKey: uploaddocumentKey,
    documentData: !isNewOrder ? documentData : null,
    orderCreationDocData: isNewOrder ? orderCreationDocData : null,
  }

}



export const downloadAllDocuments = async (documentDetails: DocumentDetails[]) => {
  documentDetails.forEach(async (doc: DocumentDetails) => {
    var oReq = new XMLHttpRequest();
    oReq.open('GET', (doc as any).url, true);
    oReq.responseType = 'blob';
    oReq.onload = function () {
      var file = new Blob([oReq.response], {
        // type: 'application/pdf',
      });
      saveAs(file, doc.docName);
    };

    oReq.send();
  });
};

export const downloadBlobDoc = async (data: any) => {
  const file = new Blob([data]);
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = new Date() + '.pdf';
  link.click();
  link.remove();
};
