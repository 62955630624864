export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Users',
  },
];

export const COLUMNS = [
  {
    id: 'userName',
    label: 'User ID',
    needsFormatter: false,
  },
  {
    id: 'firstName',
    label: 'First Name',
    needsFormatter: false,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    needsFormatter: false,
  },
  {
    id: 'role',
    label: 'Role',
    needsFormatter: false,
  },
  {
    id: 'statusFormatted',
    label: 'Status',
    needsFormatter: false,
  },
  {
    id: 'invitationStatus',
    label: 'Invitation',
    needsFormatter: false,
  },
];
