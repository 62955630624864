import { ChevronRight } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Grid, Tab, Tabs } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import MuiTooltip from 'common/components/tooltip';
import {
  FUNDING_TYPE_TOOLTIP_CONTENT,
  GP_CATCHUP_TERM_TOOLTIP_CONTENT,
  PREFERRED_RETURN_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';
import { downloadAllDocuments } from 'helpers/document';
import React, { useState } from 'react';
import FundDocument from './FundDocument';
import DownloadIcon from 'common/assets/images/downloadDoc-2.svg';
import { ReactComponent as EditIcon } from 'common/assets/images/Pencil.svg';
import PrefillDownloadModal from 'components/modals/PrefillDownloadModal';
import { EntityType } from 'common/types';
import { useAppSelector } from 'store/hooks';
import { UserProfile } from 'store/user/types';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import InvestmentDocModal from 'components/modals/InvestmentDocModal';

type Props = {
  marketingDocs: any;
  fundReturns: any;
  fundDetails: any;
  setViewDoc: any;
  setDocModalOpen: any;
  subscriptionDocs: any;
  disableFundPerformanceTab: boolean;
  disableStructAndTerms: boolean;
  disableServiceProviders: boolean;
};

const InvestmentOverview = ({
  fundReturns,
  fundDetails,
  subscriptionDocs,
  marketingDocs,
  setViewDoc,
  setDocModalOpen,
  disableFundPerformanceTab,
  disableStructAndTerms,
  disableServiceProviders,
}: Props) => {
  const [subscriptionDocDetails, setSubscriptionDocDetails] = useState([]);
  const [marketingDocDetails, setMarketingDocDetails] = useState([]);
  const [fundOverviewTab, setFundOverviewTab] = useState<string>('0');
  const [prefillModalOpen, setPrefillModalOpen] = useState<boolean>(false);
  const [docEditModalOpen, setDocEditModalOpen] = useState<boolean>(false);

  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const showEditIcon =
    process.env.REACT_APP_SHOW_PDFTRON_FEATURES?.toString().toLowerCase() === 'true';

  return (
    <Grid item xs={12} container columnSpacing={3} alignItems='stretch' sx={{ mb: 6 }}>
      <Grid item xs={7} container direction='column'>
        <h2 className='font-wt-500'>Fund Overview</h2>
        <MuiCard
          minHeight='200px'
          sx={{ pt: 2, pb: 0, px: 0, border: 'none' }}
          className='fundOverview'
        >
          <TabContext value={fundOverviewTab}>
            <Tabs
              value={fundOverviewTab}
              onChange={(e: React.SyntheticEvent, val: string) => setFundOverviewTab(val)}
            >
              <Tab label='Description' value='0' />
              <Tab label='Fund Performance' value='1' disabled={disableFundPerformanceTab} />
              <Tab label='Marketing Docs' value='2' disabled={marketingDocs.length === 0} />
              <Tab label='Bios' value='3' disabled={!fundDetails?.managers_bio} />
              <Tab label='Structure & Terms' value='4' disabled={disableStructAndTerms} />
              <Tab label='Service Providers' value='5' disabled={disableServiceProviders} />
            </Tabs>
            <TabPanel value='0'>
              <p dangerouslySetInnerHTML={{ __html: fundDetails?.strategy_description }}></p>
            </TabPanel>
            <TabPanel value='1' className='fundPerformance'>
              <Grid container>
                <Grid item xs={12} px={2} pb={1} sx={{ borderBottom: '1px solid #ddd' }}>
                  {((fundReturns && fundReturns['1_year'] && !isNaN(fundReturns['1_year'])) ||
                    (fundReturns && fundReturns['3_years'] && !isNaN(fundReturns['3_years'])) ||
                    (fundReturns && fundReturns['5_years'] && !isNaN(fundReturns['5_years'])) ||
                    (fundReturns && fundReturns['10_years'] && !isNaN(fundReturns['10_years'])) ||
                    (fundReturns &&
                      fundReturns['since_inception'] &&
                      !isNaN(fundReturns['since_inception']))) && (
                    <h5> Historical Returns (Annualized)</h5>
                  )}
                  {fundReturns && fundReturns['1_year'] && !isNaN(fundReturns['1_year']) && (
                    <section>
                      1 Year
                      <p>
                        {fundReturns && fundReturns['1_year'] && !isNaN(fundReturns['1_year'])
                          ? Number(fundReturns['1_year']).toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundReturns && fundReturns['3_years'] && !isNaN(fundReturns['3_years']) && (
                    <section>
                      3 Years
                      <p>
                        {fundReturns && fundReturns['3_years'] && !isNaN(fundReturns['3_years'])
                          ? Number(fundReturns['3_years']).toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundReturns && fundReturns['5_years'] && !isNaN(fundReturns['5_years']) && (
                    <section>
                      5 Years
                      <p>
                        {fundReturns && fundReturns['5_years'] && !isNaN(fundReturns['5_years'])
                          ? Number(fundReturns['5_years']).toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundReturns && fundReturns['10_years'] && !isNaN(fundReturns['10_years']) && (
                    <section>
                      10 Years
                      <p>
                        {fundReturns && fundReturns['10_years'] && !isNaN(fundReturns['10_years'])
                          ? Number(fundReturns['10_years']).toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundReturns &&
                    fundReturns['since_inception'] &&
                    !isNaN(fundReturns['since_inception']) && (
                      <section>
                        Since Inception
                        <p>
                          {fundReturns &&
                          fundReturns['since_inception'] &&
                          !isNaN(fundReturns['since_inception'])
                            ? Number(fundReturns['since_inception']).toFixed(2) + '%'
                            : '--'}
                        </p>
                      </section>
                    )}
                </Grid>
                <Grid item xs={12} px={2} pt={3}>
                  {(fundDetails?.standard_deviation !== null ||
                    fundDetails?.sharpe_ratio !== null ||
                    fundDetails?.profitable_months_percentage !== null ||
                    fundDetails?.maximum_drawdown !== null ||
                    (fundDetails?.s_and_p_500 !== null && fundDetails?.s_and_p_500 !== '')) && (
                    <h5>Statistics Since Inception</h5>
                  )}
                  {fundDetails?.standard_deviation !== null && (
                    <section>
                      Standard Deviation
                      <p>
                        {fundDetails?.standard_deviation !== null
                          ? fundDetails?.standard_deviation.toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundDetails?.sharpe_ratio !== null && (
                    <section>
                      Sharpe Ratio
                      <p>
                        {fundDetails?.sharpe_ratio !== null
                          ? fundDetails?.sharpe_ratio.toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundDetails?.profitable_months_percentage !== null && (
                    <section>
                      % Profitable Months
                      <p>
                        {fundDetails?.profitable_months_percentage !== null
                          ? fundDetails?.profitable_months_percentage.toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundDetails?.maximum_drawdown !== null && (
                    <section>
                      Maximum Drawdown
                      <p>
                        {fundDetails?.maximum_drawdown !== null
                          ? fundDetails?.maximum_drawdown.toFixed(2) + '%'
                          : '--'}
                      </p>
                    </section>
                  )}
                  {fundDetails?.s_and_p_500 !== null && fundDetails?.s_and_p_500 !== '' && (
                    <section>
                      S&P 500 Correlation
                      <p>
                        {fundDetails?.s_and_p_500 !== null
                          ? Number(fundDetails?.s_and_p_500).toFixed(2)
                          : '--'}
                      </p>
                    </section>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value='2'>
              {React.Children.toArray(
                marketingDocs?.map((document: any) => (
                  <FundDocument
                    document={document}
                    setDocDetails={setMarketingDocDetails}
                    docDetails={marketingDocDetails}
                    setViewDoc={setViewDoc}
                    setViewDocModalOpen={setDocModalOpen}
                  />
                )),
              )}
              <MuiButton
                className='downloadBtn'
                buttonClick={() => downloadAllDocuments(marketingDocDetails)}
              >
                Download All <ChevronRight fontSize='small' />
              </MuiButton>
            </TabPanel>
            {fundDetails?.managers_bio && (
              <TabPanel value='3'>
                <p dangerouslySetInnerHTML={{ __html: fundDetails?.managers_bio }}></p>
              </TabPanel>
            )}
            <TabPanel value='4'>
              {fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets) && (
                <section>
                  Fund Assets
                  <p>
                    {fundDetails?.fund_assets && !isNaN(fundDetails?.fund_assets)
                      ? '$' + Number(fundDetails?.fund_assets).toLocaleString()
                      : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.min_investment && !isNaN(fundDetails?.min_investment) && (
                <section>
                  Minimum Investment
                  <p>
                    {fundDetails?.min_investment && !isNaN(fundDetails?.min_investment)
                      ? '$' + Number(fundDetails?.min_investment).toLocaleString()
                      : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.management_fees && (
                <section>
                  Management Fees
                  <p>
                    {fundDetails?.management_fees
                      ? fundDetails?.management_fees.toFixed(2) + '%'
                      : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.incentives_fees && (
                <section>
                  Incentive Fees
                  <p>
                    {fundDetails?.incentives_fees
                      ? fundDetails?.incentives_fees.toFixed(2) + '%'
                      : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.redemptions && (
                <section>
                  Redemptions
                  <p>{fundDetails?.redemptions || '--'}</p>
                </section>
              )}
              {fundDetails?.lockup && (
                <section>
                  Lock Up
                  <p>{fundDetails?.lockup || '--'}</p>
                </section>
              )}
              {fundDetails?.final_close && (
                <section>
                  Final Close
                  <p>{fundDetails?.final_close || '--'}</p>
                </section>
              )}
              {fundDetails?.next_close && (
                <section>
                  Next Close
                  <p>{fundDetails?.next_close || '--'}</p>
                </section>
              )}
              {fundDetails?.target_fund_size && (
                <section>
                  Target Fund Size
                  <p>
                    {fundDetails?.target_fund_size
                      ? '$' + Number(fundDetails?.target_fund_size).toLocaleString()
                      : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.preferred_return && (
                <section>
                  <span className='position-relative'>
                    Preferred Return
                    <MuiTooltip content={PREFERRED_RETURN_TOOLTIP_CONTENT} />
                  </span>
                  <p>
                    {fundDetails?.preferred_return ? fundDetails?.preferred_return + '%' : '--'}
                  </p>
                </section>
              )}
              {fundDetails?.gp_catch_up_term && (
                <section>
                  <span className='position-relative'>
                    GP Catch-up Term
                    <MuiTooltip content={GP_CATCHUP_TERM_TOOLTIP_CONTENT} />
                  </span>
                  <p>{fundDetails?.gp_catch_up_term || '--'}</p>
                </section>
              )}
              {fundDetails?.fund_term && (
                <section>
                  Term
                  <p>{fundDetails?.fund_term || '--'}</p>
                </section>
              )}
              {fundDetails?.funding_type && (
                <section>
                  <span className='position-relative'>
                    Funding Type
                    <MuiTooltip content={FUNDING_TYPE_TOOLTIP_CONTENT} />
                  </span>
                  <p>{fundDetails?.funding_type || '--'}</p>
                </section>
              )}
            </TabPanel>
            <TabPanel value='5'>
              {fundDetails?.prime_broker && (
                <section>
                  Prime Broker
                  <p>{fundDetails?.prime_broker || '--'}</p>
                </section>
              )}
              {fundDetails?.fund_counsel && (
                <section>
                  Fund Counsel
                  <p>{fundDetails?.fund_counsel || '--'}</p>
                </section>
              )}
              {fundDetails?.auditor && (
                <section>
                  Auditor
                  <p>{fundDetails?.auditor || '--'}</p>
                </section>
              )}
              {fundDetails?.administrator && (
                <section>
                  Administrator
                  <p>{fundDetails?.administrator || '--'}</p>
                </section>
              )}
            </TabPanel>
          </TabContext>
        </MuiCard>
      </Grid>
      <Grid item xs={5} container direction='column'>
        <h2 className='font-wt-500 flex-center-between'>
          Subscription Documents
          <div className='flex-center-between'>
            {showEditIcon && isQualisUser && (
              <MuiButton className='editBtn' buttonClick={() => setDocEditModalOpen(true)}>
                <EditIcon />
              </MuiButton>
            )}
            {subscriptionDocs.length !== 0 && (
              <MuiButton
                className='downloadBtn ml-1 flex-center-evenly'
                buttonClick={() =>
                  showEditIcon
                    ? setPrefillModalOpen(true)
                    : downloadAllDocuments(subscriptionDocDetails)
                }
              >
                <img style={{ height: '20px', width: '20px' }} src={DownloadIcon} alt='' /> Download
              </MuiButton>
            )}
          </div>
        </h2>
        <MuiCard minHeight='200px' sx={{ p: 2, border: 'none' }}>
          {subscriptionDocs.length !== 0 ? (
            React.Children.toArray(
              subscriptionDocs?.map((document: any) => (
                <FundDocument
                  document={document}
                  setDocDetails={setSubscriptionDocDetails}
                  docDetails={subscriptionDocDetails}
                  setViewDoc={setViewDoc}
                  setViewDocModalOpen={setDocModalOpen}
                />
              )),
            )
          ) : (
            <p>No document available.</p>
          )}
        </MuiCard>
      </Grid>
      <InvestmentDocModal
        isModalOpen={docEditModalOpen}
        handleClose={() => setDocEditModalOpen(false)}
        id={fundDetails.fund_id}
      />
      <PrefillDownloadModal
        isModalOpen={prefillModalOpen}
        handleClose={() => setPrefillModalOpen(false)}
        prefilledDocs={subscriptionDocs}
        allDocs={subscriptionDocs}
      />
    </Grid>
  );
};

export default InvestmentOverview;
