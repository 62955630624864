import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import SelectField from 'common/components/inputField/SelectField';
import { isArrayWithDuplicates } from 'helpers';
import { useEffect, useState } from 'react';

type Props = {
  register: any;
  selectedRole: any;
  rolesData: any[];
  setValue: any;
  setSelectedRole: any;
  errors: any;
  selectedTeam: any;
  setSelectedTeam: any;
  teamsData: any;
  deleteTeamAndRoleList: any;
  updateTeamAndRoleList: any;
  i: number;
  data: any;
  setError: any;
  clearErrors: any;
  teamAndRoleList: any;
  appendToRoleAndTeamList: any;
};

const UserRoleTeam = ({
  register,
  rolesData,
  setValue,
  setSelectedRole,
  errors,
  setSelectedTeam,
  teamsData,
  deleteTeamAndRoleList,
  updateTeamAndRoleList,
  i,
  data,
  setError,
  clearErrors,
  teamAndRoleList,
  appendToRoleAndTeamList,
}: Props) => {
  const [teamId, setTeamId] = useState<string>(data?.teamId);
  const [roleId, setRoleId] = useState<string>(data?.roleId);

  useEffect(() => {
    setValue(`team${i}`, teamId);
    setValue(`role${i}`, roleId);
    updateTeamAndRoleList({ teamId: teamId, roleId: roleId }, i);
    //eslint-disable-next-line
  }, [teamId, roleId]);

  useEffect(() => {
    const convertToNumber = teamAndRoleList.map((list: any) => {
      return {
        teamId: Number(list.teamId),
        roleId: Number(list.roleId),
      };
    });
    const hasDuplicates = isArrayWithDuplicates(convertToNumber);
    if (hasDuplicates) {
      setError('rolesAndTeams', {
        type: 'manual',
        message: 'Duplicate roles and teams found',
      });
    } else clearErrors('rolesAndTeams');
    // eslint-disable-next-line
  }, [teamId, roleId, teamAndRoleList]);
  return (
    <Grid item container columnSpacing={3}>
      <Grid item xs={6}>
        <SelectField
          name={`role${i}`}
          label='Role'
          optionName='roleName'
          register={register}
          optionId='roleId'
          value={roleId}
          setSelectedValue={setSelectedRole}
          options={Array.isArray(rolesData) ? rolesData : []}
          searchIcon={false}
          required
          errorMessage='This field is required'
          onChange={(e) => {
            setValue('role', e.target.value);
            setRoleId(e.target.value);
          }}
        />
        {errors[`role${i}`] && <ErrorMessage error='This field is required' />}
      </Grid>
      <Grid item xs={6}>
        <MuiButton
          buttonClick={() => deleteTeamAndRoleList(i)}
          disabled={i === 0}
          sx={{
            float: 'right',
            height: '24.5px !important',
            marginBottom: '9px',
            fontSize: '1.5rem !important',
          }}
        >
          -
        </MuiButton>
        <MuiButton
          buttonClick={() => appendToRoleAndTeamList()}
          sx={{
            float: 'right',
            height: '24.5px !important',
            marginBottom: '9px',
            marginLeft: '4px',
            fontSize: '1.5rem !important',
          }}
        >
          +
        </MuiButton>
        <SelectField
          name={`team${i}`}
          placeholder='Team'
          optionName='teamName'
          register={register}
          optionId='teamId'
          value={teamId}
          setSelectedValue={setSelectedTeam}
          options={Array.isArray(teamsData) ? teamsData : []}
          searchIcon={false}
          required
          errorMessage='This field is required'
          onChange={(e) => {
            setValue('team', e.target.value);
            setTeamId(e.target.value);
          }}
        />
        {errors[`team${i}`] && <ErrorMessage error='This field is required' />}
      </Grid>
    </Grid>
  );
};

export default UserRoleTeam;
