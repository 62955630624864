import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import SuccessTick from 'common/assets/images/SuccessTick2.svg';
import { useTypedDispatch } from 'store/hooks';
import { setOrderSubmittedModalOpen } from 'store/order/slice';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  title: string;
  message: string;
  disableRedirect?: boolean;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 426px;
  }
  h2 {
    font-size: 1.375rem;
    color: var(--s50) !important;
    padding: 2rem 28px 28px !important;
  }
  p {
    font-size: 0.875rem;
    margin-bottom: 0;
    color: var(--s40) !important;
  }
`;

const OrderSuccessModal = ({
  isModalOpen,
  handleClose,
  title,
  message,
  disableRedirect = false,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  return (
    <StyledModal
      title={title}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='xs'
      className='new-alloc'
      disableCloseIcon
    >
      <Grid container alignItems='center' mb={5} flexWrap='nowrap'>
        <img src={SuccessTick} alt='' className='mr-3' />
        <p>{message}</p>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton
          variant='contained'
          className='ml-3'
          buttonClick={() => {
            dispatch(setOrderSubmittedModalOpen(false));
            handleClose();
            if (!disableRedirect) navigate('/orders');
          }}
          minWidth='150px'
        >
          {disableRedirect ? 'Close' : 'View Orders'}
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default OrderSuccessModal;
