import getAxiosInstance from 'common/utils/axios';
import { AuthUser, UserProfile } from 'store/user/types';

export const getUserAuth = async (): Promise<AuthUser | null> => {
  try {
    const auth: AuthUser | null = JSON.parse(localStorage.getItem('currentUser') as string);
    return auth;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getTokenId = async (): Promise<string | undefined> => {
  try {
    const auth: AuthUser | null = await getUserAuth();
    return auth?.idToken as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUserId = async (): Promise<string | undefined> => {
  try {
    const auth: AuthUser | null = await getUserAuth();
    return auth?.userDetailsResponseDTO?.userId as string;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getUserDetails = async (username: string) => {
  const axios = await getAxiosInstance();
  const { data } = await axios.get(`qaip/v1/usermanagement/users/user?username=${username}`);
  return data;
};

export const encryptDocumentUrl = async (url: string, uploadedFileData: any) => {
  const buserId = '';
  const axios = await getAxiosInstance(buserId, true);
  await axios.put(url, uploadedFileData, {
    headers: {
      'Content-Type': uploadedFileData.type,
    },
  });
};

export const getBehalfOf = async () => {
  const behalfOf: UserProfile = await JSON.parse(localStorage.getItem('behalfOf') as string);
  return behalfOf;
};

export const formatAumValues = (value: string | number, decimal: number = 2) => {
  return (
    '$' +
    (Math.abs(Number(value)) >= 1.0e9
      ? (Math.abs(Number(value)) / 1.0e9).toFixed(decimal) + 'B'
      : Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(decimal) + 'M'
        : Math.abs(Number(value)) >= 1.0e3
          ? (Math.abs(Number(value)) / 1.0e3).toFixed(decimal) + 'K'
          : Math.round(Math.abs(Number(value))))
  );
};

export const valueFormatter = (value: string | number) => {
  return Number(value).toLocaleString();
};

export const regexValidator = (value: string | number, pattern: any) => {
  if (!pattern.test(value)) return true;
  else return false;
};

export const isArrayWithDuplicates = (array: any) => {
  const set = new Set(array.map(JSON.stringify));
  if (set.size !== array.length) {
    return true;
  } else {
    return false;
  }
}

export const fetchPageBackgroundBool = (pathName: any) => {
  if (
    (pathName.includes('/investment/') &&
      !pathName.includes('/investment/edit') &&
      !pathName.includes('/investment/add')) ||
    pathName.includes('/order/')
  ) return true;
  else return false;
}
