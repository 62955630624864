import { KeyboardArrowDown } from '@mui/icons-material';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTable from 'common/components/table/Table';
import TableFilters from 'common/components/table/TableFilters';
import { ClientType, EntityType, ReactQueryDataFetch } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { LIST_COLUMNS, LIST_QUALISVIEW_COLUMNS, LIST_CRUMBS, LIST_FILTERS } from './constants';
import { UserProfile } from 'store/user/types';
import {
  setInvestorsActiveStep,
  setClientDetails,
  setInvestorAccountHolderDetails,
  setAuthRepresentativeDetails,
  setCustodianDetails,
  setEntityDetails,
  setWireTransfferBankDetails,
  setNewInvestorId,
  setStoreAawceArr,
} from 'store/investor/slice';
import ClientCreation from './ClientCreation';

const ClientList = () => {
  const [isClientDetailModalOpen, setIsClientDetailModalOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<ClientType>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<ClientType>>([]);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isDG: boolean = user?.category === EntityType.DEALERS_GROUP;
  const isQualis: boolean = user?.category === EntityType.QUALIS;

  const handleModalClick = () => {
    dispatch(setInvestorsActiveStep(0));
    dispatch(setClientDetails(null));
    dispatch(setInvestorAccountHolderDetails(null));
    dispatch(setAuthRepresentativeDetails(null));
    dispatch(setWireTransfferBankDetails(null));
    dispatch(setCustodianDetails(null));
    dispatch(setEntityDetails(null));
    dispatch(setStoreAawceArr(null));
    dispatch(setNewInvestorId(null));
    setIsClientDetailModalOpen(!isClientDetailModalOpen);
  };

  const dispatch = useTypedDispatch();

  const { data = [], isLoading } = useReactQuery(
    [`clientList${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/investors',
    },
    {
      refetchOnMount: true,
      retryOnMount: true,
    },
  ) as { data: ClientType[] } & ReactQueryDataFetch;

  useEffect(() => {
    if (data && data.length > 0)
      //First sort NAV desc, then sort Name asc
      setRows(
        data.sort((a: any, b: any) => {
          if (a.total_holdings > b.total_holdings) return -1;
          else if (a.total_holdings < b.total_holdings) return 1;
          else return a.account_name.localeCompare(b.account_name);
        }),
      );
    // setRows(data);
  }, [data]);

  // --- Filters ---
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilters = Boolean(anchorEl);
  const [currentFilters, setCurrentFilters] = useState<Record<string, String[]>>({});

  const filterOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const filterClose = () => {
    setAnchorEl(null);
  };

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = rows;
    if (rows && rows.length > 0) {
      // Specific Filtering
      Object.keys(currentFilters).forEach((f: string) => {
        if (currentFilters[f].length > 0) {
          arr = arr.filter((row: any) => currentFilters[f].indexOf(row[f]) > -1);
        }
      });

      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    // eslint-disable-next-line
  }, [currentFilters, searchQuery, rows]);

  if (isLoading) return <Loading />;
  return (
    <Grid container sx={{ mt: 8 }}>
      <Grid item xs={12}>
        <SubHeader holdings crumbs={LIST_CRUMBS} className='pb-0'>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mr-20'>Clients</h1>
            </Grid>
            <Grid item xs={5} container alignItems='center' justifyContent='space-between'>
              <Grid
                item
                xs='auto'
                container
                justifyContent='flex-end'
                sx={{ flexGrow: '1 !important' }}
              >
                <SearchBar
                  minWidth='275px'
                  className='mr-20'
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
                <MuiButton className='filterMainBtn mr-20' buttonClick={filterOpen}>
                  Filter <KeyboardArrowDown sx={{ fontSize: '16px', ml: '11px' }} />
                </MuiButton>
                <TableFilters
                  open={openFilters}
                  anchor={anchorEl}
                  onClose={filterClose}
                  filters={LIST_FILTERS}
                  currentFilters={currentFilters}
                  applyFilter={setCurrentFilters}
                />
                {isDG && (
                  <MuiButton
                    variant='contained'
                    minWidth='150px'
                    buttonClick={() => {
                      handleModalClick();
                    }}
                  >
                    Add Client
                  </MuiButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Container maxWidth='xl' className='container-lr-padding'>
        <MuiTable
          isLoading={isLoading}
          rows={filteredRows}
          columns={isQualis ? LIST_QUALISVIEW_COLUMNS : LIST_COLUMNS}
          disableSearch
          ctaType='newOrder'
          canRedirect
          redirectURLKey='investor_id'
          redirectURLPrefix='client/'
          rowClassName='cursor-pointer'
          rowsPerPage={10}
        />
        {isClientDetailModalOpen && (
          <ClientCreation
            handleModalClose={handleModalClick}
            isModalOpen={isClientDetailModalOpen}
            editing={false}
          />
        )}
      </Container>
    </Grid>
  );
};

export default ClientList;
