import styled from 'styled-components';
import MuiModal from '.';

type Props = {
  isModalOpen: boolean;
  handleClose: () => void;
  title: string;
  docLink: string;
  handleDocReuploadClick?: () => void;
  handleDocDeleteClick?: () => void;
  handleDocDownload?: () => void;
  downloadable?: boolean;
  deletable?: boolean;
};

const ViewDocumentModal = ({
  isModalOpen,
  handleClose,
  title,
  docLink,
  handleDocReuploadClick,
  handleDocDeleteClick,
  handleDocDownload,
  downloadable,
  deletable = true,
}: Props) => {
  return (
    <MuiModal
      title={title}
      maxWidth='lg'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      docView={{
        handleDocReuploadClick: handleDocReuploadClick,
        handleDocDeleteClick: handleDocDeleteClick as () => void,
        handleDocDownload: handleDocDownload,
      }}
      downloadable={downloadable}
      deletable={deletable}
    >
      <IframeWrapper>
        <iframe title='fileViewer' className='file-viewer' src={docLink}></iframe>
      </IframeWrapper>
    </MuiModal>
  );
};

export default ViewDocumentModal;

const IframeWrapper = styled.div`
  .file-viewer {
    width: 100%;
    height: 60vh;
  }
`;
