import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useState, useEffect } from 'react';

import { AuthorizedRepresentativeDetails } from 'store/investor/types';

type Props = {
  authorizedRepresentative: AuthorizedRepresentativeDetails;
  index: number;
  handleModalClick: any;
};

const AuthorizedRepresentative = ({ authorizedRepresentative, index, handleModalClick }: Props) => {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    if (authorizedRepresentative) {
      if (
        authorizedRepresentative?.email ||
        authorizedRepresentative?.faxNumber ||
        authorizedRepresentative?.address ||
        authorizedRepresentative?.name ||
        authorizedRepresentative?.phoneNumber
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, [authorizedRepresentative]);

  return (
    <section style={{ display: `${showSection ? 'block' : 'none'}` }}>
      <h3>
        {index > 0 && 'Additional'} Authorized Representative
        <MuiButton
          className='ml-2 py-0'
          buttonClick={() => {
            handleModalClick();
          }}
        >
          Edit
        </MuiButton>
      </h3>
      <Divider />
      {authorizedRepresentative?.name && (
        <Grid item container>
          <Grid item xs={5}>
            Authorized Representative {index + 1 > 1 && index + 1}
          </Grid>
          <Grid item xs={7}>
            <p>{authorizedRepresentative?.name ? authorizedRepresentative?.name : '--'}</p>
          </Grid>
        </Grid>
      )}
      {(authorizedRepresentative?.address ||
        authorizedRepresentative?.phoneNumber ||
        authorizedRepresentative?.faxNumber) && (
        <Grid item container>
          <Grid item xs={5}>
            Mailing Address
          </Grid>
          <Grid item xs={7}>
            <p>{authorizedRepresentative?.address ? authorizedRepresentative?.address : '--'}</p>
            <p>
              {authorizedRepresentative?.phoneNumber &&
                `Tel: ${authorizedRepresentative?.phoneNumber}`}{' '}
              {authorizedRepresentative?.faxNumber && `Fax: ${authorizedRepresentative?.faxNumber}`}
            </p>
          </Grid>
        </Grid>
      )}
      {authorizedRepresentative?.email && (
        <Grid item container>
          <Grid item xs={5}>
            Email
          </Grid>
          <Grid item xs={7}>
            <p>{authorizedRepresentative?.email ? authorizedRepresentative?.email : '--'}</p>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12} className='btnGroup'>
        <MuiButton>Add Account Holder</MuiButton>
        <MuiButton className='ml-3'>Duplicate Account Holder</MuiButton>
      </Grid> */}
    </section>
  );
};

export default AuthorizedRepresentative;
