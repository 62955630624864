import styled from '@emotion/styled';
import { format } from 'date-fns';
import { formatAumValues } from 'helpers';
import {
  LineChart as RechartsLine,
  XAxis,
  Line,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const StyledLineChart = styled(RechartsLine)`
  .recharts-yAxis {
    margin-left: 20px;
    text {
      fill: var(--s30);
    }
  }
  .recharts-xAxis {
    text {
      fill: var(--s30);
    }
  }

  .custom-tooltip {
    background-color: white;
    padding: 0.5rem;
    min-width: 100px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    h4 {
      font-size: 13px;
      color: var(--p500);
      font-weight: 500;
    }
    h5 {
      color: var(--s30);
    }
  }
`;

interface Props {
  data?: Array<any>;
}

const FundsLineChart = ({ data }: Props) => {
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h4 className='fundName subtitle mb-3'>${payload[0].value.toLocaleString()}</h4>
          <h5 className='fundValue font-wt-400 mb-0'>
            {format(
              new Date(
                payload[0].payload.fundAmountReleaseDate.split('-').slice(1).reverse().join('-'),
              ),
              'MMM-yyyy',
            )}
          </h5>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width={'100%'} height={600}>
      <StyledLineChart
        width={600}
        height={600}
        data={data}
        margin={{ top: 64, right: 32, left: 16, bottom: 64 }}
      >
        <XAxis
          fontSize={13}
          dataKey='fundAmountReleaseDate'
          tickMargin={30}
          angle={-45}
          tick={{ dx: -20 }}
          stroke='#bdbdbd'
          tickFormatter={(val: any) => {
            const date = val.split('-').slice(1).reverse().join('-');
            return format(new Date(date), 'MMM-yyyy');
          }}
        />
        <YAxis
          tickCount={8}
          fontSize={13}
          stroke='#bdbdbd'
          // domain={[0, 'dataMax']}
          tickFormatter={(val: any) => {
            if (val === '0') return '0';
            else return formatAumValues(val, 1);
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type='monotone'
          dataKey='fundAmount'
          stroke='var(--p200)'
          strokeWidth={2}
          dot={false}
        />
      </StyledLineChart>
    </ResponsiveContainer>
  );
};

export default FundsLineChart;
