import React, { useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import SelectField from 'common/components/inputField/SelectField';
import { CheckCircle, Close, VisibilityOutlined } from '@mui/icons-material';
import { ReactComponent as DocUpload } from 'common/assets/images/DocUpload.svg';
import { ReactComponent as DocDelete } from 'common/assets/images/DocDelete.svg';
import { useRQMutation } from 'store/hooks';
import { encryptDocumentUrl } from 'helpers';
import { generateDataForDocumentUpload, generateDocumentRelatedNames } from 'helpers/document';
import { useQueryClient } from 'react-query';
import { QueryType } from 'common/types';
import ViewDocumentModal from 'common/components/modal/ViewDocumentModal';
import { useSelector } from 'react-redux';
import { selectClientDetails, selectNewInvestorId } from 'store/investor/selectors';

interface Props {
  row: any;
  columns: Array<any>;
  i: number;
  inEditing: boolean;
  name: string;
  docOptions?: Array<string>;
  documentType: string;
  handleRowDelete?: any;
  isMultipleDto?: boolean;
  dtoIdx?: number;
}

const ClientDocsRows = ({
  row,
  columns,
  i,
  inEditing,
  name,
  docOptions = [],
  documentType,
  handleRowDelete,
  isMultipleDto,
  dtoIdx,
}: Props) => {
  const queryClient = useQueryClient();
  const [docType, setDocType] = useState<any>(row.title);
  const [viewDocModalOpen, setViewDocModalOpen] = useState<boolean>(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [documentData, setDocumentData] = useState<Object | null>({});
  const investor = useSelector(selectClientDetails);
  const investorID = useSelector(selectNewInvestorId);
  const hasUploadedFiles = row?.documentsResponseDto?.length > 0;
  const [loading, setLoading] = useState<boolean>(false);

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`investorDoc-${investor.investor_id ?? investorID}`);
        setLoading(false);
      },
      onError: () => setLoading(false),
    },
  );

  const { mutate: fetchDocUrl, data: docUrl } = useRQMutation({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
  });

  const { mutate: deleteDocument } = useRQMutation(
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${row?.documentsResponseDto[0]?.documentId}`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(`investorDoc-${investor.investor_id ?? investorID}`);
        setLoading(false);
      },
      onError: () => setLoading(false),
    },
  );

  const { mutate: fetchPresignedUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData({ documentData: documentData });
      },
      onError: () => setLoading(false),
    },
  );

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const docData = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: investor?.investor_id ?? investorID,
      documentType: documentType,
      docType: 'file',
      status: 'Active',
    });
    setDocumentData({
      ...docData.documentData,
      doc_classification_name: docType,
      doc_category_type_id: 3,
      master_checklist_id: row.id,
    });
    fetchPresignedUrl(docData.uploaddocumentKey);
    event.target.value = '';
    event.target.files = null;
  };

  const handleReUpload = async (e: any) => {
    await deleteDocument({});
    await onFileUpload(e);
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell align='right' sx={{ width: '6%' }}>
        <div className='d-flex flex-center-end'>
          <Close
            onClick={() => handleRowDelete(row.id, row.visibility === 'Mandatory')}
            className='close-icon cursor-pointer'
          />
          {loading ? (
            <CircularProgress className='loading' />
          ) : (
            hasUploadedFiles && <CheckCircle className='check' />
          )}
        </div>
      </TableCell>
      {React.Children.toArray(
        columns.slice(1, columns.length - 1).map((col: any, index: number) => (
          <TableCell
            align={col.align}
            className={`
        ${col.showOnHover && 'showOnHover'}
        ${col.bold && 'bold'}
        ${col.cellClassName ? col.cellClassName : ''}
      `}
            sx={{ width: `${col.width ? col.width : 'initial'}` }}
          >
            {col.isSelect ? (
              row.title ? (
                `${row[col.id]}${row.visibility === 'Mandatory' ? '*' : ''}`
              ) : (
                <SelectField
                  className='selectField pt-0'
                  searchIcon={false}
                  options={docOptions}
                  setSelectedValue={(e: any) => {
                    setDocType(e);
                  }}
                  disabled
                  value={docType}
                  defaultValue={docType}
                />
              )
            ) : col.id === 'docName' && isMultipleDto && dtoIdx ? (
              row?.documentsResponseDto[dtoIdx]?.docName
            ) : (
              row?.documentsResponseDto[0]?.docName
            )}
          </TableCell>
        )),
      )}
      <TableCell sx={{ width: '20%' }}>
        <div className='d-flex'>
          <input
            id={'doc-upload-' + i + name}
            hidden
            onChange={(e: any) => {
              row?.documentsResponseDto.length > 0 ? handleReUpload(e) : onFileUpload(e);
            }}
            type='file'
            disabled={loading ? loading : docType.title === ''}
          />
          <label htmlFor={'doc-upload-' + i + name} style={{ height: '1rem' }}>
            <DocUpload className='docUpload cursor-pointer' />
          </label>
          {hasUploadedFiles && (
            <VisibilityOutlined
              onClick={() => {
                fetchDocUrl({ object_key: row?.documentsResponseDto[0]?.docLink });
                setViewDocModalOpen(true);
              }}
              className='visibility cursor-pointer ml-2'
            />
          )}
          {hasUploadedFiles && (
            <DocDelete
              onClick={() => {
                setLoading(true);
                deleteDocument({});
              }}
              className='docDelete cursor-pointer'
            />
          )}
        </div>
      </TableCell>
      <ViewDocumentModal
        isModalOpen={viewDocModalOpen}
        title={row?.documentsResponseDto[0]?.docClassificationName}
        handleClose={() => setViewDocModalOpen(false)}
        docLink={docUrl?.url}
        deletable={false}
      />
    </TableRow>
  );
};

export default ClientDocsRows;
