import styled from '@emotion/styled';
import { CheckCircle, DeleteOutline, UploadFile } from '@mui/icons-material';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiModal from 'common/components/modal';
import { useState } from 'react';
import Dropzone from 'react-dropzone';

const StyledWrapper = styled(Grid)`
  .fileName {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    svg {
      font-size: 13px;
      color: var(--green);
      margin-right: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #8e8e8e;
    }
  }

  .delete {
    cursor: pointer;
    font-size: 18px;
    color: var(--s30);
  }

  .dropzone {
    background-color: var(--s7);
    border-radius: 0.5rem;
    font-size: 14px;
    padding: 30px;
    font-weight: 500;
    color: var(--s20);
    svg {
      width: 64px;
      height: 64px;
      margin-bottom: 1.5rem;
      color: var(--s15);
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  isLoading: boolean;
  handleModalClick: any;
  onFileUpload: any;
  uploadedFileData?: any;
  hasSuccessfullyUploaded?: boolean;
  handleDocumentDelete?: any;
  docName?: string;
  isDocUploaded?: boolean;
  setSelectedFile?: any;
  selectedFile?: any;
};

const UploadHoldingsModal = ({
  isModalOpen,
  handleModalClick,
  onFileUpload,
  isLoading,
  hasSuccessfullyUploaded = false,
  setSelectedFile,
  selectedFile,
}: Props) => {
  const [fileUploadError, setFileUploadError] = useState<boolean>(false);

  return (
    <MuiModal
      title='Upload Holdings'
      isModalOpen={isModalOpen}
      handleClose={handleModalClick}
      maxWidth='sm'
    >
      <StyledWrapper container>
        <Grid item xs={12} mt={4} mb={3}>
          <h4 className='font-wt-400 mb-0'>
            Uploaded file will replace all holdings data on Tifin Private Markets platform
          </h4>
        </Grid>
        <Grid item xs={12} container alignItems='center'>
          <Grid item xs={12} mb={2}>
            <Dropzone
              maxFiles={1}
              multiple={false}
              accept={
                '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              }
              onDrop={(acceptedFiles, fileRejections) => {
                console.log({ acceptedFiles: acceptedFiles, fileRejections: fileRejections });
                if (fileRejections.length > 0) {
                  setFileUploadError(true);
                  setSelectedFile(null);
                } else {
                  setFileUploadError(false);
                  setSelectedFile(acceptedFiles[0]);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  container
                  flexDirection='column'
                  className='dropzone flex-center-center'
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <UploadFile />
                  <p className='mb-1'>Drag & Drop your files here</p>
                  <p className='mb-2'>or</p>
                  <MuiButton>Browse</MuiButton>
                </Grid>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={12}>
            {fileUploadError && <ErrorMessage error='Please upload a valid file.' />}
            {selectedFile && (
              <div className='flex-center-between'>
                <div className='fileName'>
                  <CheckCircle /> <span>{selectedFile?.name}</span>
                </div>
                <DeleteOutline className='delete' onClick={() => setSelectedFile(null)} />
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 8 }} container justifyContent='flex-end'>
          <MuiButton
            disabled={fileUploadError || !selectedFile}
            variant='contained'
            minWidth='150px'
            buttonClick={onFileUpload}
          >
            Upload
          </MuiButton>
        </Grid>
      </StyledWrapper>
    </MuiModal>
  );
};

export default UploadHoldingsModal;
