import { downloadBlobDoc } from 'helpers/document';
import DocDownload from 'common/assets/images/downloadDoc-2.svg';
import { CircularProgress, Tooltip } from '@mui/material';
import { useRQMutation } from 'store/hooks';

type Props = {
  row: any;
  fundDoc: any;
  investorId?: string;
};

const DocumentDownload = ({ row, fundDoc, investorId }: Props) => {
  const { mutate: downloadPdf, isLoading } = useRQMutation(
    {
      url: 'api/document/createpdf',
      baseURL: 'https://qualisdevapi.magnifi.com/',
      responseType: 'blob',
    },
    {
      onSuccess: (data: any) => {
        downloadBlobDoc(data);
      },
    },
  );

  const handleDocDownload = () => {
    const el = fundDoc.find((doc: any) => doc.prefillMapTo === row?.masterDocId);
    if (el)
      downloadPdf({
        document_id: el?.documentId,
        document_object_key: el?.docLink,
        investor_id: investorId,
      });
  };

  return isLoading ? (
    <CircularProgress className='loading ml-2' />
  ) : (
    <Tooltip
      title={<div className='tooltipContainer'>Pre-filled document available for download</div>}
      placement='top'
      arrow
      classes={{ tooltip: 'p-0 tooltipParent' }}
    >
      <img
        style={{ height: '20px', width: '20px' }}
        src={DocDownload}
        alt='download-doc-icon'
        onClick={() => handleDocDownload()}
        className='ml-2 cursor-pointer'
      />
    </Tooltip>
  );
};

export default DocumentDownload;
