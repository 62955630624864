import MuiButton from 'common/components/button';
import MuiModal from 'common/components/modal';
import SVG1 from 'common/assets/images/admin/sub_master.svg';
import SVG2 from 'common/assets/images/admin/users.svg';
import SVG3 from 'common/assets/images/DocTick.svg';
import { Grid } from '@mui/material';
import styled from '@emotion/styled';
import { ArrowForward } from '@mui/icons-material';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
};

const StyledModal = styled(MuiModal)`
  .MuiPaper-root {
    max-width: 656px;
  }
  p {
    font-size: 13px;
    text-align: center;
    max-width: 17.5ch;
    &.desc {
      font-size: 14px;
      color: var(--s40);
      text-align: start;
      max-width: unset;
    }
  }

  .arrow {
    font-size: 1.75rem;
    color: var(--s30);
    margin-bottom: 2rem;
  }
`;

const OrderDocPrefillModal = ({ isModalOpen, handleClose }: Props) => {
  return (
    <StyledModal
      title='Documents Required for Your Order'
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='sm'
      className='new-alloc'
    >
      <Grid container>
        <p className='desc'>Refer to fund's subscription document for detailed requirements.</p>
      </Grid>
      <Grid container columns={11} columnSpacing={2} alignItems='center' sx={{ mt: 5, mb: 6 }}>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG1} alt='' className='mb-3' />
          <p>Subscription checklist based on investor type</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG2} alt='' className='mb-3' />
          <p>Client documents are saved to profile and re-used</p>
        </Grid>
        <Grid item xs={1} className='flex-center-center'>
          <ArrowForward className='arrow' />
        </Grid>
        <Grid item xs={3} container className='flex-col flex-center-center'>
          <img src={SVG3} alt='' className='mb-3' />
          <p>Pre-filled documents available for download</p>
        </Grid>
      </Grid>

      <Grid container alignItems='center' justifyContent='flex-end'>
        <MuiButton variant='contained' buttonClick={handleClose} minWidth='150px'>
          Close
        </MuiButton>
      </Grid>
    </StyledModal>
  );
};

export default OrderDocPrefillModal;
