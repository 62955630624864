import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { Search } from '@mui/icons-material';

const StyledWrapper = styled(Box)`
  .MuiOutlinedInput-root {
    padding: 10px 18px;
  }
  .MuiAutocomplete-root {
    position: relative;
    font-size: 13px;
    border-radius: 0.5rem;
    background-color: var(--s7);
    .MuiOutlinedInput-notchedOutline {
      border-color: transparent !important;
    }
    &:hover .MuiOutlinedInput-notchedOutline,
    &:active .MuiOutlinedInput-notchedOutline {
      border-color: #b3d1ed !important;
    }
  }

  .MuiButtonBase-root {
    transform: unset !important;
  }
  input {
    font-weight: 0.875rem !important;
    padding: 0 !important;
  }
  .staticIcon {
    transform: unset;
  }
  .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    pointer-events: none;
    color: var(--s30);
    font-size: 14px;
  }
`;

type Props = {
  options: Array<any>;
  label?: string;
  errorMessage?: string;
  disabled?: boolean;
  className?: string;
  control?: any;
  name: string;
  defaultValue?: any;
  setSelectedValue?: any;
  placeholder?: string;
};

const MuiAutocomplete = ({
  options,
  label,
  errorMessage,
  name,
  control,
  disabled,
  defaultValue,
  setSelectedValue,
  placeholder = 'Select',
  className = '',
}: Props) => {
  return (
    <StyledWrapper sx={{ minWidth: 120, pt: 1 }} className={className}>
      {label && <h4 className='font-wt-400 mb-2'>{label}</h4>}
      <FormControl fullWidth>
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue && defaultValue}
            rules={{ required: errorMessage }}
            render={({ field: { onChange } }) => (
              <>
                <Autocomplete
                  options={options}
                  disabled={disabled}
                  onChange={(event: any, value: any) => {
                    onChange(value);
                  }}
                  popupIcon={<Search />}
                  renderOption={(props, option, index) => {
                    const key = `listItem-${index}-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option.label}
                      </li>
                    );
                  }}
                  renderInput={(params: any) => <TextField {...params} placeholder={placeholder} />}
                />
              </>
            )}
          />
        ) : (
          <Autocomplete
            options={options}
            disabled={disabled}
            onChange={(event: any, value: any) => {
              setSelectedValue(value);
            }}
            popupIcon={<Search />}
            renderOption={(props, option, index) => {
              const key = `listItem-${index}-${option.id}`;
              return (
                <li {...props} key={key}>
                  {option.label}
                </li>
              );
            }}
            renderInput={(params: any) => <TextField {...params} placeholder={placeholder} />}
          />
        )}
      </FormControl>
    </StyledWrapper>
  );
};

export default MuiAutocomplete;
