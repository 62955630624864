import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import Textbox from 'common/components/inputField/Textbox';
import { handleSSNInput } from 'helpers/client/utils';
import { useState } from 'react';
import { AccountHolderDetails } from 'store/investor/types';
/* eslint-disable no-useless-escape */
interface Props {
  additional?: boolean;
  register: any;
  errors: any;
  index: number;
  accountHolderDetails?: AccountHolderDetails;
  onAccountDetailsChange: (property: string, idx: number, value: any) => void;
  onAccountDetailsRemove: (index: number) => void;
  handleAddAccountHolderDetails?: any;
  detailsState?: any;
  setDetailsState?: any;
}

const AccountHolder = ({
  additional = false,
  register,
  errors,
  index,
  accountHolderDetails,
  onAccountDetailsChange,
  onAccountDetailsRemove,
  handleAddAccountHolderDetails,
  detailsState,
  setDetailsState,
}: Props) => {
  const [tempSSNValue, setTempSSNValue] = useState(`${accountHolderDetails?.ssn || ''}`);
  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleKeyDownNoText = (e: any) => {
    if (/^[A-Za-z!@#$%^&*()\-,._+={:>}"?``<;'\[\]\/\|{>"}\~"]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const convertToMap = (ssn: any) => {
    if (!ssn.includes('-') && ssn.length === 9) {
      const ssnArr = [];
      ssnArr[0] = ssn.substring(0, 2);
      ssnArr[1] = ssn.substring(2, 5);
      ssnArr[2] = ssn.substring(5, 10);
      return ssnArr.join('-');
    } else {
      return ssn;
    }
  };

  return accountHolderDetails ? (
    <Grid container className='my-5'>
      {additional ? (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Additional Account Holder Details</h3>
          <MuiButton
            variant='text'
            className='p-0'
            buttonClick={() => onAccountDetailsRemove(index)}
          >
            Remove
          </MuiButton>
        </Grid>
      ) : (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Account Holder Details</h3>
        </Grid>
      )}
      <Grid item xs={12}>
        <h4 className='font-wt-400 mb-0'>Name</h4>
        <Textbox
          fullWidth
          type='text'
          value={accountHolderDetails.name}
          register={register}
          name={`name${index}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAccountDetailsChange('name', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Mailing Address</h4>
        <Textbox
          fullWidth
          multiline
          rows={4}
          type='text'
          value={accountHolderDetails.mailingAddress}
          register={register}
          name={`mailingAddress${index}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAccountDetailsChange('mailingAddress', index, e.target.value)
          }
          maxLength={255}
        />
        {errors[`mailingAddress${index}`] &&
          errors[`mailingAddress${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
          )}
      </Grid>
      <Grid item xs={12} mt={1}>
        {index > 0 && (
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='finStatements'
              disableRipple
              defaultValue={accountHolderDetails.addressSameAsPrimaryAccount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onAccountDetailsChange('addressSameAsPrimaryAccount', index, e.target.checked)
              }
            />
            <span> Mailing address same as primary account holder</span>
          </div>
        )}
        <div className='checkbox-container flex-center-start'>
          <MuiCheckbox
            name='finStatements'
            disableRipple
            defaultValue={accountHolderDetails.useableForNoticeAndCommunications}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onAccountDetailsChange('useableForNoticeAndCommunications', index, e.target.checked)
            }
          />
          <span> Use mailing address for notices and communication</span>
        </div>
        <div className='checkbox-container flex-center-start '>
          <MuiCheckbox
            name='notices'
            disableRipple
            defaultValue={accountHolderDetails.useableForFinanceAndTaxInfomation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onAccountDetailsChange('useableForFinanceAndTaxInfomation', index, e.target.checked)
            }
          />
          <span> Use for financial statements and tax information returns</span>
        </div>
      </Grid>
      <Grid container item justifyContent={'space-between'}>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Tel</h4>
          <Textbox
            fullWidth
            value={accountHolderDetails.phoneNumber}
            type='tel'
            register={register}
            name={`tel${index}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onAccountDetailsChange('phoneNumber', index, e.target.value);
            }}
            keyDownFunc={handleKeyDownOnlyNumbers}
            startAdornment='+'
          />
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 11 characters.' />
          )}
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
          )}
        </Grid>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Fax</h4>
          <Textbox
            fullWidth
            value={accountHolderDetails.faxNumber}
            register={register}
            keyDownFunc={handleKeyDownOnlyNumbers}
            maxLength={10}
            minLength={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 11) return;
              onAccountDetailsChange('faxNumber', index, e.target.value);
            }}
            name={`fax${index}`}
            startAdornment='+1'
          />
          {errors[`fax${index}`] && errors[`fax${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 10 characters.' />
          )}
          {errors[`fax${index}`] && errors[`fax${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Email</h4>
        <Textbox
          fullWidth
          value={accountHolderDetails.email}
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onAccountDetailsChange('email', index, e.target.value)
          }
          name={`email${index}`}
          pattern={{
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email',
          }}
        />
        {errors[`email${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>SSN</h4>
        <Textbox
          fullWidth
          value={tempSSNValue && convertToMap(tempSSNValue)}
          placeholder='e.g. **-***-1234'
          keyDownFunc={handleKeyDownNoText}
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            const { useableValue, mappedValue } = handleSSNInput(value);
            setTempSSNValue(mappedValue);
            if (value.length === 10) return;
            onAccountDetailsChange('ssn', index, useableValue);
          }}
          name={`ssn${index}`}
          minLength={11}
          maxLength={11}
        />
        {errors[`ssn${index}`] && errors[`ssn${index}`].type === 'minLength' && (
          <ErrorMessage error='Please enter 9 characters.' />
        )}
        {errors[`ssn${index}`] && errors[`ssn${index}`].type === 'maxLength' && (
          <ErrorMessage error='Input limit exceeded. Character Limit is 9' />
        )}
      </Grid>
      <Grid item xs={12} mt={1}>
        <div className='checkbox-container flex-center-start'>
          <MuiCheckbox
            name='notices'
            disableRipple
            defaultValue={accountHolderDetails.usCitizen}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onAccountDetailsChange('usCitizen', index, e.target.checked)
            }
          />
          <span> US citizen or resident for tax purposes</span>
        </div>
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        {additional ? (
          <Grid item xs={12} className='header flex-center-between'>
            <h3 className='mb-0 font-wt-500'>Additional Account Holder Details</h3>
          </Grid>
        ) : (
          <Grid item xs={12} className='header flex-center-between'>
            <h3 className='mb-0 font-wt-500'>Account Holder Details</h3>
          </Grid>
        )}
        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-0'>Name</h4>
          <Textbox
            fullWidth
            type='text'
            value={detailsState.name}
            register={register}
            name={`name${index}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Mailing Address</h4>
          <Textbox
            fullWidth
            multiline
            rows={4}
            type='text'
            value={detailsState.mailingAddress}
            register={register}
            name={`mailingAddress${index}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, mailingAddress: e.target.value })
            }
            maxLength={255}
          />
          {errors[`mailingAddress${index}`] &&
            errors[`mailingAddress${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
            )}
        </Grid>
        <Grid item xs={12} mt={1}>
          {index > 0 && (
            <div className='checkbox-container flex-center-start'>
              <MuiCheckbox
                name='finStatements'
                disableRipple
                defaultValue={detailsState.addressSameAsPrimaryAccount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDetailsState({
                    ...detailsState,
                    addressSameAsPrimaryAccount: e.target.checked,
                  })
                }
              />
              <span> Mailing address same as primary account holder</span>
            </div>
          )}
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='finStatements'
              disableRipple
              defaultValue={detailsState.useableForNoticeAndCommunications}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({
                  ...detailsState,
                  useableForNoticeAndCommunications: e.target.checked,
                })
              }
            />
            <span> Use mailing address for notices and communication</span>
          </div>
          <div className='checkbox-container flex-center-start '>
            <MuiCheckbox
              name='notices'
              disableRipple
              defaultValue={detailsState.useableForFinanceAndTaxInfomation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({
                  ...detailsState,
                  useableForFinanceAndTaxInfomation: e.target.checked,
                })
              }
            />
            <span> Use for financial statements and tax information returns</span>
          </div>
        </Grid>
        <Grid container item justifyContent={'space-between'}>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Tel</h4>
            <Textbox
              fullWidth
              value={detailsState.phoneNumber}
              type='tel'
              register={register}
              name={`tel${index}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDetailsState({ ...detailsState, phoneNumber: e.target.value });
              }}
              keyDownFunc={handleKeyDownOnlyNumbers}
              startAdornment='+'
            />
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 11 characters.' />
            )}
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
            )}
          </Grid>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Fax</h4>
            <Textbox
              fullWidth
              value={detailsState.faxNumber}
              register={register}
              keyDownFunc={handleKeyDownOnlyNumbers}
              maxLength={10}
              minLength={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 11) return;
                setDetailsState({ ...detailsState, faxNumber: e.target.value });
              }}
              name={`fax${index}`}
              startAdornment='+1'
            />
            {errors[`fax${index}`] && errors[`fax${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 10 characters.' />
            )}
            {errors[`fax${index}`] && errors[`fax${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Email</h4>
          <Textbox
            fullWidth
            value={detailsState.email}
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, email: e.target.value })
            }
            name={`email${index}`}
            pattern={{
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email',
            }}
          />
          {errors[`email${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>SSN</h4>
          <Textbox
            fullWidth
            value={tempSSNValue && convertToMap(tempSSNValue)}
            placeholder='e.g. **-***-1234'
            keyDownFunc={handleKeyDownNoText}
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              const { useableValue, mappedValue } = handleSSNInput(value);
              setTempSSNValue(mappedValue);
              if (value.length === 10) return;
              setDetailsState({ ...detailsState, ssn: useableValue });
            }}
            name={`ssn${index}`}
            minLength={11}
            maxLength={11}
          />
          {errors[`ssn${index}`] && errors[`ssn${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 9 characters.' />
          )}
          {errors[`ssn${index}`] && errors[`ssn${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 9' />
          )}
        </Grid>
        <Grid item xs={12} mt={1}>
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='notices'
              disableRipple
              defaultValue={detailsState.usCitizen}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({ ...detailsState, usCitizen: e.target.checked })
              }
            />
            <span> US citizen or resident for tax purposes</span>
          </div>
        </Grid>
      </Grid>
      <MuiButton
        variant='outlined'
        className='mt-2'
        buttonClick={() => handleAddAccountHolderDetails({ ...detailsState })}
      >
        Add
      </MuiButton>
    </>
  );
};

export default AccountHolder;
