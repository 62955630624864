import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useEffect } from 'react';
import { useMutationQuery } from 'store/hooks';

const StyledLink = styled.div`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--s15);

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  span {
    font-size: 13px;
    color: #525863;
    display: block;
    text-decoration: none;
    &.doc {
      font-style: italic;
      color: var(--s30);
    }
  }

  button {
    font-size: 13px;
  }
`;

const OrderDocument = ({
  document,
  setDocDetails,
  setViewDoc,
  setViewDocModalOpen,
  showFileName = false,
  availableDoc,
}: any) => {
  const docLink = document?.documentsResponseDto && document?.documentsResponseDto[0]?.docLink;
  const { mutate, data: logo } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
      params: { object_key: docLink ?? document?.docLink },
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (document) mutate();
    // eslint-disable-next-line
  }, [document]);

  useEffect(() => {
    if (logo)
      setDocDetails((data: any) => [
        ...data,
        {
          id: document?.documentId,
          docName: document.docName,
          url: logo?.url,
        },
      ]);
    // eslint-disable-next-line
  }, [logo]);

  return (
    <>
      {document?.documentsResponseDto.map((item: any, index: any) => (
        <StyledLink className='flex-center-between'>
          <Grid container columnSpacing={1} alignItems='center'>
            <Grid item xs={showFileName ? 4 : 8}>
              <span className='type'>{document.docClassificationName ?? document.title}</span>
            </Grid>
            {showFileName && (
              <Grid item xs={6}>
                <span className='doc'>
                  {document?.documentsResponseDto[index]?.docName.length <= 30
                    ? document?.documentsResponseDto[index]?.docName
                    : document?.documentsResponseDto[index]?.docName.substring(0, 30) + '...'}
                </span>
              </Grid>
            )}
            <Grid item xs={showFileName ? 2 : 4} className='flex-center-end'>
              <MuiButton
                buttonClick={() => {
                  setViewDoc({ ...document, url: logo?.url });
                  setViewDocModalOpen(true);
                }}
              >
                View Now
              </MuiButton>
            </Grid>
          </Grid>
        </StyledLink>
      ))}
    </>
  );
};

export default OrderDocument;
