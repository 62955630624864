import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import { CircularProgress, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import MuiUpload from 'common/components/inputField/Upload';
import MuiModal from 'common/components/modal';

const StyledWrapper = styled(Grid)`
  .fileName {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    svg {
      font-size: 13px;
      color: var(--green);
      margin-right: 0.5rem;
    }
    span {
      font-size: 13px;
      color: #8e8e8e;
    }
  }
`;

type Props = {
  isModalOpen: boolean;
  isLoading: boolean;
  handleModalClick: any;
  onFileUpload: any;
  setValue: any;
  uploadedFileData?: any;
  hasSuccessfullyUploaded?: boolean;
  handleDocumentDelete?: any;
  docName?: string;
  isDocUploaded?: boolean;
};

const AddOrderDocumentModal = ({
  isModalOpen,
  handleModalClick,
  onFileUpload,
  setValue,
  isLoading,
  uploadedFileData,
  hasSuccessfullyUploaded = false,
  docName,
}: Props) => {
  return (
    <MuiModal
      title='Add Document'
      isModalOpen={isModalOpen}
      handleClose={handleModalClick}
      maxWidth='sm'
    >
      <StyledWrapper container>
        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-2'>Document Title</h4>
        </Grid>
        <Grid item xs={12} container alignItems='center'>
          <Grid item xs='auto' sx={{ flexGrow: '1 !important' }}>
            <Textbox
              fullWidth
              placeholder='Enter Document Title'
              value={docName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs='auto' className='pl-3'>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <MuiUpload className='mt-2' onFileUpload={onFileUpload} disable={!!!docName}>
                Browse
              </MuiUpload>
            )}
          </Grid>
          <Grid item xs={12}>
            {uploadedFileData && hasSuccessfullyUploaded && (
              <div className='fileName'>
                <CheckCircle /> <span>{uploadedFileData?.name}</span>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 8 }} container justifyContent='flex-end'>
          <MuiButton variant='contained' minWidth='150px' buttonClick={handleModalClick}>
            Done
          </MuiButton>
        </Grid>
      </StyledWrapper>
    </MuiModal>
  );
};

export default AddOrderDocumentModal;
