import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { selectAuthUser } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import LoginPage from 'Pages/Login';
import Footer from './layout/Footer';
import Header from './layout/Header';
import styled from 'styled-components';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { useEffect, useState } from 'react';
import { EntityType } from 'common/types';
import { UserProfile } from 'store/user/types';
import TermsOfUseModal from 'components/modals/TermsOfUseModal';
import ChatBot from 'components/chatBot';

export const PrivateRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useAppSelector(selectAuthUser);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isDGUser = user?.category === EntityType.DEALERS_GROUP;
  const showChatBot = process.env.REACT_APP_SHOW_CHATBOT?.toString()?.toLowerCase() === 'true';

  useEffect(() => {
    if (userProfile && location.pathname === '/') {
      if (userOnBehalfOf) {
        if (userOnBehalfOf.category === EntityType.FUNDS_MANAGER) {
          navigate('/investments');
        }
      }
      if (!userOnBehalfOf) {
        if (userProfile.category === EntityType.FUNDS_MANAGER) {
          navigate('/investments');
        }
      }
    }
    // eslint-disable-next-line
  }, [userProfile, userOnBehalfOf, location]);

  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(
    !(userProfile as UserProfile)?.termsAndConditions,
  );

  const handleTermsModalClick = (e: any, reason: string) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') return;
    setTermsModalOpen(!(userProfile as UserProfile)?.termsAndConditions);
  };

  return authUser && authUser.accessToken ? (
    <>
      <Header />
      <AuthBodyWrapper>
        <Outlet />
        {!(userProfile as UserProfile)?.termsAndConditions && isDGUser && (
          <TermsOfUseModal
            isModalOpen={termsModalOpen}
            setTermsModalOpen={setTermsModalOpen}
            handleModalClick={(e: any, reason: string) => handleTermsModalClick(e, reason)}
          />
        )}
      </AuthBodyWrapper>
      {showChatBot && isDGUser && <ChatBot name={user?.firstName} />}

      <Footer />
    </>
  ) : (
    <LoginPage />
  );
};

const AuthBodyWrapper = styled.div`
  min-height: calc(100vh - 102px);
`;
