import styled from '@emotion/styled';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Donut from 'common/components/charts/Donut';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTabs from 'common/components/tabs';
import MuiTooltip from 'common/components/tooltip';
import { ELIGIBILITY_TOOLTIP_CONTENT } from 'common/constants/tooltipContent';
import {
  ClientDetails as ClientDetailsType,
  EntityType,
  HoldingDetails,
  QueryType,
  ReactQueryDataFetch,
} from 'common/types';
import Funds from 'components/home/Funds';
import { RECOMMENDED_FUND_QUERY } from 'components/home/Query';
import { canCreateOrder } from 'helpers/roles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector, useGraphQLQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectClientModalOpen } from 'store/investor/selectors';
import {
  setInvestorsActiveStep,
  setNewInvestorId,
  setClientDetails,
  setClientModalOpen,
  setStoreAawceArr,
} from 'store/investor/slice';
import { resetOrderData } from 'store/order/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';
import ClientCreation from './ClientCreation';

const StyledWrapper = styled(Grid)`
  .card-header {
    border-bottom: 1px solid grey;
    button {
      padding: 28px 24px;
    }
  }

  .clientDetails {
    h2,
    h4 {
      font-weight: 300;
    }
    .clientName {
      margin-bottom: 10px;
    }
    h2,
    .statusText {
      margin-bottom: 1rem;
    }

    h2 {
      line-height: 1.3em;
    }

    .statusText {
      position: relative;
      bottom: 0.25rem;
      max-height: 1.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .infoIcon {
      font-size: 1rem;
      position: absolute;
      left: 64px;
      top: -2px;
    }
  }

  .recommendedFunds {
    margin-bottom: 0;
    img,
    .MuiSkeleton-root {
      height: 60px !important;
    }
    h2 {
      font-size: 20px;
      color: #07141f;
    }
    h3 {
      font-size: 18px;
    }
    .fundCard {
      border: none;
    }
  }
`;

const tabHeadings: string[] = [
  'Holdings',
  'Order History',
  'Statements',
  'Tax Documents',
  'Profile & Documents',
];

const ClientDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const id = location.pathname.split('/')[2];
  const [AUM, setAUM] = useState<string>('$0');
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualis: boolean = user?.category === EntityType.QUALIS;

  const isClientDetailModalOpen = useAppSelector(selectClientModalOpen);
  const showFunds =
    process.env.REACT_APP_SHOW_RECOMMENDED_FUNDS?.toString().toLowerCase() === 'true';

  const { data, isLoading, error, refetch } = useReactQuery([`clientDetails-${id}`], {
    url: `qaip/v1/investormanagement/investors/id/${id}`,
  }) as { data: ClientDetailsType } & ReactQueryDataFetch;

  const addTypeInAawceArr = (arrAndTypeArr: any[]) => {
    const newAawceArr: any[] = [];
    arrAndTypeArr.forEach((el: any) => {
      el.value.forEach((v: any) => {
        const newObject = {
          ...v,
          type: el.typeName,
        };
        newAawceArr.push(newObject);
      });
    });
    dispatch(setStoreAawceArr(newAawceArr));
  };

  useEffect(() => {
    if (data) {
      const arrAndTypeArr: any[] = [];
      if (data?.account_Holder_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'AccountHolderDetails',
          value: data.account_Holder_type,
        });
      }
      if (data?.authorized_representative_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'AuthorizedRepresentativeDetails',
          value: data?.authorized_representative_type,
        });
      }
      if (data?.custodian_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'CustodianType',
          value: data.custodian_type,
        });
      }
      if (data?.entity?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'EntityTypeDetails',
          value: data.entity,
        });
      }
      if (data?.wire_transfer_bank_type?.length > 0) {
        arrAndTypeArr.push({
          typeName: 'WireTransfferBankDetails',
          value: data.wire_transfer_bank_type,
        });
      }
      addTypeInAawceArr(arrAndTypeArr);
    }
    // eslint-disable-next-line
  }, [data]);
  // const { data: latestFunds = [] } = useReactQuery([`latestFunds${user?.userId}`], {
  //   url: 'qaip/v1/fundsmanagement/funds/latest',
  // });

  const { refetch: approveClient, isLoading: isApproveLoading } = useReactQuery(
    [`approveClient-${id}`],
    {
      url: `qaip/v1/investormanagement/investors/id/${id}`,
      params: { ...data, status: 'Active' },
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => refetch(),
    },
  ) as { data: ClientDetailsType } & ReactQueryDataFetch;

  const breadcrumbs = [
    {
      link: '/',
      label: 'Home',
    },
    {
      link: '/clients',
      label: 'Clients',
    },
    {
      link: '',
      label: data?.account_name,
    },
  ];

  const {
    data: chartData,
    isLoading: chartLoading,
    error: chartError,
  } = useReactQuery([`clientHoldings-${id}`], {
    url: `qaip/v1/holdingsmanagement/holdings/investor/${id}`,
  }) as { data: [HoldingDetails] } & ReactQueryDataFetch;

  const { data: recommendedFunds } = useGraphQLQuery(
    `recommendedFunds${id}`,
    {
      query: RECOMMENDED_FUND_QUERY,
      variables: {
        investerId: id,
        fundId: '',
        userId: user?.userId,
        categoryId: user?.category,
        organisationId: (user as any)?.organisationId,
      },
    },
    { enabled: !!user },
  );

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      let amount: number = 0;
      chartData.forEach((hold: HoldingDetails) => (amount += hold.currentValue));
      setAUM(`$${amount.toLocaleString()}`);
    }
  }, [chartData]);

  const handleModalClick = () => {
    dispatch(setInvestorsActiveStep(0));
    dispatch(setNewInvestorId(null));
    dispatch(setClientDetails(data));
    dispatch(setClientModalOpen(!isClientDetailModalOpen));
  };

  if (isLoading) return <Loading />;
  if (error) return <div>Error</div>;
  return (
    <StyledWrapper container mt={8}>
      <SubHeader crumbs={breadcrumbs} className='clientDetails'>
        <Grid container pt={2}>
          <Grid item container xs={9}>
            <Grid item xs={12} mb={1} container>
              <Grid
                container
                alignItems='flex-start'
                justifyContent='space-between'
                flexWrap='nowrap'
              >
                <Grid
                  item
                  xs='auto'
                  container
                  alignItems='flex-end'
                  sx={{ flexShrink: '1 !important' }}
                >
                  <Grid item xs={12}>
                    <h6 className='subtitle clientName'>
                      {isQualis ? `Client ID: ${data?.investorIdSimple}` : 'Client Name'}
                    </h6>
                  </Grid>
                  <h2 className='mr-20'>
                    {data?.account_name}
                    <span className='statusText ml-20 mr-20'> {data?.status}</span>
                    <MuiButton
                      className='py-1 px-4'
                      variant='text'
                      color='primary'
                      buttonClick={() => handleModalClick()}
                    >
                      Edit
                    </MuiButton>
                  </h2>
                </Grid>
                <Grid item xs='auto' pr={4} pb={2} pt={3.25}>
                  {data.status !== 'Approval Requested' ? (
                    <MuiButton
                      minWidth='150px'
                      buttonClick={() => {
                        dispatch(resetOrderData());
                        navigate('/create-order', {
                          state: {
                            clientDetails: data,
                          },
                        });
                      }}
                      variant='contained'
                      disabled={(user as UserProfile) && !canCreateOrder(user as UserProfile)}
                    >
                      Add New Order
                    </MuiButton>
                  ) : (
                    <MuiButton buttonClick={approveClient} variant='outlined'>
                      {isApproveLoading ? 'Loading...' : 'Approve'}
                    </MuiButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={10} spacing={1} mb={1.5}>
              {/* <Grid item xs={4}>
                <h6 className='subtitle'>Account Number</h6>
                <h4>{data?.account_number ?? '--'}</h4>
              </Grid> */}
              <Grid item xs={4}>
                <h6 className='subtitle'>PRIMARY ADVISOR</h6>
                <h4>{data?.primary_advisor_name}</h4>
              </Grid>
              <Grid item xs={4}>
                <h6 className='subtitle'>SECONDARY ADVISOR</h6>
                <h4>{data?.secondary_advisor_name}</h4>
              </Grid>
              <Grid item xs={4}>
                <h6 className='subtitle'>TEAM</h6>
                <h4>{data?.team_name}</h4>
              </Grid>
            </Grid>
            <Grid item container xs={10} spacing={1}>
              <Grid item xs={4}>
                <h6 className='subtitle position-relative'>
                  Eligibility
                  <MuiTooltip content={ELIGIBILITY_TOOLTIP_CONTENT} />
                </h6>
                <h4 className='mb-0'>
                  {data?.qualified_purchaser
                    ? 'Qualified Purchaser'
                    : data?.qualified_client
                    ? 'Qualified Client'
                    : 'Accredited Investor'}
                </h4>
              </Grid>
              <Grid item xs={4}>
                <h6 className='subtitle'>Type</h6>
                <h4 className='mb-0'>{data?.entity_type}</h4>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className='position-relative flex-center-center'>
            <Donut
              data={chartData?.length > 0 ? chartData : [{ fundName: 'test', currentValue: 1 }]}
              dataKey='currentValue'
              nameKey='fundName'
              label1='NAV'
              label2={AUM}
              isLoading={chartLoading}
              error={chartError}
            />
          </Grid>
        </Grid>
      </SubHeader>
      {showFunds && recommendedFunds?.getRecFunds ? (
        <Grid container py={4} my={4} sx={{ backgroundColor: 'var(--s10)' }}>
          <Funds
            list={recommendedFunds?.getRecFunds}
            className='container-lr- mb-0'
            heading={`Recommended for ${data?.account_name}`}
          />
        </Grid>
      ) : (
        <Grid container my={4}></Grid>
      )}
      <Container maxWidth='xl' className='container-lr-padding' sx={{ pt: '20px !important' }}>
        <MuiTabs clientData={data} clientId={id} tabHeadings={tabHeadings as string[]} />
      </Container>
      {isClientDetailModalOpen && (
        <ClientCreation
          handleModalClose={handleModalClick}
          isModalOpen={isClientDetailModalOpen}
          editing={true}
        />
      )}
    </StyledWrapper>
  );
};

export default ClientDetails;
