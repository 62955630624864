import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import Textbox from 'common/components/inputField/Textbox';
import { regexValidator, valueFormatter } from 'helpers';
import { useEffect, useState } from 'react';

type Props = {
  register: any;
  setValue: any;
  errors: any;
  handleKeyDown: any;
  isLast: boolean;
  deleteFromList: any;
  index: number;
  updateToList: any;
  investmentStatsArr: any;
  investmentStat: any;
};

const InvestmentStatsFields = ({
  register,
  setValue,
  errors,
  handleKeyDown,
  isLast,
  deleteFromList,
  index,
  updateToList,
  investmentStatsArr,
  investmentStat,
}: Props) => {
  const [fund, setFund] = useState('');
  const [vintage, setVintage] = useState('');
  const [size, setSize] = useState('');
  const [net_dpi, setNetDpi] = useState('');
  const [net_tvpi, setNetTvpi] = useState('');
  const [net_irr, setNetIrr] = useState('');

  useEffect(() => {
    if (investmentStat) {
      setFund(investmentStat['fund']);
      setVintage(investmentStat['vintage']);
      setSize(investmentStat['size']);
      setNetDpi(investmentStat['net_dpi']);
      setNetTvpi(investmentStat['net_tvpi']);
      setNetIrr(investmentStat['net_irr']);
    }
    // eslint-disable-next-line
  }, [investmentStat]);

  useEffect(() => {
    updateToList({ fund, vintage, size, net_dpi, net_tvpi, net_irr }, index);
    // eslint-disable-next-line
  }, [fund, vintage, size, net_dpi, net_tvpi, net_irr]);

  return (
    <>
      <Grid container columnSpacing={3} className='my-5'>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Fund</h4>
          <Textbox
            fullWidth
            name={`fund-${index}`}
            onChange={(e: any) => {
              setFund(e.target.value);
            }}
            value={fund}
            type='text'
          />
          {errors.fund && <ErrorMessage error='This field is required' />}
        </Grid>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Vintage</h4>
          <Textbox
            fullWidth
            name={`vintage-${index}`}
            onChange={(e: any) => setVintage(e.target.value)}
            value={vintage}
            type='text'
          />
          {errors.vintage && <ErrorMessage error='This field is required' />}
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} className='my-5'>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Size</h4>
          <Textbox
            fullWidth
            name={`size-${index}`}
            onChange={(e: any) => {
              if (regexValidator(e.target.value.replace(/,/g, ''), /^[0-9,]*$/i)) return;
              console.log(e.target.value);
              setSize(e.target.value.replace(/,/g, ''));
            }}
            type='text'
            value={valueFormatter(size)}
            keyDownFunc={handleKeyDown}
            startAdornment={'$'}
          />
          {errors.size && <ErrorMessage error='This field is required' />}
        </Grid>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Net DPI</h4>
          <Textbox
            fullWidth
            name={`net_dpi-${index}`}
            onChange={(e: any) => {
              setNetDpi(e.target.value);
            }}
            type='text'
            value={net_dpi}
            keyDownFunc={handleKeyDown}
          />
          {errors.net_dpi && <ErrorMessage error='This field is required' />}
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} className='my-5'>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Net TVPI</h4>
          <Textbox
            fullWidth
            name={`net_tvpi-${index}`}
            onChange={(e: any) => {
              setNetTvpi(e.target.value);
            }}
            type='text'
            value={net_tvpi}
            keyDownFunc={handleKeyDown}
          />
          {errors.net_tvpi && <ErrorMessage error='This field is required' />}
        </Grid>
        <Grid item xs={6}>
          <h4 className='font-wt-400 label'>Net IRR</h4>
          <Textbox
            fullWidth
            name={`net_irr-${index}`}
            onChange={(e: any) => {
              setNetIrr(e.target.value);
            }}
            value={net_irr}
            type='text'
            keyDownFunc={handleKeyDown}
          />
          {errors.net_irr && <ErrorMessage error='This field is required' />}
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} className='my-3'>
        <Grid item xs={6}>
          {investmentStatsArr.length > 1 && (
            <MuiButton
              buttonClick={() => deleteFromList(index)}
              color='primary'
              variant='contained'
              className='mt-3'
            >
              Remove
            </MuiButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InvestmentStatsFields;
