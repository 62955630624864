export const columns = [
  {
    id: 'rowAction',
    label: '',
    needsFormatter: false,
    width: '6%',
  },
  {
    id: 'title',
    label: 'DOCUMENT TYPE',
    needsFormatter: false,
    isSelect: true,
    cellClassName: 'font-wt-500',
    width: '30%',
  },
  {
    id: 'docName',
    label: 'FILE NAME',
    needsFormatter: false,
    cellClassName: 'grey',
    width: '44%',
  },
  {
    id: 'docAction',
    label: 'ACTIONS',
    needsFormatter: false,
    width: '20%',
  },
];