import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useState, useEffect } from 'react';

import { WireTransfferBankDetails } from 'store/investor/types';

type Props = {
  wireTransfferBank: WireTransfferBankDetails;
  index: number;
  handleModalClick: any;
};

const WireTransfferBank = ({ wireTransfferBank, index, handleModalClick }: Props) => {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    if (wireTransfferBank) {
      if (
        wireTransfferBank?.bankName ||
        wireTransfferBank?.bankAddress ||
        wireTransfferBank?.bankAccountName ||
        wireTransfferBank?.bankAccountNumber ||
        wireTransfferBank?.swiftNumber ||
        wireTransfferBank?.behalfOf
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, [wireTransfferBank]);
  console.log(wireTransfferBank);

  return (
    <section style={{ display: `${showSection ? 'block' : 'none'}` }}>
      <h3>
        {index > 0 && 'Additional'} Wire Transfer Bank
        <MuiButton
          className='ml-2 py-0'
          buttonClick={() => {
            handleModalClick();
          }}
        >
          Edit
        </MuiButton>
      </h3>
      <Divider />
      {wireTransfferBank?.bankName && (
        <Grid item container>
          <Grid item xs={5}>
            Bank Name
          </Grid>
          <Grid item xs={7}>
            <p>{wireTransfferBank?.bankName ? wireTransfferBank?.bankName : '--'}</p>
          </Grid>
        </Grid>
      )}
      {wireTransfferBank?.bankAddress && (
        <Grid item container>
          <Grid item xs={5}>
            Bank Address
          </Grid>
          <Grid item xs={7}>
            <p>{wireTransfferBank?.bankAddress ? wireTransfferBank?.bankAddress : '--'}</p>
          </Grid>
        </Grid>
      )}
      {wireTransfferBank?.bankAccountName && (
        <Grid item container>
          <Grid item xs={5}>
            Account Name
          </Grid>
          <Grid item xs={7}>
            <p>{wireTransfferBank?.bankAccountName ? wireTransfferBank?.bankAccountName : '--'}</p>
          </Grid>
        </Grid>
      )}
      {wireTransfferBank?.bankAccountNumber && (
        <Grid item container>
          <Grid item xs={5}>
            Email
          </Grid>
          <Grid item xs={7}>
            <p>
              {wireTransfferBank?.bankAccountNumber ? wireTransfferBank?.bankAccountNumber : '--'}
            </p>
          </Grid>
        </Grid>
      )}
      {wireTransfferBank?.swiftNumber && (
        <Grid item container>
          <Grid item xs={5}>
            ABA/ CHIPS or SWIFT number
          </Grid>
          <Grid item xs={7}>
            <p>{wireTransfferBank?.swiftNumber ? wireTransfferBank?.swiftNumber : '--'}</p>
          </Grid>
        </Grid>
      )}
      {wireTransfferBank?.behalfOf && (
        <Grid item container>
          <Grid item xs={5}>
            For Benefit of (FBO)
          </Grid>
          <Grid item xs={7}>
            <p>{wireTransfferBank?.behalfOf ? wireTransfferBank?.behalfOf : '--'}</p>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12} className='btnGroup'>
        <MuiButton>Add Account Holder</MuiButton>
        <MuiButton className='ml-3'>Duplicate Account Holder</MuiButton>
      </Grid> */}
    </section>
  );
};

export default WireTransfferBank;
