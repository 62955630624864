import { SelectionMapper } from './SelectionMapper';
import { useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/webviewer';
import { Wrapper } from './Styles';
import MuiButton from 'common/components/button';
import LoadingButton from 'common/components/button/LoadingButton';

type Props = {
  docUrl: string;
  documentDetails: any;
  dropdownDetails: any;
  mappingDetails: any;
  mapFieldNameToKey: any;
  handleOnChange: any;
  generatePreSignedUrl: any;
  setUploadedFileData: any;
  dropdownMap: any;
  setSaveAndExit: any;
  isGeneratePreSignedUrlLoading: boolean;
};

const PrefillDoc = ({
  docUrl,
  documentDetails,
  dropdownDetails,
  mappingDetails,
  mapFieldNameToKey,
  handleOnChange,
  generatePreSignedUrl,
  setUploadedFileData,
  dropdownMap,
  setSaveAndExit,
  isGeneratePreSignedUrlLoading,
}: Props) => {
  const viewer = useRef(null);
  const [formItems, setFormItems] = useState<Array<any>>([]);
  const [ctaClicked, setCtaClicked] = useState<string>('');

  useEffect(() => {
    if (docUrl) {
      WebViewer(
        {
          path: '/webviewer/lib',
          initialDoc: docUrl,
          fullAPI: true,
        },
        (viewer as any).current,
      ).then((instance) => {
        const { annotationManager, documentViewer } = instance.Core;
        const toolNames = instance.Core.Tools.ToolNames;

        const style = instance.UI.iframeWindow.document.documentElement.style;
        style.setProperty(`--view-header-background`, '#fafafa');
        style.setProperty(`--tools-header-background`, '#EBEBEB');
        style.setProperty(`--document-background-color`, '#DDD');

        instance.UI.setToolbarGroup('toolbarGroup-Forms');
        instance.UI.disableElements([
          'leftPanel',
          'leftPanelButton',
          'viewControls',
          'toolbarGroup-Insert',
          'toolbarGroup-Edit',
          'toolbarGroup-Shapes',
          'toolbarGroup-FillAndSign',
          'signatureFieldToolGroupButton',
          'radioButtonFieldToolGroupButton',
          'listBoxFieldToolGroupButton',
          'comboBoxFieldToolGroupButton',
          'toolsOverlay',
          'viewControlsButton',
          'toolbarGroup-Annotate',
          'toolbarGroup-View',
        ]);

        //LIST FORM ITEMS
        annotationManager.addEventListener('annotationChanged', () =>
          setFormItems(
            annotationManager
              .getAnnotationsList()
              .filter((annot) => annot.Subject === 'Widget')
              .map((annot: any) => ({ name: annot.Ua.qd, type: annot.Ua.yr })),
          ),
        );

        //ADD TEXT BOX
        (document as any).getElementById('addText').addEventListener('click', () => {
          instance.UI.setToolbarGroup('toolbarGroup-Forms');
          instance.UI.setToolMode(toolNames.TEXT_FORM_FIELD);
        });

        //ADD CHECKBOX
        (document as any).getElementById('addCheckbox').addEventListener('click', () => {
          instance.UI.setToolbarGroup('toolbarGroup-Forms');
          instance.UI.setToolMode(toolNames.CHECK_BOX_FIELD);
        });

        //UPLOAD
        (document as any).getElementById('upload').addEventListener('click', async () => {
          const doc = documentViewer.getDocument();
          const xfdfString = await annotationManager.exportAnnotations();
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          });
          const arr = await new Uint8Array(data);
          const blob = await new Blob([arr], { type: 'application/pdf' });
          setUploadedFileData(blob);
          await generatePreSignedUrl({ object_key: documentDetails.docLink });
        });

        (document as any).getElementById('upload2').addEventListener('click', async () => {
          const doc = documentViewer.getDocument();
          const xfdfString = await annotationManager.exportAnnotations();
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          });
          const arr = await new Uint8Array(data);
          const blob = await new Blob([arr], { type: 'application/pdf' });
          setUploadedFileData(blob);
          await generatePreSignedUrl({ object_key: documentDetails.docLink });
        });

        // Customisation example
        //
        // (Annotations as any).WidgetAnnotation.getCustomStyles = (widget: any) => {
        //   if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
        //     return {
        //       'background-color': 'blue',
        //       color: 'white',
        //       opacity: 0.8,
        //     };
        //   }
        // };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docUrl]);

  const handleClick = () => {
    setCtaClicked('Save');
    mapFieldNameToKey(mappingDetails);
  };

  const handleSaveExit = () => {
    setCtaClicked('SaveAndExit');
    setSaveAndExit(true);
    mapFieldNameToKey(mappingDetails);
  };

  return (
    <Wrapper className='m-header'>
      <aside className='sidebar'>
        <div>
          <h3 className='mb-1'>{documentDetails?.docName}</h3>
          <h6>Doc Type - {documentDetails?.docClassificationName}</h6>
          <p className='field-use mb-3'>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit enim omnis tempore.
          </p>
          <MuiButton variant='outlined' id='addText' className='mb-3' fullWidth>
            Add a text field
          </MuiButton>{' '}
          <MuiButton variant='outlined' id='addCheckbox' className='mb-5' fullWidth>
            Add a checkbox field
          </MuiButton>
          <h3>Added Fields</h3>
          {formItems.length < 1 ? (
            <>
              <p className='field-use'>
                Please use the above button to create fields on the pdf and click on apply fields to
                get your fields.
              </p>
            </>
          ) : (
            <>
              <p className='field-use'>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit enim omnis
                tempore.
              </p>
              <section className='addedFields'>
                {formItems.map((item, index) => (
                  <SelectionMapper
                    key={`item-${index}-${item.name}`}
                    index={index}
                    name={item.name}
                    type={item.type}
                    dropdownDetails={dropdownDetails}
                    handleOnChange={handleOnChange}
                    initialValue={
                      dropdownMap.find((s: any) => s.pdf_field_name === item.name)?.tag_key_name
                    }
                  />
                ))}
              </section>
            </>
          )}
        </div>
        <div>
          {ctaClicked === 'Save' && isGeneratePreSignedUrlLoading ? (
            <LoadingButton fullWidth className='mb-2' />
          ) : (
            <MuiButton
              variant='contained'
              id='upload'
              className='mb-2'
              buttonClick={() => handleClick()}
              fullWidth
            >
              Save
            </MuiButton>
          )}
          {ctaClicked === 'SaveAndExit' && isGeneratePreSignedUrlLoading ? (
            <LoadingButton fullWidth />
          ) : (
            <MuiButton
              variant='contained'
              id='upload2'
              buttonClick={() => handleSaveExit()}
              fullWidth
            >
              Save & Exit
            </MuiButton>
          )}
        </div>
      </aside>
      <div className='webviewer' ref={viewer}></div>
    </Wrapper>
  );
};

export default PrefillDoc;
