export const ELIGIBILITY_TOOLTIP_CONTENT = (
  <>
    <h4 className='mb-2'>Accredited Investor (AI)</h4>
    <ul>
      <li>
        Individual with gross income exceeding $200,000 in each of the two most recent years or
        joint income with a spouse or partner exceeding $300,000 for those years and a reasonable
        expectation of the same income level in the current year
      </li>
      <li>
        Individual with net worth over $1 million, either alone or together with a spouse or spousal
        equivalent (excluding the value of the person's primary residence).
      </li>
      <li>
        Any trust, with total <strong>assets</strong> in excess of $5 million, not formed
        specifically to purchase the subject securities, whose purchase is directed by a
        sophisticated person
      </li>
      <li>
        Entity with total <strong>investments</strong> in excess of $5 million, not formed to
        specifically purchase the subject securities, <strong>OR</strong>
      </li>
      <li>Any entity in which all of the equity owners are accredited investors.</li>
    </ul>
    <h4 className='mb-2'>Qualified Purchaser (QP)</h4>
    <ul>
      <li>
        A person or a family business holding an investment portfolio with a value of $5 million or
        more. Elements of the portfolio in question may not include a primary residence, nor
        property used in the normal conduct of business.
      </li>
    </ul>
  </>
);

export const LOCKUP_TOOLTIP_CONTENT =
  'Period when investors are not allowed to redeem or sell shares of a particular investment. ';

export const SINCE_INCEPTION_TOOLTIP_CONTENT = 'Annualized return since inception of the fund';

export const LIQUIDITY_TOOLTIP_CONTENT =
  'Determines how often a client can redeem from a fund and the notice period required e.g. monthly redemption with 30 days notice.';

export const DPI_TOOLTIP_CONTENT = `Distribution to Paid-In represents the ratio of capital returned to investors to a fund's capital calls at the valuation date. DPI reflects the realized, cash-on-cash returns generated by its investments at the valuation date.`;

export const TVPI_TOOLTIP_CONTENT = `'Total Value to Paid In is the ratio of the total value of the fund (realized and unrealized gains), divided by the capital paid in.'`;

export const IRR_TOOLTIP_CONTENT = `The dollar-weighted internal rate of return, net of management fees and carried interest generated by an investment in the fund.`;

export const PREFERRED_RETURN_TOOLTIP_CONTENT = (
  <span>
    The preferred return, or hurdle rate, is basically{' '}
    <strong>
      a minimum annual return that the limited partners are entitled to before the general partners
      may begin receiving carried interest.
    </strong>
  </span>
);

export const GP_CATCHUP_TERM_TOOLTIP_CONTENT = `Commonly used as a means for a fund manager to earn a fee equal to a percentage of the profit but only after the investor has received back its investment and earned a preferred return.  After this it could be an 80%/20% split between the LP and the GP.`;

export const FUNDING_TYPE_TOOLTIP_CONTENT = `An investment in a fund may be funded upfront (i.e. “full investment”), or via a capital call schedule. Capital call are issued by the Fund's General Partner, and are capped at the amount the LP commits.`;

export const SUGGEST_ALLLOCATIONS_TOOLTIP_CONTENT = (
  <p className='mb-0'>
    Suggested allocation for each order is equal to the allocation requested if total capacity
    available is greater than total allocations requested.
    <br />
    <br />
    Suggested allocation for each order is pro rated if total capacity available is lower than total
    total allocations requested.
  </p>
);

export const NATURAL_PERSONS_TOOLTIP_CONTENT = (
  <ul className='mb-0'>
    <li>Individual</li>
    <li>Individual Retirement Account of the Subscriber</li>
    <li>Joint with rights of survivorship</li>
    <li>Tenants in common</li>
    <li>Individual Retirement Account</li>
  </ul>
);

export const ENTITIES_TOOLTIP_CONTENT = (
  <ul className='mb-0'>
    <li>Corporation</li>
    <li>Employee Benefit Plan</li>
    <li>Endowment</li>
    <li>Foundation</li>
    <li>Limited Liability Company</li>
    <li>Keogh Plan</li>
    <li>Partnership</li>
  </ul>
);

export const HYBRIDS_TOOLTIP_CONTENT = (
  <ul className='mb-0'>
    <li>Other</li>
    <li>Trust</li>
  </ul>
);

export const FUNDS_SIMILAR_TO_TOOLTIP_CONTENT = (
  <>
    <p>Similarity is determined using a 4-factor model</p>
    <ol className='mb-0'>
      <li>Market impact</li>
      <li>Expected return</li>
      <li>Income</li>
      <li>ESG Impact</li>
    </ol>
  </>
);

export const ORDER_DOCS_PREFILL_TOOLTIP_CONTENT = `This document has been pre-filled with Client information. Update Client's Profile to take advantage of pre-filling.`;

export const FUND_FLOW_DOC_EDIT_TOOLTIP_CONTENT = `Create pre-fillable version`;
