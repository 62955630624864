import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { KeyboardArrowDown, Search } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Grid, MenuItem } from '@mui/material';

const StyledWrapper = styled(Box)`
  padding-top: 8px;
  .MuiSelect-root {
    position: relative;
  }
  .MuiOutlinedInput-notchedOutline {
    border-radius: 0.5rem;
    border-color: var(--s7);
  }
  .MuiSelect-outlined {
    padding: 10px 18px;
    font-size: 13px;
    background-color: var(--s7);
    &:hover .MuiOutlinedInput-notchedOutline,
    &:active .MuiOutlinedInput-notchedOutline {
      border-color: #b3d1ed !important;
    }
  }

  .staticIcon {
    transform: unset;
  }

  .placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    pointer-events: none;
    color: var(--s30);
    font-size: 14px;
  }

  &.adminSelect {
    padding-top: unset;
    .MuiInputBase-root {
      color: #525863;
      font-size: 11px;
      background-color: white;
    }
    .MuiOutlinedInput-notchedOutline {
      border-radius: 0.5rem;
      border-color: transparent;
    }
    .MuiMenuItem-root {
      font-size: 11px;
    }
    .placeholder {
      font-size: 11px;
    }
  }
`;

const SubHeader = styled(Grid)`
  box-sizing: border-box;
  line-height: 48px;
  list-style: none;
  color: #828282;
  font-weight: 500;
  font-size: 0.875rem;
  height: 40px;
  margin-left: 16px;
  margin-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #dbdbdb;
  background-color: #fff;
  margin-bottom: 10px;
`;

type Props = {
  label?: string;
  isLoading?: boolean;
  value?: any;
  errorMessage?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  fullWidth?: boolean;
  margin?: 'none' | 'dense' | 'normal';
  size?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  optionName?: string;
  className?: string;
  register?: any;
  name?: string;
  defaultValue?: any;
  setSelectedValue?: any;
  required?: boolean;
  placeholder?: string;
  searchIcon?: boolean;
  MenuProps?: any;
  multiple?: boolean;
  statusOptions: any;
};

export default function GroupSelectField({
  label,
  value,
  variant,
  isLoading,
  name,
  register,
  disabled,
  defaultValue,
  setSelectedValue,
  required,
  placeholder = 'Select',
  searchIcon = true,
  className = '',
  MenuProps,
  multiple,
  statusOptions,
}: Props) {
  // This function will set the selected option object
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
  };

  // Its renders the options of 1st category by default but second category options and the heading above it only gets redered if present

  return (
    <StyledWrapper sx={{ minWidth: 120, pt: 1 }} className={className}>
      {label && <h4 className='font-wt-400 mb-2'>{label}</h4>}
      <FormControl fullWidth>
        <Select
          key={value}
          disabled={disabled}
          onChange={handleChange}
          IconComponent={searchIcon ? Search : KeyboardArrowDown}
          value={value}
          defaultValue={defaultValue && defaultValue}
          inputRef={register && register(name, { required })}
          MenuProps={{ ...MenuProps, disableScrollLock: true }}
          multiple={multiple}
          classes={{ iconOpen: searchIcon ? 'staticIcon' : '' }}
        >
          {statusOptions[0].options.map((item: any) => (
            <MenuItem value={item.value}>{item.lable}</MenuItem>
          ))}
          {statusOptions[1] && (
            <SubHeader>
              <span>ACTIVE</span>
            </SubHeader>
          )}
          {statusOptions[1] &&
            statusOptions[1].options.map((item: any) => (
              <MenuItem value={item.value}>{item.lable}</MenuItem>
            ))}
        </Select>
        {!value && !defaultValue && <div className='placeholder'>{placeholder}</div>}
      </FormControl>
    </StyledWrapper>
  );
}
