import MuiModal from 'common/components/modal';
import styled from '@emotion/styled';
import InvestmentDocumentForm from 'components/investments/investmentForms/InvestmentDocumentForm';

type Props = {
  isModalOpen: boolean;
  handleClose: any;
  buttonClick?: any;
  id: string;
};

const StyledModal = styled(MuiModal)`
  .MuiDialogTitle-root {
    display: none;
  }
`;

const InvestmentDocModal = ({ isModalOpen, handleClose, id }: Props) => {
  return (
    <StyledModal
      title=''
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      maxWidth='lg'
      disableCloseIcon
    >
      <InvestmentDocumentForm
        handleSubmitBtnClick={() => {}}
        handleBackBtnClick={handleClose}
        handleClose={handleClose}
        editing={true}
        id={id}
      />
    </StyledModal>
  );
};

export default InvestmentDocModal;
