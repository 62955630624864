export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '/',
    label: 'Organization',
  },
];

export const COLUMNS = [
  {
    id: 'orgType',
    label: 'Type',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'organisation_name',
    label: 'Name',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'key_contact_name',
    label: 'Key Contact',
    needsFormatter: false,
    width: '12%',
  },
  {
    id: 'administrator_name',
    label: 'Administrator',
    needsFormatter: false,
    width: '12%',
  },
  {
    id: 'status',
    label: 'Status',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'initiated_by',
    label: 'Initiated By',
    needsFormatter: false,
    width: '12%',
  },
  {
    id: 'behalf_of',
    label: 'Behalf of',
    needsFormatter: false,
    width: '12%',
  },
  {
    id: 'last_updated_date_formatted',
    label: 'Last Updated',
    needsFormatter: false,
    width: '12%',
  },
];
