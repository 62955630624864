import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import Textbox from 'common/components/inputField/Textbox';
import { CustodianType } from 'store/investor/types';
interface Props {
  additional?: boolean;
  register: any;
  errors: any;
  index: number;
  custodianDetails?: CustodianType;
  onCustodianChange: (property: string, idx: number, value: any) => void;
  onCustodianRemove: (index: number) => void;
  handleAddCustodian?: any;
  detailsState?: any;
  setDetailsState?: any;
}

const Custodian = ({
  additional = false,
  register,
  errors,
  index,
  custodianDetails,
  onCustodianChange,
  onCustodianRemove,
  handleAddCustodian,
  detailsState,
  setDetailsState,
}: Props) => {
  return custodianDetails ? (
    <Grid container className='my-5'>
      {additional ? (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Additional Custodian Details</h3>
          <MuiButton variant='text' className='p-0' buttonClick={() => onCustodianRemove(index)}>
            Remove
          </MuiButton>
        </Grid>
      ) : (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Custodian Details</h3>
          <MuiButton variant='text' className='p-0' buttonClick={() => onCustodianRemove(index)}>
            Remove
          </MuiButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <h4 className='font-wt-400 mb-0'>Firm Name</h4>
        <Textbox
          type='text'
          register={register}
          name={`firmNameC${index}`}
          value={custodianDetails.firmName}
          required={true}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCustodianChange('firmName', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Account Name</h4>
        <Textbox
          type='text'
          register={register}
          name={`accountNameC${index}`}
          required={true}
          fullWidth
          value={custodianDetails.accountName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCustodianChange('accountName', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Account Number</h4>
        <Textbox
          type='text'
          register={register}
          name={`accountNumberC${index}`}
          value={custodianDetails.accountNumber}
          required={true}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCustodianChange('accountNumber', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>For Benefit of (FBO)</h4>
        <Textbox
          type='text'
          register={register}
          value={custodianDetails.behalfOf}
          name={`behalfOfC${index}`}
          required={true}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCustodianChange('behalfOf', index, e.target.value)
          }
        />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Custodian Details</h3>
        </Grid>
        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-0'>Firm Name</h4>
          <Textbox
            type='text'
            register={register}
            name={`firmNameC${index}`}
            value={detailsState.firmName}
            required={true}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, firmName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Account Name</h4>
          <Textbox
            type='text'
            register={register}
            name={`accountNameC${index}`}
            required={true}
            fullWidth
            value={detailsState.accountName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, accountName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Account Number</h4>
          <Textbox
            type='text'
            register={register}
            name={`accountNumberC${index}`}
            value={detailsState.accountNumber}
            required={true}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, accountNumber: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>For Benefit of (FBO)</h4>
          <Textbox
            type='text'
            register={register}
            value={detailsState.behalfOf}
            name={`behalfOfC${index}`}
            required={true}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, behalfOf: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <MuiButton
        variant='outlined'
        className='mt-2'
        buttonClick={() => handleAddCustodian({ ...detailsState })}
      >
        Add
      </MuiButton>
    </>
  );
};

export default Custodian;
