import { Avatar, Container, Grid } from '@mui/material';
import styled from '@emotion/styled';
import MuiButton from 'common/components/button/index';
import { useNavigate } from 'react-router-dom';
import { formatAumValues } from 'helpers';
import MuiCard from 'common/components/card';
import { ChevronRight } from '@mui/icons-material';

const StyledHero = styled.div`
  background-color: white;
  padding: 7rem 0;
  margin-top: 4rem;

  .MuiContainer-root {
    padding: 0 8rem;
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #474747;
    &.mb-0 {
      margin-bottom: 0;
    }
  }

  p {
    width: 150px;
    font-size: 11px;
    margin-bottom: 0;
    line-height: 16px;
    padding-left: 0.5rem;
    &.mainDesc {
      font-size: 13px;
      max-width: 36ch;
      line-height: 20px;
      padding-left: 0;
      width: unset;
      color: #333333;
    }
  }

  p,
  h4 {
    color: #525863;
  }

  h4 {
    font-size: 14px;
    margin-bottom: 0;
    &.mb-12 {
      margin-bottom: 12px;
    }
  }

  button {
    width: 150px;
  }

  .client {
    padding: 5px;
    border-radius: 0.5rem;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;

    &:hover {
      background-color: #ebebeb;
    }
  }

  .client + .client {
    margin-top: 12px;
  }

  .MuiAvatar-root {
    border-radius: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    background-color: var(--s15);
    color: var(--s50);
    margin-right: 0.5rem;
    width: 28px;
    height: 27px;
  }

  button {
    border-radius: 6px;
    padding: 8px;
  }

  .subtitle {
    font-size: 13px;
    font-weight: 600;
    color: var(--s40);
    line-height: 1rem;
  }

  .mb-12 {
    margin-bottom: 0.75rem;
  }
`;

interface Props {
  name: string;
  totalClients: number;
  totalAum: number;
  userName: string;
  clients: Array<any>;
}

const Hero = ({ name, totalClients, totalAum, userName, clients = [] }: Props) => {
  const navigate = useNavigate();

  return (
    <StyledHero>
      <Container maxWidth='lg'>
        <Grid container alignItems='center'>
          <Grid item xs={5.25}>
            <h2>
              Hello,
              <br /> {name}
            </h2>
            <p className='mainDesc'>
              Welcome to your dashboard. Start here to explore investments and place subscriptions.
            </p>
          </Grid>
          <Grid item xs={6.75} container className={clients?.length === 0 ? 'flex-center-end' : ''}>
            <Grid item xs={6.55}>
              <MuiCard minHeight='0' sx={{ px: 2, py: '12px', mb: '25px' }}>
                <Grid container>
                  <Grid item xs={12} className='flex-start-between' mb={1}>
                    <span className='mt-1 subtitle'>CLIENTS</span>
                    <p>Add Clients, view holdings and more</p>
                  </Grid>
                  <Grid item xs={12} className='flex-start-between'>
                    <h2 className='mb-0'>{totalClients ? totalClients : '--'}</h2>
                    <MuiButton
                      variant='contained'
                      fullWidth
                      minWidth='150px'
                      buttonClick={() => navigate('/clients')}
                    >
                      Clients
                    </MuiButton>
                  </Grid>
                </Grid>
              </MuiCard>

              <MuiCard minHeight='0' sx={{ px: 2, py: '12px' }}>
                <Grid container>
                  <Grid item xs={12} className='flex-start-between' mb={1}>
                    <span className='mt-1 subtitle'>NAV</span>
                    <p>Explore investments and place subscriptions</p>
                  </Grid>
                  <Grid item xs={12} className='flex-start-between'>
                    <h2 className='mb-0'>{totalAum ? formatAumValues(totalAum, 0) : '--'}</h2>
                    <MuiButton
                      variant='contained'
                      fullWidth
                      buttonClick={() => navigate('/investments')}
                    >
                      Investments
                    </MuiButton>
                  </Grid>
                </Grid>
              </MuiCard>
            </Grid>

            {clients?.length > 0 && (
              <Grid item xs={5.45} className='d-flex'>
                <MuiCard
                  minHeight='0'
                  sx={{ px: 2, pt: '12px', pb: '13px', width: '100%', ml: '24px' }}
                >
                  <span className='subtitle text-center w-100 mb-12'>ACTIVE CLIENTS</span>
                  {clients.slice(0, 4).map((client: any, i: number) => (
                    <div
                      className='client cursor-pointer mt-2'
                      key={`activeClient${i}`}
                      onClick={() => navigate(`/client/${client.investorId}`)}
                    >
                      <Avatar variant='rounded'>
                        {client?.accountName ? client?.accountName[0] : '-'}
                      </Avatar>
                      {client?.accountName
                        ? client?.accountName.length > 14
                          ? client.accountName.substring(0, 9) +
                            '...' +
                            client.accountName.substring(
                              client?.accountName.length - 4,
                              client?.accountName.length,
                            )
                          : client?.accountName
                        : '--'}
                      <ChevronRight fontSize='small' sx={{ ml: 'auto' }} />
                    </div>
                  ))}
                </MuiCard>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </StyledHero>
  );
};

export default Hero;
