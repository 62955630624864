import styled from '@emotion/styled';
import { ChevronRight } from '@mui/icons-material';
import { trackPdfDownload } from 'analytics';
import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import Loading from 'common/components/Loading';
import ViewDocumentModal from 'common/components/modal/ViewDocumentModal';
import { DocumentOwnerType, QueryType } from 'common/types';
import AddOrderDocumentModal from 'components/modals/AddOrderDocumentModal';
import { encryptDocumentUrl } from 'helpers';
import {
  downloadAllDocuments,
  generateDataForDocumentUpload,
  generateDocumentRelatedNames,
} from 'helpers/document';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import OrderDetailsHeader from './OrderDetailsHeader';
import CancelRejectOrderModal from 'components/modals/CancelRejectOrderModal';
import { UserProfile } from 'store/user/types';
import OrderSuccessModal from 'components/modals/orders/OrderSuccessModal';
import { selectOrderSubmittedModalOpen } from 'store/order/selectors';
import { setOrderSubmittedModalOpen } from 'store/order/slice';
import OrderDocument from './OrderDocument';
import OrderDocumentTemp from './OrderDocumentTemp';

const StyledGrid = styled(Grid)`
  h3.title {
    font-size: 22px;
    color: var(--s50);
    margin-bottom: 16px;
    font-weight: 500;
  }
  .allocTooltip {
    font-size: 1rem;
    position: absolute;
    top: -2px;
    right: -1.25rem;
  }
`;

const StyledWrapper = styled.div`
  p {
    color: #525863;
    font-size: 13px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const id: string = location.pathname.split('/')[2];
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const orderSubmittedModalOpen: boolean | null = useAppSelector(selectOrderSubmittedModalOpen);
  const [orderDocuments, setOrderDocuments] = useState([]);
  const [documentKey, setDocumentKey] = useState<{ object_key: string } | null>(null);
  const [documentData, setDocumentData] = useState<Object | null>({});
  const [newDocDescription, setNewDocDescription] = useState('');
  const [viewDocModalOpen, setViewDocModalOpen] = useState<boolean>(false);
  const [viewDoc, setViewDoc] = useState<any>(null);
  const [isOrderDocumentsModalOpen, setIsOrderDocumentsModalOpen] = useState<boolean>(false);
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [orderStatus, setOrderStatus] = useState<string | null>(null);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [isDocUploaded, setIsDocUploaded] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [cancelRejectOrderComment, setCancelRejectOrderComment] = useState<string>('');
  const [cancelRejectOrder, setCancelRejectOrder] = useState<{ open: boolean; isCancel: boolean }>({
    open: false,
    isCancel: false,
  });
  const [orderApprovedModalOpen, setOrderApprovedModalOpen] = useState<boolean>(false);
  const [qualisApprovedModalOpen, setQualisApprovedModalOpen] = useState<boolean>(false);

  const [ctaClicked, setCtaClicked] = useState<string>('');

  const { data, isLoading, error, refetch } = useReactQuery(
    [`clientOrderDetails${id}`],
    {
      url: `qaip/v1/ordermanagement/order?orderId=${id}`,
    },
    {
      refetchOnMount: true,
    },
  ) as any;

  const { data: newDocuments, refetch: refetchNewOrderDocs } = useReactQuery(
    [`newOrderDoc${user?.userId}`],
    {
      url: `qaip/v1/documentmanagement/masterchecklist`,
      params: {
        type: 'order',
        investorId: data?.investorId,
        orderId: id,
      },
    },
    {
      enabled: !!data && !!id,
      refetchOnMount: true,
    },
  );
  const { data: oldDocuments = [], refetch: refetchOldOrderDocs } = useReactQuery(
    [`oldOrderDoc${user?.userId}`],
    {
      url: `qaip/v1/documentmanagement/getList/order`,
      params: {
        docType: 'file',
        order_id: id,
      },
    },
    {
      enabled: !!id,
      refetchOnMount: true,
    },
  );

  const {
    mutate: fetchPresignedUrl,
    isLoading: isFetchingPresignedUrl,
    isSuccess: fetchedPresignedUrl,
  } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
      params: documentKey,
    },
    {
      onSuccess: async (data) => {
        await encryptDocumentUrl(data.url, uploadedFileData);
        await updateDocumentData();
      },
    },
  );

  const { mutate: updateDocumentData, isSuccess: updatedDocumentData } = useMutationQuery(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      params: { documentData: documentData },
      isFormData: true,
    },
    {
      onSuccess: (res) => {
        setIsDocUploaded(true);
        setViewDoc(res);
        refetchNewOrderDocs();
        refetchOldOrderDocs();
      },
    },
  );

  const { refetch: deleteDocument } = useReactQuery(
    ['deleteDocument'],
    {
      url: `qaip/v1/documentmanagement/remove?documentId=${
        viewDoc?.documentId ?? viewDoc?.documentsResponseDto[0]?.documentId
      }`,
      method: QueryType.DELETE,
    },
    {
      enabled: false,
      onSuccess: () => {
        setNewDocDescription('');
        setIsDocUploaded(false);
        setUploadedFileData(null);
        setViewDocModalOpen(false);
        refetchNewOrderDocs();
        refetchOldOrderDocs();
        refetch();
      },
    },
  );

  const {
    refetch: modifyOrder,
    isLoading: isUpdating,
    isRefetching: isUpdatingRefetching,
  } = useReactQuery(
    ['modifyOrder'],
    {
      url: 'qaip/v1/ordermanagement/update',
      params: [
        {
          orderId: id,
          status: orderStatus,
          cancelReason: cancelReason,
          comments: cancelRejectOrderComment,
          amount: null,
          units: 0,
          eventDate: new Date(),
        },
      ],
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        if (Number(orderStatus) === 4 && data?.orderEventId === 3) {
          setOrderApprovedModalOpen(true);
        } else if (Number(orderStatus) === 3 && data?.orderEventId !== 3) {
          setQualisApprovedModalOpen(true);
        }
        setCancelReason('');
        setCancelRejectOrderComment('');
        setCancelRejectOrder({ ...cancelRejectOrder, open: false });
        navigate(`/order/${id}`);
        refetch();
      },
    },
  );

  useEffect(() => {
    if (orderStatus) modifyOrder();
    // eslint-disable-next-line
  }, [orderStatus]);

  const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocUploaded(false);
    const uploadedFile: any = event.target.files;
    setUploadedFileData(uploadedFile[0]);
    const docRelatedNames = await generateDocumentRelatedNames(uploadedFile);
    const docData = await generateDataForDocumentUpload({
      uploadedFileName: docRelatedNames.uploadedFileName,
      formattedFileName: docRelatedNames.formattedFileName,
      id: data.fundId,
      documentType: DocumentOwnerType.ORDER,
      docType: 'file',
      status: 'Active',
      isOrder: true,
      orderId: Number(id),
    });
    setDocumentKey(docData.uploaddocumentKey);
    setDocumentData({
      ...docData.documentData,
      doc_classification_name: newDocDescription,
      doc_category_type_id: 3,
      order_id: data.orderId,
    });
    fetchPresignedUrl();
  };

  if (isLoading) return <Loading />;
  if (error) return <div>Error!</div>;

  const handleModalButtonClick = () => {
    setIsOrderDocumentsModalOpen(!isOrderDocumentsModalOpen);
    setUploadedFileData(null);
    setIsDocUploaded(false);
    setNewDocDescription('');
  };

  const handleDocumentFetch = () => {
    const subscription = newDocuments?.subscription
      ? newDocuments?.subscription.filter((doc: any) => doc?.documentsResponseDto.length > 0)
      : [];
    const investor = newDocuments?.investor
      ? newDocuments?.investor.filter((doc: any) => doc?.documentsResponseDto.length > 0)
      : [];
    const orderDocs = [...investor, ...subscription];
    return orderDocs as any;
  };

  return (
    <StyledGrid container sx={{ mt: 8 }}>
      <OrderDetailsHeader
        data={data}
        user={user as UserProfile}
        setOrderStatus={setOrderStatus as any}
        orderDocs={handleDocumentFetch()}
        setCancelRejectOrder={setCancelRejectOrder}
        ctaClicked={ctaClicked}
        setCtaClicked={setCtaClicked}
        isUpdating={isUpdating || isUpdatingRefetching}
      />
      <Container maxWidth='xl' className='container-lr-padding'>
        <Grid item xs={12} container sx={{ pt: '24px' }} columnSpacing={3}>
          <Grid item xs={6}>
                  {data.old && Array.isArray(oldDocuments) && oldDocuments.length > 0 && (
                    <>
                    <h3 className='flex-center-between title'>
                    Documents
                    <MuiButton
                      className='downloadBtn py-0'
                      buttonClick={() => downloadAllDocuments(orderDocuments)}
                    >
                      Download All <ChevronRight fontSize='small' />
                    </MuiButton>
                  </h3>
                  <MuiCard minHeight='unset' sx={{ p: 2, border: 'none' }}>
                    {
                      oldDocuments?.map((doc: any) => (
                        <OrderDocumentTemp
                          document={doc}
                          setDocDetails={setOrderDocuments}
                          ls={orderDocuments}
                          setViewDoc={setViewDoc}
                          setViewDocModalOpen={setViewDocModalOpen}
                          showFileName
                        />
                      ))}
                      
                  </MuiCard></>)}
                  {!data.old && handleDocumentFetch().length > 0 && (
                  <>
                                      <h3 className='flex-center-between title'>
                    Documents
                    <MuiButton
                      className='downloadBtn py-0'
                      buttonClick={() => downloadAllDocuments(orderDocuments)}
                    >
                      Download All <ChevronRight fontSize='small' />
                    </MuiButton>
                  </h3>
                  <MuiCard minHeight='unset' sx={{ p: 2, border: 'none' }}>
                    {React.Children.toArray(
                      handleDocumentFetch()?.map((document: any) => {
                        return (
                          <>
                            <OrderDocument
                              document={document}
                              setDocDetails={setOrderDocuments}
                              ls={orderDocuments}
                              setViewDoc={setViewDoc}
                              setViewDocModalOpen={setViewDocModalOpen}
                              showFileName
                            />
                          </>
                        );
                      }),
                    )}
                  </MuiCard></>)}

                  <ViewDocumentModal
                    isModalOpen={viewDocModalOpen}
                    title={viewDoc?.docClassificationName}
                    handleClose={() => setViewDocModalOpen(false)}
                    docLink={viewDoc?.url}
                    handleDocReuploadClick={() => {}}
                    handleDocDeleteClick={() => deleteDocument()}
                    handleDocDownload={() => {
                      downloadAllDocuments([viewDoc]);
                      trackPdfDownload({
                        fileName: viewDoc?.docName,
                        fileUrl: viewDoc?.url,
                        fundName: data?.fundName,
                      });
                    }}
                    downloadable
                  />
                  <AddOrderDocumentModal
                    isModalOpen={isOrderDocumentsModalOpen}
                    handleModalClick={handleModalButtonClick}
                    onFileUpload={onFileUpload}
                    setValue={setNewDocDescription}
                    uploadedFileData={uploadedFileData}
                    isLoading={isFetchingPresignedUrl}
                    hasSuccessfullyUploaded={fetchedPresignedUrl && updatedDocumentData}
                    handleDocumentDelete={deleteDocument}
                    docName={newDocDescription}
                    isDocUploaded={isDocUploaded}
                  />
          </Grid>
          <Grid item xs={6}>
            {data && data.userComments && data.userComments.length > 0 && (
              <>
                <h3 className='title'>Comments</h3>
                <StyledWrapper>
                  <MuiCard minHeight='unset' sx={{ p: 2, border: 'none' }}>
                    {React.Children.toArray(
                      data.userComments?.map((userComment: any) => (
                        <p>
                          <strong>{userComment.userName}:</strong> {userComment.comment}
                        </p>
                      )),
                    )}
                  </MuiCard>
                </StyledWrapper>
              </>
            )}
          </Grid>
        </Grid>
      </Container>

      <CancelRejectOrderModal
        isModalOpen={cancelRejectOrder.open}
        handleClose={() => setCancelRejectOrder({ ...cancelRejectOrder, open: false })}
        isCancel={cancelRejectOrder.isCancel}
        setCancelReason={setCancelReason}
        setOrderStatus={setOrderStatus}
        setCancelRejectOrderComment={setCancelRejectOrderComment}
        cancelRejectOrderComment={cancelRejectOrderComment}
        ctaClicked={ctaClicked}
        isUpdating={isUpdating || isUpdatingRefetching}
      />
      <OrderSuccessModal
        isModalOpen={orderApprovedModalOpen}
        handleClose={() => setOrderApprovedModalOpen(false)}
        title='Order Approved!'
        message='Your order has been sent to the Fund Manager for approval.'
      />
      <OrderSuccessModal
        isModalOpen={qualisApprovedModalOpen}
        handleClose={() => setQualisApprovedModalOpen(false)}
        title='Order Approved!'
        message='Your order has been sent to Tifin Private Markets for approval.'
      />
      {orderSubmittedModalOpen && (
        <OrderSuccessModal
          isModalOpen={orderSubmittedModalOpen}
          handleClose={() => dispatch(setOrderSubmittedModalOpen(false))}
          title='Order Submitted!'
          message='Your order has been submitted to the approver.'
        />
      )}
    </StyledGrid>
  );
};

export default OrderDetails;
