import { Grid } from '@mui/material';
import MuiModal from 'common/components/modal';
import InputField from 'common/components/inputField/Textbox';
// import SelectField from 'common/components/inputField/SelectField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ErrorMessage } from 'common/components/errorMessageBox';
import UserRoleTeam from 'components/admin/users/UserRoleTeam';
import React from 'react';
import SelectField from 'common/components/inputField/SelectField';
import ButtonSection from './ButtonSection';

type Props = {
  isUserModalOpen: boolean;
  handleModalClick: any;
  rolesData: any[];
  orgId: string;
  existingUserData: any;
  refetchUsers: any;
  teamsData: any[];
  isDG: boolean;
};

const StyledModal = styled(MuiModal)`
  h4 {
    font-size: 14px;
  }
`;

const CreateUpdateUserProfileModal = ({
  isUserModalOpen,
  handleModalClick,
  rolesData,
  orgId,
  existingUserData,
  teamsData,
  isDG,
  refetchUsers,
}: Props) => {
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [teamAndRoleList, setTeamAndRoleList] = useState<any[]>([{}]);
  const [userData, setUserData] = useState<any>(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (isUserModalOpen) {
      const rhfDefaultValues = {
        ...existingUserData,
        phone: existingUserData?.phone?.replace('+', ''),
      };
      if (existingUserData) {
        setTeamAndRoleList(existingUserData?.teamsRolesDetails);
        setSelectedRole(existingUserData?.teamsRolesDetails[0]);
      } else setTeamAndRoleList([{}]);
      reset(rhfDefaultValues);
    }
    // eslint-disable-next-line
  }, [existingUserData, isUserModalOpen]);

  const deleteTeamAndRoleList = (index: number) => {
    if (index > 0) setTeamAndRoleList(teamAndRoleList.filter((x, i) => i !== index));
  };

  const appendToRoleAndTeamList = () => {
    setTeamAndRoleList([...teamAndRoleList, {}]);
  };

  const updateTeamAndRoleList = (value: any, index: number) => {
    const oldTeamAndRoleList = teamAndRoleList;
    oldTeamAndRoleList[index] = { ...value };
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (!existingUserData) {
      setUserData({
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        organisationId: orgId,
        phone: '+' + data.phone,
        teamsRoles: isDG ? teamAndRoleList : [{ teamId: null, roleId: data.role }],
      });
    } else {
      setUserData({
        firstName: data.firstName,
        lastName: data.lastName,
        organisationId: data.organisationId,
        phone: '+' + data.phone,
        userName: data.userName,
        teamsRoles: isDG ? teamAndRoleList : [{ roleId: Number(selectedRole?.roleId) }],
      });
    }
  };

  const handleClose = () => {
    if (isDG && !existingUserData) setTeamAndRoleList([{}]);
    handleModalClick();
    reset();
    setUserData(null);
    setSelectedRole(null);
    setSelectedTeam(null);
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <StyledModal
      title={existingUserData ? 'Update User' : 'Add New User'}
      maxWidth='sm'
      isModalOpen={isUserModalOpen}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={3} mt={2}>
          <Grid item container columnSpacing={3}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>First Name</h4>
              <InputField
                variant='outlined'
                type='text'
                register={register}
                name='firstName'
                fullWidth
                errorMessage='This field is required'
              />
              {errors.firstName && <ErrorMessage error='This field is required' />}
            </Grid>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Last Name</h4>
              <InputField
                variant='outlined'
                type='text'
                register={register}
                name='lastName'
                fullWidth
                errorMessage='This field is required'
              />
              {errors.lastName && <ErrorMessage error='This field is required' />}
            </Grid>
          </Grid>
          <Grid item container columnSpacing={3}>
            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Email</h4>
              <InputField
                variant='outlined'
                type='email'
                register={register}
                name='userName'
                fullWidth
                disabled={!!existingUserData}
                errorMessage='This field is required'
              />
              {errors.userName && <ErrorMessage error='This field is required' />}
            </Grid>

            <Grid item xs={6}>
              <h4 className='font-wt-400 mb-0'>Phone Number (Eg: +12025550100)</h4>
              <InputField
                variant='outlined'
                type='text'
                keyDownFunc={handleKeyDownNumber}
                register={register}
                name='phone'
                minLength={11}
                maxLength={11}
                fullWidth
                errorMessage='Please enter valid phone number.'
                startAdornment={'+'}
              />
              {errors.phone && <ErrorMessage error='Please enter valid phone number.' />}
            </Grid>
          </Grid>
          {isDG ? (
            <>
              {React.Children.toArray(
                teamAndRoleList?.map((data, i) => (
                  <UserRoleTeam
                    register={register}
                    selectedRole={selectedRole}
                    rolesData={rolesData}
                    setValue={setValue}
                    setSelectedRole={setSelectedRole}
                    errors={errors}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    teamsData={teamsData}
                    deleteTeamAndRoleList={deleteTeamAndRoleList}
                    updateTeamAndRoleList={updateTeamAndRoleList}
                    teamAndRoleList={teamAndRoleList}
                    setError={setError}
                    clearErrors={clearErrors}
                    i={i}
                    data={data}
                    appendToRoleAndTeamList={appendToRoleAndTeamList}
                  />
                )),
              )}
              {errors.rolesAndTeams && (
                <div className='mt-3'>
                  <ErrorMessage error='Please remove duplicate combination of Role and Team' />
                </div>
              )}
            </>
          ) : (
            <Grid item xs={6}>
              <SelectField
                name='role'
                label='Role'
                optionName='roleName'
                register={register}
                optionId='roleId'
                value={selectedRole?.roleId}
                setSelectedValue={setSelectedRole}
                options={Array.isArray(rolesData) ? rolesData : []}
                searchIcon={false}
                required={!!!selectedRole}
                onChange={(e) => setValue('role', e.target.value)}
              />
              {errors.role && <ErrorMessage error='This field is required' />}
            </Grid>
          )}
          <ButtonSection
            existingUserData={existingUserData}
            userData={userData}
            reset={reset}
            setUserData={setUserData}
            handleModalClick={handleModalClick}
            handleClose={handleClose}
            refetchUsers={refetchUsers}
          />
        </Grid>
      </form>
    </StyledModal>
  );
};

export default CreateUpdateUserProfileModal;
