import styled from '@emotion/styled';
import { Grid, Skeleton } from '@mui/material';
import MuiCard from 'common/components/card';
import { formatAumValues } from 'helpers';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutationQuery } from 'store/hooks';

interface StyledProps {
  bgColor: string;
  color: string;
}

const StyledWrapper = styled.div<StyledProps>`
  .fundCard {
    padding: 22px 24px;
    min-height: unset;
    cursor: pointer;

    &:hover {
      border-color: var(--p150);
    }

    .closeState {
      /* position: absolute;
      top: 0;
      right: 0; */
      position: relative;
      float: right;
      bottom: 22px;
      right: -24px;
      font-size: 12px;
      padding: 0.25rem 1rem;
      border-radius: 0 0.5rem 0 0.5rem;
      color: ${(props: StyledProps) => props.color};
      background-color: ${(props: StyledProps) => props.bgColor};
    }

    img,
    .MuiSkeleton-root {
      height: 80px;
      max-width: 40%;
      object-fit: contain;
      object-fit: left center;
      margin-bottom: 1rem;
    }
    h3 {
      color: var(--p500);
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    h4 {
      margin-bottom: 0;
    }
  }
`;

const FundCard = (props: any) => {
  const { mutate, data: fundLogo } = useMutationQuery({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
    params: { object_key: props.logo },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (props.logo) mutate();
    // eslint-disable-next-line
  }, []);

  return (
    <StyledWrapper
      bgColor={props.fund_label && !props.fund_label.includes('Next') ? '#cc3b3b' : '#ffecec'}
      color={props.fund_label && !props.fund_label.includes('Next') ? 'white' : '#cc3b3b'}
    >
      <MuiCard className='fundCard' onClick={() => navigate(`/investment/${props.fund_id}`)}>
        {props.fund_label && <div className='closeState'>{props.fund_label}</div>}
        <Grid item xs={12} sx={{ minHeight: '100px' }}>
          {fundLogo ? <img src={fundLogo?.url} alt='' /> : <Skeleton variant='rectangular' />}
        </Grid>
        <Grid item xs={12}>
          <h3>{props.fund_name}</h3>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={4}>
            <h6 className='subtitle'>ASSET CLASS</h6>
            <h4 className='font-wt-400'>
              {props.asset_class ? props.asset_class.split(',')[0] : '--'}
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h6 className='subtitle'>MIN INVESTMENT</h6>
            <h4 className='font-wt-400'>
              {props?.min_investment && '$'}
              {props?.min_investment ? Number(props.min_investment).toLocaleString() : '--'}
            </h4>
          </Grid>
          {props?.returns_since_inception &&
          props?.returns_since_inception['1_year'] &&
          !isNaN(props?.returns_since_inception['1_year']) ? (
            <Grid item xs={4}>
              <h6 className='subtitle'>1YR RETURN</h6>
              <h4 className='font-wt-400'>
                {props?.returns_since_inception && props?.returns_since_inception['1_year']
                  ? props?.returns_since_inception['1_year'] + '%'
                  : '--'}
              </h4>
            </Grid>
          ) : props?.fund_assets && !isNaN(props?.fund_assets) ? (
            <Grid item xs={4}>
              <h6 className='subtitle'>Fund AUM</h6>
              <h4 className='font-wt-400'>
                {props?.fund_assets ? formatAumValues(Number(props?.fund_assets), 0) : '--'}
              </h4>
            </Grid>
          ) : (
            <Grid item xs={4}>
              <h6 className='subtitle'>Target Fund Size</h6>
              <h4 className='font-wt-400'>
                {props?.target_fund_size
                  ? formatAumValues(Number(props?.target_fund_size), 0)
                  : '--'}
              </h4>
            </Grid>
          )}
        </Grid>
      </MuiCard>
    </StyledWrapper>
  );
};

export default FundCard;
