import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import TableSkeleton from '../skeleton/Skeleton';

type Props = {
    length?: number;
}

const TableHeadSkeleton = ({ length }: Props) => {
    return (
        <>
            {
                React.Children.toArray([1, 2, 3].map(() =>
                    <TableRow>
                        {React.Children.toArray(Array(length).fill('').map(() => (
                            <TableCell>
                                <TableSkeleton width="auto" height={40} variant="text" />
                            </TableCell>
                        )))}
                    </TableRow>)
                )}
        </>
    );
};

export default TableHeadSkeleton;
