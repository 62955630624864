import styled from 'styled-components';

const StyledWrapper = styled.div`
  .spinnerDots {
    width: 70px;
    text-align: center;
    margin: 35px auto 0px auto;
  }
  .spinnerDots > div {
    background-color: #0065ff;
    border-radius: 100%;
    display: inline-block;
    width: 14px;
    height: 14px;
    -webkit-animation: rk-bouncedelay 1.4s infinite ease-in-out both;
    animation: rk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .spinnerDots .dots1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinnerDots .dots2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @-webkit-keyframes rk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes rk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const ChatLoader = () => {
  return (
    <StyledWrapper>
      <div className='spinnerDots'>
        <div className='dots1'></div>
        <div className='dots2'></div>
        <div className='dots3'></div>
      </div>
    </StyledWrapper>
  );
};

export default ChatLoader;
