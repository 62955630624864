import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTable from 'common/components/table/Table';
import { entityDropdownList } from 'common/constants';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { COLUMNS, CRUMBS } from './constants';

const Organisation = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data, isLoading } = useReactQuery(
    [`organisation${user?.userId}`],
    {
      url: 'qaip/v1/organizationmanagement/organization',
    },
    {
      refetchOnMount: true,
      enabled: !!user,
    },
  );

  useEffect(() => {
    data &&
      Array.isArray(data) &&
      setRows(
        data.map((org: any) => ({
          ...org,
          orgType: entityDropdownList?.filter((type) => type.id === Number(org.category_id))[0]
            ?.name,
          last_updated_date_formatted: format(
            new Date(org.last_updated_date.replace(/-/g, '/')),
            'MMM dd, yyyy',
          ),
        })),
      );
  }, [data]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = rows;
    rows &&
      rows.length > 0 &&
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  if (isLoading) return <Loading />;
  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Organizations</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                className='mr-20'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <MuiButton
                variant='contained'
                buttonClick={() =>
                  navigate('/admin/organization/add-new-organization', {
                    state: { isAdd: true },
                  })
                }
              >
                Add New Organization
              </MuiButton>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MuiTable
            columns={COLUMNS}
            rows={filteredRows}
            disableHeader
            canRedirect
            redirectURLKey='organisation_id'
            redirectURLPrefix='admin/organization/'
            rowClassName='cursor-pointer'
            isLoading={isLoading}
            rowsPerPage={10}
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Organisation;
