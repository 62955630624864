import { PayloadAction } from '@reduxjs/toolkit';

const reducers = {
  setFundsActiveStep: (state: any, action: PayloadAction<any | null>) => {
    state.fundsActiveStep = action.payload;
  },
  setFundsDetails: (state: any, action: PayloadAction<any | null>) => {
    state.fundsDetails = action.payload;
  },
  setFundStructAndStats: (state: any, action: PayloadAction<any | null>) => {
    state.fundStructAndStats = action.payload;
  },
  setFundTermsAndServices: (state: any, action: PayloadAction<any | null>) => {
    state.fundTermsAndServices = action.payload;
  },
  setFundPriceDetails: (state: any, action: PayloadAction<any | null>) => {
    state.fundPriceDetails = action.payload;
  },
  setFundPriceAnnualReturns: (state: any, action: PayloadAction<any | null>) => {
    state.fundPriceAnnualReturns = action.payload;
  },
  setFundPriceMonthlyReturns: (state: any, action: PayloadAction<any | null>) => {
    state.fundPriceMonthlyReturns = action.payload;
  },
  setFundPriceInceptionReturns: (state: any, action: PayloadAction<any | null>) => {
    state.fundPriceInceptionReturns = action.payload;
  },
  setFundDocuments: (state: any, action: PayloadAction<any | null>) => {
    state.fundDocuments = action.payload;
  },
  setNewFundID: (state: any, action: PayloadAction<any | null>) => {
    state.fundID = action.payload;
  },
  setInvestmentStats: (state: any, action: PayloadAction<any | null>) => {
    state.investmentStatsArr = action.payload;
  },
};
export default reducers;
