import { TableContainer } from '@mui/material';
import styled from 'styled-components';

export const StyledWrapper = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    border-bottom: 1px solid #b8bcc4;
    padding: 0 22px;

    th {
      font-weight: 700;
      background-color: var(--s15);
      border: 0;
      color: #525863;
      text-transform: uppercase;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }
    tr:nth-of-type(even) {
      background-color: var(--s7);
    }
  }

  th:first-of-type,
  td:first-of-type {
    padding-right: 0;
    padding-left: 1rem;
  }

  .close-icon {
    opacity: 0;
    font-size: 22px;
  }

  .check {
    font-size: 22px;
    color: var(--green);
    &:hover {
      color: var(--green) !important;
      stroke: initial !important;
    }
  }

  .visibility {
    font-size: 22px;
    color: var(--s30);
    &:hover {
      color: var(--p200);
    }
  }

  .docUpload {
    &.disable {
      opacity: 0.5;
      cursor: initial;
    }
    &:hover path {
      stroke: var(--p200);
    }
    &.disable:hover path {
      stroke: #828282;
    }
  }
  .docDelete {
    &:hover path {
      stroke: #b43a3a;
    }
  }

  svg + svg {
    margin-left: 0.5rem;
  }

  tr:hover .close-icon {
    opacity: 1;
  }

  td {
    color: #626262;
    border: none;
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .showOnHover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .MuiTableRow-hover:hover .showOnHover {
    opacity: 1;
  }

  .bold {
    font-weight: 700;
  }

  .nullTable {
    background-color: #fafafa;
    height: 360px;
    color: #bdbdbd;
    text-align: center;
    vertical-align: center;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    position: relative;
    top: 6px;
    left: unset;
  }

  .loading {
    width: 1rem !important;
    height: 1rem !important;
    color: var(--s30);
  }

  .textInput .MuiInputBase-root {
    color: var(--p500);
    background-color: transparent !important;
    input {
      padding: 0 !important;
      &.Mui-disabled {
        color: var(--s50);
        -webkit-text-fill-color: var(--s50);
      }
    }
    fieldset {
      border: none;
    }
  }

  .selectField {
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--s15) !important;
    }
    div.Mui-disabled {
      color: var(--s50);
      -webkit-text-fill-color: var(--s50);
    }
  }

  .MuiTableSortLabel-icon {
    display: none;
  }

  .grey {
    color: var(--s30);
  }

  .addNew {
    color: var(--p300);
    cursor: pointer;
    &.disable {
      color: var(--s30);
    }
  }

  .active {
    color: var(--p300);
  }

  .selectField.active .MuiOutlinedInput-root {
    color: var(--p300) !important;
  }

  .infoIcon {
    top: unset;
    color: #626262;
  }
`;
