import LoginPage from 'Pages/Login';
import GlobalStyle, { darkTheme, lightTheme } from 'styles/globalStyle';
import { Route, Routes } from 'react-router-dom';
import Toast from 'common/components/Toast';
import { useEffect, useState } from 'react';
import { useTypedDispatch } from 'store/hooks';
import { PrivateRoute } from 'common/components/PrivateRoute';
import RegisterPage from 'Pages/Register';
import ForgotPasswordPage from 'Pages/ForgotPassword';
import HomePage from 'Pages/Home';
import ClientsPage from 'Pages/Clients';
import ClientDetailsPage from 'Pages/ClientDetails';
import OrderDetailsPage from 'Pages/OrderDetails';
import OrderCreationPage from 'Pages/OrderCreation';
import MyOrderbooksPage from 'Pages/MyOrderbooks';
import FundOrdersPage from 'Pages/MyOrderbooks/FundOrders';
import OrdersPage from 'Pages/Orders';
import PageNotFound from 'Pages/PageNotFound';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import theme from 'styles/MuiTheme';
import InvestmentList from 'components/investments/InvestmentList';
import InvestmentDetailsPage from 'Pages/InvestmentDetails';
import { useLocation } from 'react-router-dom';
import UsersPage from 'Pages/Admin/Users';
import RolesPage from 'Pages/Admin/Roles';
import TeamsPage from 'Pages/Admin/Teams';
import AplsPage from 'Pages/Admin/Apls';
import OrganisationsPage from 'Pages/Admin/Organisations';
import AdminPage from 'Pages/Admin';
import NewFundsPage from 'Pages/Admin/NewInvestmentPage';
import EditFundsPage from 'Pages/Admin/EditInvestmentPage';
import ApproveFundsPage from 'Pages/Admin/ApproveFunds';
import ApproveClientsPage from 'Pages/Admin/ApproveClients';
import OrganisationDetailsPage from 'Pages/Admin/OrganisationDetails';
import BehalfOfModal from 'components/modals/BehalfOfModal';
import { initializeAnalytics } from 'analytics';
import EventsPage from 'Pages/Admin/Events';
import ActivityLogsPage from 'Pages/Admin/Activity';
import ChangePasswordPage from 'Pages/ChangePassword';
import SubscriptionMasterChecklistPage from 'Pages/Admin/SubscriptionMasterChecklist';
import { fetchPageBackgroundBool } from 'helpers';
import PrefillDocWrapper from 'components/PDF/PrefillDocWrapper';

function App() {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const [styledTheme, setStyledTheme] = useState<boolean>(false);

  // This will move the page to the top
  useEffect(() => {
    dispatch({ type: 'INIT_SAGA_HANDLER' });
    window.scrollTo(0, 0);
    try {
      initializeAnalytics();
    } catch (error) {
      console.log('Trouble initializing GA...', error);
    }
    // eslint-disable-next-line
  }, [location]);

  // Change body's BG based on path
  useEffect(() => {
    const pathName = location.pathname;
    if (fetchPageBackgroundBool(pathName)) setStyledTheme(true);
    else setStyledTheme(false);
  }, [location]);

  return (
    <div className='App'>
      <StyledThemeProvider theme={styledTheme ? darkTheme : lightTheme}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Toast />
          <BehalfOfModal />
          <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route element={<PrivateRoute />}>
              <Route path='/' element={<HomePage />} />
              <Route path='/clients' element={<ClientsPage />} />
              <Route path='/client/:id' element={<ClientDetailsPage />} />
              <Route path='/orders' element={<OrdersPage />} />
              <Route path='/order/:id' element={<OrderDetailsPage />} />
              <Route path='/create-order' element={<OrderCreationPage />} />
              <Route path='/edit-order/:id' element={<OrderCreationPage />} />
              <Route path='/myorderbooks' element={<MyOrderbooksPage />} />
              <Route path='/myorderbooks/:id' element={<FundOrdersPage />} />
              <Route path='/investments' element={<InvestmentList />} />
              <Route path='/investment/:id' element={<InvestmentDetailsPage />} />
              <Route path='/investment/edit/:id' element={<EditFundsPage />} />
              <Route path='/investment/add' element={<NewFundsPage />} />
              <Route path='/admin' element={<AdminPage />} />
              <Route path='/admin/approve-clients' element={<ApproveClientsPage />} />
              <Route path='/admin/approve-funds' element={<ApproveFundsPage />} />
              <Route path='/admin/users' element={<UsersPage />} />
              <Route path='/admin/roles' element={<RolesPage />} />
              <Route path='/admin/teams' element={<TeamsPage />} />
              <Route path='/admin/apls' element={<AplsPage />} />
              <Route path='/admin/events' element={<EventsPage />} />
              <Route path='/admin/new-investment' element={<NewFundsPage />} />
              <Route path='/admin/activity-logs' element={<ActivityLogsPage />} />
              <Route path='/admin/organizations' element={<OrganisationsPage />} />
              <Route path='/admin/organization/:id' element={<OrganisationDetailsPage />} />
              <Route
                path='/admin/subscriptionMasterChecklist'
                element={<SubscriptionMasterChecklistPage />}
              />
              <Route
                path='/admin/organization/add-new-organization'
                element={<OrganisationDetailsPage />}
              />
              <Route path='/change-password' element={<ChangePasswordPage />} />
              <Route path='/:fundId/prefill/:docId' element={<PrefillDocWrapper />} />
              <Route path='*' element={<PageNotFound />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </StyledThemeProvider>
    </div>
  );
}

export default App;
