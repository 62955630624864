import { Box, Grid, Skeleton } from '@mui/material';
import MuiButton from 'common/components/button';
import Chips from 'common/components/chips';
//import GroupSelectField from 'common/components/inputField/GroupSelectField';
import MuiTooltip from 'common/components/tooltip';
import {
  ELIGIBILITY_TOOLTIP_CONTENT,
  LIQUIDITY_TOOLTIP_CONTENT,
  LOCKUP_TOOLTIP_CONTENT,
} from 'common/constants/tooltipContent';
import { EntityType } from 'common/types';
import AllocationDetailsModal from 'components/modals/AllocationDetailsModal';
import NewOrderDetailsModal from 'components/modals/NewOrderDetailsModal';
import { format } from 'date-fns';
import { getEmoji } from 'helpers/investments';
import { canCreateEditApproveFund, canCreateOrder } from 'helpers/roles';
import { useEffect, useState } from 'react';
//import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  setFundDocuments,
  setFundPriceAnnualReturns,
  setFundPriceInceptionReturns,
  setFundPriceMonthlyReturns,
  setFundsActiveStep,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
  setInvestmentStats,
  setNewFundID,
} from 'store/funds/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { resetOrderData } from 'store/order/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { UserProfile } from 'store/user/types';

type Props = {
  logoLoading: boolean;
  logo: { url: string };
  fundDetails: any;
};

const InvestmentIntroBox = ({ logoLoading, logo, fundDetails }: Props) => {
  //const location = useLocation();
  //const queryClient = useQueryClient();
  //const id = location.pathname.split('/')[2];
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualis = user?.category === EntityType.QUALIS;
  const isAllocationWorkflowEnabled =
    fundDetails?.isAllocationWorkflowEnabled === 'no' ? false : true;
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [isNextCloseInSameMonth, setIsNextCloseInSameMonth] = useState<boolean>(false);
  const [isFinalCloseInSameMonth, setIsFinalCloseInSameMonth] = useState<boolean>(false);
  const [allocModalOpen, setAllocModalOpen] = useState<boolean>(false);
  const [newOrderModalOpen, setNewOrderModalOpen] = useState<boolean>(false);
  //const [fund, setFundStatus] = useState<string>('');

  useEffect(() => {
    if (fundDetails) {
      const nextClose = new Date(fundDetails?.next_close);
      const finalClose = new Date(fundDetails?.final_close);
      if (nextClose.getMonth() === new Date().getMonth()) setIsNextCloseInSameMonth(true);
      if (finalClose.getMonth() === new Date().getMonth()) setIsFinalCloseInSameMonth(true);
    }
  }, [fundDetails]);

  // const checkFundStatus = (fund: any) => {
  //   if (fund === 'Inactive') {
  //     return 'InActive';
  //   } else if (fund === 'Onboarding Awaiting Apporval') {
  //     return 'Onboarding - Awaiting Approval';
  //   } else {
  //     return fund;
  //   }
  // };

  // const { mutate: setFundStatusRequest } = useRQMutation(
  //   {
  //     url: `/qaip/v1/fundsmanagement/fund/id/${fundDetails.fund_id}?status=${checkFundStatus(
  //       fund,
  //     )}`,
  //     method: QueryType.PUT,
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(`fundDetails-${id}`);
  //     },
  //   },
  // );

  const navigateToFund = () => {
    navigate('/create-order', {
      state: {
        fundDetails: { ...fundDetails, fundsId: fundDetails.fund_id },
      },
    });
  };

  const handleCardButtonClick = () => {
    dispatch(resetOrderData());
    if (isAllocationWorkflowEnabled) {
      setAllocModalOpen(!allocModalOpen);
    } else {
      setNewOrderModalOpen(!newOrderModalOpen);
    }
  };
  // useEffect(() => {
  //   let fundStatusToBeUsed =
  //     fundDetails.status === 'Active'
  //       ? `Active,${fundDetails.fund_status}`
  //       : fundDetails.status === 'InActive'
  //       ? 'Inactive'
  //       : 'Onboarding Awaiting Apporval';
  //   setFundStatus(fundStatusToBeUsed);
  // }, [fundDetails]);

  return (
    <Grid container alignItems='stretch' columnSpacing={4} columns={10}>
      <Grid item xs={2} className='flex-stretch-center'>
        <Box
          className='justify-center'
          sx={{ width: '100%', border: '1px solid #DDDDDD', p: 1, borderRadius: '0.5rem' }}
        >
          {!logoLoading ? (
            <img src={logo?.url} alt='' className='fundLogo' />
          ) : (
            <Skeleton variant='rectangular' sx={{ height: '100%' }} />
          )}
        </Box>
      </Grid>
      <Grid item xs={8} container>
        <Grid item xs={12} container mb={4.5}>
          {fundDetails?.fundIdSimple && isQualis && (
            <Grid item xs={12} className='flex-center-between'>
              <h6 className='subtitle' style={{ marginBottom: 0 }}>
                ID: {fundDetails?.fundIdSimple}
              </h6>
            </Grid>
          )}
          <Grid item xs={12} className='flex-center-between' mb={1}>
            <h1 className='mb-0 mr-3 fundName'>{fundDetails?.fund_name}</h1>
            <div>
              {user && canCreateEditApproveFund(user, 'edit') && (
                <MuiButton
                  variant='outlined'
                  className='ml-auto mr-3'
                  buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    dispatch(setFundsActiveStep(0));
                    dispatch(setFundsDetails(null));
                    dispatch(setFundStructAndStats(null));
                    dispatch(setFundTermsAndServices(null));
                    dispatch(setFundPriceAnnualReturns(null));
                    dispatch(setFundPriceMonthlyReturns(null));
                    dispatch(setFundPriceInceptionReturns(null));
                    dispatch(setFundDocuments(null));
                    dispatch(setNewFundID(null));
                    dispatch(setInvestmentStats([{}]));
                    navigate(`/investment/edit/${fundDetails?.fund_id}`);
                  }}
                >
                  Edit Fund
                </MuiButton>
              )}
              <MuiButton
                variant={`${isAllocationWorkflowEnabled ? 'outlined' : 'contained'}`}
                disabled={user && !canCreateOrder(user)}
                buttonClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  handleCardButtonClick();
                }}
              >
                {isAllocationWorkflowEnabled ? 'Request Allocation' : 'Add New Order'}
              </MuiButton>
            </div>
          </Grid>
          <Grid item xs={12} className='flex-center-between position-relative'>
            <div className='flex-center-start'>
              {fundDetails?.fund_themes
                ?.split(',')
                .filter((str: string) => str !== '')
                .map((theme: string, t: number) => (
                  <span key={`theme${t}`} className='theme'>
                    {getEmoji(theme)}
                    {theme}
                  </span>
                ))}
              {isAllocationWorkflowEnabled && (
                <div className='theme'>
                  {getEmoji('Alloc')}
                  Time Limited Funds
                </div>
              )}
              {fundDetails?.asset_class === 'Private Equity' &&
                (isNextCloseInSameMonth || isFinalCloseInSameMonth) && (
                  <Chips label={'Closing Soon'} bg={'#CC3B3B'} color={'white'} />
                )}
            </div>
            {/* {isQualis && (
              <GroupSelectField
                searchIcon={false}
                setSelectedValue={async (val: any) => {
                  await setFundStatus(val);
                  setFundStatusRequest({});
                }}
                value={fund}
                defaultValue={fund}
                className={`pt-0 groupSelect ${isAllocationWorkflowEnabled ? 'allocReq' : ''}`}
              />
            )} */}
          </Grid>
        </Grid>
        <Grid item xs={12} container columns={5} columnSpacing={1} mb={3.5} pr={'150px'}>
          <Grid item xs={1}>
            <h6 className='subtitle'>FUND MANAGER</h6>
            <h4 className='font-wt-300 mb-0'>
              {fundDetails?.fund_manager_name ? fundDetails?.fund_manager_name : '--'}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <h6 className='subtitle'>ASSET CLASS</h6>
            <h4 className='font-wt-300 mb-0'>
              {fundDetails?.asset_class ? fundDetails?.asset_class : '--'}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <h6 className='subtitle'>FUND Strategy</h6>
            <h4 className='font-wt-300 mb-0'>
              {fundDetails?.strategy ? fundDetails?.strategy : '--'}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <h6 className='subtitle'>SUB-Strategy</h6>
            <h4 className='font-wt-300 mb-0'>
              {fundDetails?.sub_strategy ? fundDetails?.sub_strategy : '--'}
            </h4>
          </Grid>
          <Grid item xs={1}>
            <h6 className='subtitle position-relative'>
              Eligibility
              <MuiTooltip content={ELIGIBILITY_TOOLTIP_CONTENT} />
            </h6>
            <h4 className='font-wt-300 mb-0'>
              {fundDetails?.eligibility ? fundDetails?.eligibility : '--'}
            </h4>
          </Grid>
        </Grid>
        <Grid item xs={12} container columns={5} columnSpacing={1} pr={'150px'}>
          <Grid item xs={1}>
            <h6 className='subtitle'>MINIMUM Investment</h6>
            <h4 className='font-wt-300 mb-0'>
              ${fundDetails?.min_investment ? fundDetails?.min_investment.toLocaleString() : '--'}
            </h4>
          </Grid>
          {fundDetails?.asset_class === 'Private Equity' ? (
            <>
              {fundDetails?.next_close && (
                <Grid item xs={1}>
                  <h6 className='subtitle'>NEXT CLOSE</h6>
                  <h4 className={`font-wt-300 mb-0 ${isNextCloseInSameMonth && 'red-close'}`}>
                    {format(new Date(fundDetails?.next_close), 'MM/dd/yyy')}
                  </h4>
                </Grid>
              )}
              {fundDetails?.final_close && (
                <Grid item xs={1}>
                  <h6 className='subtitle'>FINAL CLOSE</h6>
                  <h4 className={`font-wt-300 mb-0 ${isFinalCloseInSameMonth && 'red-close'}`}>
                    {format(new Date(fundDetails?.final_close), 'MM/dd/yyy')}
                  </h4>
                </Grid>
              )}
            </>
          ) : (
            <>
              {fundDetails?.lockup_for_summary && (
                <Grid item xs={1}>
                  <h6 className='subtitle position-relative'>
                    LOCKUP
                    <MuiTooltip content={LOCKUP_TOOLTIP_CONTENT} />
                  </h6>
                  <h4 className='font-wt-300 mb-0'>
                    {fundDetails?.lockup_for_summary ? fundDetails?.lockup_for_summary : '--'}
                  </h4>
                </Grid>
              )}
              {fundDetails?.liquidity_for_summary && (
                <Grid item xs={1}>
                  <h6 className='subtitle position-relative'>
                    Liquidity
                    <MuiTooltip content={LIQUIDITY_TOOLTIP_CONTENT} />
                  </h6>
                  <h4 className='font-wt-300 mb-0'>
                    {fundDetails?.liquidity_for_summary ? fundDetails?.liquidity_for_summary : '--'}
                  </h4>
                </Grid>
              )}
            </>
          )}
          {fundDetails?.management_fees && (
            <Grid item xs={1}>
              <h6 className='subtitle'>MGMT. FEES</h6>
              <h4 className='font-wt-300 mb-0'>
                {fundDetails?.management_fees ? fundDetails?.management_fees + '%' : '--'}
              </h4>
            </Grid>
          )}
          {fundDetails?.incentives_fees && (
            <Grid item xs={1}>
              <h6 className='subtitle'>INcentive FEE</h6>
              <h4 className='font-wt-300 mb-0'>
                {fundDetails?.incentives_fees ? fundDetails?.incentives_fees + '%' : '--'}
              </h4>
            </Grid>
          )}
        </Grid>
      </Grid>
      <AllocationDetailsModal
        isModalOpen={allocModalOpen}
        handleClose={() => setAllocModalOpen(!allocModalOpen)}
        buttonClick={navigateToFund}
      />
      <NewOrderDetailsModal
        isModalOpen={newOrderModalOpen}
        handleClose={() => setNewOrderModalOpen(!newOrderModalOpen)}
        buttonClick={navigateToFund}
      />
    </Grid>
  );
};

export default InvestmentIntroBox;
