import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import MuiCheckbox from 'common/components/inputField/Checkbox';
import Textbox from 'common/components/inputField/Textbox';
import { handleSSNInput } from 'helpers/client/utils';
import { useState } from 'react';
import { EntityTypeDetails } from 'store/investor/types';
/* eslint-disable no-useless-escape */
interface Props {
  additional?: boolean;
  register: any;
  errors: any;
  index: number;
  entityDetails?: EntityTypeDetails;
  onEntityDetailsChange: (property: string, idx: number, value: any) => void;
  onEntityDetailsRemove: (index: number) => void;
  reset: any;
  handleAddEntity?: any;
  detailsState?: any;
  setDetailsState?: any;
}

const Entity = ({
  additional = false,
  register,
  errors,
  index,
  entityDetails,
  onEntityDetailsChange,
  onEntityDetailsRemove,
  handleAddEntity,
  detailsState,
  setDetailsState,
}: Props) => {
  const [tempTIN, setTempTIN] = useState(`${entityDetails?.tin || ''}`);
  const handleKeyDownOnlyNumbers = (e: any) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleKeyDownNoText = (e: any) => {
    if (/^[A-Za-z!@#$%^&*()\-,._+={:>}"?``<;'\[\]\/\|{>"}\~"]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const convertToMap = (ssn: any) => {
    if (!ssn.includes('-') && ssn.length === 9) {
      const ssnArr = [];
      ssnArr[0] = ssn.substring(0, 2);
      ssnArr[1] = ssn.substring(2, 5);
      ssnArr[2] = ssn.substring(5, 10);
      return ssnArr.join('-');
    } else {
      return ssn;
    }
  };

  return entityDetails ? (
    <Grid container className='my-5'>
      {additional ? (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Additional Entity Details</h3>
          <MuiButton
            variant='text'
            className='p-0'
            buttonClick={() => onEntityDetailsRemove(index)}
          >
            Remove
          </MuiButton>
        </Grid>
      ) : (
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Entity Details</h3>
          <MuiButton
            variant='text'
            className='p-0'
            buttonClick={() => onEntityDetailsRemove(index)}
          >
            Remove
          </MuiButton>
        </Grid>
      )}
      <Grid item xs={12}>
        <h4 className='font-wt-400 mb-0'>Investor Name</h4>
        <Textbox
          fullWidth
          type='text'
          register={register}
          value={entityDetails.name}
          name={`nameE${index}`}
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEntityDetailsChange('name', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Investor Mailing Address</h4>
        <Textbox
          fullWidth
          multiline
          rows={4}
          type='text'
          register={register}
          value={entityDetails.address}
          name={`addressE${index}`}
          required={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEntityDetailsChange('address', index, e.target.value)
          }
          maxLength={255}
        />
        {errors[`addressE${index}`] && errors[`addressE${index}`].type === 'maxLength' && (
          <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
        )}
      </Grid>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Tel</h4>
          <Textbox
            fullWidth
            value={entityDetails.phoneNumber}
            type='tel'
            register={register}
            name={`telE${index}`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 12) return;
              onEntityDetailsChange('phoneNumber', index, e.target.value);
            }}
            keyDownFunc={handleKeyDownOnlyNumbers}
            startAdornment='+'
            maxLength={11}
            minLength={11}
          />
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 11 characters.' />
          )}
          {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
          )}
        </Grid>
        <Grid item xs={5} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Fax</h4>
          <Textbox
            fullWidth
            value={entityDetails.faxNumber}
            register={register}
            name={`faxE${index}`}
            maxLength={10}
            minLength={10}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length === 11) return;
              onEntityDetailsChange('faxNumber', index, e.target.value);
            }}
          />
          {errors[`faxE${index}`] && errors[`faxE${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 10 characters.' />
          )}
          {errors[`faxE${index}`] && errors[`faxE${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Email</h4>
        <Textbox
          fullWidth
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEntityDetailsChange('email', index, e.target.value)
          }
          value={entityDetails.email}
          name={`emailE${index}`}
          pattern={{
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Please enter a valid email',
          }}
        />
        {errors[`emailE${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <div className='checkbox-container flex-center-start'>
          <MuiCheckbox
            name='notices'
            disableRipple
            defaultValue={entityDetails.useableForFinanceAndTaxInfomation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onEntityDetailsChange('useableForFinanceAndTaxInfomation', index, e.target.checked)
            }
          />
          <span> Use mailing address for financial statements and Tax information returns</span>
        </div>
        <div className='checkbox-container flex-center-start'>
          <MuiCheckbox
            name='notices'
            disableRipple
            defaultValue={entityDetails.useableForNoticeAndCommunications}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onEntityDetailsChange('useableForNoticeAndCommunications', index, e.target.checked)
            }
          />
          <span> Use mailing address for notices and communication</span>
        </div>
        <div className='checkbox-container flex-center-start'>
          <MuiCheckbox
            name='notices'
            disableRipple
            defaultValue={entityDetails.useableForElectronicDeliveryOfStatements}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onEntityDetailsChange(
                'useableForElectronicDeliveryOfStatements',
                index,
                e.target.checked,
              )
            }
          />
          <span> Opt out of electronic delivery of statements and notices</span>
        </div>
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Investor TIN</h4>
        <Textbox
          fullWidth
          placeholder='e.g. **-***-1234'
          value={tempTIN && convertToMap(tempTIN)}
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            const { useableValue, mappedValue } = handleSSNInput(value);
            setTempTIN(mappedValue);
            if (e.target.value.length === 10) return;
            onEntityDetailsChange('tin', index, useableValue);
          }}
          name={`tinE${index}`}
          minLength={11}
          maxLength={11}
          keyDownFunc={handleKeyDownNoText}
        />
        {errors[`tinE${index}`] && errors[`tinE${index}`].type === 'minLength' && (
          <ErrorMessage error='Please enter 9 characters.' />
        )}
        {errors[`tinE${index}`] && errors[`tinE${index}`].type === 'maxLength' && (
          <ErrorMessage error='Input limit exceeded. Character Limit is 9' />
        )}
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Contact Person Name</h4>
        <Textbox
          fullWidth
          value={entityDetails.contactName}
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEntityDetailsChange('contactName', index, e.target.value)
          }
          name={`contactNameE${index}`}
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Country of Formation/ Incorporation</h4>
        <Textbox
          fullWidth
          value={entityDetails.incorporation}
          placeholder='e.g. United States'
          register={register}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onEntityDetailsChange('incorporation', index, e.target.value)
          }
          name={`incorporationE${index}`}
        />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        {additional ? (
          <Grid item xs={12} className='header flex-end-between'>
            <h3 className='mb-0 font-wt-500'>Additional Entity Details</h3>
          </Grid>
        ) : (
          <Grid item xs={12} className='header flex-end-between'>
            <h3 className='mb-0 font-wt-500'>Entity Details</h3>
          </Grid>
        )}

        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-0'>Investor Name</h4>
          <Textbox
            fullWidth
            type='text'
            register={register}
            value={detailsState.name}
            name={`nameE${index}`}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Investor Mailing Address</h4>
          <Textbox
            fullWidth
            multiline
            rows={4}
            type='text'
            register={register}
            value={detailsState.address}
            name={`addressE${index}`}
            required={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, address: e.target.value })
            }
            maxLength={255}
          />
          {errors[`addressE${index}`] && errors[`addressE${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
          )}
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Tel</h4>
            <Textbox
              fullWidth
              value={detailsState.phoneNumber}
              type='tel'
              register={register}
              name={`telE${index}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 12) return;
                setDetailsState({ ...detailsState, phoneNumber: e.target.value });
              }}
              keyDownFunc={handleKeyDownOnlyNumbers}
              startAdornment='+'
              maxLength={11}
              minLength={11}
            />
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 11 characters.' />
            )}
            {errors[`telAR${index}`] && errors[`telAR${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 11' />
            )}
          </Grid>
          <Grid item xs={5} mt={'24px'}>
            <h4 className='font-wt-400 mb-0'>Fax</h4>
            <Textbox
              fullWidth
              value={detailsState.faxNumber}
              register={register}
              name={`faxE${index}`}
              maxLength={10}
              minLength={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length === 11) return;
                setDetailsState({ ...detailsState, faxNumber: e.target.value });
              }}
            />
            {errors[`faxE${index}`] && errors[`faxE${index}`].type === 'minLength' && (
              <ErrorMessage error='Please enter 10 characters.' />
            )}
            {errors[`faxE${index}`] && errors[`faxE${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 10' />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Email</h4>
          <Textbox
            fullWidth
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, email: e.target.value })
            }
            value={detailsState.email}
            name={`emailE${index}`}
            pattern={{
              value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email',
            }}
          />
          {errors[`emailE${index}`]?.message && <ErrorMessage error='Please enter valid email' />}
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='notices'
              disableRipple
              defaultValue={detailsState.useableForFinanceAndTaxInfomation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({
                  ...detailsState,
                  useableForFinanceAndTaxInfomation: e.target.checked,
                })
              }
            />
            <span> Use mailing address for financial statements and Tax information returns</span>
          </div>
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='notices'
              disableRipple
              defaultValue={detailsState.useableForNoticeAndCommunications}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({
                  ...detailsState,
                  useableForNoticeAndCommunications: e.target.checked,
                })
              }
            />
            <span> Use mailing address for notices and communication</span>
          </div>
          <div className='checkbox-container flex-center-start'>
            <MuiCheckbox
              name='notices'
              disableRipple
              defaultValue={detailsState.useableForElectronicDeliveryOfStatements}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDetailsState({
                  ...detailsState,
                  useableForElectronicDeliveryOfStatements: e.target.checked,
                })
              }
            />
            <span> Opt out of electronic delivery of statements and notices</span>
          </div>
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Investor TIN</h4>
          <Textbox
            fullWidth
            placeholder='e.g. **-***-1234'
            value={tempTIN && convertToMap(tempTIN)}
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              let value = e.target.value;
              const { useableValue, mappedValue } = handleSSNInput(value);
              setTempTIN(mappedValue);
              if (e.target.value.length === 10) return;
              setDetailsState({ ...detailsState, tin: useableValue });
            }}
            name={`tinE${index}`}
            minLength={11}
            maxLength={11}
            keyDownFunc={handleKeyDownNoText}
          />
          {errors[`tinE${index}`] && errors[`tinE${index}`].type === 'minLength' && (
            <ErrorMessage error='Please enter 9 characters.' />
          )}
          {errors[`tinE${index}`] && errors[`tinE${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 9' />
          )}
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Contact Person Name</h4>
          <Textbox
            fullWidth
            value={detailsState.contactName}
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, contactName: e.target.value })
            }
            name={`contactNameE${index}`}
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Country of Formation/ Incorporation</h4>
          <Textbox
            fullWidth
            value={detailsState.incorporation}
            placeholder='e.g. United States'
            register={register}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, incorporation: e.target.value })
            }
            name={`incorporationE${index}`}
          />
        </Grid>
      </Grid>
      <MuiButton
        variant='outlined'
        className='mt-2'
        buttonClick={() => handleAddEntity({ ...detailsState })}
      >
        Add
      </MuiButton>
    </>
  );
};

export default Entity;
