import styled from '@emotion/styled';
import { CheckCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { format } from 'date-fns';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectNewOrderDetails, selectNewOrderDocs } from 'store/order/selectors';
import { setOrderActiveStep, setOrderDetails } from 'store/order/slice';
import { ReactComponent as PlaneIcon } from 'common/assets/images/CTA/Plane.svg';
import LoadingButton from 'common/components/button/LoadingButton';

const StyledDocRow = styled(Grid)`
  padding-bottom: 8px;
  span {
    font-size: 13px;
    &.file {
      color: #8e8e8e;
      font-style: italic;
    }
  }
  svg {
    margin-right: 0.5rem;
    color: var(--green);
  }
`;

const StyledWrapper = styled(Grid)`
  .comments {
    font-size: 13px;
  }

  h3.subheader {
    color: var(--s50);
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    font-size: 1rem !important;
    font-weight: 500;
    border-bottom: 1px solid var(--s15);
  }

  h3.subheader2 {
    font-size: 1rem !important;
    margin-bottom: 0.5rem;
    color: var(--s50);
  }
`;

type Props = {
  handleRequestClick: any;
  isUpdateLoading: boolean;
};

const OrderSummary = ({ handleRequestClick, isUpdateLoading }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const isRedeemOrder = (location as any)?.state?.isRedeemOrder;
  // const isRequestAllocation = (location as any)?.state?.isRequestAllocation;

  const newOrderDetails: any = useAppSelector(selectNewOrderDetails);
  const newOrderDocs: any = useAppSelector(selectNewOrderDocs);

  const isAllocationWorkflowEnabled =
    newOrderDetails?.selectedFund?.isAllocationWorkflowEnabled === 'no' ? false : true;

  const onSubmit = () => {
    dispatch(setOrderActiveStep(0));
    dispatch(setOrderDetails(null));
    navigate(`/order/${newOrderDetails?.orderId}`);
  };
  const handleBackClick = async () => {
    await dispatch(setOrderActiveStep(1));
  };

  const handleDocHeaderVisibility = (docs: any) => {
    return docs?.some((doc: any) => doc?.documentsResponseDto.length > 0);
  };

  return (
    <MuiCard
      minHeight='unset'
      sx={{
        py: 0,
        pl: 1,
        pr: 18,
        border: 'none',
        h4: { textOverflow: 'ellipsis', overflow: 'hidden' },
      }}
    >
      <StyledWrapper container>
        <Grid item xs={12} mb={'38px'}>
          <h2 className='font-wt-500 mb-0'>Review Your Order</h2>
          <p className='mt-3 mb-0'>
            {isAllocationWorkflowEnabled
              ? `
                Review the order and submit it for approval within your firm. Once approved, the order will be forwarded to the Fund Manager for allocation.
                `
              : `
                Review the order and submit it for approval within your firm. Once approved, the order will be forwarded to the Fund Manager for review.
                `}
          </p>
        </Grid>
        <Grid item xs={12} mb={'2px'}>
          <h3 className='subheader mb-0'>Order Details</h3>
        </Grid>
        <Grid item container xs={12} mb='34px' columnSpacing={1}>
          <Grid item xs={4} mb={1}>
            <h6 className='subtitle'>Client:</h6>
            <h4 className='font-wt-300'>{newOrderDetails?.investorName}</h4>
          </Grid>
          <Grid item xs={4} mb={1}>
            <h6 className='subtitle'>FUND NAME:</h6>
            <h4 className='font-wt-300'>{newOrderDetails?.selectedFund?.fund_name}</h4>
          </Grid>
          <Grid item xs={4} mb={1}>
            <h6 className='subtitle'>
              {isAllocationWorkflowEnabled ? 'Allocation Request' : 'Amount'}:
            </h6>
            <h4 className='font-wt-300'>
              ${Number(newOrderDetails?.eventAmount).toLocaleString()}
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h6 className='subtitle'>Date:</h6>
            <h4 className='font-wt-300'>
              {format(new Date(newOrderDetails?.eventDate), 'MMM-dd-yyyy')}
            </h4>
          </Grid>
          <Grid item xs={4}>
            <h6 className='subtitle'>Event:</h6>
            <h4 className='font-wt-300'>
              {isAllocationWorkflowEnabled ? 'Allocation Request' : newOrderDetails?.event}
            </h4>
          </Grid>
        </Grid>

        <Grid item container xs={12} mb={6.5}>
          {newOrderDocs && handleDocHeaderVisibility(newOrderDocs) && (
            <Grid item xs={12}>
              <h3 className='subheader mb-0'>
                {isRedeemOrder ? 'Redemption Documents' : 'Subscription Documents'}
              </h3>
            </Grid>
          )}
          <Grid item xs={12}>
            {React.Children.toArray(
              newOrderDocs?.map(
                (doc: any) =>
                  doc?.documentsResponseDto &&
                  doc?.documentsResponseDto.length > 0 && (
                    <StyledDocRow item xs={12} container className='flex-start-between'>
                      <Grid item xs={12}>
                        <span className='file flex-start-between'>
                          <Grid container rowSpacing={1}>
                            {doc?.documentsResponseDto.map((item: any) => {
                              return (
                                <>
                                  <Grid item xs={6} mt={0.6}>
                                    <span>{item.docClassificationName}</span>
                                  </Grid>
                                  <Grid item xs={6} mt={0.6}>
                                    <CheckCircle fontSize='inherit' /> <span>{item.docName}</span>
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        </span>
                      </Grid>
                    </StyledDocRow>
                  ),
              ),
            )}
            {React.Children.toArray(
              newOrderDocs?.subscription?.map(
                (doc: any) =>
                  doc?.documentsResponseDto &&
                  doc?.documentsResponseDto.length > 0 && (
                    <StyledDocRow item xs={12} container className='flex-center-between'>
                      <Grid item xs={4}>
                        <span>{doc?.documentsResponseDto[0]?.docClassificationName}</span>
                      </Grid>
                      <Grid item xs={8}>
                        <span className='file flex-center-start'>
                          <CheckCircle fontSize='inherit' />
                          {doc?.documentsResponseDto[0]?.docName}
                        </span>
                      </Grid>
                    </StyledDocRow>
                  ),
              ),
            )}
          </Grid>
        </Grid>
        {newOrderDetails?.comments && newOrderDetails?.comments !== '' && (
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <h3 className='subheader mb-0'>Comments</h3>
              <span className='comments'>{newOrderDetails?.comments}</span>
            </Grid>
          </Grid>
        )}
      </StyledWrapper>
      <section className='flex-center-end mt-5'>
        <div>
          <MuiButton buttonClick={handleBackClick} className='mr-3'>
            Back
          </MuiButton>
          <MuiButton className=' mr-3' buttonClick={onSubmit}>
            Save as Draft
          </MuiButton>
          {isUpdateLoading ? (
            <LoadingButton minWidth='176px' />
          ) : (
            <MuiButton minWidth='176px' variant='contained' buttonClick={handleRequestClick}>
              <PlaneIcon className='mr-2' />
              Request Approval
            </MuiButton>
          )}
        </div>
      </section>
    </MuiCard>
  );
};

export default OrderSummary;
