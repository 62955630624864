import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import MuiCheckbox from 'common/components/inputField/Checkbox';

const StyledWrapper = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    background-color: #ddd;
    border-bottom: 1px solid #b8bcc4;
    padding: 0 22px;

    th {
      font-weight: 700;
      border: 0;
      border-radius: 4px;
      color: #525863;
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 50px;
      }
      &:last-of-type {
        padding-right: 50px;
      }
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }
    tr:nth-of-type(even) {
      background-color: var(--s7);
    }
  }

  td {
    color: #626262;
    border: none;
    &:first-of-type {
      padding-left: 50px;
    }
    &:last-of-type {
      padding-right: 50px;
    }
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .showOnHover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .MuiTableRow-hover:hover .showOnHover {
    opacity: 1;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bold {
    font-weight: 700;
  }

  .pagination {
    padding: 1.2rem 2.5rem;
    margin-top: 1.2rem;
    border-top: 1px solid #d7d7d7;
    button {
      color: grey;
      border-radius: 8px;
      &.Mui-selected {
        color: var(--p500);
        border: 2px solid #b3d1ed;
        background-color: unset;
      }
      svg {
        color: var(--p500);
      }
    }
  }
`;

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  isCheckbox?: boolean;
  cellClassName?: string;
  width?: string;
}

type Row = any;

interface TableProps {
  rows: Row;
  columns: Array<Column>;
  rowClassName?: string;
  isLoading?: boolean;
  tableClassName?: string;
  handleOnChange: any;
  canColToggle?: boolean;
}

const RolesTable = ({
  rows = [],
  columns,
  rowClassName,
  isLoading,
  tableClassName,
  handleOnChange,
  canColToggle = false,
}: TableProps) => {
  const [finalRows, setFinalRows] = useState<Row[]>(rows);
  const [toggleAll, setToggleAll] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const checkboxCols = columns.filter((col: Column) => col.isCheckbox);
    const reduced = checkboxCols.reduce((acc: any, elem: any) => {
      acc[elem.id] = false; // or what ever object you want inside
      return acc;
    }, {});
    setToggleAll(reduced);
    // setToggleAll()
  }, [columns]);

  useEffect(() => {
    if (rows.length > 0) setFinalRows(rows);
  }, [rows]);

  return (
    <StyledWrapper className={tableClassName}>
      <div className='tableWrapper'>
        <Table sx={{ minWidth: 650 }}>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableHead>
              <TableRow>
                {React.Children.toArray(
                  columns.map((col: Column) => (
                    <TableCell align={col.align}>
                      {col.label}
                      {canColToggle && col.isCheckbox && (
                        <MuiCheckbox
                          key={'toggleAllCheckboxes'}
                          name={col.id}
                          className='d-inline'
                          onChange={() => {
                            setToggleAll((prevState) => {
                              return { ...prevState, [col.id]: !prevState[col.id] };
                            });
                            finalRows.forEach((row: Row, i: number) => {
                              if (row[col.id] === toggleAll[col.id])
                                handleOnChange({ role: row, name: col.id, i: i });
                            });
                          }}
                          defaultValue={toggleAll[col.id]}
                          circleCheck
                        />
                      )}
                    </TableCell>
                  )),
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {React.Children.toArray(
                  finalRows.map((row: Row, i: number) => (
                    <TableRow
                      hover
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={rowClassName}
                    >
                      {React.Children.toArray(
                        columns.map((col: Column, j: number) => (
                          <TableCell
                            align={col.align}
                            className={`
                            ${col.showOnHover && 'showOnHover'}
                            ${col.bold && 'bold'}
                            ${col.cellClassName ? col.cellClassName : ''}
                          `}
                            sx={{ width: `${col.width ? col.width : 'initial'}` }}
                          >
                            {col.isCheckbox ? (
                              <MuiCheckbox
                                key={j}
                                name={col.id}
                                onChange={() => handleOnChange({ role: row, name: col.id, i: i })}
                                defaultValue={row[col.id]}
                                circleCheck
                              />
                            ) : row[col.id] ? (
                              row[col.id]
                            ) : (
                              '--'
                            )}
                          </TableCell>
                        )),
                      )}
                    </TableRow>
                  )),
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </StyledWrapper>
  );
};

export default RolesTable;
