import { Grid } from '@mui/material';
import Loading from 'common/components/Loading';
import { ClientType, ReactQueryDataFetch } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector, useGraphQLQuery, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { Events as EventType } from 'common/types';
import Events from './Events';
import Funds from './Funds';
import Hero from './Hero';
import { UserProfile } from 'store/user/types';
import Themes from './Themes';
import { THEMES_DATA } from 'common/constants/betaData';
import { RECOMMENDED_FUND_QUERY } from './Query';

const Intro = () => {
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const [filteredEvents, setFilteredEvents] = useState<Array<EventType>>([]);
  const [themes, setThemes] = useState<Array<any>>([]);
  const showFunds =
    process.env.REACT_APP_SHOW_RECOMMENDED_FUNDS?.toString().toLowerCase() === 'true';

  const { data: activeClients = [], isLoading: activeClientsLoading } = useReactQuery(
    [`activeClientList${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/recentActiveClients',
      params: user?.userId,
    },
    {
      refetchOnMount: true,
      retryOnMount: true,
    },
  ) as { data: ClientType[] } & ReactQueryDataFetch;

  const { data, isLoading: eventsLoading } = useReactQuery(
    [`dashboard${user?.userId}`],
    {
      url: 'qaip/v1/notificationmanagement/events/dashboard',
    },
    {
      retryOnMount: true,
      cacheTime: 0,
    },
  ) as { data: EventType[] } & ReactQueryDataFetch;

  useEffect(() => {
    if (data) {
      setFilteredEvents(
        data.filter(
          (event: EventType) =>
            new Date((event.endTime as string).replace(/-/g, '/')) >= new Date(),
        ),
      );
    }
  }, [data]);

  const { data: recommendedFunds } = useGraphQLQuery(
    'recommendedFunds',
    {
      query: RECOMMENDED_FUND_QUERY,
      variables: {
        investerId: '',
        fundId: '',
        userId: user?.userId,
        categoryId: user?.category,
        organisationId: (user as any)?.organisationId,
      },
    },
    { enabled: !!user },
  );

  // const { data: fundData, isLoading: fundsLoading } = useReactQuery(
  //   [`latestFunds${user?.userId}`],
  //   {
  //     url: 'qaip/v1/fundsmanagement/funds/latest',
  //   },
  // ) as { data: FundDetails[] } & ReactQueryDataFetch;

  const { data: themesData = [] } = useReactQuery(['themes'], {
    url: 'qaip/v1/fundsmanagement/fundThemes',
  });

  useEffect(() => {
    if (themesData && themesData.length > 0) {
      setThemes(
        themesData.map((t: any) => ({
          ...t,
          themeImageUrl: THEMES_DATA.find((d: any) => d.themeName === t.themeName)?.themeImageUrl,
        })),
      );
    }
  }, [themesData]);

  if (!userProfile) return null;

  // if (eventsLoading || fundsLoading) return <Loading />;
  if (eventsLoading || activeClientsLoading) return <Loading />;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hero
          totalClients={user?.totalClients as number}
          clients={activeClients}
          totalAum={user?.aum as number}
          name={user?.firstName as string}
          userName={user?.userName as string}
        />
      </Grid>
      {themes?.length > 0 && (
        <Grid item container xs={12}>
          <Themes list={themes} />
        </Grid>
      )}
      {showFunds && (
        <Grid item container xs={12}>
          <Funds
            list={recommendedFunds?.getRecFunds}
            className='container-lr-padding'
            heading='Suggested Funds Based On Your Activity'
          />
        </Grid>
      )}
      {filteredEvents?.length > 0 && (
        <Grid item container xs={12}>
          <Events
            list={filteredEvents.sort((a: EventType, b: EventType) =>
              (a.starTime as string).localeCompare(b.starTime as string),
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Intro;
