export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Roles & Permissions',
  },
];

export const COLUMNS = [
  {
    id: 'menu',
    label: 'Role',
    isCheckbox: false,
  },
  {
    id: 'viewaccess',
    label: 'View',
    isCheckbox: true,
    align: 'center' as const,
    width: '17.5%',
  },
  {
    id: 'createaccess',
    label: 'Create',
    isCheckbox: true,
    align: 'center' as const,
    width: '17.5%',
  },
  {
    id: 'modifyaccess',
    label: 'Modify',
    isCheckbox: true,
    align: 'center' as const,
    width: '17.5%',
  },
  {
    id: 'approvalaccess',
    label: 'Approval',
    isCheckbox: true,
    align: 'center' as const,
    width: '17.5%',
  },
];
