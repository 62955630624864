export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '',
    label: 'My Orderbooks',
  },
];

export const COLUMNS = [
  {
    id: 'fundName',
    label: 'Fund Name',
    needsFormatter: false,
    width: '30%',
  },
  {
    id: 'type',
    label: 'Type',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'openAmount',
    label: 'New Requests',
    needsFormatter: true,
    align: 'right' as const,
    width: '20%',
  },
  {
    id: 'totalAllocatedAmount',
    label: 'Allocated So Far',
    needsFormatter: true,
    align: 'right' as const,
    width: '20%',
  },
  {
    id: 'latestUpdatedDate',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
    align: 'right' as const,
    width: '20%',
  },
];

export const FUND_ORDERS_COLUMNS = [
  {
    id: 'orderId',
    label: 'ID',
    needsFormatter: false,
    width: '8%',
  },
  {
    id: 'investorName',
    label: 'Investor',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'amountRequested',
    label: 'Allocation Requested',
    needsFormatter: true,
    align: 'right' as const,
    width: '18%',
  },
  {
    id: 'eventAmount',
    label: 'Amount Allocated',
    needsFormatter: true,
    isInputField: true,
    align: 'right' as const,
    width: '15%',
  },
  {
    id: 'statusName',
    label: 'Status',
    needsFormatter: false,
    showProgress: true,
    width: '12%',
  },
  {
    id: 'lastUpdatedBy',
    label: 'Last Updated By',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'lastUpdatedDate',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
    width: '10%',
  },
];

export const ORDER_COLUMNS = [
  {
    id: 'orderId',
    label: 'ID',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: 'investorName',
    label: 'INVESTOR',
    needsFormatter: false,
    width: '22%',
  },
  {
    id: 'eventAmount',
    label: 'AMOUNT',
    needsFormatter: true,
    align: 'right' as const,
    width: '8%',
  },
  {
    id: 'eventName',
    label: 'EVENT',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'eventDateFormatted',
    label: 'EVENT DATE',
    needsFormatter: false,
    isDate: true,
    width: '15%',
  },
  {
    id: 'statusName',
    label: 'STATUS',
    showProgress: true,
    needsFormatter: false,
    width: '13%',
  },
  {
    id: 'lastUpdatedBy',
    label: 'Last Updated By',
    needsFormatter: false,
  },
  {
    id: 'lastUpdatedDate',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
  },
];
