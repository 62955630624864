import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import SubHeader from 'common/components/layout/SubHeader';
import { getRoleBasedAdminLinks } from 'helpers/roles';
import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { CRUMBS } from './constants';

const Card = styled.div`
  width: 150px;
  min-height: 180px;
  border-radius: 0.5rem;
  background-color: white;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  cursor: pointer;

  .svg {
    width: 64px;
    height: 64px;
    padding: 28px;
    background-color: var(--s7);
    border-radius: 100%;
    margin-bottom: 22px;
    display: flex;
  }

  &:hover {
    background-color: var(--s10);
  }
`;

const Admin = () => {
  const navigate = useNavigate();
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const [adminLinks, setAdminLinks] = useState<any[]>([]);

  useEffect(() => {
    if (userProfile) {
      const data = getRoleBasedAdminLinks(userOnBehalfOf ? userOnBehalfOf : userProfile);
      setAdminLinks(data);
    }
  }, [userProfile, userOnBehalfOf]);

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS}>
          <Grid container>
            <Grid item xs={8} container mx='auto' justifyContent='center'>
              {React.Children.toArray(
                adminLinks.map((card: any) => (
                  <Grid p={2} item className='flex-center-center'>
                    <Card onClick={() => navigate(card.link)}>
                      <div className='svg'>
                        <img src={card.icon} alt='' />
                      </div>
                      {card.title}
                    </Card>
                  </Grid>
                )),
              )}
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
    </Grid>
  );
};

export default Admin;
