import { Grid, Container } from '@mui/material';
import SearchBar from 'common/components/inputField/SearchBar';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTable from 'common/components/table/Table';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { CLIENT_APPROVAL_COLUMNS } from '../activity/constants';
import { CLIENT_CRUMBS } from './constants';

const ApproveFunds = () => {
  const [rows, setRows] = useState<Array<any>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<any>>([]);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data, isLoading } = useReactQuery(
    [`clientsWithApprovalPending${user?.userId}`],
    {
      url: 'qaip/v1/investormanagement/investors?status=Approval%20Requested',
    },
    {
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    data &&
      data.length > 0 &&
      setRows(
        data.map((client: any) => {
          return {
            ...client,
            lastUpdatedFormatted: client.last_updated.replace(/-/g, '/'),
          };
        }),
      );
  }, [data]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = rows;
    rows &&
      rows.length > 0 &&
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  if (isLoading) return <Loading />;
  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CLIENT_CRUMBS} className='activityList'>
          <Grid container alignItems='end' justifyContent='space-between'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Client Approval</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MuiTable
            columns={CLIENT_APPROVAL_COLUMNS}
            rows={filteredRows}
            canRedirect
            redirectURLPrefix='client/'
            redirectURLKey='investor_id'
            rowClassName='cursor-pointer'
            disableHeader
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default ApproveFunds;
