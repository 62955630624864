import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { ClientDetails, DocumentOwnerType, OrderDetails } from 'common/types';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableSubHeader from 'common/components/table/TableSubHeader';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import ClientDocsRow from './ClientDocsRow';
import { StyledWrapper } from 'common/styles/DocUploadTableStyle';
import NewRow from 'components/orders/orderCreation/NewRow';
import { useSelector } from 'react-redux';
import { selectClientDetails, selectNewInvestorId } from 'store/investor/selectors';

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string | React.ReactNode;
  isSelect?: boolean;
  isInput?: boolean;
  selectOptions?: Array<string>;
}

// Add row types here
// Need to figure out the right way to do this
// Right now it throws error when you try to use it as index
type Row = ClientDetails | OrderDetails | any;

interface TableProps {
  rows: Row;
  columns: Array<Column>;
  inEditing: boolean;
  isLoading?: boolean;
  ctaType?: string;
  tableClassName?: string;
  rowDocOptions: Row;
  handleRowDelete: (id: string) => void;
}

const ClientDocsTable = ({
  rows = [],
  columns,
  inEditing,
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  rowDocOptions,
  handleRowDelete,
}: TableProps) => {
  const investor = useSelector(selectClientDetails);
  const id = useSelector(selectNewInvestorId);
  const [isAddRowVisible, setIsAddRowVisible] = React.useState(false);

  return (
    <StyledWrapper className={tableClassName}>
      <div className='tableWrapper'>
        <Table>
          {isLoading ? (
            <TableHeadSkeleton length={columns?.length} />
          ) : (
            <TableSubHeader finalCols={columns} ctaType={ctaType} />
          )}
          <TableBody>
            {isLoading ? (
              <TableBodySkeleton length={columns?.length} />
            ) : (
              <>
                {rows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={columns?.length + (ctaType ? 1 : 0)} className='nullTable'>
                      No Data Available
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {rows?.map((row: Row, i: number) =>
                      row.documentsResponseDto.length > 0 ? (
                        row.documentsResponseDto.map((dto: any, idx: number) => (
                          <ClientDocsRow
                            inEditing={inEditing}
                            key={dto.documentId}
                            name={'mandatoryClientDocs'}
                            i={i}
                            isMultipleDto={true}
                            dtoIdx={idx}
                            row={row}
                            columns={columns}
                            handleRowDelete={handleRowDelete}
                            documentType={
                              row.type === 'Investor'
                                ? DocumentOwnerType.INVESTOR_PERSONAL
                                : DocumentOwnerType.ORDER
                            }
                          />
                        ))
                      ) : (
                        <ClientDocsRow
                          inEditing={inEditing}
                          key={row.documentId}
                          name={'mandatoryClientDocs'}
                          i={i}
                          row={row}
                          columns={columns}
                          handleRowDelete={handleRowDelete}
                          documentType={
                            row.type === 'Investor'
                              ? DocumentOwnerType.INVESTOR_PERSONAL
                              : DocumentOwnerType.ORDER
                          }
                        />
                      ),
                    )}
                    {isAddRowVisible && (
                      <NewRow
                        isClientRow
                        name={'addOptionalClientDocs'}
                        docOptions={rowDocOptions}
                        columns={columns}
                        setIsAddRowVisible={setIsAddRowVisible}
                        id={investor.investor_id ?? id}
                      />
                    )}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell
                        onClick={() => setIsAddRowVisible(true)}
                        className={`addNew ${isAddRowVisible ? 'disable' : ''} font-wt-500`}
                      >
                        + Add New
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </StyledWrapper>
  );
};

export default ClientDocsTable;
