import { AccountHolderDetails, AuthorizedRepresentativeDetails, WireTransfferBankDetails, CustodianType, EntityTypeDetails } from 'store/investor/types';

export const accountHolderDetails: AccountHolderDetails = {
    type: 'AccountHolderDetails',
    name: '',
    mailingAddress: '',
    addressSameAsPrimaryAccount: false,
    useableForFinanceAndTaxInfomation: false,
    useableForNoticeAndCommunications: false,
    phoneNumber: '',
    faxNumber: '',
    email: '',
    ssn: '',
    usCitizen: false,
};

export const authorizedRepresentativeDetails: AuthorizedRepresentativeDetails = {
    type: 'AuthorizedRepresentativeDetails',
    name: '',
    address: '',
    phoneNumber: '',
    faxNumber: '',
    email: '',
};

export const wireTransfferBankDetails: WireTransfferBankDetails = {
    type: 'WireTransfferBankDetails',
    bankName: '',
    bankAddress: '',
    swiftNumber: '',
    bankAccountNumber: '',
    bankAccountName: '',
    behalfOf: '',
};

export const custodianDetails: CustodianType = {
    type: 'CustodianType',
    firmName: '',
    accountName: '',
    accountNumber: '',
    behalfOf: '',
};

export const entityDetails: EntityTypeDetails = {
    type: 'EntityTypeDetails',
    name: '',
    address: '',
    email: '',
    phoneNumber: '',
    faxNumber: '',
    tin: '',
    contactName: '',
    incorporation: '',
    useableForFinanceAndTaxInfomation: false,
    useableForNoticeAndCommunications: false,
    useableForElectronicDeliveryOfStatements: false,
};