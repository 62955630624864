export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '/admin',
    label: 'Admin',
  },
  {
    link: '',
    label: 'Events',
  },
];

export const COLUMNS = [
  {
    id: 'id',
    label: 'Id',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: 'name',
    label: 'Name',
    needsFormatter: false,
    width: '20%',
  },
  {
    id: 'start_time',
    label: 'Start Time',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'publish_status',
    label: 'Status',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'created_by',
    label: 'Created By',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'updated_at',
    label: 'Last Updated',
    needsFormatter: false,
    width: '15%',
  },
];
