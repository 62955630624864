import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { ClientDetails, OrderDetails, OrderStatusType } from 'common/types';
import { useNavigate } from 'react-router';
import TableHeadSkeleton from 'common/components/skeleton/TableHeadSkeleton';
import TableBodySkeleton from 'common/components/skeleton/TableBodySkeleton';
import OrderStatusBar from 'common/components/orderStatusBar';
import { format } from 'date-fns';
import { statusList } from 'common/constants';
import TableSubHeader from 'common/components/table/TableSubHeader';
import { Pagination } from '@mui/material';
import { ALLOCATION_REQUEST_ORDERS, getAllocRequest } from 'helpers/orders';
import Textbox from 'common/components/inputField/Textbox';
import { regexValidator, valueFormatter } from 'helpers';
import CircleIcon from '@mui/icons-material/Circle';

const StyledWrapper = styled(TableContainer)`
  .tableWrapper {
    background: white;
    border-radius: 1rem;
    border: 1px solid #ddd;
    width: unset;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
  }

  thead {
    border-bottom: 1px solid #b8bcc4;
    padding: 0 22px;

    th {
      font-weight: 700;
      background-color: var(--s15);
      border: 0;
      color: #525863;
      text-transform: uppercase;
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: #fff;
    }
    tr:nth-of-type(even) {
      background-color: var(--s7);
    }
  }

  td {
    color: #626262;
    border: none;
    &:first-of-type {
      padding-left: 2rem;
    }
    &:last-of-type {
      padding-right: 2rem;
    }
  }

  .MuiTableRow-hover:hover {
    background-color: #dfe1e9 !important;
  }

  .showOnHover {
    opacity: 0;
    transition: opacity 250ms;
  }

  .MuiTableRow-hover:hover .showOnHover {
    opacity: 1;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .bold {
    font-weight: 700;
  }

  .pagination {
    padding: 1.2rem 2.5rem;
    border-top: 1px solid #d7d7d7;
  }

  .nullTable {
    background-color: #fafafa;
    height: 360px;
    color: #bdbdbd;
    text-align: center;
    vertical-align: center;
  }

  .infoIcon {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    position: relative;
    top: 6px;
    left: unset;
  }

  .alloc {
    font-style: italic;
    color: var(--s20);
  }

  .allocTooltip {
    font-size: 1.25rem;
    margin-left: 0.5rem;
    position: absolute;
    top: 50%;
    right: 0.25rem;
    transform: translateY(-50%);
  }
`;

interface Column {
  id: string;
  label: string;
  align?: 'left' | 'center' | 'inherit' | 'justify' | 'right';
  showOnHover?: boolean;
  bold?: boolean;
  showProgress?: boolean;
  needsFormatter: boolean;
  isDate?: boolean;
  cellClassName?: string;
  percent?: boolean;
  width?: string;
  tooltip?: string;
  isInputField?: boolean;
}

// Add row types here
// Need to figure out the right way to do this
// Right now it throws error when you try to use it as index
type Row = ClientDetails | OrderDetails | any;

interface TableProps {
  rows: Row;
  columns: Array<Column>;
  rowClassName?: string;
  isLoading?: boolean;
  ctaType?: string;
  tableClassName?: string;
  redirectURLKey?: string;
  redirectURLPrefix?: string;
  canRedirect?: boolean;
  rowsPerPage?: number;
  activityTable?: boolean;
  activityTableNewColumns?: Array<Column>;
  handleCtaClick?: any;
  dateFormat?: string;
  allocClick?: boolean;
  isAllocatedAmountDisabled?: boolean;
  onRowClick?: any;
  allocationDetails?: any[];
  setAllocationDetails?: any;
  allocate: boolean;
  fundId: string;
  notificationArray?: any[];
  clearNotification: any;
}

const FundOrdersTable = ({
  rows = [],
  columns,
  rowClassName = '',
  isLoading = false,
  ctaType = '',
  tableClassName = '',
  redirectURLKey = '',
  redirectURLPrefix = '',
  canRedirect = false,
  rowsPerPage = 10,
  activityTable = false,
  activityTableNewColumns,
  dateFormat = 'MMM-dd-yyyy',
  allocClick = false,
  onRowClick,
  isAllocatedAmountDisabled = false,
  allocationDetails = [],
  setAllocationDetails,
  allocate = false,
  fundId = '',
  notificationArray = [],
  clearNotification,
}: TableProps) => {
  const [finalRows, setFinalRows] = useState<Row[]>(rows);
  const [rowsBeforePagination, setRowsBeforePagination] = useState<Row[]>([]);
  const [orderBy, setOrderBy] = useState<{ id: string; dir: boolean; index: number }>({
    id: '',
    dir: true,
    index: -1,
  });
  const [page, setPage] = useState<number>(0);
  const [finalCols, setFinalCols] = useState<Array<Column>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFinalRows(rows);
  }, [rows]);

  // If activity Table, do not render Status column
  useEffect(() => {
    if (activityTableNewColumns) {
      setFinalCols(activityTableNewColumns);
    } else setFinalCols(columns);
  }, [activityTable, columns, activityTableNewColumns]);

  // Column Ordering
  const orderByReturnVal = (a: any, b: any) => {
    if (finalCols[orderBy.index].isDate) {
      if (orderBy.dir) {
        const c: any = new Date(a[orderBy.id]);
        const d: any = new Date(b[orderBy.id]);
        return c - d;
        // return (new Date(a[orderBy.id]) - new Date(b[orderBy.id]));
      } else {
        const c: any = new Date(a[orderBy.id]);
        const d: any = new Date(b[orderBy.id]);
        return d - c;
      }
    } else if (isNaN(a[orderBy.id]) && isNaN(b[orderBy.id])) {
      if (!a[orderBy.id]) {
        return 1;
      } else if (orderBy.dir) {
        return a[orderBy.id]?.localeCompare(b[orderBy.id]);
      } else {
        return a[orderBy.id]?.localeCompare(b[orderBy.id]) * -1;
      }
    } else {
      if (orderBy.dir) {
        return a[orderBy.id] - b[orderBy.id];
      } else {
        return b[orderBy.id] - a[orderBy.id];
      }
    }
  };
  useEffect(() => {
    const arr = rowsBeforePagination.sort((a: Row, b: Row) => {
      const val = orderBy.id !== '' ? orderByReturnVal(a, b) : 0;
      return val;
    });
    setRowsBeforePagination([...arr]);
    // eslint-disable-next-line
  }, [orderBy]);

  //Filtering
  useEffect(() => {
    let filteredRows: Row = rows;
    setRowsBeforePagination(filteredRows);
    // eslint-disable-next-line
  }, [rows]);

  // Pagination
  useEffect(() => {
    if (rowsPerPage)
      setFinalRows(
        rowsBeforePagination.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      );
  }, [page, rowsBeforePagination, rowsPerPage]);

  return (
    <StyledWrapper className={tableClassName}>
      <div className='tableWrapper'>
        <div style={{ overflowX: 'auto' }}>
          <Table>
            {isLoading ? (
              <TableHeadSkeleton length={finalCols?.length} />
            ) : (
              <TableSubHeader
                finalCols={finalCols}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                ctaType={ctaType}
              />
            )}
            <TableBody>
              {isLoading ? (
                <TableBodySkeleton length={finalCols?.length} />
              ) : (
                <>
                  {finalRows.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={finalCols?.length + (ctaType ? 1 : 0)}
                        className='nullTable'
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  ) : (
                    React.Children.toArray(
                      finalRows.map((row: Row, i: number) => (
                        <TableRow
                          onClick={() => {
                            !!clearNotification && clearNotification([row[redirectURLKey]]);
                            canRedirect &&
                              navigate(`/${redirectURLPrefix}${row[redirectURLKey]}`, {
                                state: { fromMyOrderBooks: `/myorderbooks/${fundId}` },
                              });
                            allocClick && onRowClick();
                          }}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          className={rowClassName}
                        >
                          {React.Children.toArray(
                            finalCols.map((col: Column) => (
                              <TableCell
                                onClick={() => {
                                  if (col.id === 'investorName') {
                                    !!clearNotification && clearNotification([row[redirectURLKey]]);
                                    navigate(`/${redirectURLPrefix}${row[redirectURLKey]}`, {
                                      state: { fromMyOrderBooks: `/myorderbooks/${fundId}` },
                                    });
                                  }
                                }}
                                align={col.align}
                                className={`
                            ${col.showOnHover && 'showOnHover'}
                            ${col.bold && 'bold'}
                            ${
                              activityTable &&
                              col.id === 'eventAmount' &&
                              getAllocRequest(row?.orderId) >= 0 &&
                              ALLOCATION_REQUEST_ORDERS[getAllocRequest(row?.orderId)].subAmount ===
                                '--' &&
                              'italic'
                            }
                            ${col.cellClassName ? col.cellClassName : ''}
                            ${
                              activityTable &&
                              col.id === 'eventAmount' &&
                              row['eventName'] === 'Allocation Request'
                                ? 'alloc'
                                : ''
                            }
                            position-relative
                            ${!canRedirect && col.id === 'investorName' && 'cursor-pointer'}
                          `}
                                sx={{ width: `${col?.width ? col?.width : 'initial'}` }}
                              >
                                {col.showProgress && (
                                  <OrderStatusBar
                                    value={
                                      statusList.filter(
                                        (val: any) => val.statusString === row[col.id],
                                      )[0]?.width
                                    }
                                  />
                                )}

                                {col.isInputField &&
                                allocate &&
                                Number(row.orderStatusId) ===
                                  OrderStatusType.SENT_TO_FUND_MANAGER &&
                                row.orderEventId === '3' ? (
                                  <div
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Textbox
                                      startAdornment={'$'}
                                      className={
                                        !isAllocatedAmountDisabled ? 'tableInputActive' : ''
                                      }
                                      disabled={isAllocatedAmountDisabled}
                                      value={valueFormatter(
                                        allocationDetails.find((a) => a.orderId === row.orderId)
                                          ?.amount,
                                      )}
                                      onChange={(e: any) => {
                                        if (
                                          regexValidator(e.target.value, /^[0-9,]*$/i) ||
                                          e.target.value.replace(/,/g, '') >
                                            Number(row['amountRequested'])
                                        )
                                          return;
                                        setAllocationDetails(
                                          allocationDetails.map((alloc: any, index: number) => ({
                                            ...alloc,
                                            amount:
                                              alloc.orderId === row.orderId
                                                ? e.target.value.replace(/,/g, '')
                                                : alloc?.amount.replace(/,/g, ''),
                                          })),
                                        );
                                      }}
                                    />
                                  </div>
                                ) : row[col.id] ? (
                                  col.needsFormatter ? (
                                    row.orderEventId === '3' &&
                                    col.id === 'eventAmount' &&
                                    Number(row.orderStatusId) ===
                                      OrderStatusType.SENT_TO_FUND_MANAGER ? (
                                      '--'
                                    ) : (
                                      `$${Number(row[col.id])?.toLocaleString()}`
                                    )
                                  ) : col.isDate ? (
                                    format(new Date(row[col.id]), dateFormat)
                                  ) : col.percent ? (
                                    row[col.id] + '%'
                                  ) : col.label === 'ID' &&
                                    notificationArray.includes(row['orderId'].toString()) ? (
                                    <div className='d-flex'>
                                      <CircleIcon
                                        className='notification-bubble row-bubble'
                                        fontSize='small'
                                      />{' '}
                                      {row[col.id]}
                                    </div>
                                  ) : (
                                    row[col.id]
                                  )
                                ) : (
                                  '--'
                                )}
                              </TableCell>
                            )),
                          )}
                        </TableRow>
                      )),
                    )
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </div>
        {rowsPerPage && rowsBeforePagination.length > rowsPerPage && (
          <Pagination
            className='pagination'
            count={Math.ceil(rowsBeforePagination.length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)}
          />
        )}
      </div>
    </StyledWrapper>
  );
};

export default FundOrdersTable;
