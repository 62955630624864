import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { ErrorMessage } from 'common/components/errorMessageBox';
import Textbox from 'common/components/inputField/Textbox';
import { WireTransfferBankDetails } from 'store/investor/types';

interface Props {
  additional?: boolean;
  register: any;
  errors: any;
  index: number;
  wireTransfferBank?: WireTransfferBankDetails;
  onWireTransfferChange: (property: string, idx: number, value: any) => void;
  onWireTransfferRemove: (index: number) => void;
  handleAddWireTransfferBankDetails?: any;
  detailsState?: any;
  setDetailsState?: any;
}

const WireTransfferBank = ({
  additional = false,
  register,
  errors,
  index,
  wireTransfferBank,
  onWireTransfferChange,
  onWireTransfferRemove,
  handleAddWireTransfferBankDetails,
  detailsState,
  setDetailsState,
}: Props) => {
  return wireTransfferBank ? (
    <Grid container className='my-5'>
      <Grid item xs={12} className='header flex-end-between'>
        <h3 className='mb-0 font-wt-500'>Wire Transfer Bank Details</h3>
        <MuiButton variant='text' className='p-0' buttonClick={() => onWireTransfferRemove(index)}>
          Remove
        </MuiButton>
      </Grid>
      <Grid item xs={12}>
        <h4 className='font-wt-400 mb-0'>Bank Name</h4>
        <Textbox
          type='text'
          register={register}
          name={`bankNameWT${index}`}
          fullWidth
          value={wireTransfferBank.bankName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('bankName', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Address</h4>
        <Textbox
          type='text'
          register={register}
          name={`bankAddressWT${index}`}
          fullWidth
          multiline
          rows={4}
          value={wireTransfferBank.bankAddress}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('bankAddress', index, e.target.value)
          }
          maxLength={255}
        />
        {errors[`bankAddressWT${index}`] &&
          errors[`bankAddressWT${index}`].type === 'maxLength' && (
            <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
          )}
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Email</h4>
        <Textbox
          register={register}
          fullWidth
          value={wireTransfferBank.bankAccountNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('bankAccountNumber', index, e.target.value)
          }
          name={`bankAccountNumberWT${index}`}
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>Account Name</h4>
        <Textbox
          register={register}
          value={wireTransfferBank.bankAccountName}
          fullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('bankAccountName', index, e.target.value)
          }
          name={`bankAccountNameWT${index}`}
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>For Benefit of (FBO)</h4>
        <Textbox
          type='text'
          register={register}
          name={`behalfOfWT${index}`}
          fullWidth
          value={wireTransfferBank.behalfOf}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('behalfOf', index, e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} mt={'24px'}>
        <h4 className='font-wt-400 mb-0'>ABA/ CHIPS or SWIFT number</h4>
        <Textbox
          type='text'
          register={register}
          name={`swiftNumberWT${index}`}
          fullWidth
          value={wireTransfferBank.swiftNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onWireTransfferChange('swiftNumber', index, e.target.value)
          }
        />
      </Grid>
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs={12} className='header flex-end-between'>
          <h3 className='mb-0 font-wt-500'>Wire Transfer Bank Details</h3>
        </Grid>
        <Grid item xs={12}>
          <h4 className='font-wt-400 mb-0'>Bank Name</h4>
          <Textbox
            type='text'
            register={register}
            name={`bankNameWT${index}`}
            fullWidth
            value={detailsState.bankName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, bankName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Address</h4>
          <Textbox
            type='text'
            register={register}
            name={`bankAddressWT${index}`}
            fullWidth
            multiline
            rows={4}
            value={detailsState.bankAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, bankAddress: e.target.value })
            }
            maxLength={255}
          />
          {errors[`bankAddressWT${index}`] &&
            errors[`bankAddressWT${index}`].type === 'maxLength' && (
              <ErrorMessage error='Input limit exceeded. Character Limit is 255' />
            )}
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Email</h4>
          <Textbox
            register={register}
            fullWidth
            value={detailsState.bankAccountNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, bankAccountNumber: e.target.value })
            }
            name={`bankAccountNumberWT${index}`}
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>Account Name</h4>
          <Textbox
            register={register}
            value={detailsState.bankAccountName}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, bankAccountName: e.target.value })
            }
            name={`bankAccountNameWT${index}`}
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>For Benefit of (FBO)</h4>
          <Textbox
            type='text'
            register={register}
            name={`behalfOfWT${index}`}
            fullWidth
            value={detailsState.behalfOf}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, behalfOf: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={'24px'}>
          <h4 className='font-wt-400 mb-0'>ABA/ CHIPS or SWIFT number</h4>
          <Textbox
            type='text'
            register={register}
            name={`swiftNumberWT${index}`}
            fullWidth
            value={detailsState.swiftNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDetailsState({ ...detailsState, swiftNumber: e.target.value })
            }
          />
        </Grid>
      </Grid>
      <MuiButton
        variant='outlined'
        className='mt-2'
        buttonClick={() => {
          handleAddWireTransfferBankDetails({ ...detailsState });
        }}
      >
        Add
      </MuiButton>
    </>
  );
};

export default WireTransfferBank;
