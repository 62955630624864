import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTable from 'common/components/table/Table';
import { format } from 'date-fns';
import { getOrderStatus } from 'helpers/orders';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import SearchBar from 'common/components/inputField/SearchBar';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { canCreateOrder } from 'helpers/roles';
import { resetOrderData } from 'store/order/slice';
import { QUALIS_USER_COLUMNS, COLUMNS, CRUMBS } from './constants';
import { UserProfile } from 'store/user/types';
import { OrderSummary, QueryType, ReactQueryDataFetch } from 'common/types';
import { useQueryClient } from 'react-query';

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const [rows, setRows] = useState<Array<OrderSummary>>([]);
  const [filteredRows, setFilteredRows] = useState<Array<OrderSummary>>([]);
  const [notificationArray, setNotificationArray] = useState<Array<string>>([]);
  const userOnBehalfOf: UserProfile | null = useAppSelector(selectBehalfOf);
  const userProfile: UserProfile | null = useAppSelector(selectUserProfile);
  const user: UserProfile | null = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const { data = [], isLoading } = useReactQuery(
    [`orders${user?.userId}`],
    {
      url: 'qaip/v1/ordermanagement/orders/',
    },
    {
      refetchOnMount: true,
      retryOnMount: true,
    },
  ) as { data: OrderSummary[] } & ReactQueryDataFetch;

  const { data: notifications } = useReactQuery(
    ['notifications'],
    {
      url: 'qaip/v1/notificationmanagement/emails',
    },
    {
      refetchOnMount: true,
    },
  );

  const { mutate: clearOrderNotification } = useRQMutation(
    {
      url: 'qaip/v1/ordermanagement/deleteNotification',
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries('notifications');
      },
    },
  );

  useEffect(() => {
    setNotificationArray(notifications?.order_emails?.map((email: any) => email.order_id));
  }, [notifications]);

  useEffect(() => {
    data.length > 0 &&
      setRows(
        data
          .sort((a: any, b: any) => {
            if (a.lastUpdatedDate > b.lastUpdatedDate) return -1;
            else if (a.lastUpdatedDate < b.lastUpdatedDate) return 1;
            else return 0;
          })
          .map((row: any) => ({
            ...row,
            lastUpdatedBy: `${row.firstName} ${row.lastName}`,
            eventName:
              row.orderEventId === 1
                ? 'Subscription'
                : row.orderEventId === 2
                ? 'Redemption'
                : 'Allocation Request',
            statusName:
              row.orderStatusId === 4 && row.orderEventId === 3
                ? 'Pending Allocation'
                : getOrderStatus(row.orderStatusId),
            eventDateFormatted: format(new Date(row.eventDate), 'dd MMM yyyy'),
          })),
      );
  }, [data]);

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = rows;
    if (rows && rows.length > 0) {
      setFilteredRows(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    }
    // eslint-disable-next-line
  }, [searchQuery, rows]);

  if (isLoading) return <Loading />;
  return (
    <Grid container sx={{ mt: 8 }}>
      <Grid item xs={12} mb={0.5}>
        <SubHeader crumbs={CRUMBS} className='activityList'>
          <Grid container alignItems='flex-end' justifyContent='space-between' sx={{ pt: 1 }}>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Orders</h1>
            </Grid>
            <Grid item xs={5} container alignItems='center' justifyContent='flex-end'>
              <SearchBar
                minWidth='275px'
                className=' mr-20'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <MuiButton
                buttonClick={() => {
                  dispatch(resetOrderData());
                  navigate('/create-order');
                }}
                variant='contained'
                className='ml-3 '
                disabled={(user as UserProfile) && !canCreateOrder(user as UserProfile)}
              >
                Add New Order
              </MuiButton>
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <MuiTable
            rows={filteredRows}
            notificationArray={notificationArray}
            columns={user?.category === 3 ? QUALIS_USER_COLUMNS : COLUMNS}
            rowsPerPage={10}
            canRedirect
            activityTable
            rowClassName='cursor-pointer'
            isLoading={isLoading}
            clearNotification={clearOrderNotification}
            redirectURLKey='orderId'
            redirectURLPrefix='order/'
            activityTableNewColumns={
              user?.category === 3
                ? QUALIS_USER_COLUMNS.filter((col: any) => col.id !== 'statusName')
                : COLUMNS.filter((col: any) => col.id !== 'statusName')
            }
          />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Orders;
