import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import MuiCard from 'common/components/card';
import { columns } from 'components/orders/orderCreation/constants';
import { useSelector } from 'react-redux';
import { useReactQuery } from 'store/hooks';
import { selectClientDetails, selectNewInvestorId } from 'store/investor/selectors';
import ClientDocsTable from './ClientDocsTable';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const StyledCard = styled(MuiCard)`
  h2 {
    font-size: 22px;
  }

  .docRow {
    padding: 12px 0;
    & > div {
      display: flex;
      align-items: center;
      padding: 0 0.875rem;
    }
    svg {
      font-size: 1rem;
      color: #8e8e8e;
      &.check {
        color: var(--green);
      }
    }
    .leftActions {
      border-right: 1px solid var(--s30);
    }
    .docName {
      font-size: 0.875rem;
    }
    .file {
      color: var(--s30);
      span {
        font-size: 13px;
      }
    }
  }

  .docRow + .docRow {
    border-top: 1px solid var(--s15);
  }
`;

type Props = {
  moveBack: () => void;
  inEditing: boolean;
  handleModalClose?: any;
};

const Step3 = ({ moveBack, inEditing, handleModalClose }: Props) => {
  const investor = useSelector(selectClientDetails);
  const newClientID = useSelector(selectNewInvestorId);
  const [clientDocRows, setClientDocRows] = useState<any[]>([]);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { data: orderDocs, isLoading } = useReactQuery(
    [`investorDoc-${investor.investor_id ?? newClientID}`],
    {
      url: 'qaip/v1/documentmanagement/masterchecklist',
      params: {
        type: 'client',
        investorId: investor.investor_id ?? newClientID,
      },
    },
  );

  useEffect(() => {
    if (orderDocs) {
      setClientDocRows(orderDocs?.investor);
    }
  }, [orderDocs]);

  const handleDeleteRow = (id: string) => {
    setClientDocRows(clientDocRows.filter((row: any) => row.id !== id));
  };

  return (
    <StyledCard sx={{ py: 0, pl: 1, border: 'none' }}>
      <Grid container rowSpacing={'30px'} columnSpacing={3}>
        <Grid item xs={12} mb={'10px'}>
          <h2 className='font-wt-500 mb-0'>Upload Documents</h2>
          <p className='mt-3'>
            Optional. Upload client documentation here. Any documents uploaded as part of an order
            submission will be saved here.
          </p>
        </Grid>
        <Grid item xs={12}>
          <ClientDocsTable
            rows={clientDocRows}
            rowDocOptions={orderDocs?.investor}
            columns={columns}
            inEditing={inEditing}
            isLoading={isLoading}
            handleRowDelete={(id: string) => handleDeleteRow(id)}
          />
        </Grid>
        {/* This will prevent the submission of form on pressing enter key */}
        <button type='submit' disabled className='d-none' aria-hidden='true'></button>
        <Grid item xs={12} sx={{ mt: 2 }} container alignItems='center' justifyContent='flex-end'>
          <MuiButton buttonClick={() => moveBack()} className='mr-3'>
            Back
          </MuiButton>
          {/* <MuiButton buttonClick={() => {}} color='error' className='mr-3'>
            Exit Without Saving
          </MuiButton> */}
          <MuiButton
            buttonClick={() => {
              !inEditing ? navigate(`/client/${newClientID}`) : handleModalClose();
            }}
            variant='contained'
            type='submit'
            minWidth='150px'
          >
            Save & Exit
          </MuiButton>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default Step3;
