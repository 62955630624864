import { encryptDocumentUrl } from 'helpers';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import PrefillDoc from './PrefillDoc';

const PrefillDocWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const DOC_ID = location.pathname.split('/')[3];
  const FUND_ID = location.pathname.split('/')[1];
  const [mappingDetails, setMappingDetails] = useState([]);
  const [uploadedFileData, setUploadedFileData] = useState<any>({});
  const [saveAndExit, setSaveAndExit] = useState<boolean>(false);

  const { data: documentDetails } = useReactQuery(['documentDetails'], {
    params: { doc_id: DOC_ID },
    url: 'qaip/v1/documentmanagement/getDocument',
  });

  const { data: dropdownDetails = [] } = useReactQuery(['dropdownDetails'], {
    url: 'qaip/v1/documentmanagement/prefillDataBlocks',
  });

  const { data: dropdownMap = [] } = useReactQuery(
    ['dropdownMap'],
    {
      url: `qaip/v1/documentmanagement/prefillDocument?doc_id=${documentDetails?.documentId}&doc_object_key=${documentDetails?.docLink}`,
    },
    {
      enabled: !!documentDetails,
      onSuccess: (res: any) =>
        setMappingDetails(
          res.map((option: any) => ({
            pdf_field_name: option.pdf_field_name,
            pdf_field_type: option.pdf_field_type,
            tag_key_name: option.tag_key_name,
          })),
        ),
    },
  );

  const { mutate: fetchPreSignedUrl, data: documentLink } = useRQMutation({
    url: 'qaip/v1/documentmanagement/fetchdownloadpresignedurl',
  });

  const { mutate: updateDocumentData } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/updatedocumentdata',
      isFormData: true,
    },
    {
      onSuccess: () => {
        saveAndExit && navigate(`/investment/${FUND_ID}`);
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Changes have been saved.',
        };
        dispatch(showToast(toast));
      },
    },
  );
  const { mutate: generatePreSignedUrl, isLoading: isGeneratePreSignedUrlLoading } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      onSuccess: async (data) => {
        var fileData = new File([uploadedFileData], documentDetails.docName, {
          type: 'application/pdf',
        });
        await encryptDocumentUrl(data.url, fileData);
        const docDetails = {
          doc_name: documentDetails.docName,
          doc_config_type_id: documentDetails.docConfigTypeId,
          doc_type: documentDetails.docType,
          doc_category_id: documentDetails.docCategoryId,
          doc_classification_name: documentDetails.docClassificationName,
          doc_category_type_id: 2,
          status: documentDetails.status,
          doc_config_id: documentDetails.docConfigId,
          object_key: documentDetails.docLink,
          document_type: 'FUND_DOCUMENT',
          prefill_document_tagged: true,
          reupload_doc_id: DOC_ID,
        };
        await updateDocumentData({ documentData: docDetails });
      },
    },
  );

  const { mutate: mapFieldNameToKey } = useRQMutation({
    url: `qaip/v1/documentmanagement/prefillDocument?doc_id=${documentDetails?.documentId}&doc_object_key=${documentDetails?.docLink}`,
  });

  const handleOnChange = async (e: any, name: any, type: any) => {
    let details: any = [...mappingDetails];
    const isValid = mappingDetails.some((mapping: any) => mapping.pdf_field_name === name);

    if (isValid) {
      const index = mappingDetails.map((e: any) => e.pdf_field_name).indexOf(name);
      const detailsObj: any = mappingDetails[index];
      const newDetailsObj: any = { ...detailsObj, tag_key_name: e.target.value };
      details[index] = newDetailsObj;
    } else {
      details = [
        ...mappingDetails,
        {
          pdf_field_name: name,
          tag_key_name: e.target.value,
          pdf_field_type: type,
        },
      ];
    }
    setMappingDetails(details);
  };

  useEffect(() => {
    if (documentDetails) fetchPreSignedUrl({ object_key: documentDetails.docLink } as any);
  }, [documentDetails]); // eslint-disable-line

  return (
    <PrefillDoc
      documentDetails={documentDetails}
      docUrl={documentLink?.url}
      dropdownDetails={dropdownDetails?.map((option: any) => option.display)}
      dropdownMap={dropdownMap}
      mappingDetails={mappingDetails}
      mapFieldNameToKey={mapFieldNameToKey}
      handleOnChange={handleOnChange}
      generatePreSignedUrl={generatePreSignedUrl}
      setUploadedFileData={setUploadedFileData}
      setSaveAndExit={setSaveAndExit}
      isGeneratePreSignedUrlLoading={isGeneratePreSignedUrlLoading}
    />
  );
};

export default PrefillDocWrapper;
