import { Button, Container, Divider, Grid, Menu, MenuItem } from '@mui/material';
import DownArrow from '@mui/icons-material/ArrowDownwardOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiButton from 'common/components/button';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Textbox from 'common/components/inputField/Textbox';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  selectFundID,
  selectFundPriceAnnualReturns,
  selectFundPriceMonthlyReturns,
  selectFundPriceInceptionReturns,
  selectFundsDetails,
  selectFundStructAndStats,
  selectFundsTermsAndServices,
} from 'store/funds/selectors';
import {
  setFundPriceAnnualReturns,
  setFundPriceMonthlyReturns,
  setFundPriceInceptionReturns,
  setFundsDetails,
  setFundStructAndStats,
  setFundTermsAndServices,
} from 'store/funds/slice';
import { useAppSelector, useReactQuery, useRQMutation, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryType } from 'common/types';
import { useQueryClient } from 'react-query';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { encryptDocumentUrl } from 'helpers';

type Props = {
  handleSubmitBtnClick: any;
  handleBackBtnClick: any;
  editing?: boolean;
};

const StyledWrapper = styled.div`
  .heading {
    font-weight: 500;
  }
  .sub-heading {
    color: #898989;
    &--table {
      color: #525863;
    }
    &--tableBody {
      font-weight: 500;
      color: var(--s40);
    }
  }
  .label {
    margin: 0;
    color: #0d0c22;
  }
  .date-picker {
    margin-top: -20px;
    margin-right: auto;
  }
  .action-btns {
    margin: 0 5px;
  }
  /* .select-years-div {
    back
  } */
  .dropdown-menu {
    max-height: 10px;
  }

  #basic-button {
    font-size: 1rem;
  }

  .delete-icon {
    opacity: 0.5;
    cursor: pointer;
  }

  .monthYearToggle {
    border-radius: 1rem;
    border: 1px solid var(--p200);
    overflow: hidden;
    button {
      border: none;
      color: var(--p200);
      &.Mui-selected {
        background-color: var(--p200);
        color: white;
      }
    }
  }

  .table {
    border-radius: 0.5rem;
    border: 1px solid var(--s15);
    overflow: hidden;

    .table-head {
      padding: 1rem 2rem;
      background-color: var(--s15);
    }
    .table-row:nth-of-type(odd) {
      background-color: var(--s7);
    }
  }
`;

const StyledDatePicker = styled(DatePicker)``;

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const InvestmentHistoricalReturnsForm = ({
  handleSubmitBtnClick,
  handleBackBtnClick,
  editing,
}: Props) => {
  const [toggle, setToggle] = useState<string>('Monthly');
  const [yearDateInMonthlyTab, setYearDateInMonthlyTab] = useState<any>(new Date());
  const [monthlyDateInMonthlyTab, setMonthlyDateInMonthlyTab] = useState<any>(new Date());
  const [yearDateInAnnualyTab, setYearDateInAnnualyTab] = useState<any>(new Date());
  const [yearInMonthlyTab, setYearInMonthlyTab] = useState<any>(new Date().getFullYear());
  const [allYearsInMonthlyTab, setAllYearsInMonthlyTab] = useState<any[]>([
    new Date().getFullYear(),
  ]);
  const [allYearsInYearlyTab, setAllYearsInYearlyTab] = useState<any[]>([new Date().getFullYear()]);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false); // * This state is used to show/hide the datePicker in the Annual returns
  const [openMonthlyDatePicker, setOpenMonthlyDatePicker] = useState<boolean>(false); // * This state is used to show/hide the datePicker in the monthly returns
  const [inEditingMode, setInEditingMode] = useState<boolean>(false);
  const [annualReturns, setAnnualReturns] = useState<any[]>([
    {
      year: new Date().getFullYear(),
      value: 0,
    },
  ]);
  const [allMonthsInMonthlyTab, setAllMonthInMonthlyTab] = useState<any[]>([]);
  const [monthlyPriceReturns, setMonthlyPriceReturns] = useState<any[]>([]);
  const [filteredMonthlyPriceReturns, setFilteredMonthlyPriceReturns] = useState<any[]>([]);
  const [filteredYearIndex, setFilteredYearIndex] = useState<number>(0);

  const [documentData, setDocumentData] = useState<any>({
    doc_name: 'logo.jpeg',
    doc_type: 'logo',
    doc_config_type_id: null,
    doc_category_id: '4bc73402-852e-452a-91fc-cd405d3d48e3',
    doc_classification_name: null,
    doc_category_type_id: 1,
    status: 'Active',
    doc_config_id: null,
    object_key: '4bc73402-852e-452a-91fc-cd405d3d48e3/logo/logo.jpeg',
    document_type: 'FUND',
  });

  const addNewForYear = useRef(null);
  const addNewForMonth = useRef(null);

  // inception returns state
  const [firstYearReturn, setFirstYearReturn] = useState<string>('');
  const [thirdYearReturn, setThirdYearReturn] = useState<string>('');
  const [fifthYearReturn, setFifthYearReturn] = useState<string>('');
  const [tenthYearReturn, setTenthYearReturn] = useState<string>('');
  const [sinceInceptionReturn, setSinceInceptionReturn] = useState<string>('');
  const [returnsSinceInceptionReturns, setReturnsSinceInception] = useState<any>({});

  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile: any = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const navigate = useNavigate();

  const location = useLocation();
  const id = location.pathname.split('/')[3];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    if (e.target.textContent) {
      setYearInMonthlyTab(parseInt(e.target.textContent));
    }
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const storeFundAnnualPriceReturns = useAppSelector(selectFundPriceAnnualReturns);
  const storeFundMonthlyPriceReturns = useAppSelector(selectFundPriceMonthlyReturns);
  const storeFundInceptionReturns = useAppSelector(selectFundPriceInceptionReturns);
  const storeFundsTandSProviders = useAppSelector(selectFundsTermsAndServices);
  const storeFundDetails = useAppSelector(selectFundsDetails);
  const storeFundStructAndStats = useAppSelector(selectFundStructAndStats);
  const storeFundNewID = useAppSelector(selectFundID);
  const dispatch = useTypedDispatch();

  const createMonthForYears = (year: any) => {
    const newMonthlyPriceReturns: any[] = [];
    const limitMonths = year === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
    for (let index = 1; index <= limitMonths; index++) {
      newMonthlyPriceReturns.push({
        year: year,
        month: index,
        value: 0,
      });
    }
    return newMonthlyPriceReturns;
  };

  const sortMonthlyPriceReturns = (monthlyPRArr: any[]) => {
    const newMonthlyPriceReturns = [...monthlyPRArr];
    const yearlySortedMonthlyPriceReturns = newMonthlyPriceReturns.sort(
      (a, b) => a.year - b.year || a.month - b.month,
    );
    setMonthlyPriceReturns(yearlySortedMonthlyPriceReturns);
    filterMonthlyPriceReturns(yearlySortedMonthlyPriceReturns);
  };

  const filterMonthlyPriceReturns = (
    currentMonthlyPriceReturns?: any[],
    lastYearInReturns?: number,
  ) => {
    const currentYear = lastYearInReturns ? lastYearInReturns : yearInMonthlyTab;
    const currentMonthlyReturns = currentMonthlyPriceReturns
      ? currentMonthlyPriceReturns
      : monthlyPriceReturns;

    const filteredMonthlyReturns = currentMonthlyReturns.filter((el) => el.year === currentYear);
    const requiredIndex = currentMonthlyReturns
      .map(function (e) {
        return e.year;
      })
      .indexOf(currentYear);

    setFilteredYearIndex(requiredIndex);
    setFilteredMonthlyPriceReturns(filteredMonthlyReturns);
  };

  const fetchAllMonthsForAYear = (
    currentMonthlyPriceReturns?: any[],
    lastYearInReturns?: number,
  ) => {
    const currentYear = lastYearInReturns ? lastYearInReturns : yearInMonthlyTab;
    const currentMonthlyReturns = currentMonthlyPriceReturns
      ? currentMonthlyPriceReturns
      : monthlyPriceReturns;

    const filteredMonthlyReturns = currentMonthlyReturns.filter((el) => el.year === currentYear);
    const presentMonths = filteredMonthlyReturns.map((el) => el.month);

    setAllMonthInMonthlyTab(presentMonths);
  };

  useEffect(() => {
    if (storeFundMonthlyPriceReturns) {
      (async function () {
        const yearsPresent: any[] = [];

        const defaultMonthlyPriceReturns: any[] = [...storeFundMonthlyPriceReturns];

        const sortedStoreFundMonthlyReturns = defaultMonthlyPriceReturns.sort(
          (a, b) => a.year - b.year || a.month - b.month,
        );

        sortedStoreFundMonthlyReturns.forEach((el: any) => {
          if (!yearsPresent.includes(el.year)) {
            yearsPresent.push(el.year);
          }
        });

        const lastYear = yearsPresent[yearsPresent.length - 1];
        setYearInMonthlyTab(lastYear);

        setAllYearsInMonthlyTab(yearsPresent);

        await setMonthlyPriceReturns(sortedStoreFundMonthlyReturns);
        filterMonthlyPriceReturns(sortedStoreFundMonthlyReturns, lastYear);

        fetchAllMonthsForAYear(sortedStoreFundMonthlyReturns, lastYear);
      })();
    } else {
      (async function () {
        const currentYear = new Date().getFullYear();
        // setYearInMonthlyTab(currentYear);
        setAllYearsInMonthlyTab([currentYear]);
        const defaultMonthlyPriceReturns: any[] = await createMonthForYears(currentYear);
        filterMonthlyPriceReturns(defaultMonthlyPriceReturns, currentYear);
        fetchAllMonthsForAYear(defaultMonthlyPriceReturns, currentYear);
        setMonthlyPriceReturns(defaultMonthlyPriceReturns);
        setFilteredMonthlyPriceReturns(defaultMonthlyPriceReturns);
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (editing) {
      const existingYearsInAnnualTab = storeFundAnnualPriceReturns.map((el: any) => el.year);
      setAllYearsInYearlyTab(existingYearsInAnnualTab);
    }
  }, [editing, storeFundAnnualPriceReturns]);

  useEffect(() => {
    if (storeFundAnnualPriceReturns) {
      setAnnualReturns(storeFundAnnualPriceReturns);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (storeFundInceptionReturns) {
      setFirstYearReturn(storeFundInceptionReturns['1_year']);
      setThirdYearReturn(storeFundInceptionReturns['3_years']);
      setFifthYearReturn(storeFundInceptionReturns['5_years']);
      setTenthYearReturn(storeFundInceptionReturns['10_years']);
      setSinceInceptionReturn(storeFundInceptionReturns['since_inception']);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    filterMonthlyPriceReturns();
    fetchAllMonthsForAYear();
    // eslint-disable-next-line
  }, [yearInMonthlyTab]);

  const { mutate: addAnnualReturns, isLoading: annualReturnsLoading } = useRQMutation(
    {
      url: `qaip/v1/fundsmanagement/funds/annualreturns/${storeFundNewID}`,
    },
    {
      onSuccess: () => {
        handleSubmitBtnClick();
      },
    },
  );

  const { mutate: addMonthlyPriceReturns, isLoading: monthlyReturnsLoading } = useRQMutation(
    {
      url: `qaip/v1/fundsmanagement/funds/monthlyreturns/${storeFundNewID}`,
    },
    {
      onSuccess: () => {
        addAnnualReturns({
          annual_returns: [...annualReturns],
          returns_since_inception: {
            '1_year': firstYearReturn,
            '3_years': thirdYearReturn,
            '5_years': fifthYearReturn,
            '10_years': tenthYearReturn,
            since_inception: sinceInceptionReturn,
          },
        });
      },
    },
  );

  const { mutate: updateDocumentData, isLoading: isUploadingDoc } = useRQMutation({
    url: 'qaip/v1/documentmanagement/updatedocumentdata',
    isFormData: true,
  });

  const { mutate: fetchPresignedUrl, isLoading: isFetchingPredesignUrl } = useRQMutation(
    {
      url: 'qaip/v1/documentmanagement/fetchpresignedurl',
    },
    {
      enabled: false,
      onSuccess: async (data) => {
        handleSubmitBtnClick();
        await encryptDocumentUrl(data.url, storeFundDetails.logo_file);
        updateDocumentData({ documentData });
      },
    },
  );

  const handleSaveAndNext = async () => {
    const parsedMonthlyReturns = monthlyPriceReturns.map((item: any) => {
      return {
        ...item,
        value: parseFloat(item.value) || 0,
      };
    });

    const parsedAnnualReturns = annualReturns.map((item: any) => {
      return {
        ...item,
        value: parseFloat(item.value) || 0,
      };
    });

    dispatch(setFundPriceAnnualReturns(parsedAnnualReturns));
    dispatch(setFundPriceMonthlyReturns(parsedMonthlyReturns));

    const returnsSinceInception = {
      '1_year': !isNaN(parseFloat(firstYearReturn)) ? parseFloat(firstYearReturn) : null,
      '3_years': !isNaN(parseFloat(thirdYearReturn)) ? parseFloat(thirdYearReturn) : null,
      '5_years': !isNaN(parseFloat(fifthYearReturn)) ? parseFloat(fifthYearReturn) : null,
      '10_years': !isNaN(parseFloat(tenthYearReturn)) ? parseFloat(tenthYearReturn) : null,
      since_inception: !isNaN(parseFloat(sinceInceptionReturn))
        ? parseFloat(sinceInceptionReturn)
        : null,
    };

    await setReturnsSinceInception(returnsSinceInception);
    dispatch(setFundPriceInceptionReturns(returnsSinceInception));

    if (editing) {
      editFund();
      return;
    }

    addMonthlyPriceReturns({ monthly_returns: [...monthlyPriceReturns] });
  };
  console.log('hehe', storeFundDetails);
  const { fund_status, status, ...fundDetails } = storeFundDetails;
  const { refetch: editFund } = useReactQuery(
    [`editFund-${id}`],
    {
      url: `qaip/v1/fundsmanagement/funds/id/${id}`,
      params: {
        ...fundDetails,
        ...storeFundStructAndStats,
        ...storeFundsTandSProviders,
        annual_returns: annualReturns,
        monthly_returns: monthlyPriceReturns,
        returns_since_inception: returnsSinceInceptionReturns,
      },
      method: QueryType.PUT,
    },
    {
      enabled: false,
      onSuccess: () => {
        queryClient.refetchQueries(`fundDetails-${id}`);
        queryClient.refetchQueries(`clientsWithApprovalPending${user?.userId}`);
        const objectKey = {
          object_key: `${id}/logo/${storeFundDetails.logo}`,
        };
        const newDocumentData = {
          ...documentData,
          doc_name: storeFundDetails.logo,
          doc_category_id: id,
          object_key: objectKey.object_key,
        };
        fetchPresignedUrl(objectKey);
        setDocumentData(newDocumentData);
      },
    },
  );

  const handleYearChange = (date: any, toggle: string) => {
    setOpenDatePicker(false);
    if (toggle === 'Monthly') {
      setYearDateInMonthlyTab(date);

      const selectedYear = date.getFullYear();
      const yearsInMonthlyTab = [...allYearsInMonthlyTab];

      if (yearsInMonthlyTab.includes(selectedYear)) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'This year is already present',
        };
        dispatch(showToast(toast));
        return;
      } else {
        yearsInMonthlyTab.push(selectedYear);
        const sortedYearsInMonthlyTab = yearsInMonthlyTab.sort((a, b) => a - b);
        setAllYearsInMonthlyTab(sortedYearsInMonthlyTab);

        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: `${selectedYear} year has been added in the dropdown.`,
        };
        dispatch(showToast(toast));

        const currentMonthlyPriceReturns = [...monthlyPriceReturns];
        const newMonthlyPriceReturns = createMonthForYears(selectedYear);
        sortMonthlyPriceReturns([...currentMonthlyPriceReturns, ...newMonthlyPriceReturns]);
      }
    } else {
      setYearDateInAnnualyTab(date);

      const yearsInYearlyTab = [...allYearsInYearlyTab];

      const selectedYear = date.getFullYear();

      if (yearsInYearlyTab.includes(selectedYear)) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'This year is already present',
        };
        dispatch(showToast(toast));
        return;
      } else {
        yearsInYearlyTab.push(selectedYear);
        const sortedYearsInYearlyTab = yearsInYearlyTab.sort((a, b) => a - b);
        setAllYearsInYearlyTab(sortedYearsInYearlyTab);

        const newAnnualReturns = [...annualReturns];
        newAnnualReturns.push({
          year: selectedYear,
          value: 0,
        });

        const sortedNewAnnualReturns = newAnnualReturns.sort((a, b) => a.year - b.year);

        setAnnualReturns(sortedNewAnnualReturns);
      }
    }
  };

  const handleAddMonth = (date: any) => {
    setMonthlyDateInMonthlyTab(date);

    const selectedMonth = date.getMonth() + 1;
    const currentPresentMonths = [...allMonthsInMonthlyTab];
    const currentMonthlyPriceReturns = [...monthlyPriceReturns];

    if (currentPresentMonths.includes(selectedMonth)) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'This month is already present',
      };
      dispatch(showToast(toast));
      setOpenMonthlyDatePicker(false);
      return;
    }

    currentMonthlyPriceReturns.push({
      year: yearInMonthlyTab,
      month: selectedMonth,
      value: 0,
    });

    sortMonthlyPriceReturns(currentMonthlyPriceReturns);
    setOpenMonthlyDatePicker(false);
  };

  const handleDeleteMonth = (index: number) => {
    const currentMonthlyPriceReturns = [...monthlyPriceReturns];
    currentMonthlyPriceReturns.splice(index, 1);

    sortMonthlyPriceReturns(currentMonthlyPriceReturns);
    fetchAllMonthsForAYear(currentMonthlyPriceReturns);
  };

  const handleDeleteYear = (toggle: string, year: any) => {
    if (toggle === 'Monthly') {
      const newYearsInMonthlyTab = [...allYearsInMonthlyTab];
      if (newYearsInMonthlyTab.length === 1) {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'You cannot have less than 1 year.',
        };
        dispatch(showToast(toast));
        return;
      }
      const reqIndex = newYearsInMonthlyTab.indexOf(year);
      if (reqIndex > -1) {
        newYearsInMonthlyTab.splice(reqIndex, 1);
      }
      setAllYearsInMonthlyTab(newYearsInMonthlyTab);
      setYearInMonthlyTab(newYearsInMonthlyTab[0]);

      // delete all the instances of that particular year from monthly returns
      const oldMonthlyPriceReturns = [...monthlyPriceReturns];
      const newMonthlyPriceReturns = oldMonthlyPriceReturns.filter((el) => el.year !== year);
      setMonthlyPriceReturns(newMonthlyPriceReturns);
    } else {
      const oldAnnualReturns = [...annualReturns];
      const oldYearsInYearlyTab = [...allYearsInYearlyTab];
      const newFilteredAnnualReturns = oldAnnualReturns.filter((el: any) => el.year !== year);
      const newYearsInYearlyTab = oldYearsInYearlyTab.filter((el: any) => el !== year);
      setAllYearsInYearlyTab(newYearsInYearlyTab);
      setAnnualReturns(newFilteredAnnualReturns);
    }
  };

  const handleBtnClick: any = (type: string) => {
    const actions: any = {
      edit: () => setInEditingMode(true),
      cancel: () => setInEditingMode(false),
      save: () => {
        setOpenDatePicker(false);
        handleSaveData();
      },
      new: () => {
        setOpenDatePicker((prevValue) => !prevValue);
      },
    };

    return actions[type]();
  };

  const handleDiscardChanges = () => {
    dispatch(setFundsDetails(null));
    dispatch(setFundStructAndStats(null));
    dispatch(setFundTermsAndServices(null));
    dispatch(setFundPriceAnnualReturns(null));
    dispatch(setFundPriceMonthlyReturns(null));
    dispatch(setFundPriceInceptionReturns(null));
    if (editing) {
      navigate(`/investment/${id}`);
    } else {
      navigate(`/investments`);
    }
  };

  const handleGoBack = async () => {
    const parsedMonthlyReturns = monthlyPriceReturns.map((item: any) => {
      return {
        ...item,
        value: parseFloat(item.value) || 0,
      };
    });

    const parsedAnnualReturns = annualReturns.map((item: any) => {
      return {
        ...item,
        value: parseFloat(item.value) || 0,
      };
    });

    await dispatch(setFundPriceAnnualReturns(parsedAnnualReturns));
    await dispatch(setFundPriceMonthlyReturns(parsedMonthlyReturns));

    const returnsSinceInception = {
      '1_year': !isNaN(parseFloat(firstYearReturn)) ? parseFloat(firstYearReturn) : null,
      '3_years': !isNaN(parseFloat(thirdYearReturn)) ? parseFloat(thirdYearReturn) : null,
      '5_years': !isNaN(parseFloat(fifthYearReturn)) ? parseFloat(fifthYearReturn) : null,
      '10_years': !isNaN(parseFloat(tenthYearReturn)) ? parseFloat(tenthYearReturn) : null,
      since_inception: !isNaN(parseFloat(sinceInceptionReturn))
        ? parseFloat(sinceInceptionReturn)
        : null,
    };

    await dispatch(setFundPriceInceptionReturns(returnsSinceInception));

    handleBackBtnClick();
  };

  const handlePriceInputChange = (value: string, index: number, type: string) => {
    if (type === 'annually') {
      let newAnnualReturns = [...annualReturns];
      let newAnnualReturnObj = { ...annualReturns[index] };
      newAnnualReturnObj.value = /^-?[0-9]*\.?[0-9]*$/.test(value)
        ? value
        : newAnnualReturnObj.value;
      newAnnualReturns[index] = newAnnualReturnObj;

      setAnnualReturns(newAnnualReturns);
    } else {
      let newMonthlyReturns = [...monthlyPriceReturns];
      let newMonthlyReturnObj = { ...monthlyPriceReturns[index] };
      newMonthlyReturnObj.value = /^-?[0-9]*\.?[0-9]*$/.test(value)
        ? value
        : newMonthlyReturnObj.value;
      newMonthlyReturns[index] = newMonthlyReturnObj;

      setMonthlyPriceReturns(newMonthlyReturns);
    }
  };

  const handleSaveData = async () => {
    setInEditingMode(false);
  };

  const handleKeyDownNumber = (e: any) => {
    if (!/^-?[0-9]*\.?[0-9]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <StyledWrapper>
      <Container maxWidth='md' className='container-lr-padding my-5'>
        <Container maxWidth='lg' className='px-0'>
          <h2 className='heading'>Historical Returns</h2>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0'>
          <Grid container className='my-5'>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color='primary'
                value={toggle}
                exclusive
                fullWidth
                className='monthYearToggle'
                onChange={(e: any) => {
                  setToggle(e.target.value);
                  setOpenDatePicker(false);
                  setOpenMonthlyDatePicker(false);
                }}
              >
                <ToggleButton value='Monthly'>Monthly</ToggleButton>
                <ToggleButton value='Yearly'>Yearly</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            className='my-5'
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs={7} className='flex-center-start'>
              <h4 className='sub-heading mb-0'>RATE OF RETURN {toggle === 'Monthly' && 'FOR'}</h4>
              {toggle === 'Monthly' && (
                <>
                  <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='ml-2'
                  >
                    {allYearsInMonthlyTab.length === 0 && 'Add New Year'} {yearInMonthlyTab}{' '}
                    <DownArrow sx={{ fontSize: '1rem' }} />
                  </Button>
                  <Menu
                    className='dropdown-menu'
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem>
                      {toggle === 'Monthly' && (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StyledDatePicker
                            className='date-picker'
                            views={['year']}
                            inputFormat={'yyyy'}
                            value={yearDateInMonthlyTab}
                            onChange={(date) => handleYearChange(date, 'Monthly')}
                            open={openDatePicker}
                            maxDate={new Date()}
                            PopperProps={{ className: 'styled-date-picker' }}
                            renderInput={(params) => {
                              return (
                                <div ref={params.inputRef}>
                                  <MuiButton
                                    variant='text'
                                    color='primary'
                                    className='action-btns'
                                    ref={addNewForYear}
                                    buttonClick={() => handleBtnClick('new')}
                                  >
                                    Add New
                                  </MuiButton>
                                </div>
                              );
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </MenuItem>
                    {allYearsInMonthlyTab.map((el) => (
                      <MenuItem onClick={(e: any) => handleClose(e)}>
                        {el}{' '}
                        <DeleteIcon
                          className='delete-icon ml-3'
                          onClick={() => handleDeleteYear('Monthly', el)}
                        />
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
            </Grid>

            <Grid item xs={5} className='flex-center-end'>
              {!inEditingMode && (
                <MuiButton
                  variant='outlined'
                  className='action-btns'
                  buttonClick={() => handleBtnClick('edit')}
                >
                  Edit
                </MuiButton>
              )}
              {inEditingMode && (
                <>
                  <MuiButton
                    variant='text'
                    className='action-btns'
                    buttonClick={() => handleBtnClick('cancel')}
                  >
                    Cancel
                  </MuiButton>
                  {toggle === 'Yearly' && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className='date-picker'
                        views={['year']}
                        inputFormat={'yyyy'}
                        value={yearDateInAnnualyTab}
                        open={openDatePicker}
                        onChange={(date) => handleYearChange(date, 'Yearly')}
                        minDate={new Date('2005/01/01')}
                        maxDate={new Date()}
                        PopperProps={{ className: 'styled-date-picker-yearly' }}
                        renderInput={(params) => (
                          <div ref={params.inputRef}>
                            <MuiButton
                              variant='text'
                              color='primary'
                              className='action-btns'
                              buttonClick={() => handleBtnClick('new')}
                            >
                              Add New
                            </MuiButton>
                          </div>
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {toggle === 'Monthly' && (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StyledDatePicker
                        className='date-picker'
                        views={['month']}
                        inputFormat={'MM'}
                        value={monthlyDateInMonthlyTab}
                        onChange={(date) => handleAddMonth(date)}
                        open={openMonthlyDatePicker}
                        maxDate={yearInMonthlyTab === new Date().getFullYear() ? new Date() : null}
                        PopperProps={{ className: 'styled-date-picker-monthly' }}
                        renderInput={(params) => (
                          <div ref={params.inputRef}>
                            <MuiButton
                              variant='text'
                              color='primary'
                              className='action-btns'
                              ref={addNewForMonth}
                              buttonClick={() =>
                                setOpenMonthlyDatePicker((prevValue) => !prevValue)
                              }
                            >
                              Add New Month
                            </MuiButton>
                          </div>
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  <MuiButton
                    variant='contained'
                    className='action-btns'
                    buttonClick={() => handleBtnClick('save')}
                  >
                    Save
                  </MuiButton>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth='lg' className='my-5 px-0 table'>
          <Grid container className='table-head'>
            <Grid item xs={6}>
              <h4 className='sub-heading sub-heading--table mb-0'>
                {toggle === 'Monthly' ? 'MONTH' : 'YEAR'}
              </h4>
            </Grid>
            <Grid item xs={6}>
              <h4 className='sub-heading sub-heading--table mb-0'>RETURN</h4>
            </Grid>
          </Grid>
          {(toggle === 'Monthly' ? filteredMonthlyPriceReturns : annualReturns)?.map(
            (el: any, i: number) => (
              <>
                <Grid
                  container
                  className='py-3 table-row'
                  key={el}
                  sx={{ px: 4 }}
                  alignItems='center'
                >
                  <Grid item xs={5} className='flex-center-start'>
                    <h4 className='sub-heading sub-heading--tableBody mb-0'>
                      {toggle === 'Monthly' ? `${monthNames[el.month - 1]}` : el.year}
                    </h4>
                  </Grid>
                  <Grid item xs={5}>
                    <Textbox
                      value={
                        toggle === 'Monthly'
                          ? monthlyPriceReturns[i + filteredYearIndex]?.value
                          : annualReturns[i]?.value
                      }
                      fullWidth
                      className='mt-0'
                      disabled={!inEditingMode}
                      type='text'
                      keyDownFunc={handleKeyDownNumber}
                      startAdornment='%'
                      onChange={(e: any) =>
                        handlePriceInputChange(
                          e.target.value,
                          toggle === 'Monthly' ? i + filteredYearIndex : i,
                          toggle === 'Monthly' ? 'monthly' : 'annually',
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {toggle === 'Yearly' && (
                      <DeleteIcon
                        className='delete-icon ml-3'
                        onClick={() => handleDeleteYear('Yearly', el.year)}
                      />
                    )}
                    {toggle === 'Monthly' && (
                      <DeleteIcon
                        className='delete-icon ml-3'
                        onClick={() => handleDeleteMonth(i + filteredYearIndex)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider />
              </>
            ),
          )}
        </Container>
        {toggle === 'Yearly' && (
          <Container maxWidth='lg' className='my-5 px-0'>
            <Grid container spacing={1} className='mt-3'>
              <Grid item>
                <h4 className='sub-heading'>INCEPTION RETURNS</h4>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-3'>
              <Grid item xs={6}>
                <h4 className='sub-heading sub-heading--table'>1 Year</h4>
              </Grid>
              <Grid item xs={6}>
                <Textbox
                  value={firstYearReturn === null ? '' : firstYearReturn}
                  disabled={!inEditingMode}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  startAdornment='%'
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setFirstYearReturn(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-3'>
              <Grid item xs={6}>
                <h4 className='sub-heading sub-heading--table'>3 Year</h4>
              </Grid>
              <Grid item xs={6}>
                <Textbox
                  value={thirdYearReturn === null ? '' : thirdYearReturn}
                  disabled={!inEditingMode}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  startAdornment='%'
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setThirdYearReturn(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-3'>
              <Grid item xs={6}>
                <h4 className='sub-heading sub-heading--table'>5 Year</h4>
              </Grid>
              <Grid item xs={6}>
                <Textbox
                  value={fifthYearReturn === null ? '' : fifthYearReturn}
                  disabled={!inEditingMode}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  startAdornment='%'
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setFifthYearReturn(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-3'>
              <Grid item xs={6}>
                <h4 className='sub-heading sub-heading--table'>10 Year</h4>
              </Grid>
              <Grid item xs={6}>
                <Textbox
                  value={tenthYearReturn === null ? '' : tenthYearReturn}
                  disabled={!inEditingMode}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  startAdornment='%'
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setTenthYearReturn(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='mt-3'>
              <Grid item xs={6}>
                <h4 className='sub-heading sub-heading--table'>Since Inception</h4>
              </Grid>
              <Grid item xs={6}>
                <Textbox
                  value={sinceInceptionReturn === null ? '' : sinceInceptionReturn}
                  disabled={!inEditingMode}
                  type='text'
                  keyDownFunc={handleKeyDownNumber}
                  startAdornment='%'
                  onChange={(e: any) => {
                    if (/^-?[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                      setSinceInceptionReturn(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        )}
        <Grid container justifyContent='flex-end'>
          <MuiButton variant='text' buttonClick={handleGoBack}>
            Back
          </MuiButton>
          <MuiButton
            variant='text'
            color='error'
            className='ml-3'
            buttonClick={handleDiscardChanges}
          >
            Discard Changes
          </MuiButton>
          <MuiButton
            minWidth='150px'
            className='ml-3'
            variant='contained'
            buttonClick={handleSaveAndNext}
            disabled={inEditingMode}
          >
            {(monthlyReturnsLoading ||
              annualReturnsLoading ||
              isFetchingPredesignUrl ||
              isUploadingDoc) &&
            !editing
              ? 'Saving...'
              : 'Save'}
          </MuiButton>
        </Grid>
      </Container>
    </StyledWrapper>
  );
};

export default InvestmentHistoricalReturnsForm;
