import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './slice';

const orderCreationSlice = (state: RootState) => state.order || initialState;

export const selectNewOrderDetails = createSelector(
  [orderCreationSlice],
  (state) => state.orderDetails,
);
export const selectNewOrderDocs = createSelector([orderCreationSlice], (state) => state.orderDocs);
export const selectOrderActiveStep = createSelector(
  [orderCreationSlice],
  (state) => state.orderActiveStep,
);
export const selectOrderAllocationDetails = createSelector(
  [orderCreationSlice],
  (state) => state.allocationOrdersDetails,
);
export const selectOrderSubmittedModalOpen = createSelector(
  [orderCreationSlice],
  (state) => state.orderSubmittedModalOpen,
);
export const selectCurrentOrderActivityTab = createSelector(
  [orderCreationSlice],
  (state) => state.currentTab,
);
export const selectPreviousOrderActivityTab = createSelector(
  [orderCreationSlice],
  (state) => state.previousTab,
);

export const selectHasNewNotifications = createSelector(
  [orderCreationSlice],
  (state) => state.hasNewNotifications,
);
export const selectMyOpenOrders = createSelector(
  [orderCreationSlice],
  (state) => state.myOpenOrders,
);
export const selectMyCompletedOrders = createSelector(
  [orderCreationSlice],
  (state) => state.myCompletedOrders,
);
export const selectMyCancelledOrder = createSelector(
  [orderCreationSlice],
  (state) => state.myCancelledOrders,
);
