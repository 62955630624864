import { Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import LoadingButton from 'common/components/button/LoadingButton';
import { ErrorMessage } from 'common/components/errorMessageBox';
import SelectField from 'common/components/inputField/SelectField';
import Textbox from 'common/components/inputField/Textbox';
import MuiModal from 'common/components/modal';
import { OrderStatusType } from 'common/types';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface Props {
  isModalOpen: boolean;
  handleClose: any;
  isCancel: boolean;
  setCancelReason?: any;
  setOrderStatus: any;
  setCancelRejectOrderComment: any;
  cancelRejectOrderComment: string;
  ctaClicked: string;
  isUpdating: boolean;
}

const CancelRejectOrderModal = ({
  isModalOpen,
  handleClose,
  isCancel,
  setCancelReason,
  setOrderStatus,
  setCancelRejectOrderComment,
  cancelRejectOrderComment,
  ctaClicked,
  isUpdating,
}: Props) => {
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const CANCEL_OPTIONS: Array<string> = [
    'Incorrect Fund',
    'Incorrect Amount',
    'Incorrect Client',
    'Client Changed Their Mind',
    'Other',
  ];

  const [selectedReason, setSelectedReason] = useState<string>('');
  const [spaceCheck, setSpaceCheck] = useState<boolean>(false);

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (isCancel) {
      setCancelReason(data.cancelReason);
      if (data.cancelReason === 'Other') {
        if (data.specificReason.trim().length > 0) {
          setSpaceCheck(false);
          setCancelRejectOrderComment(data.specificReason);
        } else setSpaceCheck(true);
      } else {
        setCancelRejectOrderComment(data.cancelReason);
      }
    } else {
      if (data.specificReason.trim().length > 0) {
        setSpaceCheck(false);
        setCancelRejectOrderComment(data.specificReason);
      } else setSpaceCheck(true);
    }
  };

  useEffect(() => {
    if (cancelRejectOrderComment !== '') {
      setOrderStatus(isCancel ? OrderStatusType.ORDER_CANCELLED : OrderStatusType.ORDER_REJECTED);
    }
    //eslint-disable-next-line
  }, [cancelRejectOrderComment, isCancel]);

  return (
    <MuiModal
      title={`Do you want to ${isCancel ? 'cancel' : 'reject'} the order?`}
      isModalOpen={isModalOpen}
      handleClose={() => {
        reset();
        setSelectedReason('');
        handleClose();
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container mt={6}>
          <Grid item xs={12}>
            <h4 className='font-wt-400 mb-1'>
              {' '}
              Reason for {isCancel ? 'Cancellation' : 'Rejection'}
            </h4>
          </Grid>
          {isCancel && (
            <Grid item xs={12} mb={isCancel && selectedReason === 'Other' ? 2 : 8}>
              <SelectField
                name='cancelReason'
                options={CANCEL_OPTIONS}
                searchIcon={false}
                value={selectedReason}
                register={register}
                onChange={(e) => setValue('cancelReason', e.target.value)}
                required
                errorMessage='This field is required'
                setSelectedValue={setSelectedReason}
                className='mb-1'
              />
              {errors.cancelReason && <ErrorMessage error={'This field is required'} />}
            </Grid>
          )}
          {!isCancel || (isCancel && selectedReason === 'Other') ? (
            <Grid item xs={12} mb={8}>
              <Textbox
                name='specificReason'
                placeholder='Specify Reason'
                errorMessage='This field is required'
                register={register}
                multiline
                fullWidth
                rows={5}
                required
                className='mb-1'
              />
              {errors.specificReason && <ErrorMessage error='This field is required' />}
              {spaceCheck && <ErrorMessage error='This field is required' />}
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} className='flex-center-end'>
            <MuiButton
              buttonClick={() => {
                reset();
                setSelectedReason('');
                handleClose();
              }}
            >
              Do Not {isCancel ? 'Cancel' : 'Reject'}
            </MuiButton>
            {isUpdating && (ctaClicked === 'Cancel' || ctaClicked === 'Reject') ? (
              <LoadingButton
                variant='text'
                className='ml-3'
                color='error'
                minWidth={isCancel ? '105px' : '100px'}
              />
            ) : (
              <MuiButton type='submit' className='ml-3' color='error'>
                {isCancel ? 'Cancel' : 'Reject'} Order
              </MuiButton>
            )}
          </Grid>
        </Grid>
      </form>
    </MuiModal>
  );
};

export default CancelRejectOrderModal;
