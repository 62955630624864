import { PayloadAction } from '@reduxjs/toolkit';
import { AuthSlice, AuthUser, RequestLoader, UserDetailsResponseDTO, UserProfile } from './types';

const reducers = {
  initiateAuthFetch: (state: AuthSlice) => {
    state.requestStatus = RequestLoader.LOADING;
  },
  setAuth: (state: AuthSlice, action: PayloadAction<AuthUser | null>) => {
    state.authUser = action.payload;
  },
  initiateProfileFetch: (state: AuthSlice) => {
    state.requestStatus = RequestLoader.LOADING;
  },
  setForgotPasswordActiveStepper: (state: AuthSlice, action: PayloadAction<number>) => {
    state.forgotPasswordActiveStepper = action.payload;
  },
  setProfile: (
    state: AuthSlice,
    action: PayloadAction<UserProfile | null>,
  ) => {
    state.userProfile = action.payload;
  },
  setBehalfOf: (
    state: AuthSlice,
    action: PayloadAction<UserProfile | null>,
  ) => {
    state.behalfOf = action.payload;
  },
  updateProfile: (state: AuthSlice, action: PayloadAction<{ profile: UserDetailsResponseDTO & UserProfile }>) => {
    state.userProfile = {
      ...state.userProfile,
      ...action.payload.profile,
    };
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  setAuthFailed: (state: AuthSlice) => {
    state.authUser = null;
    state.userProfile = null;
    state.behalfOf = null;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('behalfOf');
  },
};
export default reducers;
