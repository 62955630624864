import { Container, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import SearchBar from 'common/components/inputField/SearchBar';
import SelectField from 'common/components/inputField/SelectField';
import SubHeader from 'common/components/layout/SubHeader';
import Loading from 'common/components/Loading';
import MuiTable from 'common/components/table/Table';
import { entityDropdownList } from 'common/constants';
import { EntityType } from 'common/types';
import CreateUpdateUserProfileModal from 'components/modals/userModal/CreateUpdateUserModal';
import { useEffect, useState } from 'react';
import { useAppSelector, useReactQuery } from 'store/hooks';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';
import { COLUMNS, CRUMBS } from './constants';

const Users = () => {
  const [selectedEntity, setSelectedEntity] = useState<any>(entityDropdownList[0]);
  const [selectedName, setSelectedName] = useState<any>(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState<any>(false);
  const [users, setUsers] = useState<Array<any>>([]);
  const [userData, setUserData] = useState<any>(null);
  const [filteredUsers, setFilteredUsers] = useState<Array<any>>([]);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const userProfile = useAppSelector(selectUserProfile);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;
  const isQualisUser = user?.category === EntityType.QUALIS;
  const orgId = !isQualisUser ? user?.organisationId : selectedName?.organisationId;

  const { data, refetch, isLoading } = useReactQuery(
    [`subCategory${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/organisations?categoryId=${selectedEntity?.id}`,
    },
    {
      enabled: selectedEntity !== null,
    },
  );

  const {
    data: usersData,
    refetch: refetchUsers,
    isLoading: userLoading,
    isRefetching: isUserRefetching,
  } = useReactQuery(
    [`users${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/qualisuser/users/organisation?organisationId=${orgId}`,
    },
    {
      enabled: false,
      onSuccess: (data) => {
        refetchRoles();
        refetchTeams();
        if (!Array.isArray(data)) {
          setUsers([]);
          setFilteredUsers([]);
        }
      },
    },
  );

  useEffect(() => {
    usersData &&
      usersData.length > 0 &&
      setUsers(
        usersData.map((u: any) => {
          return {
            ...u,
            statusFormatted: u.active ? 'Active' : 'Inactive',
            role: u.teamsRolesDetails[0]?.roleName,
          };
        }),
      );
  }, [usersData]);

  const { data: rolesData, refetch: refetchRoles } = useReactQuery(
    [`roles${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/roles/organisation?organisationId=${
        !isQualisUser ? user?.organisationId : selectedName?.organisationId
      }`,
    },
    {
      enabled: false,
    },
  );

  const { data: teamsData, refetch: refetchTeams } = useReactQuery(
    [`teams${user?.userId}`],
    {
      url: `qaip/v1/usermanagement/teams/organisation?organisationId=${
        !isQualisUser ? user?.organisationId : selectedName?.organisationId
      }`,
    },
    {
      enabled: false,
    },
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [selectedEntity]);

  const handleModalClick = () => {
    setIsUserModalOpen(!isUserModalOpen);
  };

  // --- Search ---
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    let arr: Array<any> = users;
    users &&
      users.length > 0 &&
      setFilteredUsers(
        arr.filter((row: any) => {
          for (let prop in row) {
            if (String(row[prop]).toLowerCase().includes(searchQuery.toLowerCase())) return true;
          }
          return false;
        }),
      );
    // eslint-disable-next-line
  }, [searchQuery, users]);

  if (isLoading) return <Loading />;

  return (
    <Grid container mt={8}>
      <Grid item xs={12}>
        <SubHeader crumbs={CRUMBS} className='activityList'>
          <Grid container justifyContent='space-between' alignItems='flex-end'>
            <Grid item xs='auto'>
              <h1 className='mb-0 mr-20'>Users</h1>
            </Grid>
            <Grid item xs='auto'>
              <SearchBar
                minWidth='275px'
                className='mr-20'
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              {selectedName && (
                <MuiButton
                  variant='contained'
                  buttonClick={() => {
                    setUserData(null);
                    handleModalClick();
                  }}
                >
                  Add New User
                </MuiButton>
              )}
            </Grid>
          </Grid>
        </SubHeader>
      </Grid>
      <Grid item xs={12} sx={{ pt: 2 }}>
        <Container maxWidth='xl' className='container-lr-padding'>
          <Grid container>
            {isQualisUser && (
              <>
                <Grid item xs={2} sx={{ mr: '14px' }}>
                  <SelectField
                    placeholder='Entity'
                    options={entityDropdownList}
                    setSelectedValue={setSelectedEntity}
                    optionName='name'
                    optionId='id'
                    name='entity'
                    onChange={() => {
                      setSelectedName(null);
                    }}
                    defaultValue={selectedEntity.id}
                    value={selectedEntity.id}
                    searchIcon={false}
                    className='adminSelect'
                    MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
                  />
                </Grid>
                <Grid item xs={2}>
                  {!isLoading && data.length > 0 && (
                    <SelectField
                      placeholder='Name'
                      options={
                        data &&
                        [...data].sort((a: any, b: any) =>
                          a.organisationName.localeCompare(b.organisationName),
                        )
                      }
                      setSelectedValue={async (value: any) => {
                        await setSelectedName(value);
                        refetchUsers();
                      }}
                      optionName='organisationName'
                      optionId='organisationId'
                      name='name'
                      defaultValue={selectedName?.organisationId}
                      value={selectedName?.organisationId}
                      searchIcon={false}
                      className='adminSelect'
                      MenuProps={{ sx: { li: { fontSize: '11px !important' } } }}
                    />
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={4}>
              <MuiTable
                columns={COLUMNS}
                rows={filteredUsers}
                isLoading={userLoading || isUserRefetching}
                disableHeader
                rowsPerPage={10}
                ctaType='updateUserProfile'
                handleCtaClick={(user: any) => {
                  setIsUserModalOpen(!isUserModalOpen);
                  setUserData(user);
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <CreateUpdateUserProfileModal
          isUserModalOpen={isUserModalOpen}
          handleModalClick={handleModalClick}
          rolesData={Array.isArray(rolesData) ? rolesData : []}
          teamsData={Array.isArray(teamsData) ? teamsData : []}
          orgId={!isQualisUser ? user?.organisationId : selectedName?.organisationId}
          existingUserData={userData}
          isDG={selectedEntity.id === EntityType.DEALERS_GROUP}
          refetchUsers={refetchUsers}
        />
      </Grid>
    </Grid>
  );
};

export default Users;
