export const CRUMBS = [
  {
    link: '/',
    label: 'Home',
  },
  {
    link: '',
    label: 'Activity',
  },
];

export const QUALIS_USER_COLUMNS = [
  {
    id: 'orderId',
    label: 'ID',
    needsFormatter: false,
    width: '5%',
  },
  {
    id: 'accountName',
    label: 'INVESTOR',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'fundName',
    label: 'Fund Name',
    needsFormatter: false,
    width: '15%',
  },
  {
    id: 'assetClass',
    label: 'CLASS',
    needsFormatter: false,
    width: '7%',
  },
  {
    id: 'eventAmount',
    label: 'AMOUNT',
    needsFormatter: true,
    align: 'right' as const,
    width: '8%',
  },
  {
    id: 'eventName',
    label: 'EVENT',
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'eventDateFormatted',
    label: 'EVENT DATE',
    needsFormatter: false,
    isDate: true,
    width: '10%',
  },
  {
    id: 'statusName',
    label: 'STATUS',
    showProgress: true,
    needsFormatter: false,
    width: '10%',
  },
  {
    id: 'behalfOf',
    label: 'Behalf Of',
    needsFormatter: false,
    width: '8%',
  },
  {
    id: 'lastUpdatedBy',
    label: 'Last Updated By',
    needsFormatter: false,
  },
  {
    id: 'lastUpdatedDate',
    label: 'Last Updated',
    needsFormatter: false,
    isDate: true,
  },
];

export const COLUMNS = QUALIS_USER_COLUMNS.filter((col: any) => col.id !== 'behalfOf');
