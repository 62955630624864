import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./slice";

const investorCreationSlice = (state: RootState) => state.investor || initialState;

export const selectInvestorActiveStep = createSelector([investorCreationSlice], (state) => state.investorActiveStep);
export const selectInvestorProfileStep = createSelector([investorCreationSlice], (state) => state.investorProfileStep);
export const selectNewInvestorId = createSelector([investorCreationSlice], (state) => state.newInvestorId);
export const selectClientDetails = createSelector([investorCreationSlice], (state) => state.clientDetails);
export const selectAccountHolderDetails = createSelector([investorCreationSlice], (state) => state.investorAccountHolderDetails);
export const selectAuthRepresentativeDetails = createSelector([investorCreationSlice], (state) => state.authRepresentativeDetails);
export const selectWireTransfferBankDetails = createSelector([investorCreationSlice], (state) => state.wireTransfferBankDetails);
export const selectCustodianDetails = createSelector([investorCreationSlice], (state) => state.custodianDetails);
export const selectEntityDetails = createSelector([investorCreationSlice], (state) => state.entityDetails);
export const selectStoreAawceArr = createSelector([investorCreationSlice], (state) => state.storeAawceArr);
export const selectClientModalOpen = createSelector([investorCreationSlice], (state) => state.clientModalOpen);





