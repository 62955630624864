import { CheckCircle } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';

const Documents = ({ docs, handleDocumentModalClick }: any) => {
  if (!docs) return null;
  return (
    <section>
      <h3>
        Documents{' '}
        <MuiButton className='py-0' buttonClick={() => handleDocumentModalClick()}>
          Edit
        </MuiButton>
      </h3>
      <Divider />
      {docs.map((doc: any) =>
        doc?.documentsResponseDto?.length > 1 ? (
          doc?.documentsResponseDto?.map((dto: any) => (
            <Grid item container>
              <Grid item xs={5} pr={2}>
                {dto?.docClassificationName}
              </Grid>
              <Grid item xs={7} className='docFile flex-center-start'>
                <CheckCircle className='mr-2' />
                {dto?.docName}
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item container>
            <Grid item xs={5} pr={2}>
              {doc?.title}
            </Grid>
            <Grid item xs={7} className='docFile flex-center-start'>
              <CheckCircle className='mr-2' />
              {doc?.documentsResponseDto[0]?.docName}
            </Grid>
          </Grid>
        ),
      )}
    </section>
  );
};

export default Documents;
