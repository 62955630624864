import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import AccountHolder from './Blocks/AccountHolder';
import AuthorizedRepresentative from './Blocks/AuthorizedRepresentative';
import Custodian from './Blocks/Custodian';
import Entity from './Blocks/Entity';
import WireTransfferBank from './Blocks/WireTransfferBank';

type Props = {
  entityTab: any;
  accountHolderArr: Array<any>;
  handleAawceChange: Function;
  register: any;
  reset: any;
  errors: any;
  handleAawceRemove: Function;
  handleAddWireTransfferBankDetails: Function;
  handleAddAccountHolderDetails: Function;
  wireTransfferBankArr: Array<any>;
  aawceArr: Array<any>;
  entityArr: Array<any>;
  handleAddEntity: Function;
  custodianArr: Array<any>;
  authorizedRepresentativeArr: Array<any>;
  handleAddCustodian: Function;
  handleAddAuthorizedRepresentativeDetails: Function;
  accountHolderState: any;
  setAccountHolderState: any;
  authRepresentativeState: any;
  setAuthRepresentativeState: any;
  custodianState: any;
  setCustodianState: any;
  entityState: any;
  setEntityState: any;
  wireTransferState: any;
  setWireTransferState: any;
};

const Accordians = ({
  entityTab,
  accountHolderArr,
  handleAawceChange,
  register,
  reset,
  errors,
  handleAawceRemove,
  handleAddWireTransfferBankDetails,
  handleAddAccountHolderDetails,
  wireTransfferBankArr,
  aawceArr,
  entityArr,
  handleAddEntity,
  custodianArr,
  authorizedRepresentativeArr,
  handleAddCustodian,
  handleAddAuthorizedRepresentativeDetails,
  accountHolderState,
  setAccountHolderState,
  authRepresentativeState,
  setAuthRepresentativeState,
  custodianState,
  setCustodianState,
  entityState,
  setEntityState,
  wireTransferState,
  setWireTransferState,
}: Props) => {
  return (
    <>
      <Grid container item mt={1}>
        <Grid container item>
          <h4 className='font-wt-400 mb-0'>Add More Details</h4>
        </Grid>
        <Grid container item mt={2} sx={{ border: '1px solid #DDDDDD', borderRadius: '8px' }}>
          {(entityTab === 'Other' || entityTab === 'Natural') && accountHolderArr.length < 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Account Holder
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AccountHolder
                    additional={false}
                    register={register}
                    errors={errors}
                    key={`'AccountHolderDetails' -${accountHolderArr.length}`}
                    index={accountHolderArr.length}
                    onAccountDetailsChange={(property: string, idx: number, value: any) =>
                      handleAawceChange(property, idx, value)
                    }
                    onAccountDetailsRemove={(index: number) => handleAawceRemove(index)}
                    handleAddAccountHolderDetails={(details: Object) =>
                      handleAddAccountHolderDetails(false, details)
                    }
                    detailsState={accountHolderState}
                    setDetailsState={setAccountHolderState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {(entityTab === 'Other' || entityTab === 'Natural') && accountHolderArr.length === 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Additional Account Holder
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AccountHolder
                    additional={true}
                    register={register}
                    errors={errors}
                    key={`'AccountHolderDetails' -${accountHolderArr.length}`}
                    index={accountHolderArr.length}
                    onAccountDetailsChange={(property: string, idx: number, value: any) =>
                      handleAawceChange(property, idx, value)
                    }
                    onAccountDetailsRemove={(index: number) => handleAawceRemove(index)}
                    handleAddAccountHolderDetails={(details: Object) =>
                      handleAddAccountHolderDetails(false, details)
                    }
                    detailsState={accountHolderState}
                    setDetailsState={setAccountHolderState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {wireTransfferBankArr.length < 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Wire Transfer Bank
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <WireTransfferBank
                    additional={false}
                    key={`WireTransfferBankDetails-${aawceArr.length}`}
                    register={register}
                    errors={errors}
                    index={aawceArr.length}
                    onWireTransfferChange={(property, idx, value) =>
                      handleAawceChange(property, idx, value)
                    }
                    onWireTransfferRemove={(index) => handleAawceRemove(index)}
                    handleAddWireTransfferBankDetails={(details: Object) =>
                      handleAddWireTransfferBankDetails({ ...details })
                    }
                    detailsState={wireTransferState}
                    setDetailsState={setWireTransferState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {authorizedRepresentativeArr.length < 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Authorized Representative
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AuthorizedRepresentative
                    register={register}
                    errors={errors}
                    key={`AuthorizedRepresentativeDetails-${aawceArr.length}`}
                    index={aawceArr.length}
                    onAuthorizedRepresentativeChange={(property, idx, value) =>
                      handleAawceChange(property, idx, value)
                    }
                    onAuthorizedRepresentativeRemove={(index) => handleAawceRemove(index)}
                    handleAddAuthorizedRepresentativeDetails={(details: Object) =>
                      handleAddAuthorizedRepresentativeDetails({ ...details })
                    }
                    detailsState={authRepresentativeState}
                    setDetailsState={setAuthRepresentativeState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {entityTab === 'Other' && entityArr.length < 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Entity
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Entity
                    additional={false}
                    register={register}
                    key={`Entity-${aawceArr.length}`}
                    errors={errors}
                    index={aawceArr.length}
                    onEntityDetailsChange={(property, idx, value) =>
                      handleAawceChange(property, idx, value)
                    }
                    onEntityDetailsRemove={(index) => handleAawceRemove(index)}
                    reset={reset}
                    handleAddEntity={(details: Object) => handleAddEntity(false, { ...details })}
                    detailsState={entityState}
                    setDetailsState={setEntityState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          {custodianArr.length < 1 && (
            <Grid container item>
              <Accordion style={{ all: 'unset', borderBottom: '1px solid #DDDDDD' }}>
                <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
                  <Typography
                    sx={{ color: '#4590D3 !important', fontSize: '14px', fontWeight: '600' }}
                  >
                    + Custodian
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Custodian
                    additional={true}
                    register={register}
                    key={`CustodianType-${aawceArr.length}`}
                    errors={errors}
                    index={aawceArr.length}
                    onCustodianChange={(property, idx, value) =>
                      handleAawceChange(property, idx, value)
                    }
                    onCustodianRemove={(index) => handleAawceRemove(index)}
                    handleAddCustodian={(details: Object) => handleAddCustodian({ ...details })}
                    detailsState={custodianState}
                    setDetailsState={setCustodianState}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Accordians;
