import { Divider, Grid } from '@mui/material';
import MuiButton from 'common/components/button';
import { useEffect, useState } from 'react';

import { EntityTypeDetails } from 'store/investor/types';

type Props = {
  entityDetails: EntityTypeDetails;
  index: number;
  handleModalClick: any;
};

const Entity = ({ entityDetails, index, handleModalClick }: Props) => {
  const [showSection, setShowSection] = useState(false);

  useEffect(() => {
    if (entityDetails) {
      if (
        entityDetails?.email ||
        entityDetails?.faxNumber ||
        entityDetails?.address ||
        entityDetails?.name ||
        entityDetails?.phoneNumber ||
        entityDetails?.tin
      ) {
        setShowSection(true);
      } else {
        setShowSection(false);
      }
    }
  }, [entityDetails]);

  const formatTINNumber = (ssn: string) => {
    const formattedSSNArr = [];
    formattedSSNArr[0] = '**';
    formattedSSNArr[1] = '***';
    formattedSSNArr[2] = ssn.substring(5, 9);
    return formattedSSNArr.join('-');
  };

  return (
    <section style={{ display: `${showSection ? 'block' : 'none'}` }}>
      <h3>
        {index > 0 && 'Additional'} Entity
        <MuiButton
          className='ml-2 py-0'
          buttonClick={() => {
            handleModalClick();
          }}
        >
          Edit
        </MuiButton>
      </h3>
      <Divider />
      {entityDetails?.name && (
        <Grid item container>
          <Grid item xs={5}>
            Investor Name
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.name ? entityDetails?.name : '--'}</p>
          </Grid>
        </Grid>
      )}
      {(entityDetails?.address || entityDetails?.phoneNumber || entityDetails?.faxNumber) && (
        <Grid item container>
          <Grid item xs={5}>
            Investor Mailing Address
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.address ? entityDetails?.address : '--'}</p>
            <p>
              {entityDetails?.phoneNumber && `Tel: ${entityDetails?.phoneNumber}`}{' '}
              {entityDetails?.faxNumber && `Fax: ${entityDetails?.faxNumber}`}
            </p>
          </Grid>
        </Grid>
      )}
      {entityDetails?.email && (
        <Grid item container>
          <Grid item xs={5}>
            Investor Email
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.email ? entityDetails?.email : '--'}</p>
          </Grid>
        </Grid>
      )}
      {entityDetails?.tin && (
        <Grid item container>
          <Grid item xs={5}>
            Investor TIN
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.tin ? formatTINNumber(entityDetails?.tin) : '--'}</p>
          </Grid>
        </Grid>
      )}
      {entityDetails?.contactName && (
        <Grid item container>
          <Grid item xs={5}>
            Contact Person Name
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.contactName ? entityDetails?.contactName : '--'}</p>
          </Grid>
        </Grid>
      )}
      {entityDetails?.incorporation && (
        <Grid item container>
          <Grid item xs={5}>
            Country of Formation/ Incorporation
          </Grid>
          <Grid item xs={7}>
            <p>{entityDetails?.incorporation ? entityDetails?.incorporation : '--'}</p>
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12} className='btnGroup'>
        <MuiButton>Add Account Holder</MuiButton>
        <MuiButton className='ml-3'>Duplicate Account Holder</MuiButton>
      </Grid> */}
    </section>
  );
};
export default Entity;
