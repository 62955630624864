import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';

export const initialState: any = {
  fundsActiveStep: 0,
  fundsDetails: null,
  fundStructAndStats: null,
  fundTermsAndServices: null,
  fundPriceDetails: null,
  fundPriceAnnualReturns: null,
  fundPriceMonthlyReturns: null,
  fundPriceInceptionReturns: null,
  fundDocuments: null,
  fundID: null,
  investmentStatsArr: [{}],
};

const slice = createSlice({
  name: 'funds',
  initialState,
  reducers: reducers,
});

export const {
    setFundsActiveStep,
    setFundsDetails,
    setFundStructAndStats,
    setFundTermsAndServices,
    setFundPriceDetails,
    setFundPriceAnnualReturns,
    setFundPriceMonthlyReturns,
    setFundPriceInceptionReturns,
    setFundDocuments,
    setNewFundID,
    setInvestmentStats,
} = slice.actions;

export default slice.reducer;
