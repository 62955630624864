import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./slice";
import { RequestLoader } from "./types";

const authSlice = (state: RootState) => state.user || initialState;

export const selectAuthUser = createSelector([authSlice], (state) => state.authUser);

export const selectIsLoading = createSelector(
    [authSlice],
    (state): boolean => !state.requestStatus || state.requestStatus === RequestLoader.NOT_STARTED ||
        state.requestStatus === RequestLoader.LOADING
);

export const selectUserProfile = createSelector(
    [authSlice], (state) => state.userProfile);

export const selectBehalfOf = createSelector(
    [authSlice], (state) => state.behalfOf);

export const selectForgotPasswordActiveStepper = createSelector(
    [authSlice], (state) => state.forgotPasswordActiveStepper);