import MuiButton from 'common/components/button';
import UploadHoldingsModal from 'components/modals/UploadHoldingsModal';
import { exportHoldingsAsExcel } from 'helpers/client';
import { canDownloadHoldings, canUploadHoldings } from 'helpers/roles';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAppSelector, useMutationQuery, useReactQuery, useTypedDispatch } from 'store/hooks';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { selectBehalfOf, selectUserProfile } from 'store/user/selectors';

const HoldingsDownloadUpload = () => {
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const dispatch = useTypedDispatch();
  const userProfile: any = useAppSelector(selectUserProfile);
  const userOnBehalfOf: any = useAppSelector(selectBehalfOf);
  const user = userOnBehalfOf ? userOnBehalfOf : userProfile;

  const { data: investmentData = [] } = useReactQuery(
    [`holdings${user?.organisationId}`],
    {
      url: `qaip/v1/holdingsmanagement/dashboard/${user?.organisationId}`,
    },
    {
      enabled: !!user,
    },
  );

  const { mutate: updateDocumentData, isLoading } = useMutationQuery(
    {
      url: 'qaip/v1/holdingsmanagement/import',
      params: uploadedFile,
      isFileUpload: true,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`clientList${user?.userId}`);
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Holdings data updated.',
        };
        dispatch(showToast(toast));
        setSelectedFile(null);
      },
      onError: () => {
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'An error occured while uploading Holdings. Please try again later.',
        };
        dispatch(showToast(toast));
        setSelectedFile(null);
      },
    },
  );

  useEffect(() => {
    if (uploadedFile) updateDocumentData();
    //eslint-disable-next-line
  }, [uploadedFile]);

  const onFileUpload = () => {
    const uploadedFile: any = selectedFile;
    setUploadedFile(uploadedFile);
    setIsModalOpen(false);
  };

  return (
    <div className='d-flex'>
      {user && canUploadHoldings(user) && (
        <MuiButton disabled={isLoading} buttonClick={() => setIsModalOpen(true)}>
          {isLoading ? 'Loading...' : ' Upload Holdings'}
        </MuiButton>
      )}
      {user && canDownloadHoldings(user) && (
        <MuiButton buttonClick={() => exportHoldingsAsExcel(investmentData)} className='ml-3'>
          Download Holdings
        </MuiButton>
      )}
      <UploadHoldingsModal
        isModalOpen={isModalOpen}
        handleModalClick={() => {
          setIsModalOpen(false);
          setSelectedFile(null);
        }}
        onFileUpload={onFileUpload}
        isLoading={isLoading}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
      />
    </div>
  );
};

export default HoldingsDownloadUpload;
