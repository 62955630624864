import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Loading = () => {
  return (
    <StyledWrapper className="loader-wrapper">
      <CircularProgress color="inherit" />
    </StyledWrapper>
  )
}

export default Loading;

const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);

  .loader-img {
    width: 100px;
    height: 100px;
  }

 `;