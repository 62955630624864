import { FieldErrors } from 'react-hook-form';
import styled from 'styled-components';

export const ErrorMessage = (message: FieldErrors) => {
  if (!message) return null;
  return (
    <Wrapper>
      <span className='d-flex'>
        <svg viewBox='0 0 20 20' fill='currentColor' className='error-icon mr-2'>
          <path
            fillRule='evenodd'
            d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z'
            clipRule='evenodd'
          ></path>
        </svg>
        <span className='error-message justify-center'>{message.error}</span>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .error-icon {
    color: #e53e3e;
    width: 15px;
    height: 15px;
  }

  .error-message {
    color: #e53e3e;
    opacity: 0.8;
    font-size: 12px;
  }
`;
